import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import getPrincipal from '../../utils/getPrincipal'
import moment from 'moment'
import {
	processFlightRotations,
	swapInboundFlights,
	processSchedules,
	getFlightRotations,
	getSegments,
	updateSegments,
	exportPdf,
} from '../../services/scheduleService'
import { Toast } from 'primereact/toast'
import { v4 as uuidv4 } from 'uuid'

import FlightDataDropzone from "./FlightDataDropzone"
import WeekSelector from './WeekSelector'
import FlightRotations from './FlightRotations'
import FlightSegments from './FlightSegments'
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator'
import { Alert } from '../../common/Alert'
import { NOTIFICATION_MESSAGE_TYPE } from '../../constants/'

const UploadFlightData = ({

}) => {
	//for multilingual
	const { t, i18n } = useTranslation(['labels', 'errors'])
	const [file, setFile] = useState(null)

	const scheduleToast = useRef(null)

	const [displayUploadSection, setDisplayUploadSection] = useState(false)
	const [fileList, setFileList] = useState([])
	const [isListPageOpen, setListPageOpen] = useState(false)
	const [fileName, setFileName] = useState('')
	const [flightRotations, setFlightRotations] = useState()
	const [flightRotationsDisplay, setFlightRotationsDisplay] = useState()
	const [currentIndex, setCurrentIndex] = useState(100)

	const [weekData, setWeekData] = useState('')
	const [startDate, setStartDate] = useState()
	const [endDate, setEndDate] = useState()
	const [month, setMonth] = useState('');

	const [segments, setSegments] = useState()

	const [isLoading, setLoading] = useState(false)

	const setDropDownFileList = (list) => {
		setFileList(list)
	}
	const [fileData, setFileData] = useState()
	const [pageNumber, setPageNumber] = useState(1)
	const [selectedFlights, setSelectedFlights] = useState([])
	const [selectedCount, setSelectedCount] = useState(0)

	const [unSelectedSegments, setUnSelectedSegments] = useState([])
	const [unSelectedSegmentsCount, setUnSelectedSegmentsCount] = useState(0)
	const [alertMessage, setAlertMessage] = useState('')
	const [status, setStatus] = useState(null)

	const setInitialList = async () => {
		const res = await getPermanentBookingFileList()
		setDropDownFileList(res.permanentBookingDetails)
		if (res.permanentBookingDetails?.length > 0) {
			handleFileNameSelect(res.permanentBookingDetails[0].fileName)
			setListPageOpen(true)
			setFileName(res.permanentBookingDetails[0].fileName)
		}
	}

	const setListPage = (e) => {
		e.stopPropagation()
		setListPageOpen(false)
	}

	const saveExcelData = () => {

		//send excell file to api
		let principal = getPrincipal()

		var formData = new FormData()

		formData.append("requestID", uuidv4())
		formData.append("userId", principal ? principal.sub : null)
		formData.append("agentCode", principal ? principal.sub : null)
		formData.append("startDate", startDate)
		formData.append("endDate", endDate)
		formData.append("multipartFile", fileData)

		setLoading(true)

		processFlightRotations(formData).then((res) => {
			setLoading(false)

			if (res?.status === 200) {
				if (res.data && res.data) {
					if (res.data.length > 0) {
						const response = res.data
						setFlightRotations(response)
						const displayedRecords = response.slice(0, 100)
						setFlightRotationsDisplay(displayedRecords)
						setStatus(true)
						setPageNumber(3)
					} else {
						setStatus(false)
						setAlertMessage('Flight details not available for the selected week')
					}
				}

			}
		}).catch((err) => {
			console.log('error', err);
			setLoading(false)
			if (err.response && err.response.data
				&& err.response.data.errorDescription
				&& err.response.data.errorDescription.includes('INVALID_EXCEL_DATA')) {
				scheduleToast.current.show({ severity: 'error', summary: 'Error', detail: 'Sorry, We could not process the flight schedules. The format of the data uploaded is not correct.', life: 4000 })
			} else {
				scheduleToast.current.show({ severity: 'error', summary: 'Error', detail: 'Sorry, We could not process the flight schedules.', life: 4000 })
			}
		})

	}

	const handleSubmit = () => {
		if (fileData) {
			setPageNumber(2)
		}
	}
	const handleBack = () => {
		let pageNo = 1
		if (pageNumber > 1) {
			pageNo = (pageNumber - 1)
		}
		if (pageNo === 3) {
			handleFlightRotationsSearch()
		}
		setPageNumber(pageNo)
	}

	const handleSelection = (index, e) => {
		let tempFlightRotations = [...flightRotations]
		let tempSelectedFlights = [...selectedFlights]
		const flight = tempFlightRotations[index]
		if (flight.checked) {
			flight.checked = false
			var tempIndex = tempSelectedFlights.indexOf(index)
			if (tempIndex !== -1) {
				tempSelectedFlights.splice(tempIndex, 1)
			}
		} else {
			flight.checked = true
			tempSelectedFlights.push(index)
		}
		tempFlightRotations[index] = flight

		setFlightRotations(tempFlightRotations)
		setSelectedFlights(tempSelectedFlights)
	}

	const handleSwap = () => {
		setLoading(true)
		let tempFlightRotations = [...flightRotations]
		let outbound1, outbound2, inbound1, inbound2, outboundFlights1, outboundFlights2, inboundFlights1, inboundFlights2
		selectedFlights.forEach((selectedFlightIndex, index) => {
			const flight = tempFlightRotations[selectedFlightIndex]
			if (index === 0) {
				outbound1 = flight.id
				inbound1 = flight.flightInbounds.map((item, index) => {
					return item.id
				})
				outboundFlights1 = flight
				inboundFlights1 = flight.flightInbounds
			} else {
				outbound2 = flight.id
				inbound2 = flight.flightInbounds.map((item, index) => {
					return item.id
				})
				outboundFlights2 = flight
				inboundFlights2 = flight.flightInbounds
			}
		})

		if (inboundFlights2[0].origin !== outboundFlights1.destination || inboundFlights2[0].aircrafttype !== outboundFlights1.aircrafttype) {
			console.log('validation failed');
			setLoading(false)
			scheduleToast.current.show({ severity: 'error', summary: 'Error', detail: 'Flights swap failed - Outboud and inbound flights not matching', life: 4000 })
			return
		} else {
			selectedFlights.forEach((selectedFlightIndex, index) => {
				const flight = tempFlightRotations[selectedFlightIndex]
				if (index === 0) {
					flight.flightInbounds = inboundFlights2
				} else {
					flight.flightInbounds = inboundFlights1
				}
				flight.checked = false
				tempFlightRotations[selectedFlightIndex] = flight
			})

			setFlightRotations(tempFlightRotations)
			const displayedRecords = tempFlightRotations.slice(0, currentIndex)
			setFlightRotationsDisplay(displayedRecords)

			let request = {
				inboundIds1: inbound1,
				outboundId1: outbound1,
				inboundIds2: inbound2,
				outboundId2: outbound2,
			}
			swapInboundFlights(request).then((res) => {
				setLoading(false)
				if (res.data) {
					setSelectedFlights([])
					setSelectedCount(0)
					scheduleToast.current.show({ severity: 'success', summary: 'Success', detail: 'Flights swapped succesfully', life: 4000 })
				}
			}).catch((error) => {
				scheduleToast.current.show({ severity: 'error', summary: 'Error', detail: 'Flights swap failed', life: 4000 })
				setLoading(false)
			})
		}
	}

	const handleProcessSchedules = () => {
		setLoading(true)
		let principal = getPrincipal()
		const request = {
			agentCode: principal ? principal.sub : null
		}
		processSchedules(request).then((res) => {
			setLoading(false)
			if (res && res.data && res.data.flightSegments) {
				setSegments(res.data.flightSegments)
			}
			setPageNumber(4)
		}).catch((error) => {
			scheduleToast.current.show({ severity: 'error', summary: 'Error', detail: 'Sorry, We could not process the flight schedules.', life: 4000 })
			setLoading(false)
		})
	}

	const handleFlightRotationsSearch = (selectedDestination) => {
		setCurrentIndex(100)
		setSelectedFlights([])
		setSelectedCount(0)

		let principal = getPrincipal()
		const request = {
			destination: selectedDestination && selectedDestination.code ? selectedDestination.code : null,
			agentCode: principal ? principal.sub : null,
		}
		setLoading(true)

		getFlightRotations(request).then((res) => {
			setLoading(false)

			if (res && res.data) {
				const response = res.data
				setFlightRotations(res.data)
				const displayedRecords = response.slice(0, 100)
				setFlightRotationsDisplay(displayedRecords)
			}
		}).catch((error) => {
			setLoading(false)
		})
	}

	const handleFlightsLoading = () => {
		let tempFlightRotations = [...flightRotations]
		let tempFlightRotationsDisplay = [...flightRotationsDisplay]
		let displayedRecords = tempFlightRotations.slice(currentIndex + 1, currentIndex + 100)
		setFlightRotationsDisplay([...tempFlightRotationsDisplay, ...displayedRecords])
		setCurrentIndex(currentIndex + 100)
	}

	const handleSegmentSelection = (segmentIndex, index, type, selectedOrigin, selectedDestination, e) => {
		let tempSegments = [...segments]
		let tempSelectedSegments = [...unSelectedSegments]
		const airport = tempSegments[segmentIndex]

		let visible = false


		let segment
		if (type === 'inbound') {
			segment = airport.inboundSegments[index]
		} else if (type === 'outbound') {
			segment = airport.outboundSegments[index]
		}
		if (segment.visible) {
			segment.visible = false
			visible = false
			if (!tempSelectedSegments.includes(segment.id)) {
				tempSelectedSegments.push(segment.id)
			}
		} else {
			segment.visible = true
			visible = true
			tempSelectedSegments.push(segment.id)
			var tempIndex = tempSelectedSegments.indexOf(segment.id)
			if (tempIndex !== -1) {
				tempSelectedSegments.splice(tempIndex, 1)
			}
		}
		if (type === 'inbound') {
			airport.inboundSegments[index] = segment
		} else if (type === 'outbound') {
			airport.outboundSegments[index] = segment
		}
		tempSegments[segmentIndex] = airport


		setLoading(true)
		const request = {
			segmentId: segment.id,
			visible: visible
		}
		updateSegments(request).then((res) => {
			if (res && res.data) {
				//scheduleToast.current.show({ severity: 'success', summary: 'Success', detail: 'Flights unselected succesfully', life: 4000 })
			}
			handleFlightSegmentsSearch(selectedOrigin, selectedDestination)
		}).catch((error) => {
			setLoading(false)
			scheduleToast.current.show({ severity: 'error', summary: 'Error', detail: 'Sorry, We could not update the flight schedule.', life: 4000 })
		})

		setSegments(tempSegments)
		setUnSelectedSegments(tempSelectedSegments)
	}

	const handleFlightSegmentsSearch = (selectedOrigin, selectedDestination) => {
		let principal = getPrincipal()

		const request = {
			origin: selectedOrigin && selectedOrigin.code ? selectedOrigin.code : null,
			destination: selectedDestination && selectedDestination.code ? selectedDestination.code : null,
			agentCode: principal ? principal.sub : null,
		}
		setLoading(true)

		getSegments(request).then((res) => {
			setLoading(false)

			if (res && res.data && res.data.flightSegments) {
				setSegments(res.data.flightSegments)
			}
		}).catch((error) => {
			setLoading(false)
		})
	}

	const handleAlertClose = () => {
		setStatus(false)
		setAlertMessage('')
	}

	const exportScheduleToPdf = () => {
		setLoading(true);
		let principal = getPrincipal()
		const request = {
			startDate : startDate,
			endDate : endDate,
			month: moment(month, 'yyyy-MMM').format('MMM yyyy'),
			agentCode: principal ? principal.sub : null,
		}
		exportPdf(request).then((res) => {
			setLoading(false)

			if (res && res.data) {
				window.open(res.data, "_blank")
			}
		}).catch((error) => {
			setLoading(false)
		})
	}

	return (
		<div>
			{alertMessage !== '' && status !== null && (
				<div className='mb-4'>
					<Alert
						type={NOTIFICATION_MESSAGE_TYPE.INFO}
						showAlert={true}
						message={alertMessage}
						setTimeout={true}
						onAlertClose={handleAlertClose}
					/>
				</div>
			)}
			{pageNumber === 1 &&
				<div className='flight-data-upload'>
					<div className='row mb-0'>
						<div className='col-12'>
							<h2 className='mb-3 pb-1 mt-2'>Upload file (SSIM files)</h2>
							<FlightDataDropzone setDisplayUploadSection={setDisplayUploadSection}
								setDropDownFileList={setDropDownFileList}
								fileList={fileList}
								setListPageOpen={setListPageOpen}
								setFileName={setFileName}
								setFileList={setFileList}
								fileName={fileName}
								setFileData={setFileData}
								file={file}
								setFile={setFile}
							// handleFileNameSelect={handleFileNameSelect}
							/>
						</div>
					</div>
					<div className='row mt-5'>
						<div className='col-2'>
							<button className='primary-button' onClick={handleSubmit} disabled={fileData ? false : true}>
								{t('COMMON.CONT')}
							</button>
						</div>
					</div>
				</div>
			}
			{fileData && pageNumber === 2 &&
				<WeekSelector
					saveExcelData={saveExcelData}
					handleBack={handleBack}
					setStartDate={setStartDate}
					setEndDate={setEndDate}
					weekData={weekData}
					setWeekData={setWeekData}
					setMonth={setMonth}
					month={month}
				>
				</WeekSelector>
			}
			{pageNumber === 3 &&
				<FlightRotations
					flightRotations={flightRotationsDisplay}
					showMore={flightRotations && flightRotations.length > 100 && currentIndex < flightRotations.length ? true : false}
					handleBack={handleBack}
					handleSelection={handleSelection}
					handleSwap={handleSwap}
					setSelectedCount={setSelectedCount}
					selectedCount={selectedCount}
					handleProcessSchedules={handleProcessSchedules}
					weekData={weekData}
					handleFlightRotationsSearch={handleFlightRotationsSearch}
					handleFlightsLoading={handleFlightsLoading}
				/>
			}
			{pageNumber === 4 &&
				<FlightSegments
					segments={segments}
					handleBack={handleBack}
					weekData={weekData}
					handleSegmentSelection={handleSegmentSelection}
					unSelectedSegmentsCount={unSelectedSegmentsCount}
					setUnSelectedSegmentsCount={setUnSelectedSegmentsCount}
					handleFlightSegmentsSearch={handleFlightSegmentsSearch}
					unSelectedSegments={unSelectedSegments}
					exportScheduleToPdf={exportScheduleToPdf}
				/>
			}
			{isLoading &&
				<LoadingIndicator />
			}
			<Toast ref={scheduleToast} />
		</div>
	)
}

export default UploadFlightData
