import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Chips } from 'primereact/chips'
import { Alert } from '../../../common/Alert'
import LoadingIndicator from '../../LoadingIndicator'
import { emailPattern, EMAIL_RECIPIENTS_LIMIT } from '../../../constants'
import { sendEmail } from '../../../services/notificationService'
import { getShareEmailRequest } from './printEmailUtil'
import MESSAGES from '../../../constants/Messages'
import { useTranslation } from 'react-i18next'
import { addError } from '../../../utils/analytics'
const ShareEmailDialog = ({
	bookingDetails,
	displayBasic,
	setDisplayBasic,
}) => {
	//for multilingual
	
	const { t,i18n } = useTranslation(['labels', 'errors'])
	const [email, setEmail] = useState([])
	const [error, setError] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')
	const [sentSuccess, setSentSuccess] = useState(false)
	const [showCharges, setShowCharges] = useState(false)
	const emailType = "shareBooking"

	const handleShareEmail = () => {
		if (email && email.length > 0) {
			setIsLoading(true)
			onHide()
			sendEmail(getShareEmailRequest(email, bookingDetails, showCharges, emailType))
				.then((response) => {
					setIsLoading(false)
					if (response.errorDetails) {
						setAlertMessage(
							t(MESSAGES.SHARE_BOOKING_DETAILS_FAILED, { ns: 'errors' })
						)
						setSentSuccess(false)
						addError(MESSAGES.SHARE_BOOKING_DETAILS_FAILED, i18n.getResource('en', 'errors', MESSAGES.SHARE_BOOKING_DETAILS_FAILED))
					} else {
						setAlertMessage(
							t(MESSAGES.SHARE_CONFIRMATION_SUCCESS, {
								emailId: email,
								ns: 'errors',
							})
						)
						setSentSuccess(true)
					}
					window.scrollTo(0, 0)
				})
				.catch((error) => {
					setIsLoading(false)
					setAlertMessage(
						t(MESSAGES.SHARE_BOOKING_DETAILS_FAILED, { ns: 'errors' })
					)
					addError(MESSAGES.SHARE_BOOKING_DETAILS_FAILED, i18n.getResource('en', 'errors', MESSAGES.SHARE_BOOKING_DETAILS_FAILED))
					setSentSuccess(false)
					window.scrollTo(0, 0)
				})
		} else {
			setError(t(MESSAGES.INVALID_EMAIL, { ns: 'errors' }))
		}
	}

	const onHide = () => {
		setDisplayBasic(false)
		setEmail([])
		setError(null)
		setAlertMessage('')
		setSentSuccess(false)
		setShowCharges(false)
	}

	const onChange = (e) => {
		const value = e.value.toString().trim()
		let lastVal = ''
		if (value && value.indexOf(',') === -1) {
			lastVal = value
		} else {
			let values = value.split(',')
			lastVal = values[values.length - 1]
		}
		if (!emailPattern.test(lastVal)) {
			setError(
				t(MESSAGES.INCORRECT_MAIL_ENTERED, {
					val: lastVal,
					ns: 'errors',
				})
			)
			setTimeout(() => {
				setError(null)
			}, 5000)
		} else {
			setError(null)
			setEmail(e.value)
		}
	}

	const handleAlertClose = () => {
		setAlertMessage('')
		setSentSuccess(false)
	}

	const onBlur = (e) => {
		if (e.target.value && email) {
			if (!emailPattern.test(e.target.value)) {
				setError(
					t(MESSAGES.INCORRECT_MAIL_ENTERED, {
						val: e.target.value,
						ns: 'errors',
					})
				)
			} else {
				setError(null)
				setEmail([...email, ...[e.target.value]])
				e.target.value = ''
			}
		}
	}

	const onFocus = (e) => {
		setError(null)
		e.target.value = ''
	}

	const onRemove = (e) => {
		if (email && email.length === 1 && e.value.toString() === email[0]) {
			setEmail([])
			setError(null)
		}
	}

	const RenderFooter = () => {
		return (
			<div>
				<button onClick={handleShareEmail} className='button mr-0'>
					{t('COMMON.SEND')}
				</button>
			</div>
		)
	}

	return (
		<div className='cargo-portal-container dialog-container'>
			{alertMessage !== '' && (
				<Alert
					isError={!sentSuccess}
					showAlert={true}
					message={alertMessage}
					onAlertClose={handleAlertClose}
					setTimeout={true}
				/>
			)}
			{isLoading ? <LoadingIndicator /> : <></>}
			<div className='portal-dialog p-fluid'>
				<Dialog
					header=''
					className = 'dialogWrapper'
					visible={displayBasic}
					onHide={onHide}
				>
					<div>
						<h3 className='mt-1 mb-3'>
							{t(MESSAGES.POP_UP_LABEL_SHARE_BOOKING, { ns: 'errors' })}
						</h3>
						<p>{t(MESSAGES.POP_UP_TEXT_SHARE_BOOKING, { ns: 'errors' })}</p>
						<label>
							<span className='input-info'>
								{t(MESSAGES.POP_UP_TEXT_BOX_INFO, { ns: 'errors' })}
							</span>
						</label>
						<Chips
							value={email}
							onChange={onChange}
							separator=','
							className='email-ids'
							allowDuplicate={false}
							max={EMAIL_RECIPIENTS_LIMIT}
							onBlur={onBlur}
							onFocus={onFocus}
							onRemove={onRemove}
						/>
						<span className='form-item__error'>{error ? error : ''}</span>
						<div className='form-row mt-3'>
							<div className='col-12 col-md-12'>
								<div
									className='form-item form-item--checkbox'
									data-module='molecules/form-item/FormItem'
								>
									<input
										id='showCharges'
										checked={showCharges}
										value='All'
										type='checkbox'
										name='showCharges'
										className='form-item__field'
										onChange={(e) => setShowCharges(e.target.checked)}
									/>
									<label
										htmlFor='showCharges'
										className='form-item__label label-text'
									>
										{t('COMMON.INCLUDE_CHARGES')}
									</label>
								</div>
							</div>
						</div>
						<div className='form-row mt-4 mb-2'>
							<div className='col-12 align-center'>
								<button onClick={handleShareEmail} className='primary-button mr-0'>
									{t('COMMON.SEND')}
								</button>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		</div>
	)
}

export default ShareEmailDialog
