import React, { useCallback, useState, useEffect, useRef } from 'react'
import './flight-segments.scss'
import AirportAutocomplete from '../../AirportAutocomplete'
import { AUTOCOMPLETE_TYPE } from '../../../constants'
import { t } from 'i18next'
import '../../../services/scheduleService'
import moment from 'moment'


const FlightSegments = ({
  segments,
  handleBack,
  weekData,
  handleSegmentSelection,
  unSelectedSegmentsCount,
  setUnSelectedSegmentsCount,
  handleFlightSegmentsSearch,
  unSelectedSegments,
  exportScheduleToPdf,
}) => {

  const [selectedOrigin, setSelectedOrigin] = useState(null)
  const [selectedDestination, setSelectedDestination] = useState(null)

  const handleSubmit = () => {
    console.log('handleSubmit');
    exportScheduleToPdf()
  }

  const handleSetError = (key, text) => {

  }

  const clearError = (key) => {

  }

  const draftCallback = () => { }



  return (
    <>
      <div className='row segments-page'>
        <div className='col-12'>

          <div className='row ml-lg-0'>
            <div
              className={`col-12 col-md-3 mb-md-3 mb-4 form-item form-item--text`}
              data-module='molecules/form-item/FormItem'
            >
              <label className='form-item__label'>{t('COMMON.ORG')}</label>
              <AirportAutocomplete
                autoCompleteType={AUTOCOMPLETE_TYPE.ORIGIN}
                selectedOrigin={selectedOrigin}
                selectedDestination={selectedDestination}
                setSelectedOrigin={setSelectedOrigin}
                handleSetError={handleSetError}
                clearError={clearError}
                draftCallback={draftCallback}
                screen='MANAGE_BOOKING'
              />

            </div>
            <div
              className={`col-12 col-md-3 mb-md-3 mb-4 form-item form-item--text`}
              data-module='molecules/form-item/FormItem'
            >
              <label className='form-item__label'>{t('COMMON.DEST')}</label>
              <AirportAutocomplete
                autoCompleteType={AUTOCOMPLETE_TYPE.DESTINATION}
                selectedOrigin={selectedOrigin}
                selectedDestination={selectedDestination}
                setSelectedDestination={setSelectedDestination}
                handleSetError={handleSetError}
                clearError={clearError}
                draftCallback={draftCallback}
                screen='MANAGE_BOOKING'
              />

            </div>
            <div className='col-12 col-md-2 mt-1'>
              <button className='primary-button mt-4' onClick={(e) => handleFlightSegmentsSearch(selectedOrigin, selectedDestination)}>Search</button>
            </div>
            <div className='col-12 col-md-4 mt-5'>
              <div className='selected-week float-right'>
                Selected week:  {weekData &&
                  <span>{weekData.fromDate} to {weekData.toDate}</span>
                }
              </div>
            </div>
          </div>
          <div className='flights-segments mt-5'>
            <div className='row segments-header sticky-top'>
              <div className='col-1'>
                Select
              </div>
              <div className='col-1 col-flight-no'>
                Flight No.
              </div>
              <div className='col-1 col-freq'>
                Frequency
              </div>
              <div className='col-1 col-org'>
                Departure
              </div>
              <div className='col-1 col-dep'>
                Departure time
              </div>
              <div className='col-1 col-dest'>
                Arrival
              </div>
              <div className='col-1 col-arv'>
                Arrival time
              </div>
              <div className='col-1 col-via'>
                Via
              </div>
              <div className='col-1 col-aircraft'>
                Aircraft
              </div>
            </div>
            {
              segments && segments.map((segment, segmentIndex) => {
                return (
                  <div className='segment-airport'>
                    <div>
                      <h2 className='airport-name'>{segment.airportName} ({segment.airportCode})</h2>
                    </div>
                    <div className='flights-outbound'>
                      {segment.outboundSegments && segment.outboundSegments.map((flight, index) => (
                        <div className='row flights-segment flight-outbound'>
                          <div className='col-12 flight-outbound-segment' id={`segment-${flight.id}`}>
                            <div className='row flight-row'>
                              <div className='col-1'>
                                <div
                                  className='form-item form-item--checkbox col-2'
                                  data-module='molecules/form-item/FormItem'
                                >
                                  <>
                                    <input
                                      id={`select-segment-outbound-${segmentIndex}--${flight.id}`}
                                      /*checked={unSelectedSegments && unSelectedSegments.length > 0 ? !unSelectedSegments.includes(flight.id) : flight.visible }*/
                                      checked={flight.visible}
                                      value={flight.id}
                                      type='checkbox'
                                      name={`select-segment-outbound-${segmentIndex}--${flight.id}`}
                                      className='form-item__field'
                                      onChange={(e) => {
                                        handleSegmentSelection(segmentIndex, index, 'outbound', selectedOrigin, selectedDestination, e)
                                        if (e.target.checked) {
                                          setUnSelectedSegmentsCount(unSelectedSegmentsCount + 1)
                                        } else {
                                          setUnSelectedSegmentsCount(unSelectedSegmentsCount - 1)
                                        }
                                      }}
                                    />
                                    <label htmlFor={`select-segment-outbound-${segmentIndex}--${flight.id}`} className='form-item__label'>
                                    </label>
                                    <span className='form-item__error'></span>
                                  </>

                                </div>
                              </div>
                              <div className='col-1 col-flight-no'>
                                EY {flight.flightNumber}
                              </div>
                              <div className='col-1 col-freq'>
                                {flight.frequency.split(' ').map((item, i) => {
                                  return (<span className='freq-day'>{item}</span>)
                                })}
                              </div>
                              <div className='col-1 col-org'>
                                {flight.fromAirport}
                              </div>
                              <div className='col-1 col-dep'>
                                {flight.departureTime}
                              </div>
                              <div className='col-1 col-dest'>
                                {flight.toAirport}
                              </div>
                              <div className='col-1 col-arv'>
                                {flight.arrivalTime} {flight.arrivalDate ? flight.arrivalDate : ''}                              </div>
                              <div className='col-1 col-via'>
                                {flight.viaAirport}
                              </div>
                              <div className='col-1 col-aircraft'>
                                {flight.aircraftType}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                      }
                    </div>
                    <>
                      {segment.inboundSegments && segment.inboundSegments.map((flight, index) => (
                        <div className='row flights-segment flight-inbound'>
                          <div className='col-12 flight-inbound-segment' id={`segment-${flight.id}`}>
                            <div className='row flight-row'>
                              <div className='col-1'>
                                <div
                                  className='form-item form-item--checkbox col-2'
                                  data-module='molecules/form-item/FormItem'
                                >
                                  <>
                                    <input
                                      id={`select-segment-inbound-${segmentIndex}--${flight.id}`}
                                      checked={flight.visible}
                                      /*checked={unSelectedSegments && unSelectedSegments.length > 0 ? !unSelectedSegments.includes(flight.id) : flight.visible }*/
                                      value={flight.id}
                                      type='checkbox'
                                      name={`select-segment-inbound-${segmentIndex}--${flight.id}`}
                                      className='form-item__field'
                                      onChange={(e) => {
                                        handleSegmentSelection(segmentIndex, index, 'inbound', selectedOrigin, selectedDestination, e)
                                        if (e.target.checked) {
                                          setUnSelectedSegmentsCount(unSelectedSegmentsCount + 1)
                                        } else {
                                          setUnSelectedSegmentsCount(unSelectedSegmentsCount - 1)
                                        }
                                      }}
                                    />
                                    <label htmlFor={`select-segment-inbound-${segmentIndex}--${flight.id}`} className='form-item__label'>
                                    </label>
                                    <span className='form-item__error'></span>
                                  </>

                                </div>
                              </div>
                              <div className='col-1 col-flight-no'>
                                EY {flight.flightNumber}
                              </div>
                              <div className='col-1 col-freq'>
                                {flight.frequency.split(' ').map((item, i) => {
                                  return (<span className='freq-day'>{item}</span>)
                                })}
                              </div>
                              <div className='col-1 col-org'>
                                {flight.fromAirport}
                              </div>
                              <div className='col-1 col-dep'>
                                {flight.departureTime}
                              </div>
                              <div className='col-1 col-dest'>
                                {flight.toAirport}
                              </div>
                              <div className='col-1 col-arv'>
                                {flight.arrivalTime} {flight.arrivalDate ? flight.arrivalDate : ''}
                              </div>
                              <div className='col-1 col-via'>
                                {flight.viaAirport}
                              </div>
                              <div className='col-1 col-aircraft'>
                                {flight.aircraftType}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                      }
                    </>
                  </div>
                )
              })
            }
            {
              segments && segments.length === 0 &&
              <div className='row text-center pt-4'>
                <div className='col-12'>No records found</div>
              </div>
            }
          </div>
        </div>

      </div>
      <div className='row mt-5 ml-0 mr-0'>
        <div className='col col-12 col-md-3 col-lg-1 cta_wrap mr-md-4 ml-lg-2'>
          <button className='primary-button' onClick={handleSubmit}>
            Export
          </button>
        </div>
        <div className='col col-12 col-md-2 col-log-2 cta_wrap'>
          <button className='primary-button secondary' onClick={handleBack}>
            {t('COMMON.BACK_BUTTON')}
          </button>
        </div>
      </div>
    </>
  )
}



export default FlightSegments
