import { useEffect, useState } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import sessionKeys from "../../constants/sessionKeys";
import { getGeoLocation } from "../../services/edgeService";
import ICPInformation from "./ICPInformation";

import ellipseIcon from "../../assets/images/ellipseIcon.svg";
import linkedInIcon from "../../assets/images/linkedInIcon.svg";
import externalUrlIcon from "../../assets/images/icon-external-link.svg";
import { LINKEDIN_URL } from "../../configs/appConfigs";

const Footer = () => {
  const { t, i18n } = useTranslation(["labels"]);
  const [geoLocation, setGeoLocation] = useState();

  useEffect(() => {
    (async () => {
      let geoLocationStr = sessionStorage.getItem(sessionKeys.GEO_LOCATION);
      let geoLocation = geoLocationStr ? JSON.parse(geoLocationStr) : null;
      if (!geoLocation) {
        try {
          geoLocation = await getGeoLocation();
          sessionStorage.setItem(
            sessionKeys.GEO_LOCATION,
            JSON.stringify(geoLocation)
          );
        } catch (err) {
          console.log("Error getting geo-location", err);
        }
      }
       setGeoLocation(geoLocation);
    })();
  }, []);

  return (
    <div
      className="container-fluid cargo-portal-footer"
      id="cargo-portal-footer"
    >
      <div className="fixed-content-container container-lg fixedRowWidth footer-contents">
        <div className="left-block">
          <div className="left-top d-flex flex-column">
            <div className='footer-links'>
              <div className="tc-links">
                <p className="copyright-labeltext mr-2">{t("FOOTER.FOOTER_TXT")}</p>
                <img
                  src={ellipseIcon}
                  className="img-responsive ellipse"
                />
                <a
                  className="tc-labeltext"
                  href="https://www.etihadcargo.com/en/terms-and-conditions"
                >
                  {t("FOOTER.T_C")}
                </a>
                <img
                  src={ellipseIcon}
                  className="img-responsive ellipse"
                />
                <a
                  className="tc-labeltext"
                  href="https://www.etihadcargo.com/en/privacy-policy"
                >
                  {t("FOOTER.PRIVACY_POLICY")}
                </a>
                <img
                  src={ellipseIcon}
                  className="img-responsive ellipse"
                />
                <a
                  className="tc-labeltext"
                  href="https://www.etihadcargo.com/en/cookie-policy"
                >
                  {t("FOOTER.COOKIE_POLICY")}
                </a>
                <a
                  className="tc-labeltext"
                  href="https://beian.miit.gov.cn/"
                >Shanghai, ICP prepared No. 14027180
                <img className="pl-1" src={externalUrlIcon}/>
                </a>
                
              </div>

            </div>

          </div>
          {geoLocation &&
            geoLocation.countrycode &&
            geoLocation.countrycode === "cn" && <ICPInformation />}
        </div>
        <div className="right-block">
          <a
            href={LINKEDIN_URL}
            target="_blank"
            rel="noreferrer"
            className="linkedin-icon icon-hover-magnify"
          >
            <img
              src={linkedInIcon}
              alt="linkedin"
              className="img-responsive"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;