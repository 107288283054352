import React, { useCallback, useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import moment from 'moment';
import './petdoc-upload.scss'
import { v4 as uuidv4 } from 'uuid'
import {
  postAviPetAttachment,
} from "../../../../services/aviBookingService";
import { Toast } from 'primereact/toast';
import {
  getToastSuccess,
  getToastError
} from '../../../../redux/actions/toastActions';
import LoadingIndicator from "../../../LoadingIndicator";
import { t } from 'i18next';
import {
  clearItemFromSessionStorage,
  getBase64Decoded,
  getBase64Encoded,
  getFromSessionStorage,
  setToSessionStorage,
  getFromLocalStorage,
  setToLocalStorage,
} from '../../../../utils/StorageUtils'


let arr = []
var arrayListAVI = []

const PetDocUpload = ({
  setDisplayUploadSection,
  toastDetails,
  getToastSuccess,
  getToastError,
  setDropDownFileList,
  setListPageOpen,
  setFileName,
  fileList,
  handleFileNameSelect,
  index,
  file,
  setFile,
  fileCount,
  arrayListDoc,
  setArrayListDoc,
  cageIndex,
  attachments,
  setAttachments,
  petList,
  cageList,
  handleAttachment,

}) => {

  const [isLoading, setLoading] = useState(false);
  const myToast = useRef(null);

  const [loadingArray, setLoadingArray] = useState([]);

  const [uuid, setuuid] = useState()

  const [documentArray, setDocumentArray] = useState(arrayListDoc)

  const [finalarray, setFinalArray] = useState([])




  useEffect(() => {
    //console.log(file, 'file')
    setFinalArray([arrayListAVI])
  }, [file])

  const checkFileName = (acceptedFiles) => {
    let isValid = false

    const cageListJson = getFromSessionStorage('cageList')
    //let petDocs = [...petList]
    let petDocs = []

    if (cageListJson) {
      const cageListData = JSON.parse(cageListJson)
      if (cageListData[cageIndex].pets && cageListData[cageIndex].pets.length > 0) {
         petDocs = [...cageListData[cageIndex].pets[index].attachments]
      }
    }
    //const attachments = [...petDocs[index].attachments]

    if (petDocs.length >= 1) {
      const checkArray = petDocs.map((el) => { return el.fileName })
      const returnCheck = (checkArray.includes((acceptedFiles[0].name)) ? true : false)
      return isValid = returnCheck
    }
    else {

      return isValid
    }
    

    // if (arr.length >= 1) {
    //   const retunCheck = arr.some((el) => el.name === acceptedFiles[0].name) ? true : false
    //   return isValid = retunCheck
    // }
    // return isValid

  }

  const getUuid = () => {
    if (petList[index].attachments?.length > 0) {
      let idFromSession = getFromSessionStorage('petAttachmentId')
      return idFromSession;
    }
    else {
      let newId = uuidv4();
      setToSessionStorage('petAttachmentId', newId);
      return newId;
    }
  }

  const onDrop = useCallback((acceptedFiles) => {
    setDisplayUploadSection(false);
    setLoading(true);

    var formData = new FormData();
    const filepathArray = acceptedFiles[0]?.path?.split('.')

    if (filepathArray[filepathArray.length - 1] !== 'xlsx' &&
      filepathArray[filepathArray.length - 1] !== 'xls' &&
      filepathArray[filepathArray.length - 1] !== 'xlx' && filepathArray[filepathArray.length - 1] !== 'docx' &&
      filepathArray[filepathArray.length - 1] !== 'pdf' && filepathArray[filepathArray.length - 1] !== 'jpeg' && 
      filepathArray[filepathArray.length - 1] !== 'png' && filepathArray[filepathArray.length - 1] !== 'jpg' &&
      filepathArray[filepathArray.length - 1] !== 'PNG'
    ) {
      setLoading(false);
      getToastError("Upload a vaild file format(pdf, doc, xlsx, jpeg,png)");
      return
    }

    else {

      let fileCheck = checkFileName(acceptedFiles)
      if (fileCheck === true) {
        setLoading(false);
        getToastError("File already Exists");
        return;
      }

      else {
        arr.push({ name: acceptedFiles[0].name });
        formData.append("id", 0);
        formData.append("attachmentId", getUuid());
        formData.append("petOrder", index);
        formData.append("cageOrder", cageIndex);
        formData.append("multipartFile", acceptedFiles[0]);

        postAviPetAttachment(formData).then((res) => {

          //  try {
            if (res.status === "SUCCESS") {
              setLoading(false);
              const cageListJson = getFromSessionStorage('cageList')
              let petDocs = [...petList]
  
              if (cageListJson) {
                const cageListData = JSON.parse(cageListJson)
                if (cageListData[cageIndex].pets && cageListData[cageIndex].pets.length > 0) {
                  petDocs = [...cageListData[cageIndex].pets]
                }
              }
            const attachments = [...petDocs[index].attachments]

            handleAttachment(index, res, attachments)



            //call toaster
            getToastSuccess("File uploaded successfully");
            myToast.current.show({ ...toastDetails, life: 4000 })
            // setFile(null);
            setFile([res]);

            // destructure state array


            // check if the current index exists in docArray 



            let docIndex = null;
            //console.log(arrayListAVI,'arrayListAVI2345')

            if (arrayListAVI.length > 0) {

              docIndex = typeof arrayListAVI[index] === undefined ? false : true;


            }

            else {
              let fileObject = {
                fileName: res.fileName,
                petOrder: res.petOrder,
                attachmentId: uuidv4()
              }

              arrayListAVI.push([fileObject])
              setFinalArray([fileObject])
              //console.log(arrayListAVI, "arrayListAVI")
              setArrayListDoc([...arrayListAVI])
            }
          }

          else {
            setLoading(false);
            console.log(error)
            // setFile(null);
            // getToastError("Failed to upload file");
            return
          }
        }
        )
      }
    }

  }, [])


  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false })

  return (
    <>

      {
        isLoading ? <LoadingIndicator /> : ''
      }
      <div className='file-name'>
        <div>

          <div className='secondary-button d-flex ml-4'>
            <Toast ref={myToast} />

            <div className='m-0 d-flex align-items-center justify-content-center' {...getRootProps()}>
              <input {...getInputProps()} />

              {fileCount ? <p className='m-0 text-center'>{t('COMMON.UPLOAD_ANOTHER_FILE')}</p> : <p className='m-0 text-center'>{t('COMMON.UPLOAD_TEXT')} </p>}
            </div>
          </div>
        </div>

        {/* {

          file ?
            (
              <div className='filename-upload uploadfile'>
                {`${file[index]?.path} - ${file[index]?.size} kb`}
              </div>
            ) : <></>


        } */}
      </div>

    </>
  )
}

const mapStateToProps = ({ loader, toast }) => ({
  //	principal: auth.principal,
  //	isLoading: loader.isLoading,
  toastDetails: toast.toastDetails,
})

const mapDispatchToProps = (dispatch) => ({
  getToastSuccess: (message) => {
    dispatch(getToastSuccess(message))
  },
  getToastError: (message) => {
    dispatch(getToastError(message))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PetDocUpload)
