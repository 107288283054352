import { InputText } from 'primereact/inputtext'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import '../index.scss'
import search from '../../../assets/images/Search.svg'
const TemplateFilter = ({ userDetails, getTemplateDetails, deleteSuccess }) => {
	const { t, i18n } = useTranslation(['labels', 'errors'])

	const [error, setError] = useState(false)
	const [filterData, setFilterData] = useState({
		userId: '',
	})

	const handleSubmit = (e) => {
		e.preventDefault()
		buildRequest()
	}
	const handleKeyPress = (evnt) => {
		let charCode = evnt.which ? evnt.which : evnt.keyCode
		if (charCode === 13) handleSubmit(evnt)
	}

	useEffect(() => {
		if (filterData) {
			buildRequest()
		}
	}, [])

	useEffect(() => {
		if (deleteSuccess) {
			buildRequest()
		}
	}, [deleteSuccess])

	const buildRequest = () => {
		const request = {
			requestID: uuidv4(),
			agentCode:
				userDetails && userDetails.agentCode ? userDetails.agentCode : null,
			userId: filterData && filterData.userId ? filterData.userId.trim() : null,
		}
		getTemplateDetails(request)
	}

	return (
		<div className='form-item--text searchField'>
			<span className='p-input-icon-right'>
				<InputText
					id='inputWrapper'
					type='text'
					className='form-item__field'
					value={filterData.userId}
					placeholder='User ID'
					autoComplete='off'
					onChange={(e) => {
						setFilterData({
							...filterData,
							userId: e.target.value,
						})
					}}
					onKeyPress={(e) => handleKeyPress(e)}
				/>
			</span>
			<button
				type='button'
				className='d-none d-lg-inline-block primary-button'
				id='btn-template-search'
				onClick={handleSubmit}
			>
				{t('COMMON.SEARCH')}
			</button>
			<button type='button' onClick={handleSubmit} className='d-block d-lg-none template-search_mobile'>
				<img src={search} alt="searchIcon" />
			</button>
		</div>
	)
}

export default TemplateFilter
