import React from 'react'
import { useHistory } from 'react-router-dom'
import './index.scss'
import newBookingImage from '../../../assets/images/New-Booking.svg'
import { useTranslation } from 'react-i18next'
import {pushEventDashboardBookingandTracking} from '../../../utils/analytics'

const NewBookingTile = ({
	
}) => {
	const { t } = useTranslation(['labels'])

	const history = useHistory()
	
	const handleSubmitButton = () => {
					history.push('/booking/search')
					pushEventDashboardBookingandTracking("Book Now")
					
				}
	
	return (
		<div className='new-booking-tile-panel'>
			<h3 className='section-sub-heading mt-0'>{t('COMMON.NEW_BKG')}</h3>
			<img src={newBookingImage} alt='New booking' className='mb-4' />
			<button
				type='button'
				className='secondary-button cta-button'
				onClick={handleSubmitButton}
				
			>
				
				{t('DASHBOARD.BOOK_NOW')}
				

			</button>
		</div>
	)
	}

export default NewBookingTile
