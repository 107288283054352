import React, { useState, useEffect } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import './filter-popup.scss';
import Calendar from '../../../../common/Calendar'
import { useTranslation } from 'react-i18next'
import { Slider } from 'primereact/slider';
import { InputText } from 'primereact/inputtext';
import filterIcon from '../../../../assets/images/filter.svg';
import filterActiveIcon from '../../../../assets/images/filter-active.svg';
import moment from 'moment';
import { padLeadingZeros } from '../../../../utils/Validations'
import { validateNumericIfPresent, validateRequired } from '../../../CaptureAWB/captureAwbUtils'
import {
    validateFlightNoRegex,
    allowAlphaOnly,
    validateCarrierCodeRequired,
    removeError,
    restrictSpaceSpecial,
    hasError,
    allowNumricOnly,
    formatInputTypeNumber,
    validateAWB,
} from '../../../ManageBooking/ListBooking/ListbookingValidations'
import { Dropdown } from '../../../../common/DropDown'

const FilterPopup = ({
    op,
    setFilterSummary,
    handleFilterAction,
    flightFromDate,
    flightToDate,
    flightSummaryApplyFilter,
    flightSummaryFilter,
    setFlightsRefreshed,
    flightsRefreshed,
    setFiltered,
    setFlightFilter,
    flightFilter,
    setFilterValues,
    filterValues,
}) => {
    const { t, i18n } = useTranslation(['labels', 'errors'])
    const [carrierCode, setCarrierCode] = useState('EY')
    const [flightNo, setFlightNo] = useState('')
    const [departureFromDate, setDepartureFromDate] = useState('')
    const [departureToDate, setDepartureToDate] = useState('')
    const [arrivalFromDate, setArrivalFromDate] = useState('')
    const [arrivalToDate, setArrivalToDate] = useState('')
    const [toDate, setToDate] = useState('')
    //const [showFilterModal, setShowFilterModal] = useState(false);
    const [departureTime, setDepartureTime] = useState([0, 1439]);
    const [departureTimeInHours, setDepartureTimeInHours] = useState([
        '0:00 AM',
        '23:59 PM',
    ])
    const [arrivalTime, setArrivalTime] = useState([0, 1439])
    const [arrivalTimeInHours, setArrivalTimeInHours] = useState([
        '0:00 AM',
        '23:59 PM',
    ])
    const [received, setReceived] = useState(false)
    const [notReceived, setNotReceived] = useState(false);
    const [error, setError] = useState(null);
    const [utc, setUtc] = useState(false);
    const [departedFlights, setDepartedFlights] = useState(false);
    // const [timeText, setTimeText] = useState(' ');
    const [timeText, setTimeText] = useState('');

    const [timeError, setTimeError] = useState(false);
    const [depDateError, setDepDateError] = useState(false);
    const [arrDateError, setArrDateError] = useState(false);
    const [depFromerrorMessage, setDepFromErrorMessage] = useState(false);
    const [depToerrorMessage, setDepToErrorMessage] = useState(false);
    const [arrivalFromerrorMessage, setArrivalFromErrorMessage] = useState(false);
    const [arrivalToerrorMessage, setArrivalToErrorMessage] = useState(false);
    const [awbPrefix, setAwbPrefix] = useState('607')
    const [awbNumber, setAwbNumber] = useState('')
    const [filterByAwbNumber, setFilterByAwbNumber] = useState(false)
    const [stdOptions, setStdOptions] = useState([])

    const remainingTimeList = [
        { label: ' 01:00', value: '01:00' },
        { label: ' 02:00', value: '02:00' },
        { label: ' 03:00', value: '03:00' },
        { label: ' 04:00', value: '04:00' },
        { label: ' 05:00', value: '05:00' },
        { label: ' 06:00', value: '06:00' },
        { label: ' 07:00', value: '07:00' },
        { label: ' 08:00', value: '08:00' },
        { label: ' 09:00', value: '09:00' },
        { label: ' 10:00', value: '10:00' },
        { label: ' 11:00', value: '11:00' },
        { label: ' 12:00', value: '12:00' },
    ]

    useEffect(() => {
        if (flightsRefreshed) {
            //resetFilter()
            setFlightsRefreshed(false)
        }
    }, [flightsRefreshed])

    useEffect(() => {
        // if(!flightSummaryFilter?.depDetails?.utcInterval){
        if (utc && !departedFlights) {
            setTimeText('03:00')
            setStdOptions(remainingTimeList)
        }
        else {
            setTimeText('')
            //setStdOptions([{ label: ' HH:mm', value: '' }])
            setStdOptions([])
        }
        // }
    }, [utc, flightSummaryFilter])

    const getFilterData = () => {
        let statusArray = [];
        if (received) {
            statusArray.push('RECEIVED')
        }
        if (notReceived) {
            statusArray.push('NOT_RECEIVED')
        }
        return {
            "depDetails": {
                "fromDate": departureFromDate,
                "toDate": departureToDate,
                "fromTime": departureTimeInHours[0],
                "toTime": departureTimeInHours[1],
                "utc": utc,
                "utcInterval": timeText,
                showDepartedFlights: departedFlights,
            },
            "arrDetails": {
                "fromDate": arrivalFromDate,
                "toDate": arrivalToDate,
                "fromTime": arrivalTimeInHours[0],
                "toTime": arrivalTimeInHours[1]
            },
            "ffmStatus": statusArray,
            carrierCode: flightNo && flightNo !== '' && carrierCode && carrierCode !== '' ? carrierCode : null,
            flightNumber: flightNo && flightNo !== '' ? flightNo : null,
            airlinePrefix: awbPrefix && awbPrefix !== '' && awbNumber && awbNumber !== '' ? awbPrefix : null,
            awbNumber: awbNumber && awbNumber !== '' ? awbNumber : null,
        }
    }

    useEffect(() => {
        if (flightSummaryFilter) {
            if (flightSummaryFilter.depDetails?.fromDate !== "") {
                setDepartureFromDate(flightSummaryFilter.depDetails.fromDate)

            }

            if (flightSummaryFilter.depDetails?.toDate !== "") {
                setDepartureToDate(flightSummaryFilter.depDetails.toDate)

            }
            if (flightSummaryFilter.arrDetails?.fromDate !== "") {
                setArrivalFromDate(flightSummaryFilter.arrDetails.fromDate)
            }
            if (flightSummaryFilter.arrDetails?.toDate !== "") {
                setArrivalToDate(flightSummaryFilter.arrDetails.toDate)
            }
            setDepartureTimeInHours([
                flightSummaryFilter.depDetails.fromTime, flightSummaryFilter.depDetails.toTime
            ])
            setArrivalTimeInHours([
                flightSummaryFilter.arrDetails.fromTime, flightSummaryFilter.arrDetails.toTime
            ])
            if (flightSummaryFilter.ffmStatus.includes('RECEIVED')) {
                setReceived(true)
            }
            if (flightSummaryFilter.ffmStatus.includes('NOT_RECEIVED')) {
                setNotReceived(true)
            }
            setUtc(flightSummaryFilter.depDetails.utc)
            setDepartedFlights(flightSummaryFilter.depDetails.showDepartedFlights)
            setTimeText(flightSummaryFilter.depDetails.utcInterval)
            setFlightNo(flightSummaryFilter.flightNumber ? flightSummaryFilter.flightNumber : '')
            setCarrierCode(flightSummaryFilter.carrierCode ? flightSummaryFilter.carrierCode : 'EY')
            setAwbPrefix(flightSummaryFilter.airlinePrefix ? flightSummaryFilter.airlinePrefix : '607')
            setAwbNumber(flightSummaryFilter.awbNumber ? flightSummaryFilter.awbNumber : '')
            setFilterByAwbNumber(flightSummaryFilter.awbNumber ? true : false)

        }
    }, [flightSummaryFilter])

    const showFilter = (e) => {
        op.current.toggle(e)

        if (filterValues) {
            if (filterValues.depDetails) {
                setDepartureFromDate(filterValues.depDetails.fromDate)
                setDepartureToDate(filterValues.depDetails.toDate)
                setDepartureTimeInHours(filterValues.depDetails.departureTimeInHours)
                setDepartureTime(filterValues.depDetails.departureTime)
                setUtc(filterValues.depDetails.utc)
                setTimeText(filterValues.depDetails.showDepartedFlights ? '' : filterValues.depDetails.utcInterval)
                setDepartedFlights(filterValues.depDetails.showDepartedFlights)
            }
            if (filterValues.arrDetails) {
                setArrivalFromDate(filterValues.arrDetails.fromDate)
                setArrivalToDate(filterValues.arrDetails.toDate)
                setArrivalTimeInHours(filterValues.arrDetails.arrivalTimeInHours)
                setArrivalTime(filterValues.arrDetails.arrivalTime)
            }
            if (filterValues.ffmStatus) {
                setReceived(filterValues.ffmStatus.received)
                setNotReceived(filterValues.ffmStatus.notReceived)
            }
            setFlightNo(filterValues.flightNumber ? filterValues.flightNumber : '')
            setCarrierCode(filterValues.carrierCode ? filterValues.carrierCode : 'EY')
        }
    }

    const covertDecimalToTime = (component, value) => {
        let startTime, endTime

        value.forEach((element, index) => {
            let hoursAndMinute = element / 60
            let hour = parseInt(hoursAndMinute)
            let minute = hoursAndMinute % 1
            minute = parseInt(minute * 60)
                .toString()
                .padStart(2, 0)
            if (index === 0) {
                startTime =
                    hour >= 12 ? hour + ':' + minute + ' PM' : hour + ':' + minute + ' AM'
            } else {
                endTime =
                    hour >= 12 ? hour + ':' + minute + ' PM' : hour + ':' + minute + ' AM'
            }
        })
        if (component === 'departure') {
            setDepartureTimeInHours([startTime, endTime])
            setDepartureTime(value)
        } else if (component === 'arrival') {
            setArrivalTimeInHours([startTime, endTime])
            setArrivalTime(value)
        }
    }

    const handleRadioUtc = () => {
        setUtc(!utc)
        setDepartedFlights(false)
        setDepDateError(null)
        setDepartureFromDate('')
        setDepartureToDate('')
        setDepartureTime([0, 1439]);
        setDepartureTimeInHours([
            '0:00 AM',
            '23:59 PM',
        ]);
        setCarrierCode('EY')
        setFlightNo('')
        setAwbPrefix('607')
        setAwbNumber('')
        setError(null)
        setFilterByAwbNumber(false)
        setArrivalFromDate('');
        setArrivalToDate('');
        setArrivalTime([0, 1439]);
        setArrivalTimeInHours([
            '0:00 AM',
            '23:59 PM',
        ]);
        setArrDateError(false)
    }

    const handleRadioFinalized = (e) => {
        setReceived(!received)
    }
    const handleRadioOnHold = (e) => {
        setNotReceived(!notReceived)
    }


    const onFilter = (e) => {
        setFiltered(true)
        const filterData = getFilterData()
        handleFilterAction(getFilterData());

        filterData.ffmStatus = {
            received,
            notReceived
        }
        filterData.depDetails.departureTimeInHours = departureTimeInHours
        filterData.depDetails.departureTime = departureTime
        filterData.arrDetails.arrivalTimeInHours = arrivalTimeInHours
        filterData.arrDetails.arrivalTime = arrivalTime
        // Set filter values to state to be used to retain the filter selection
        setFilterValues(filterData)

        // Reset filter data if not filter selected
        if (departureFromDate === '' && arrivalFromDate === '' && !utc && !departedFlights && !received && !notReceived && (!flightNo || flightNo === '') && (!awbNumber || awbNumber === '')) {
            setFlightFilter(null)
        }

        if ((departureFromDate !== '') && (departureToDate !== '') && (arrivalFromDate !== '') && (arrivalToDate !== '')) {
            //    added

            setDepFromErrorMessage(false);
            setDepToErrorMessage(false);
            setArrivalFromErrorMessage(false);
            setArrivalToErrorMessage(false);


        }
        else {


            setDepFromErrorMessage(true);
            setDepToErrorMessage(true);
            setArrivalFromErrorMessage(true);
            setArrivalToErrorMessage(true);
            //setDateError(true);

        }


    }

    const clearError = (key) => {
        const tempError = error ? { ...error } : null
        if (tempError) {
            tempError[key] = null
            setError(tempError)
        }
    }

    const getMinDate = () => {
        if (flightFromDate) {
            let minDateCalculated = moment(flightFromDate)
            return minDateCalculated._d;
        }
    }

    const getMaxDate = () => {
        if (flightToDate) {
            let maxDateCalculated = moment(flightFromDate).add(1, 'days');
            return maxDateCalculated._d;
        }
    }

    const handleDepartureFromDate = (date) => {
        date ? setDepartureFromDate(moment(date).format('DD-MMM-YYYY'))
            : setDepartureFromDate('')

        if (date && departureToDate === '') {
            setDepDateError(true)
        } else {
            setDepDateError(false)
        }
    }

    const handleDepartureToDate = (date) => {
        date ? setDepartureToDate(moment(date).format('DD-MMM-YYYY'))
            : setDepartureToDate('')
        if (date && departureFromDate === '') {
            setDepDateError(true)
        } else {
            setDepDateError(false)
        }

    }

    const handleArrivalFromDate = (date) => {
        date ? setArrivalFromDate(moment(date).format('DD-MMM-YYYY'))
            : setArrivalFromDate('')
        if (date && arrivalToDate === '') {
            setArrDateError(true)
        } else {
            setArrDateError(false)
        }
    }

    const handleArrivalToDate = (date) => {
        date ? setArrivalToDate(moment(date).format('DD-MMM-YYYY'))
            : setArrivalToDate('')
        if (date && arrivalFromDate === '') {
            setArrDateError(true)
        } else {
            setArrDateError(false)
        }

    }

    const resetFilter = (awbFilterChecked) => {
        flightSummaryApplyFilter(null)
        setFilterSummary({});
        setDepartureFromDate('');
        setDepartureToDate('');
        setArrivalFromDate('');
        setArrivalToDate('');
        setDepartureTime([0, 1439]);
        setDepartureTimeInHours([
            '0:00 AM',
            '23:59 PM',
        ]);
        setArrivalTime([0, 1439]);
        setArrivalTimeInHours([
            '0:00 AM',
            '23:59 PM',
        ]);
        setReceived(false);
        setNotReceived(false);
        setUtc(false);
        setTimeText('');
        setStdOptions([])
        setTimeError(false)
        setDepDateError(false)
        setArrDateError(false)
        setFiltered(false)
        //setFlightFilter(null)
        setCarrierCode('EY')
        setFlightNo('')
        setAwbPrefix('607')
        setAwbNumber('')
        setError(null)
        if (!awbFilterChecked) {
            setFilterByAwbNumber(false)
        }
        setDepartedFlights(false)
    }

    const regex = new RegExp('^([0-9][0-9][0-9]*):[0-5][0-9]$');

    const handleText = (e) => {

        setTimeText(e.target.value);
        if ((e.target.value.charAt(0) === '+') || (e.target.value.charAt(0) === '-')) {
            //setTimeError(false);
            let timeEntered = e.target.value.substring(1)
            if (regex.test(timeEntered) === false) {
                setTimeError("Please enter valid time format")
            }
            else {
                setTimeError(false);
            }
        }
        else if (e.target.value === '') {
            setTimeError("Please enter valid time format")
        }
        else {
            if (regex.test(e.target.value) === false) {
                setTimeError("Please enter valid time format")
            }
            else {
                setTimeError(false);
            }
        }
    }

    const validateFlightNo = (flightNo) => {
        let newFlightNumber = flightNo
        if (flightNo) {
            if (flightNo.length < 4) {
                newFlightNumber = padLeadingZeros(flightNo, 4)
                setFlightNo(newFlightNumber)
            }
            if (flightNo.length > 0) {
                const lastDigit = newFlightNumber.charAt(newFlightNumber.length - 1)
                if (isNaN(lastDigit)) {
                    newFlightNumber = padLeadingZeros(flightNo, 5)
                }
                newFlightNumber = newFlightNumber.toUpperCase()
                setFlightNo(newFlightNumber)
            }

            return validateFlightNoRegex(
                newFlightNumber,
                carrierCode,
                setError
            )
        } else {
            return true
        }
    }

    const handleChangeOfFlightNo = (flightNumber) => {
        if (!flightNumber) {
            setFlightNo('')
        }
        if (flightNumber && flightNumber.length <= 5) {
            setFlightNo(flightNumber)
        }
    }

    const handleChangeOfAWB = (value) => {
        if (value.length <= 8) {
            setAwbNumber(value)
        }
    }

    return (
        <li className='filter__item'>
            <div className={`showFilter csn-filter filter-link ${flightFilter ? 'active' : ''}`} onClick={(e) => showFilter(e)}>
                <span className='icon-filter'>
                    <img className='uld-form filter-icon' src={flightFilter ? filterActiveIcon : filterIcon} />
                </span>
                <span>Filters</span>
            </div>
            <div className='b-none d-lg-block'>
                <OverlayPanel
                    ref={op}
                    className='custom_overlay_panel'
                    appendTo={document.body}
                >

                    <div>
                        <div className="popup-header">
                            <button type="button" className='ml-3 primary-button'
                                onClick={() => resetFilter()}>Clear</button>
                        </div>
                    </div>


                    <div className='form-container pt-3 cargo-portal-container'>
                        <div className='form-row flight-filter'>
                            <div className={`col-12 col-md-6 col-lg-3  ${(filterByAwbNumber || utc || departedFlights) ? 'disable-time-selection' : ''}`}>
                                <label className='form-item__label filter-title' style={{ 'font-weight': 'bold' }}>{t('COMMON.FLT_NO')}</label>

                                <div className='form-row'>
                                    <div className='col-3 col-md-3'>
                                        <input
                                            id='carrierCode'
                                            name='carrierCode'
                                            type='text'
                                            className='form-item__field textFiledCaps'
                                            aria-invalid={hasError('carrierCode', error)}
                                            maxLength='2'
                                            value={carrierCode}
                                            onKeyPress={(envt) => {
                                                return allowAlphaOnly(envt)
                                            }}
                                            onChange={(e) => {
                                                setCarrierCode(e.target.value)
                                            }}
                                            onBlur={(e) => {
                                                validateCarrierCodeRequired(
                                                    flightNo,
                                                    e.target.value,
                                                    setError,
                                                    'carrierCode'
                                                )
                                            }}
                                            onFocus={(e) => {
                                                removeError(e, setError)
                                            }}
                                        />
                                        <span className='form-item__error'>
                                            {error && error['carrierCode']
                                                ? t(error['carrierCode'], { ns: 'errors' })
                                                : ''}
                                        </span>
                                    </div>

                                    <div className='col-9 col-md-9 col-flt-no'>
                                        <input
                                            id='flightNumber'
                                            name='flightNumber'
                                            type='text'
                                            maxLength={5}
                                            placeholder='Flight number'
                                            className='form-item__field'
                                            aria-invalid={hasError('flightNumber', error)}
                                            value={flightNo}
                                            onKeyPress={(e) => {
                                                return restrictSpaceSpecial(e)
                                            }}
                                            onFocus={(e) => {
                                                removeError(e, setError)
                                            }}
                                            onChange={(e) => handleChangeOfFlightNo(e.target.value)}
                                            onBlur={(e) => {
                                                validateFlightNo(e.target.value)
                                            }}
                                        />
                                        <span className='form-item__error'>
                                            {error && error['flightNumber']
                                                ? t(error['flightNumber'], { ns: 'errors' })
                                                : ''}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 col-lg-6'>
                                <div className='col-md status-field filterField_wrap stops-radio-wrapper mt-12'>
                                    <label className="filter-title">Status</label>
                                    <div class="form-row m-0 mt-6">
                                        <div class="checkbox-custom">
                                            <div class="checkbox-item">

                                                <input
                                                    id='received'
                                                    type='checkbox'
                                                    value={received}
                                                    name='numberOfStops'
                                                    className='checkbox-item__field'
                                                    aria-describedby='nonStop'
                                                    onChange={handleRadioFinalized}
                                                    checked={received}
                                                />
                                                <label htmlFor='received' className='checkbox-item__label'>
                                                    Received
                                                </label>
                                            </div>
                                        </div>

                                        <div class="checkbox-custom">
                                            <div class="checkbox-item">
                                                <input
                                                    id='not-received'
                                                    type='checkbox'
                                                    value={notReceived}
                                                    name='numberOfStops'
                                                    className='checkbox-item__field'
                                                    aria-describedby='oneStop'
                                                    onChange={handleRadioOnHold}
                                                    checked={notReceived}
                                                />
                                                <label htmlFor='not-received' className='checkbox-item__label'>
                                                    Not received
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-5'>
                                {/*filterByAwbNumber &&
                                <div className='form-row mt-4'>
                                    <div className='col-12'>
                                        <div className='nudge'>
                                            <p>This will show the flight details having the entered AWB number</p>
                                        </div>
                                    </div>
                                </div>
                            */ }
                            </div>
                        </div>
                    </div>
                    <div className={`row form-container pt-3 ${filterByAwbNumber ? 'disable-time-selection' : ''}`}>

                        <div className="col-md-6 row form-columns border-BR">
                            <div className={(utc !== false) || (timeText !== '') || departedFlights ? 'disable-time-selection row' : 'row'}>
                                <div className='col-md-12 elements-col'>
                                    <label className="filter-title">Departure Date</label>
                                </div>
                                <div className={`col-md-6 elements-col custom-filterDate ${departureFromDate === '' && departureToDate !== '' ? 'red-border' : ''}`}>
                                    <Calendar
                                        className='form-control'
                                        value={new Date(departureFromDate)}
                                        onChangeHandler={handleDepartureFromDate}
                                        onFocusHandler={clearError}
                                        placeholder='From Date'
                                        minDate={getMinDate()}
                                        maxDate={getMaxDate()}
                                        onErrorHandler={''}
                                    />
                                    <label className='validation-error'>{departureFromDate === '' && departureToDate !== '' ? "Please select date" : ''}</label>
                                </div>
                                <div className={`col-md-6 elements-col custom-filterDate ${departureToDate === '' && departureFromDate !== '' ? 'red-border' : ''}`}>
                                    <Calendar
                                        className='form-control'
                                        value={new Date(departureToDate)}
                                        onChangeHandler={handleDepartureToDate}
                                        onFocusHandler={clearError}
                                        placeholder='To Date'
                                        minDate={getMinDate()}
                                        maxDate={getMaxDate()}
                                        onErrorHandler={''}
                                    />
                                    <label className='validation-error'>{departureToDate === '' && departureFromDate !== '' ? "Please select date" : ''}</label>
                                </div>
                                <div className="col-md-12 elements-col mt-3">
                                    <label className="filter-title">Departure time</label>
                                </div>
                                <div className={(utc !== false) || departedFlights || (timeText !== '') || (departureFromDate === '') || (departureToDate === '') ? 'disable-time-selection col-md-12 elements-col' : 'col-md-12 elements-col'}>
                                    <Slider
                                        value={departureTime}
                                        onChange={(e) =>
                                            covertDecimalToTime('departure', e.value)
                                        }
                                        range
                                        min={0}
                                        max={1439}
                                        className='mt-3 mb-4'
                                    />
                                    <div class='row'>
                                        <div class='col-12 col-md'>
                                            <span className='p-inputgroup time-panel'>
                                                <InputText value={departureTimeInHours[0]} />
                                                {/* <span className='p-inputgroup-addon'>
																<img src={clockIcon} alt='Clock' title='Clock' />
															</span> */}
                                            </span>
                                        </div>
                                        <div className='seperator'> - </div>
                                        <div class='col-12 col-md'>
                                            <span className='p-inputgroup time-panel'>
                                                <InputText value={departureTimeInHours[1]} />
                                                {/* <span className='p-inputgroup-addon'>
																<img src={clockIcon} alt='Clock' title='Clock' />
															</span> */}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className={`input-dropdown-wrapper col-12 col-md-8 ml-0 stops-radio-wrapper`}>
                                    <div class="checkbox-custom pr-0">
                                        <div class="checkbox-item">
                                            <input
                                                id='utc'
                                                type='checkbox'
                                                value={utc}
                                                // name='numberOfStops'
                                                className='checkbox-item__field'
                                                // aria-describedby='nonStop'
                                                onChange={handleRadioUtc}
                                                checked={utc}

                                            />
                                            <label htmlFor='utc' className='checkbox-item__label filter-title'>
                                                Flights departed within last X hours
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-4 ml-0 input-dropdown-wrapper mt-3 cargo-portal-container'>
                                    <Dropdown
                                        value={timeText}
                                        disabled={utc && !departedFlights ? false : true}
                                        options={stdOptions}
                                        label=''
                                        onChange={(e) => {
                                            handleText(e)
                                        }}
                                        isUpdate={true}
                                    ></Dropdown>
                                    <input type='text' value={timeText} onChange={handleText} placeholder='HH:MM'
                                        className={`form-item__field std-input ${!utc || departedFlights ? 'form-item--disabled' : ''}`} disabled={utc && !departedFlights ? false : true} />
                                    <span className='form-item__error mb-1'>{timeError ? timeError : ''}</span>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6 row form-columns">
                            <div className={`col-md-12 elements-col ${utc || departedFlights ? 'disable-time-selection' : ''}`}>
                                <label className="filter-title">Arrival Date</label>
                            </div>
                            <div className={`${utc || departedFlights ? 'disable-time-selection ' : ''}col-md-6 elements-col custom-filterDate ${arrivalFromDate === '' && arrivalToDate !== '' ? 'red-border' : ''}`}>
                                <Calendar
                                    className='form-control'
                                    value={new Date(arrivalFromDate)}
                                    onChangeHandler={handleArrivalFromDate}
                                    onFocusHandler={clearError}
                                    placeholder='From Date'
                                    minDate={getMinDate()}
                                    maxDate={getMaxDate()}
                                    onErrorHandler={''}
                                />
                                <label className='validation-error'>{arrivalFromDate === '' && arrivalToDate !== '' ? "Please select date" : ''}</label>
                            </div>

                            <div className={`${utc || departedFlights ? 'disable-time-selection ' : ''}col-md-6 elements-col custom-filterDate ${arrivalToDate === '' && arrivalFromDate !== '' ? 'red-border' : ''}`}>

                                <Calendar
                                    className='form-control'
                                    value={new Date(arrivalToDate)}
                                    onChangeHandler={handleArrivalToDate}
                                    onFocusHandler={clearError}
                                    placeholder='To Date'
                                    minDate={getMinDate()}
                                    maxDate={getMaxDate()}
                                    onErrorHandler={''}
                                />
                                <label className='validation-error'>{arrivalFromDate !== '' && arrivalToDate == '' ? "Please select date" : ''}</label>
                                {/* <label className='validation-error'>{ setDepFromErrorMessage||setDepToErrorMessage ? "Please select date" : ''}</label> */}
                            </div>
                            <div className={`${utc || departedFlights ? 'disable-time-selection ' : ''} col-md-12 elements-col mt-3`}>
                                <label className="filter-title">Arrival time</label>
                            </div>
                            <div className={`col-md-12 elements-col`}>
                                <div className={(arrivalFromDate === '') || (arrivalToDate === '') || utc || departedFlights ? 'disable-time-selection' : ''}>
                                    <Slider
                                        value={arrivalTime}
                                        onChange={(e) =>
                                            covertDecimalToTime('arrival', e.value)
                                        }
                                        range
                                        min={0}
                                        max={1439}
                                        className='mt-3 mb-4'
                                    />
                                    <div class='row'>
                                        <div class='col-12 col-md'>
                                            <span className='p-inputgroup time-panel'>
                                                <InputText value={arrivalTimeInHours[0]} />
                                                {/* <span className='p-inputgroup-addon'>
																<img src={clockIcon} alt='Clock' title='Clock' />
															</span> */}
                                            </span>





                                        </div>
                                        <div className='seperator'> - </div>

                                        <div class='col-12 col-md'>
                                            <span className='p-inputgroup time-panel'>
                                                <InputText value={arrivalTimeInHours[1]} />
                                                {/* <span className='p-inputgroup-addon'>
																<img src={clockIcon} alt='Clock' title='Clock' />
															</span> */}
                                            </span>



                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>

                        <div className='col-12'>
                            <div className='popup-footer'>
                                <button
                                    type='button'
                                    className='secondary-button'
                                    onClick={(e) => {
                                        op.current.hide(e)
                                        //resetFilter()
                                    }

                                    }
                                >
                                    {t('COMMON.CANCEL')}
                                </button>
                                <button
                                    type='button'
                                    className='ml-3 primary-button'
                                    onClick={(e) => {
                                        onFilter(e)
                                        op.current.hide(e)
                                    }
                                    }
                                    // disabled={( (timeError)||(dateError)||(arrivalFromDate !=='')||(arrivalToDate !==''))? true : false}
                                    disabled={((timeError) || depDateError || arrDateError || (hasError('awbNumber', error)) || hasError('flightNumber', error)) ? true : false}


                                >
                                    {t('COMMON.FILTER')}
                                </button>
                            </div>
                        </div>
                    </div>
                </OverlayPanel>
            </div>
        </li>
    )
}

export default FilterPopup;