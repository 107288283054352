import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid'
import { connect } from 'react-redux'
import AirportAutocomplete from '../AirportAutocomplete';
import { Dropdown } from 'primereact/dropdown'
import './permanent-booking.scss';
import { ALLOWABLE_BOOKING_DATE } from '../../configs/appConfigs'
import ShippingItemAutocomplete from '../ShippingItemAutocomplete';
import { SHIPPING_ITEM_PLACEHOLDER } from '../../constants/Placeholders';
import SccAutoComplete from '../SccAutoComplete'
import moment from 'moment';
import RecommendedSccList from '../RecommendedSccList/RecommendedSccList'
import MESSAGES from '../../constants/Messages'
import { Calendar } from 'primereact/calendar'
import {
    validateRequired,
    hasError,
    removeError
} from './PermanentBookingUtils'
import {
    checkSccCompatibility,
    getAirportsByCode,
    getServices,
    getShippingItems,
    getULDTypeByCode,
} from '../../services/searchPanelServices'
import {
    reprocessPermanentbooking,
    getPermanentBookingsForFile
} from "../../services/permanentService";
import {
    getCountryByCode,
} from "../../services/dataCaptureService";
import getCurrentUser from '../../utils/getCurrentUser';
import LoadingIndicator from "../../components/LoadingIndicator";
import {
    getToastSuccess,
    getToastError
} from '../../redux/actions/toastActions';
import { Alert } from '../../common/Alert';
import ULDTypeAutocomplete from '../ULDTypeAutocomplete';
import LooseItemForm from '../LooseItemForm'
import {
    FORM_FIELDS,
    ULD_FORM_FIELDS,
    DIMENSION_UNIT,
    WEIGHT_UNIT_SELECT_ITEMS,
    WEIGHT_UNIT_SELECT_ITEMS_WO_TON,
    ULD_TYPES_WITH_CONTOUR,
    AUTOCOMPLETE_TYPE,
    SHIPPING_TYPE,
    getInitLooseItemDetail,
    getInitPharmaObject,
    getInitULDItemDetail
} from '../../constants';

const PermanentBookingForm = ({
    ULDDetails,
    searchFormData,
    parsedbookingItem,
    bookingItem,
    fileName,
    toastDetails,
    getToastSuccess,
    getToastError,
    setBookingList,
    setNotificationMessage,
    setNotificationType,
    setAutoRefresh,
    setEditMode,
    changeEditMode,
    setEditButtonActive
}) => {
    const { t, i18n } = useTranslation(['labels', 'errors']);
    const [error, setError] = useState('');
    //const [maxShippingDate, setMaxShippingDate] = useState(moment().add(ALLOWABLE_BOOKING_DATE - 1, 'days').toDate())
    const [shippingDate, setShippingDate] = useState(null)
    const [sccWarning, setSccWarning] = useState(null);
    const [shippingItems, setShippingItems] = useState([])
    const [uldVal, setUldVal] = useState([])

    const [shippingItem, setShippingItem] = useState(null)
    const [promoCode, setPromoCode] = useState([])
    const [awbNumber, setAwbNumber] = useState(null)
    const [contour, setContour] = useState([])
    const [numberOfUlds, setNoofUlds] = useState([])
    const [diableButton, setDisableButton] = useState(true)
    const [segmentOrigin, setSegmentOrigin] = useState([])
    const [flightNumber, setFlightNumber] = useState([])
    const [segmentDestination, setSegmentDestination] = useState([])
    const [aircraftType, setAirCraftType] = useState([])
    const [sccCode, setSccCodeOne] = useState([])
    const [uldWeight, setUldWeight] = useState([])
    const [isLoader, setLoader] = useState(true)

    const [sch1, setSCH1] = useState('');
    const [sch2, setSCH2] = useState('');
    const [sch3, setSCH3] = useState('');

    const [isLoading, setLoading] = useState(false);
    //const [editButtonHidden, setEditButtonHidden] = useState(true);
    const [weightUnit, setWeightUnit] = useState('');
    const [errorBanner, setErrorBanner] = useState(null);

    const [uldType, setUldType] = useState([])
    const [uldVolume, setUldVolume] = useState([])
    const [flight, setFlight] = useState([{}])
    const [flag, setFlag] = useState(false)

    const CONTOUR_ITEMS = [
        { label: t('BOOKING.MAIN_DECK'), value: 'MD' },
        { label: t('BOOKING.LOWER_DECK'), value: 'LD' },
    ]

    const uldTypeError =
        error &&
            error[FORM_FIELDS.ULD_ITEMS] &&
            error[FORM_FIELDS.ULD_ITEMS][index] &&
            error[FORM_FIELDS.ULD_ITEMS][index][ULD_FORM_FIELDS.ULD_TYPE]
            ? error[FORM_FIELDS.ULD_ITEMS][index][ULD_FORM_FIELDS.ULD_TYPE]
            : null
    const contourError =
        error &&
            error[FORM_FIELDS.ULD_ITEMS] &&
            error[FORM_FIELDS.ULD_ITEMS][index] &&
            error[FORM_FIELDS.ULD_ITEMS][index][ULD_FORM_FIELDS.CONTOUR]
            ? error[FORM_FIELDS.ULD_ITEMS][index][ULD_FORM_FIELDS.CONTOUR]
            : null
    const piecesError =
        error &&
            error[FORM_FIELDS.ULD_ITEMS] &&
            error[FORM_FIELDS.ULD_ITEMS][index] &&
            error[FORM_FIELDS.ULD_ITEMS][index][ULD_FORM_FIELDS.PIECES]
            ? error[FORM_FIELDS.ULD_ITEMS][index][ULD_FORM_FIELDS.PIECES]
            : null
    const weightError =
        error &&
            error[FORM_FIELDS.ULD_ITEMS] &&
            error[FORM_FIELDS.ULD_ITEMS][index] &&
            error[FORM_FIELDS.ULD_ITEMS][index][ULD_FORM_FIELDS.WEIGHT]
            ? error[FORM_FIELDS.ULD_ITEMS][index][ULD_FORM_FIELDS.WEIGHT]
            : null


    const [selectedOrigin, setSelectedOrigin] = useState(null)
    const [selectedFlightOrigin, setSelectedFlightOrigin] = useState([]);
    const [selectedFlightDestination, setSelectedFlightDestination] = useState([]);
    const [defaultOrigin, setDefaultOrigin] = useState(null)
    const [selectedDestination, setSelectedDestination] = useState(
        parsedbookingItem ? parsedbookingItem?.shipmentDetails?.shipmentDestination : null
    )
    const [totalWeight, setTotalWeight] = useState(
        parsedbookingItem?.bookingCommodityDetails[0]?.weight ? parsedbookingItem?.bookingCommodityDetails[0]?.weight : 0
    )
    const [recommendedServices, setRecommendedServices] = useState([])
    const [shipmentSccs, setShipmentSccs] = useState(
        searchFormData ? searchFormData.shipmentSccs : []
    )
    const [appliedServices, setAppliedServices] = useState(
        searchFormData ? searchFormData.appliedServices : []
    )
    const [services, setServices] = useState([])

    const [looseDetails, setLooseDetails] = useState([{}])
    const [flightLegs, setFlightLegs] = useState([])
    const [originCurrencyCode, setOriginCurrencyCode] = useState(null);



    const handleBack = () => {
        history.push({
            pathname: '/permanent-booking',
            state: {
                retainFormData: true,
            },
        })
        window.scrollTo(0, 0)
    }
    const handleSetError = (key, text) => {
        error
            ? setError({
                ...error,
                [key]: text,
            })
            : setError({
                [key]: text,
            })
    }


    useEffect(() => {
        let shippingItemVal = {
            key: parsedbookingItem?.bookingCommodityDetails[0]?.shipmentDescription,
            description: parsedbookingItem?.bookingCommodityDetails[0]?.shipmentDescription,
            product: parsedbookingItem?.shipmentDetails.productDetails.productName,
            scc: parsedbookingItem?.sccCodes[0]
        }
        setShippingItem(shippingItemVal);
    }, [parsedbookingItem])



    useEffect(() => {
        parsedbookingItem?.bookingFlightDetails?.map(item => {
            item.carrierCode = item?.carrierCode?.slice(0, 2)
        })
        setUldVal(parsedbookingItem?.bookingCommodityDetails[0]?.uldInfoDetaills);

        let uldTypes = [];
        let contours = [];
        parsedbookingItem?.bookingCommodityDetails[0]?.uldInfoDetaills?.map((item, index) => {
            if (item?.uldType) {
                uldTypes.push(item.uldType);
            }
            if (item?.contour) {
                contours[index] = item.contour;
            }
        })
        setUldType(uldTypes);
        setContour(contours);


        (async () => {
            try {
                let shippingItems = await getShippingItems()
                if (shippingItems) {
                    shippingItems = shippingItems.sort((a, b) => {
                        if (a.description < b.description) {
                            return -1
                        }
                        if (a.description > b.description) {
                            return 1
                        }
                        return 0
                    })
                }
                setShippingItems(shippingItems)
            } catch (err) {
                console.log(err)
            }
        })()
        if (parsedbookingItem?.bookingFlightDetails?.length == 0) {
            setFlight([{}])
        }
        else {
            setFlight(parsedbookingItem?.bookingFlightDetails)
        }
        if (parsedbookingItem?.bookingCommodityDetails[0]?.uldInfoDetaills === null) {
            setUldVal(null)
        }
        else if (parsedbookingItem?.bookingCommodityDetails[0]?.uldInfoDetaills?.length === 0) {
            setUldVal([{}])
        }
        else {
            setUldVal(parsedbookingItem?.bookingCommodityDetails[0]?.uldInfoDetaills);
        }
        if (parsedbookingItem?.bookingCommodityDetails[0]?.dimensionDetaills == null) {
            setLooseDetails(null)
        }
        else if (parsedbookingItem?.bookingCommodityDetails[0]?.dimensionDetaills?.length === 0) {
            setLooseDetails([{}])
        }
        else {
            setLooseDetails(parsedbookingItem?.bookingCommodityDetails[0]?.dimensionDetaills)
        }
    }, [])
    useEffect(() => {
        //    setLooseDetails (parsedbookingItem?.bookingCommodityDetails[0]?.dimensionDetaills);

        var total_weight = 0;
        var total_pieces = [];

        parsedbookingItem?.bookingCommodityDetails[0]?.dimensionDetaills?.map((item, index) => {
            if (item?.weight) {
                total_weight = total_weight + item.weight;
            }
        })


        parsedbookingItem?.bookingCommodityDetails[0]?.dimensionDetaills?.map(i => i.pieces = i.numberOfPieces);
        parsedbookingItem?.bookingCommodityDetails[0]?.dimensionDetaills?.map(item => item.stackable === "Y" ? item.stackable = true : item.stackable = false)
        parsedbookingItem?.bookingCommodityDetails[0]?.dimensionDetaills?.map(item => item.tiltable === "Y" ? item.turnable = true : item.turnable = false)

        setLooseItemsTotalWeight(total_weight);

    }, [parsedbookingItem])

    useEffect(() => {
        setSCH1(parsedbookingItem?.sccCodes[1]);
        setSCH2(parsedbookingItem?.sccCodes[2]);
        setSCH3(parsedbookingItem?.sccCodes[3]);
        setPromoCode(parsedbookingItem?.promoCode);
        if (parsedbookingItem?.shipmentIdentifierDetails?.shipmentPrefix && parsedbookingItem?.shipmentIdentifierDetails?.masterDocumentNumber) {
            setAwbNumber(parsedbookingItem?.shipmentIdentifierDetails?.shipmentPrefix + parsedbookingItem?.shipmentIdentifierDetails?.masterDocumentNumber);
        }
        setWeightUnit(parsedbookingItem?.weightUnit);
        let shippingItemVal = {
            key: parsedbookingItem?.bookingCommodityDetails[0]?.shipmentDescription,
            description: parsedbookingItem?.bookingCommodityDetails[0]?.shipmentDescription,
            product: parsedbookingItem?.shipmentDetails.productDetails.productName,
            scc: parsedbookingItem?.sccCodes[0]
        }
        setShippingItem(shippingItemVal);
        setErrorBanner(bookingItem?.message);
    }, [])

    useEffect(() => {

        // if(parsedbookingItem?.shipmentDetails?.shipmentOrigin){

        let flightInfoArray = []
        parsedbookingItem?.bookingFlightDetails.map((item, index) => {
            let origin = {}
            let destination = {}
            if (item?.segmentOrigin) {
                getAirportsByCode(item.segmentOrigin).then((res) => {
                    Object.assign(origin, res);
                })
            }
            if (item?.segmentDestination) {
                getAirportsByCode(item.segmentDestination).then((res) => {
                    Object.assign(destination, res);
                })
            }
            let flightObj = {
                origin,
                destination
            }
            flightInfoArray.push(flightObj)
        });
        setFlightLegs(flightInfoArray);

        getAirportData(parsedbookingItem?.shipmentDetails?.shipmentOrigin, setSelectedOrigin)
        getAirportData(parsedbookingItem?.shipmentDetails?.shipmentDestination, setSelectedDestination)
    }, [])

    const getAirportData = async (code, setState) => {
        getAirportsByCode(code).then((res) => {
            setState(res)
            if (res?.code) {
                setLoader(false)
            }
        })
    }


    const draftref = useRef(false);
    const draftCallback = () => {
        draftref.current = true
    }


    const [notLeave, setNotLeave] = useState(draftref?.current);
    const [draftName, setDraftName] = useState(`Draft`)

    const [isAllStackable, setAllStackable] = useState(false)
    const [isAllTiltable, setAllTiltable] = useState(false)
    const handleAddLineItem = (e) => {
        e.preventDefault()
        if (shippingType === SHIPPING_TYPE.LOOSE) {
            setLooseDetails([
                ...looseDetails,
                handleTilteableAndStackable(
                    getInitLooseItemDetail(),
                    isAllTiltable,
                    isAllStackable
                ),
            ])
        } else {
            setULDDetails([...ULDDetails, getInitULDItemDetail()])
        }
    }
    //const [totalWeight, setTotalWeight] = useState(null)
    const [totalPieces, setTotalPieces] = useState(
        searchFormData ? searchFormData.totalPieces : 0
    )
    const [totalVolumeInMtrs, setTotalVolumeInMeters] = useState(
        searchFormData ? searchFormData.totalVolumeInMtrs : 0
    )
    const [chargeableWeight, setChargeableWeight] = useState(
        searchFormData ? searchFormData.chargeableWeight : 0
    )
    const [displayShippingItemPopup, setDisplayShippingItemPopup] = useState(false);
    const clearError = (key) => {
        const tempError = error ? { ...error } : null
        if (tempError) {
            tempError[key] = null
            setError(tempError)
        }
    }
    const [looseItemsTotalWeight, setLooseItemsTotalWeight] = useState(
        parsedbookingItem ? parsedbookingItem?.bookingCommodityDetails[0]?.uldInfoDetaills?.weight : null
    )
    const [dimensionUnit, setDimensionUnit] = useState(
        parsedbookingItem ? parsedbookingItem.dimensionUnit : DIMENSION_UNIT.CENTIMETERS
    )


    const [looseItemsGrossWeight, setLooseItemsGrossWeight] = useState(0)
    const [calculatedTotalWeight, setCalculatedTotalWeight] = useState(0)
    const [chargeableWeightInSelectedUnit, setChargeableWeightInSelectedUnit] =
        useState(0)

    const [totalWeightInKg, setTotalWeightInKg] = useState(
        searchFormData ? searchFormData.totalWeightInKg : 0
    )
    const [actualVolume, setActualVolume] = useState(0)
    const [actualGrossWeight, setActualGrossWeight] = useState(0)
    const [actualTotalIneItemWeight, setActualTotalIneItemWeight] = useState(0)
    const [actualChargeableWeight, setActualChargeableWeight] = useState(0)

    const [grossWeightInSelectedUnit, setGrossWeightInSelectedUnit] = useState(0)
    const [grossWeightInConvertedUnit, setGrossWeightInConvertedUnit] =
        useState(0)
    const [densityInSelectedUnit, setDensityInSelectedUnit] = useState(0)
    const [densityInConvertedUnit, setdensityInConvertedUnit] = useState(0)
    const [adjustedWeight, setAdjustedWeight] = useState(
        searchFormData ? searchFormData.adjustedWeight : null
    )
    const [showOpWeight, setShowOpweight] = useState(
        searchFormData ? searchFormData.showOpWeight : false
    )

    const handleSubmit = (e) => {
        e.preventDefault();
        if (uldVal?.length > 0) {
            let totalWeight = 0;
            for (let i = 0; i < uldVal?.length; i++) {
                totalWeight = totalWeight + uldVal[i].numberOfUlds * uldVal[i].uldWeight
            }
            // const calculatedTotalWeight = [...uldVal, {}].reduce((curr, next) => {
            // 	return {
            // 		weight:
            // 			(curr.uldWeight ? curr.uldWeight * 1 : 0) *
            // 				(curr.numberOfUlds ? curr.numberOfUlds * 1 : 0) +
            // 			(next.uldWeight ? next.uldWeight * 1 : 0) *
            // 				(next.numberOfUlds ? next.numberOfUlds * 1 : 0),
            // 		pieces: 1,
            // 	}
            // }).weight
            // setTotalWeight(calculatedTotalWeight)

            parsedbookingItem.bookingCommodityDetails[0].weight = totalWeight;
            parsedbookingItem.bookingCommodityDetails[0].uldInfoDetaills = uldVal;
        }
        parsedbookingItem.bookingFlightDetails = flight;


        let userDetails = getCurrentUser();

        //console.log(bookingItem)
        if (shippingItem?.code) {
            let bookingCommodityDetails = parsedbookingItem?.bookingCommodityDetails[0];
            bookingCommodityDetails.commodityCode = shippingItem.code;
            bookingCommodityDetails.shipmentDescription = shippingItem.description;
            parsedbookingItem.sccCodes.splice(0, 1, shippingItem.scc);

            let shipmentDetails = parsedbookingItem?.shipmentDetails?.productDetails;
            shipmentDetails.productName = shippingItem.product;
        }
        if (shippingDate) {
            let shipmentDetails = parsedbookingItem?.shipmentDetails;
            shipmentDetails.shippingDate = parsedbookingItem?.bookingFlightDetails[0]?.flightDate
        }
        if (originCurrencyCode) {
            let shipmentDetails = parsedbookingItem?.shipmentDetails;
            shipmentDetails.currency = originCurrencyCode;
        }
        looseDetails?.map(i => i.tiltable = i.turnable === true ? "Y" : "N");
        looseDetails?.map(i => i.stackable === true ? i.stackable = "Y" : i.stackable = "N");
        looseDetails?.map(i => i.length = parseInt(i.length));
        looseDetails?.map(i => i.height = parseInt(i.height));
        looseDetails?.map(i => i.width = parseInt(i.width));
        looseDetails?.map(i => i.weight = 0);
        looseDetails?.map(i => i.numberOfPieces = parseInt(i.pieces));
        looseDetails?.map(i => i.pieces = parseInt(i.pieces));
        if (looseDetails?.length >= 0) {
            parsedbookingItem.bookingCommodityDetails[0].dimensionDetaills = looseDetails;
            parsedbookingItem.bookingCommodityDetails[0].weight = parseInt(totalWeight);
        }
        else {
            setLooseDetails(null)
            parsedbookingItem.bookingCommodityDetails[0].dimensionDetaills = null;
        }
        //let masterDOcumentNUmberValue = parsedbookingItem?.shipmentIdentifierDetails?.masterDocumentNumber;
        let bookingCommodityDetails = parsedbookingItem?.bookingCommodityDetails[0];
        let uldVolArr = [];
        if (bookingCommodityDetails?.uldInfoDetaills) {
            bookingCommodityDetails?.uldInfoDetaills?.map((uldItem) => {
                uldVolArr.push(uldItem.uldVolume);
            })
            let totalUldVolume = uldVolArr.reduce(function (acc, val) { return acc + val; }, 0)
            // console.log(totalUldVolume,"totalUldVolume")
            bookingCommodityDetails.uldInfoDetaills.volume = totalUldVolume;
        }

        let shipmentIdentifierObj = parsedbookingItem?.shipmentIdentifierDetails;
        if (shipmentIdentifierObj && awbNumber !== null) {
            let shipmentPrefix = awbNumber?.substring(0, 3);
            let masterDocumentNumber = awbNumber?.substring(3, awbNumber.length);
            // let newShipmentIdentifierObj = {};
            shipmentIdentifierObj.shipmentPrefix = parseInt(shipmentPrefix);
            shipmentIdentifierObj.masterDocumentNumber = parseInt(masterDocumentNumber);
        }
        else if (!shipmentIdentifierObj && awbNumber !== null) {
            let shipmentPrefix = awbNumber?.substring(0, 3);
            let masterDocumentNumber = awbNumber?.substring(3, awbNumber.length);
            let newShipmentIdentifierObj = {};
            newShipmentIdentifierObj.shipmentPrefix = parseInt(shipmentPrefix);
            newShipmentIdentifierObj.masterDocumentNumber = parseInt(masterDocumentNumber);
            parsedbookingItem.shipmentIdentifierDetails = newShipmentIdentifierObj;
        }
        else {
            parsedbookingItem.shipmentIdentifierDetails = null;
        }


        let apiData = {
            requestID: uuidv4(),
            bookingGrpId: bookingItem.bookingGroupId,
            id: parsedbookingItem.id,
            fileName: fileName,
            userId: userDetails.userId,
            agentCode: userDetails.agentCode,
            bookingDetails: parsedbookingItem,
        }

        //call reprocess api service
        setLoading(true);
        setTimeout(() => {
            reprocessPermanentbooking(apiData).then((res) => {
                if (res?.status === 200) {
                    setLoading(false);
                    //getToastSuccess("Booking has been processed");
                    //showAlert("processing")
                    // setSubmittedGrpId(apiData?.bookingGrpId);
                    let submittedGroupId = apiData?.bookingGrpId
                    getPermanentBookingsForFile(fileName).then((res) => {

                        let statusList = [];
                        let bookingGrpIdList = [];
                        let request = [];
                        res?.bookingList?.map(item => {
                            statusList.push(item.status)
                        })
                        res?.bookingList?.map(item => {
                            bookingGrpIdList.push(item.bookingGroupId)

                            if (submittedGroupId == item.bookingGroupId) {
                                if (!statusList.includes("Loading")) {

                                    if (item.status == 'Failed') {
                                        getToastError("Resubmission Failed")
                                    }
                                    else if (item.status == 'Confirmed' || item.status == 'To be Processed' || item.status == 'Queued') {
                                        getToastSuccess("Resubmission successful")
                                    }
                                    // else{
                                    //     getToastSuccess("Booking is Success")
                                    // }
                                }
                                else {
                                    setAutoRefresh(submittedGroupId);
                                }
                            }
                        })
                        setBookingList(res)

                    })
                    setEditMode(false);
                    setEditButtonActive(true)
                }
            }).catch((error) => {
                setLoading(false);
                getToastError("Failed to process booking");
            })

        }, 4000)

    }



    const handleAlertClose = () => {
        setErrorBanner(null);
    }
    const handleAddAdditinalScc = async (scc) => {
        let compatible
        const selectedSccList = [
            ...shipmentSccs,
            ...appliedSccs,
            ...additionalSccs,
            ...appliedServices,
        ].map((item) => item.scc)
    }


    const [appliedSccs, setAppliedSccs] = useState(
        searchFormData ? searchFormData.appliedSccs : []
    )
    const [additionalSccs, setAdditionalSccs] = useState(
        searchFormData ? searchFormData.additionalSccs : []
    )
    const [shippingType, setShippingType] = useState(
        searchFormData ? searchFormData.shippingType : SHIPPING_TYPE.LOOSE
    )

    const [additionalInfoData, setAdditionalInfoData] = useState({
        type: null,
        pharma: {},
    })
    const [displayContainerPopup, setDisplayContainerPopup] = useState(false)
    const showWarning = () => {
        setWarning(true)
        setTimeout(function () {
            setWarning(false)
        }, 3000)
    }

    const handleSccChange = (e, index) => {
        setDisableButton(false)
        if (e.target.value === '') {
            parsedbookingItem.sccCodes.splice(index, 1)
        }
    }

    const getCurrency = async (countryCode) => {
        let countryByCode = await getCountryByCode(countryCode)
        setOriginCurrencyCode(countryByCode.currencyCode)
    }

    return (

        <div>
            {
                isLoader ? <LoadingIndicator /> : ''
            }
            {errorBanner != null && (
                <div className='cargo-portal-container'>
                    <Alert
                        isError={errorBanner != null}
                        showAlert={true}
                        message={errorBanner != null && errorBanner}
                        onAlertClose={handleAlertClose}
                        setTimeout={false}
                    />
                </div>
            )}
            {
                isLoading ? <LoadingIndicator /> : ''
            }
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className='permament-view-edit mb-5 search-panel'>
                    {/* <div className='col-md-12'> */}
                    <div class="mt-5 pt-2 mb-4 pb-1 row row-cols-3 row-cols-sm-2 row-cols-md-5">
                        {/* <div className='row mb-0'> */}
                        <div
                            className={`form-group col-2 ${error && error[FORM_FIELDS.ORIGIN] ? 'red-border' : ''
                                }`}
                        >
                            <label className='f-15'>{t('COMMON.ORG')}</label>
                            <div class='input-wrapper f-15'>
                                <AirportAutocomplete
                                    autoCompleteType={AUTOCOMPLETE_TYPE.ORIGIN}
                                    selectedOrigin={selectedOrigin}
                                    selectedDestination={selectedDestination}
                                    setSelectedOrigin={(e) => {
                                        e?.countryCode && getCurrency(e.countryCode)
                                        setDisableButton(false)
                                        setSelectedOrigin(e)
                                        parsedbookingItem.shipmentDetails.shipmentOrigin = e?.code
                                    }}
                                    handleSetError={handleSetError}
                                    clearError={clearError}
                                    draftCallback={draftCallback}
                                    setNotificationMessage={setNotificationMessage}
                                    setNotificationType={setNotificationType}
                                />
                                <span className='search-panel__errormsg'>
                                    {error && error[FORM_FIELDS.ORIGIN]
                                        ? t(error[FORM_FIELDS.ORIGIN], { ns: 'errors' })
                                        : ''}
                                </span>
                            </div>
                        </div>

                        <div
                            className={`form-group col-2 ${error && error[FORM_FIELDS.DESTINATION] ? 'red-border' : ''
                                }`}
                        >
                            <label className='f-15'>{t('COMMON.DEST')}</label>
                            <div class='input-wrapper f-15'>
                                <AirportAutocomplete
                                    autoCompleteType={AUTOCOMPLETE_TYPE.DESTINATION}
                                    selectedOrigin={selectedOrigin}
                                    selectedDestination={selectedDestination}
                                    setSelectedDestination={(e) => {
                                        setDisableButton(false)
                                        setSelectedDestination(e)
                                        parsedbookingItem.shipmentDetails.shipmentDestination = e?.code
                                    }}
                                    handleSetError={handleSetError}
                                    clearError={clearError}
                                    displaySimilarBooking={false}
                                    setNotLeave={setNotLeave}
                                    draftCallback={draftCallback}
                                    setNotificationMessage={setNotificationMessage}
                                    setNotificationType={setNotificationType}
                                />
                                <span className='search-panel__errormsg'>
                                    {error && error[FORM_FIELDS.DESTINATION]
                                        ? t(error[FORM_FIELDS.DESTINATION], { ns: 'errors' })
                                        : ''}
                                </span>
                            </div>
                        </div>

                        <div className='form-group shipping-info-drop col-md-4 shipping-item-row'>
                            <div
                                className={`${error && error[FORM_FIELDS.SHIPPING_ITEM] ? 'red-border' : ''
                                    }`}
                            >
                                <label> {t('COMMON.SHP_ITEM')}</label>
                                <div className='input-wrapper shipping-wrapper'>
                                    <ShippingItemAutocomplete
                                        fieldType='SHIPPING_ITEM'
                                        selectedShippingItem={shippingItem}
                                        setSelectedShippingItem={setShippingItem}
                                        setDisableButton={setDisableButton}
                                        error={error}
                                        clearError={clearError}
                                        setError={setError}
                                        shippingItems={shippingItems}
                                        setDisplayShippingItemPopup={setDisplayShippingItemPopup}
                                        draftCallback={draftCallback}
                                    />
                                </div>
                                <span className='search-panel__errormsg'>
                                    {error && error[FORM_FIELDS.SHIPPING_ITEM]
                                        ? t(error[FORM_FIELDS.SHIPPING_ITEM], { ns: 'errors' })
                                        : ''}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="row row-cols-2 row-cols-sm-2 row-cols-md-5">
                        <div class="form-group mb-0 col-2">
                            <label className='col f-15 mb-2'>{t('DASHBOARD.SHIPMT_DATE')}</label>
                            <span className='col f-15'>{shippingDate ? shippingDate : parsedbookingItem?.shipmentDetails?.shippingDate}</span>
                        </div>

                        <div class="col-2 mb-0  pl-1 pr-3 form-group" >
                            <label className=' additional-style'>{t('PERMANENT_BOOKING.AWB')}</label>
                            <div class='input-wrapper'>
                                <input
                                    type='text'
                                    className='form-control'
                                    value={awbNumber}
                                    id="AwbNumber"
                                    onChange={(e) => {
                                        //parsedbookingItem.awbNumber = parseInt(e.target.value)
                                        setDisableButton(false)
                                        setAwbNumber(e.target.value === '' ? null : e.target.value)
                                    }}

                                    onBlur={(e) => {
                                        validateRequired(e.target.value, setError, 'AwbNumber')
                                    }}
                                    aria-invalid={hasError('AwbNumber', error)}
                                    onFocus={(e) => {
                                        removeError(e, setError)
                                    }}
                                />
                            </div>
                            <span className='form-item__error'>
                                {error && error['AwbNumber']
                                    ? t(error['AwbNumber'], { ns: 'errors' })
                                    : ''}
                            </span>

                        </div>

                        {/* </div> */}
                        <div class="form-group mb-0 col-2">
                            <label className='col f-15 mb-2'>{t('COMMON.STATUS')}</label>
                            <span className='col f-15'>{parsedbookingItem?.bookingStatus ? parsedbookingItem?.bookingStatus : '-'}</span>
                        </div>
                    </div>
                    {/* </div> */}
                    {/* Details 1 */}

                    <div className='mt-5 pb-2'>
                        <h3>{t('COMMON.DTLS')}</h3>
                    </div>
                    {/* <div class="col-12"> */}
                    <div class="row">

                        <div className={`col-2 mb-0 pr-3 form-group`}>
                            <label className="additional-style">SHC-1</label>
                            <div class='input-wrapper'>
                                <SccAutoComplete
                                    handleAddAdditinalScc={(e) => {
                                        parsedbookingItem?.sccCodes.splice(1, 1, e?.scc)
                                        setDisableButton(false)
                                        //handleAddAdditinalScc
                                    }}
                                    handleSccChange={(e) => handleSccChange(e, 1)}
                                    clearError={clearError}
                                    setSccWarning={setSccWarning}

                                    setNotificationMessage={setNotificationMessage}
                                    setNotificationType={setNotificationType}
                                    isCustomized={true}
                                    initialValue={parsedbookingItem?.sccCodes[1] ? parsedbookingItem?.sccCodes[1] : null}
                                />
                            </div>
                            <span className='search-panel__errormsg'>{sccWarning}</span>
                            <RecommendedSccList
                                recommendedSccs={recommendedServices}
                                handleAddAdditinalScc={handleAddAdditinalScc}
                                services={services}
                                showWarning={showWarning}
                            />
                        </div>
                        <div className={`col-2 mb-0 pl-1 pr-3 form-group`}>
                            <label className="additional-style">SHC-2</label>
                            <div class='input-wrapper'>
                                <SccAutoComplete
                                    handleAddAdditinalScc={(e) => {
                                        parsedbookingItem?.sccCodes.splice(2, 1, e?.scc)
                                        setDisableButton(false)
                                        //handleAddAdditinalScc
                                    }}
                                    handleSccChange={(e) => handleSccChange(e, 2)}
                                    clearError={clearError}
                                    setSccWarning={setSccWarning}
                                    setNotificationMessage={setNotificationMessage}
                                    setNotificationType={setNotificationType}
                                    isCustomized={true}
                                    initialValue={parsedbookingItem?.sccCodes[2] ? parsedbookingItem?.sccCodes[2] : null}
                                />
                            </div>
                            <span className='search-panel__errormsg'>{sccWarning}</span>
                            <RecommendedSccList
                                recommendedSccs={recommendedServices}
                                handleAddAdditinalScc={handleAddAdditinalScc}
                                services={services}
                                showWarning={showWarning}
                            />
                        </div>
                        <div className={`col-2 mb-0 pl-1 pr-3 form-group`}>
                            <label className="additional-style">SHC-3</label>
                            <div class='input-wrapper'>
                                <SccAutoComplete
                                    handleAddAdditinalScc={(e) => {
                                        parsedbookingItem?.sccCodes.splice(3, 1, e?.scc)
                                        setDisableButton(false)
                                        //handleAddAdditinalScc
                                    }}
                                    handleSccChange={(e) => handleSccChange(e, 3)}
                                    clearError={clearError}
                                    setSccWarning={setSccWarning}
                                    setNotificationMessage={setNotificationMessage}
                                    setNotificationType={setNotificationType}
                                    isCustomized={true}
                                    initialValue={parsedbookingItem?.sccCodes[3] ? parsedbookingItem?.sccCodes[3] : null}
                                />
                            </div>
                            <span className='search-panel__errormsg'>{sccWarning}</span>
                            <RecommendedSccList
                                recommendedSccs={recommendedServices}
                                handleAddAdditinalScc={handleAddAdditinalScc}
                                services={services}
                                showWarning={showWarning}
                            />
                        </div>



                        <div class="col-2 mb-0  pl-1 pr-3 form-group" >
                            <label className=' additional-style'>{t('BOOKING.PROMO_CODE')}</label>
                            <div class='input-wrapper'>
                                <input
                                    type='text'
                                    className='form-control'
                                    value={promoCode}
                                    id="PromoCode"
                                    onChange={(e) => {
                                        parsedbookingItem.promoCode = e.target.value
                                        setDisableButton(false)
                                        setPromoCode(e.target.value)
                                    }}
                                    autoComplete='off'
                                    onBlur={(e) => {
                                        validateRequired(e.target.value, setError, 'PromoCode')
                                    }}
                                    aria-invalid={hasError('PromoCode', error)}
                                    onFocus={(e) => {
                                        removeError(e, setError)
                                    }}
                                />
                            </div>
                            <span className='form-item__error'>
                                {error && error['PromoCode']
                                    ? t(error['PromoCode'], { ns: 'errors' })
                                    : ''}
                            </span>

                        </div>

                    </div>
                    {/* </div> */}



                    {/* Details2  */}



                    <div className='row'>
                        <div className='form-group col-12  mb-0 '>
                            {parsedbookingItem?.bookingCommodityDetails[0]?.dimensionDetaills ? (
                                <>
                                    <div className='mt-5 pb-2'>
                                        <h3>{t('PERMANENT_BOOKING.DIMENSION_DETAILS')}</h3>
                                    </div>
                                    <LooseItemForm
                                        looseDetails={looseDetails}
                                        setLooseDetails={setLooseDetails}
                                        totalWeight={totalWeight}
                                        setTotalWeight={setTotalWeight}
                                        setLooseItemsGrossWeight={setLooseItemsGrossWeight}
                                        weightUnit={weightUnit}
                                        setWeightUnit={setWeightUnit}
                                        dimensionUnit={dimensionUnit}
                                        setDimensionUnit={setDimensionUnit}
                                        isAllStackable={isAllStackable}
                                        setAllStackable={setAllStackable}
                                        isAllTiltable={isAllTiltable}
                                        setAllTiltable={setAllTiltable}
                                        handleAddLineItem={handleAddLineItem}
                                        setTotalPieces={setTotalPieces}
                                        setTotalVolumeInMeters={setTotalVolumeInMeters}
                                        setChargeableWeight={setChargeableWeight}
                                        setChargeableWeightInSelectedUnit={setChargeableWeightInSelectedUnit}
                                        setTotalWeightInKg={setTotalWeightInKg}
                                        totalWeightInKilo={totalWeightInKg}
                                        totalVolumeinMtrs={totalVolumeInMtrs}
                                        error={error}
                                        setError={setError}
                                        calculatedTotalWeight={calculatedTotalWeight}
                                        setCalculatedTotalWeight={setCalculatedTotalWeight}
                                        setActualVolume={setActualVolume}
                                        setActualChargeableWeight={setActualChargeableWeight}
                                        setActualGrossWeight={setActualGrossWeight}
                                        actualTotalIneItemWeight={actualTotalIneItemWeight}
                                        setActualTotalIneItemWeight={setActualTotalIneItemWeight}
                                        draftCallback={draftCallback}
                                        adjustedWeight={adjustedWeight}
                                        setAdjustedWeight={setAdjustedWeight}
                                        showOpWeight={showOpWeight}
                                        setShowOpweight={setShowOpweight}

                                        isHideReprocessComponents={true}
                                        setDisableButton={setDisableButton}
                                    />
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                    <div>
                    </div>
                    {uldVal && uldVal.length >= 0 ?
                        <h3>{t('PERMANENT_BOOKING.ULD_DETAILS')}</h3>
                        : <></>}
                    {uldVal && uldVal.length >= 0 ? (
                        uldVal.map((uldvalue, index) => (

                            <>
                                <div class="row">
                                    <div class="col-md-2 pr-3">
                                        <label className=' additional-style'>{t('COMMON.ULD_TYPE')}</label>
                                        <ULDTypeAutocomplete
                                            className={'form-control autocomplete'}
                                            selectedUldType={{ code: uldvalue.uldType }}
                                            setSelectedUldType={(uldTypeObj) => {
                                                if (uldTypeObj && uldTypeObj.uldtypcod) {
                                                    const newUldVal = [...uldVal];
                                                    newUldVal[index].uldType = uldTypeObj.uldtypcod;
                                                    setDisableButton(false)
                                                    setUldVal(newUldVal);
                                                    uldvalue.uldType = uldTypeObj.uldtypcod

                                                    getULDTypeByCode(uldTypeObj.uldtypcod)
                                                        .then((selectedUldType) => {
                                                            if (selectedUldType) {
                                                                uldvalue.uldVolume = parseFloat(selectedUldType.maxvol) * uldvalue.numberOfUlds
                                                            }
                                                        })

                                                } else {
                                                    const newUldVal = [...uldVal];
                                                    newUldVal[index].uldType = null;
                                                    setUldVal(newUldVal)
                                                    //setUldVolume([])
                                                }

                                            }}
                                            clearError={clearError}
                                            setNotificationMessage={''}
                                            setNotificationType={''}
                                        />
                                    </div>
                                    {ULD_TYPES_WITH_CONTOUR.includes(uldVal[index].uldType) ? (
                                        <div className="col-md-2 pl-1 pr-3">
                                            <label className="additional-style">{t('COMMON.CONTOUR')}</label>
                                            <Dropdown
                                                className='form-control'
                                                options={CONTOUR_ITEMS}
                                                value={uldVal[index].contour}
                                                onChange={(e) => {
                                                    const newContour = [...contour];
                                                    newContour[index] = e.target.value;
                                                    setDisableButton(false)
                                                    setContour(newContour);
                                                    uldvalue.contour = e.target.value ? e.target.value : null
                                                }}
                                            //onFocus={(e) => clearItemError(ULD_FORM_FIELDS.CONTOUR, index)}
                                            />
                                            <span className='search-panel__errormsg'>
                                                {contourError ? t(contourError, { ns: 'errors' }) : ''}
                                            </span>
                                        </div>
                                    ) : (
                                        <></>
                                    )}

                                    <div class="col-md-2 pl-1 pr-3">
                                        <label className=' additional-style'>{t('COMMON.NO_OF_UNITS')}</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            value={uldvalue.numberOfUlds === 0 ? null : uldvalue.numberOfUlds}
                                            //value={ uldvalue?.numberOfUlds}

                                            id="NoofUnits"
                                            onChange={(e) => {
                                                uldvalue.numberOfUlds = e.target.value
                                                setDisableButton(false)
                                                setNoofUlds((prevState) => ({
                                                    ...prevState,
                                                    uldvalue: uldvalue,
                                                }))
                                            }}

                                            onBlur={(e) => {
                                                validateRequired(e.target.value, setError, 'NoofUnits')
                                            }}
                                            aria-invalid={hasError('NoofUnits', error)}
                                            onFocus={(e) => {
                                                removeError(e, setError)
                                            }}
                                        />
                                        <span className='form-item__error'>
                                            {error && error['NoofUnits']
                                                ? t(error['NoofUnits'], { ns: 'errors' })
                                                : ''}
                                        </span>
                                    </div>
                                    <div class="col-md-2 pl-1 pr-3">
                                        <label className=' additional-style'>{t('COMMON.WT')}</label>
                                        <div
                                            className={`search-panel__double_element weight-div${error && error[FORM_FIELDS.TOTAL_WEIGHT] ? 'red-border' : ''
                                                }`}
                                        >
                                            <div className='uld-form__double_element weight-div'>

                                                <input
                                                    type='text'
                                                    className='form-control borderRadius-R0'
                                                    value={uldvalue.uldWeight}

                                                    onChange={(e) => {
                                                        uldvalue.uldWeight = e.target.value
                                                        setDisableButton(false)
                                                        setUldWeight((prevState) => ({
                                                            ...prevState,
                                                            uldvalue: uldvalue,
                                                        }
                                                        ))


                                                    }}


                                                    onFocus={(e) => clearError(FORM_FIELDS.TOTAL_WEIGHT)}
                                                />
                                                <div className='form-group borderRadius-L0 border-left0 dropdown_div m-b-0'>
                                                    <Dropdown
                                                        className='form-control cust-dropdown'
                                                        value={weightUnit}
                                                        options={WEIGHT_UNIT_SELECT_ITEMS_WO_TON}
                                                        // onChange={(e) => {
                                                        //     setUldVal((prevState) => ({
                                                        //         ...prevState,
                                                        //         dimensionUnit: e.target.value,
                                                        //         volumeUnit: e.target.value,
                                                        //     }))
                                                        // }}
                                                        onChange={(e) => {
                                                            parsedbookingItem.weightUnit = e.target.value
                                                            setDisableButton(false)
                                                            setWeightUnit(e.target.value)
                                                        }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <span className='search-panel__errormsg'>
                                            {error && error[FORM_FIELDS.TOTAL_WEIGHT]
                                                ? t(error[FORM_FIELDS.TOTAL_WEIGHT], { ns: 'errors' })
                                                : ''}
                                        </span>
                                    </div>
                                </div>
                                <br />
                            </>
                        ))
                    ) : (<></>
                    )}



                    <div className='mt-5'>
                        <h3>{t('PERMANENT_BOOKING.FLIGHT_INFORMATION')}</h3>
                    </div>


                    {parsedbookingItem?.bookingFlightDetails && parsedbookingItem?.bookingFlightDetails?.length >= 0 ? (
                        flight.map((flightVal, ind) => (
                            <>
                                <h4 className='mt-4 pt-1 mb-4'> {t('PERMANENT_BOOKING.LEG')}{ind + 1}</h4>
                                <div class="row">
                                    <div
                                        className={`col-2 pr-3${error && error[FORM_FIELDS.ORIGIN] ? '' : ''
                                            }`}
                                    >
                                        <label className='f-15'>{t('CHOOSE_FLT.FROM')}</label>
                                        <div class='input-wrapper f-15'>
                                            <AirportAutocomplete
                                                autoCompleteType={AUTOCOMPLETE_TYPE.ORIGIN}
                                                //selectedOrigin={selectedFlightOrigin.length > 0 ? selectedFlightOrigin[ind] : ''}
                                                selectedOrigin={flightLegs[ind]?.origin}
                                                selectedDestination={flightLegs[ind]?.destination}
                                                setSelectedOrigin={(e) => {
                                                    var newFlightLegs = [...flightLegs];
                                                    if (newFlightLegs.length === 0) {
                                                        newFlightLegs = [{}]
                                                    }
                                                    newFlightLegs[ind].origin = e;
                                                    setDisableButton(false)
                                                    setFlightLegs(newFlightLegs);
                                                    flightVal.segmentOrigin = e?.code
                                                }}
                                                // setSelectedAirportOrigin={(e) => {

                                                // }}
                                                handleSetError={handleSetError}
                                                clearError={clearError}
                                                draftCallback={draftCallback}
                                                setNotificationMessage={setNotificationMessage}
                                                setNotificationType={setNotificationType}
                                            />
                                        </div>
                                    </div>


                                    <div
                                        className={`col-2 pl-1 pr-3 ${error && error[FORM_FIELDS.DESTINATION] ? '' : ''
                                            }`}
                                    >
                                        <label className='f-15'>{t('PERMANENT_BOOKING.TO')}</label>
                                        <div class='input-wrapper f-15'>
                                            <AirportAutocomplete
                                                autoCompleteType={AUTOCOMPLETE_TYPE.DESTINATION}
                                                selectedOrigin={flightLegs[ind]?.origin}
                                                selectedDestination={flightLegs[ind]?.destination}
                                                setSelectedDestination={(e) => {
                                                    var newFlightLegs = [...flightLegs];
                                                    if (newFlightLegs.length === 0) {
                                                        newFlightLegs = [{}]
                                                    }
                                                    newFlightLegs[ind].destination = e;
                                                    setDisableButton(false)
                                                    setFlightLegs(newFlightLegs);
                                                    flightVal.segmentDestination = e?.code
                                                }}
                                                handleSetError={handleSetError}
                                                clearError={clearError}
                                                displaySimilarBooking={false}
                                                setNotLeave={setNotLeave}
                                                draftCallback={draftCallback}
                                                setNotificationMessage={setNotificationMessage}
                                                setNotificationType={setNotificationType}
                                            />
                                        </div>
                                    </div>

                                    <div class="col-2 pl-1 pr-3">
                                        <label className='additional-style'>{t('COMMON.FLT_NO')}</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            value={flightVal?.carrierCode ? flightVal.carrierCode + flightVal.flightNumber : ''}
                                            id="FlightNo"
                                            onChange={(e) => {
                                                flightVal.flightNumber = e.target.value.slice(2)
                                                flightVal.carrierCode = e.target.value.slice(0, 2)
                                                setDisableButton(false)
                                                setFlightNumber((prevState) => ({
                                                    ...prevState,
                                                    flightVal: flightVal,
                                                }))
                                            }}
                                            onBlur={(e) => {
                                                validateRequired(e.target.value, setError, 'FlightNo')
                                            }}
                                            aria-invalid={hasError('FlightNo', error)}
                                            onFocus={(e) => {
                                                removeError(e, setError)
                                            }}
                                        />
                                        <span className='form-item__error'>
                                            {error && error['FlightNo']
                                                ? t(error['FlightNo'], { ns: 'errors' })
                                                : ''}
                                        </span>


                                    </div>
                                    <div class="col-3 pl-1 pr-3">
                                        <label className=' additional-style'>{t('PERMANENT_BOOKING.DATE')}</label>
                                        <Calendar
                                            className='form-control'
                                            dateFormat='dd M yy'
                                            value={flightVal.flightDate ? moment(flightVal.flightDate).toDate() : null}
                                            onChange={(e) => {
                                                flightVal.flightDate = moment(e.target.value).format('DD-MMM-YYYY')
                                                setDisableButton(false)
                                                if (ind === 0) {
                                                    setShippingDate(moment(e.target.value).format('DD-MMM-YYYY'))
                                                }
                                            }}
                                            placeholder='Shipping date'
                                            minDate={moment().toDate()}
                                            //maxDate={maxShippingDate}
                                            onErrorHandler={() => {
                                                handleSetError(
                                                    FORM_FIELDS.SHIPPING_DATE,
                                                    MESSAGES.INVALID_SHIPPING_DATE
                                                )
                                            }}
                                        />
                                        <span className='search-panel__errormsg'>
                                            {error && error[FORM_FIELDS.SHIPPING_DATE]
                                                ? t(error[FORM_FIELDS.SHIPPING_DATE], { ns: 'errors' })
                                                : ''}
                                        </span>
                                    </div>

                                    <div class="col-3 pl-1 pr-3">
                                        <label className=' additional-style'>{t('DASHBOARD.ALLMNT')}</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            value={flightVal.allotmentId}
                                            id="AllotmentId"
                                            onChange={(e) => {
                                                if (e.target.value) {
                                                    flightVal.allotmentId = e.target.value
                                                }
                                                else {
                                                    flightVal.allotmentId = null
                                                }
                                                setDisableButton(false)
                                                setAirCraftType((prevState) => ({
                                                    ...prevState,
                                                    flightVal: flightVal,
                                                }))
                                            }}
                                            autoComplete='off'

                                            onBlur={(e) => {
                                                validateRequired(e.target.value, setError, 'AllotmentId')
                                            }}
                                            aria-invalid={hasError('AllotmentId', error)}
                                            onFocus={(e) => {
                                                removeError(e, setError)
                                            }}
                                        />
                                        <span className='form-item__error'>
                                            {error && error['AllotmentId']
                                                ? t(error['AllotmentId'], { ns: 'errors' })
                                                : ''}
                                        </span>

                                    </div>
                                </div>
                            </>

                        ))
                    ) : (<></>
                    )}

                    <div className="row button-submit-row">
                        <div className='col-3 btn-reprocess'>
                            <button
                                type='submit'
                                value='Submit'
                                className='secondary-button'
                                disabled={diableButton}
                            >
                                {t('PERMANENT_BOOKING.RE_SUBMIT')}
                            </button>
                        </div>
                        {/* <div className='col-2 btn-reprocess-cancel'>
                            <button
                                type='button'
                                className='button button--secondary'
                            onClick={changeEditMode}
                            >
                                {t('COMMON.BACK_BUTTON')}
                            </button>
                        </div> */}
                    </div>
                </div>
            </form>
        </div>

    )
}

const mapStateToProps = ({ loader, toast }) => ({
    //	principal: auth.principal,
    //	isLoading: loader.isLoading,
    toastDetails: toast.toastDetails,
})

const mapDispatchToProps = (dispatch) => ({
    getToastSuccess: (message) => {
        dispatch(getToastSuccess(message))
    },
    getToastError: (message) => {
        dispatch(getToastError(message))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(PermanentBookingForm)

