import React from 'react'
import './ICP.scss'
import shareIcon from "../../assets/images/shareIcon.svg";

const ICPInformation = () => {
	return (
		<div className='ICPfooter mb-0'>
			<p className='icplabel mr-0'>
				Shanghai, ICP prepared No. 14027180
			</p>
			<a href='https://beian.miit.gov.cn/'
				className="icp-share-icon icon-hover-magnify m-0">
				<img
					src={shareIcon}
					alt="share-icon"
					className="img-responsive"
				/>
			</a>
		</div>
	)
}
export default ICPInformation