
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import PaginatorComponent from '../../Pagination/PaginatorComponent'
import LoadingIndicator from '../../LoadingIndicator'
import { getFlightDetails } from '../../../services/csnDashboardService'
import FilterPopup from './filterPopup/filterPopup';
import FilterColumn from './filterColumn/filterColumn';
import DoughnutChart from '../../Dashboard/DoughnutChart';
import getCurrentUser from '../../../utils/getCurrentUser';
import getPrincipal from '../../../utils/getPrincipal'
import getPLACIUser from '../../../utils/getPLACIUser'
import './flight-summary.scss';
import { flightSummaryApplyFilter } from '../../../redux/actions/csnDashboardActions'
import { getPLACIUserDetails } from  '../../../services/onLoadServices'
import { setToLocalStorage } from '../../../utils/StorageUtils'
import sessionKeys from '../../../constants/sessionKeys'

const FlightSummary = ({
    setInitialAWB,
    handleTabChange,
    setSelectedFlightData,
    setLastUpdated,
    setNextUpdated,
    setFlights,
    flights,
    setTotalFlights,
    totalFlights,
    setProducts,
    products ,
    setTotalRecordCount,
    totalRecordCount,
    setMaxDate,
    maxDate,
    setFlightFromDate,
    flightFromDate,
    setFlightToDate,
    flightToDate,
    setShowLoader,
    showLoader, 
    flightNumberSelection,
    departureTimeSelection,
    arrivalTimeSelection,
    remainingTimeSelection,
    flightOriginSelection,
    flightDestinationSelection,
    statusSelection,
    impactedAWBSelection,
    setFlightNumberSelection,
    setDepartureTimeSelection,
    setArrivalTimeSelection,
    setRemainingTimeSelection,
    setFlightOriginSelection,
    setFlightDestinationSelection,
    setStatusSelection,
    setImpactedAWBSelection,
    flightSummaryApplyFilter,
    flightSummaryFilter,
    consolidatedFlightDetails,
    setConsolidatedFlightDetails,
    setAwbProducts,
    setFlightsRefreshed,
    flightsRefreshed,
    setFiltered,
    setFlightFilter,
    flightFilter,
    setAwbFilter,
    setFilterValues,
    filterValues,
    tabClicked,
    setTabClicked,
    currentPage,
    setCurrentPage,
    pageIndex,
    setPageIndex,
    pageReset,
    setPageReset,
    setFfmFlightFilter,
    setClearFlightFilter,
    airportsMasterData,
}) => {
    // const [products, setProducts] = useState([]);
    const [multiSortMeta, setMultiSortMeta] = useState([{ field: 'category', order: -1 }]);
    const initialPageNumber = 1
    const rowCount = 10;
    const recordsPerRequest = 30;
    const [initialSearch, setInitialSearch] = useState(null)
    const [calculatedPageNumber, setCalculatedPageNumber] = useState(1)
    const [filterSummary, setFilterSummary] = useState({});

    const [count1, setCount1] = useState(1)
    const [count2, setCount2] = useState(1)
    const [count3, setCount3] = useState(1)
    const [count4, setCount4] = useState(1)
    const [count5, setCount5] = useState(1)
    const [count6, setCount6] = useState(1)
    const [count7, setCount7] = useState(1)
    const [count8, setCount8] = useState(1)
    const [sortByHeader, setSortByHeader] = useState('');
    const [sortOrder, setSortOrder] = useState('ASC')
    
    const op = useRef(null);
    const refs = useRef(null);
    let userDetails = getCurrentUser();
    let principal = getPrincipal()
    let placiUser = getPLACIUser()

    const getPlaciUserInfo = async () => {
        try {
            const placiUserDetails = getPLACIUser()
            if (!placiUserDetails) {
                setShowLoader(true)
                const userDetailsResponse = await getPLACIUserDetails(principal.sub)
                setShowLoader(false)
                if (userDetailsResponse && userDetailsResponse.data) {
                    setToLocalStorage(
                        sessionKeys.PLACI_USER_DETAILS,
                        JSON.stringify(userDetailsResponse.data)
                    )
                    return userDetailsResponse.data
                }
            } else {
                return placiUserDetails
            }

        } catch (err) {
            setShowLoader(false)
            console.log(err)
        }
    }

    const getProducts = async (reqObj) => {
        try {
            setShowLoader(true);
            if (principal && principal.type === 'consumer') {
                if (!placiUser) {
                    placiUser = await getPlaciUserInfo()
                }
                if (placiUser && placiUser.stationCode && !reqObj.stationCode) {
                    reqObj.stationCode = placiUser.stationCode
                }
            }
            setProducts([])
            const res = await getFlightDetails(reqObj);
            if (res && res.data) {
                setFlights(res.data?.consolidatedFlightDetails?.flightsCleared);
                setConsolidatedFlightDetails(res.data?.consolidatedFlightDetails)
                setTotalFlights(res.data.consolidatedFlightDetails.totalFlights);
                setProducts(res.data.flightDetails);
                setTotalRecordCount(res.data.pagination.totalRecordCount)
                setMaxDate(res.data.maxDate);
                setFlightFromDate(res.data.flightFromDate);
                setFlightToDate(res.data.flightToDate);
                setLastUpdated(res.data.lastRefreshedTime)
                setNextUpdated(res.data.nextRefreshTime)
                setAwbProducts([])
                setFlightsRefreshed(true)
                setShowLoader(false)
                if (reqObj.airlinePrefix && reqObj.airlinePrefix !== '' && reqObj.awbNumber && reqObj.awbNumber !== '') {
                    let awbFilterData = {
                        airlinePrefix: reqObj.airlinePrefix,
                        awbNumber: reqObj.awbNumber,
                    }
                    setAwbFilter(awbFilterData)
                    if((!tabClicked || tabClicked) && tabClicked !== 'FLIGHT' && res?.data?.flightDetails && res.data.flightDetails.length > 0) {
                        handleTableClick(res.data.flightDetails[0])
                    }

                    if((!tabClicked || tabClicked) && tabClicked !== 'FLIGHT' && res?.data?.fwbPresent) {
                        handleFWBSearch()
                    }
                } else {
                    setAwbFilter(null)
                }
            } else {
                setShowLoader(false);
            }
            setTabClicked(null)
        } catch (err) {
            console.log(err)
            setShowLoader(false);
        }
    }
    useEffect(() => {
        setShowLoader(true);
        let reqObj = flightSummaryFilter ? setRequestObj(flightSummaryFilter, pageIndex) : setRequestObj(null, pageIndex)
        getProducts(reqObj);
        setInitialSearch(false)
        // setProducts(data.RESPONSE);
    }, [])

    useEffect(() => {
        if(flightSummaryFilter){
            setFilterSummary(flightSummaryFilter);
        }
    },[flightSummaryFilter])

    const handleFilterAction = (filterValues) => {
        setPageReset(true)
        setFfmFlightFilter(false)

        setFilterSummary(filterValues);
        setFlightFilter(filterValues)
        let reqObj = setRequestObj(filterValues,1);
        getProducts(reqObj);
        //setShowLoader(true);
        flightSummaryApplyFilter(filterValues);
}

    const formatDate = (date) => {
        if(date){
            let datearray = date.split("-");
            let newdate = datearray[1] + '/' + datearray[0] + '/' + datearray[2];
            let dateObject = new Date(newdate);
            return moment(dateObject).format('DD/MMM/YYYY');
        }
    }

    useEffect(() => {
        if (initialSearch) {
            setPageIndex(1)
            setCurrentPage(0)
        }
    }, [initialSearch])

    const handleColumnSort = (header,counter) =>{
        let count = 0;
        if(header == "NUMBER"){
            count = count1
            setSortByHeader(header)
            setSortOrder(counter)
        }
        else if(header == "ORIGIN"){
            count = count2
            setSortByHeader(header)
            setSortOrder(counter)
        
        }
        else if(header == "DESTINATION"){
            count = count3
            setSortByHeader(header)
            setSortOrder(counter)
        }
        else if(header == "DEPARTURETIME"){
            count = count4
            setSortByHeader(header)
            setSortOrder(counter)
        }
        else if(header == "ARRIVALTIME"){
            count = count5
            setSortByHeader(header)
            setSortOrder(counter)
        }
        else if(header == "STATUS"){
            count = count6
            setSortByHeader(header)
            setSortOrder(counter)
        }
        else if(header == "IMPACTEDAWBS"){
            count = count7
            setSortByHeader(header)
            setSortOrder(counter)
        }
        else if(header == 'REMAININGTIMEBEFORESTD'){
            count = count8
            setSortByHeader(header)
            setSortOrder(counter)
        }
    


        let sortValues ={
            "sortBy" :  header ,
            "sortOrder": counter%2==0 ? "ASC" : counter === 1 ? "ASC" : "DESC"
        }
        setPageReset(true)
        let reqObj = setRequestObj(filterSummary,1, counter === 1 ? {} : sortValues);
        getProducts(reqObj);
        //setShowLoader(true);
    }

    const setRequestObj = (filterData,pageNumber,sortColumn) => {
           let reqObj = {
                "requestId": uuidv4(),
                "agentCode": userDetails ? userDetails.agentCode : principal ? principal.sub : null,
                "userId": userDetails ? userDetails.userId : principal ? principal.sub : null,
                userType: principal ? principal.type : null,
                stationCode: placiUser ? placiUser.stationCode : '',
                  "pagination": {
                  "pageNumber": pageNumber ? pageNumber : calculatedPageNumber,
                  "pageSize": 10,
                },
              
        }
        if (filterData && filterData?.depDetails?.showDepartedFlights) {
            filterData.depDetails.utc = false
        }
        let reqData = {
            ...reqObj,
            ...filterData,
            ...sortColumn
        }
        /*if (filterData) {
            if (filterData.airlinePrefix && filterData.airlinePrefix !== '' && filterData.awbNumber && filterData.awbNumber !== '') {
                let awbFilterData = {
                    airlinePrefix: filterData.airlinePrefix,
                    awbNumber: filterData.awbNumber,
                }
                console.log('awbFilterData, tabClicked', awbFilterData, tabClicked);
                setAwbFilter(awbFilterData)
            }
        }*/

        if(filterData || sortColumn){
            return reqData;
        }
        else{
            return reqObj;
        }
    }

    const onPageIndexChange = (newPageIndex, currentPageToDisplay) => {
        setPageIndex(newPageIndex)
        setCurrentPage(currentPageToDisplay)
        setInitialSearch(false)
        setCalculatedPageNumber(newPageIndex)
        let sortValues = {
            "sortBy" : sortByHeader ,
            "sortOrder": sortOrder%2==0 ? "ASC" : sortOrder === 1 ? "ASC" : "DESC"
        }
        let reqObj = setRequestObj(filterSummary,newPageIndex, sortOrder === 1 ? {} : sortValues)
        getProducts(reqObj);
        setShowLoader(true);
        setPageReset(false);
    }

    const handleTableClick = (row) => {
        setInitialAWB(true);
        document.documentElement.scrollTop = 0;
        let reqObj = {
             carrierCode: row.carrierCode,
             flightNo: row.flightNo,
             departureDate: row.departureDetails.departureDate + " " + row.departureDetails.departureTime,
             arrivalDate:row.arrivalDetails.arrivalDate+" "+row.arrivalDetails.arrivalTime,
             origin: row.origin,
             destination: row.destination,
             departureDateTimeUTC: row.departureDetails.departureDateUTC + " " + row.departureDetails.departureTimeUTC,
             arrivalDateTimeUTC: row.arrivalDetails.arrivalDateUTC + " " + row.arrivalDetails.arrivalTimeUTC,
             flightDate:row.flightDate,
         }
        setSelectedFlightData(reqObj);
        setClearFlightFilter(false)
        handleTabChange(2);
        setExpandedRows(false)
    }

    const handleFWBSearch = () => {
        setInitialAWB(true);
        document.documentElement.scrollTop = 0;
        let reqObj = {
            fwbPresent: true
         }
        setSelectedFlightData(reqObj);
        setClearFlightFilter(true)
        flightSummaryApplyFilter(null)
        handleTabChange(2);
    }

    const getFlightData = () => {
        let arr = []
        if (consolidatedFlightDetails) {
            let flightCount = flights;
            arr.push(flightCount, consolidatedFlightDetails?.flightsInProgress, consolidatedFlightDetails?.flightsOnHold, consolidatedFlightDetails?.flightsCancelled, consolidatedFlightDetails?.flightsNoBookings);
        }
        return arr;
    }
     
    const getFlightNo = (row) => {
       
        return <span className='flight-no-chart'
        style={{cursor: "pointer",textDecoration:"underline",textDecorationColor:"rgb(0, 95, 150)"}}
         onClick={() => handleTableClick(row)}> 
         {
           row.carrierCode + '-' + row.flightNo 
         }
        </span>
    }

    const formatTime = (time) => {
        let dateArray = time.split(':');
        let hours = dateArray[0];
        let minutes = dateArray[1];    

        const strTime = `${hours}:${minutes}`;
        return strTime;
    }

    const secondsToHms = (d) => {

        d = Number(d);

        if (d > 0) {
            var h = Math.floor(d / (1000 * 60 * 60));
            var m = Math.floor((d / (1000 * 60)) % 60);
            //var s = Math.floor(d % 3600 % 60);
            if (h < 0 && m < 0) {
                var hDisplay = '';
                var mDisplay = '';
            } else {
                var hDisplay = h > 0 ? h > 9 ? h + '' : '0' + h + '' : '00';
                var mDisplay = m > 0 ? m > 9 ? m : '0' + m : '00';
            }

            // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
            return `${hDisplay} hrs ${mDisplay} mins`
        } else {
            return 'Departed'
        }
    }

    const getRemainingTime = (rowData) => {
    
        const hasActDeparture = rowData.actDepartureDetails?.departureDateUTC && rowData.actDepartureDetails?.departureTimeUTC;
        const hasEctDeparture = rowData.estDepartureDetails?.departureDateUTC && rowData.estDepartureDetails?.departureTimeUTC;
        const hasStdDeparture = rowData.departureDetails?.departureDateUTC && rowData.departureDetails?.departureTimeUTC;
       
        let dateUTC = null;
        let timeUTC = null;
    
        if (hasActDeparture) {
            dateUTC = rowData.actDepartureDetails.departureDateUTC;
            timeUTC = rowData.actDepartureDetails.departureTimeUTC;
        } else if (hasEctDeparture) {   
            dateUTC = rowData.estDepartureDetails.departureDateUTC;
            timeUTC = rowData.estDepartureDetails.departureTimeUTC;
        } else if (hasStdDeparture) { 
            dateUTC = rowData.departureDetails.departureDateUTC;
            timeUTC = rowData.departureDetails.departureTimeUTC;
        }
    
        // Calculate remaining time
        const newDate = `${dateUTC} ${' '} ${timeUTC}Z`;
        const currDate = new Date().toUTCString();
        const currentDate = new Date(currDate);
        const departureDate = new Date(newDate);
    
        const currentTimeStamp = currentDate.getTime();
        const departureTimeStamp = departureDate.getTime();
    
        return (
            <div>
                {secondsToHms(departureTimeStamp - currentTimeStamp)}
            </div>
        );
    };

      const stockBodyTemplate = (rowData) => {
        let statusClassName = '' 
        let statusValue = ''
        switch(rowData.status) {
            case 'FINALISED': 
            statusClassName = 'status-finalized'
            statusValue = 'Cleared'
            break;
            case 'ONHOLD' :
            statusClassName = 'status-onhold'
            statusValue = 'On Hold'
            break;
            case 'IN_PROGRESS' :
            statusClassName = 'no-information'
            statusValue = 'In progress'
            break;
            case 'BLUE_STATUS' :
            statusClassName = 'blue-status'
            statusValue = 'No information'
            break;
            case 'CANCELLED' :
            statusClassName = 'cancelled-status'
            statusValue = 'Cancelled'
            break;
            case 'NO_BOOKINGS':
            statusClassName = 'blue-status'
            statusValue = 'No bookings'
            break; 
            default: ''
        }
       
        return (
        <div className={`sign-post-container`}>
            <div className={`sign-post flight-status ${statusClassName}`} >
                {statusValue}
            </div>
        </div>
        );
    }

    const getExpander = (row) => {
        return (
            <div className='text-center'>
                <i className="sort-icon pi pi-angle-right chevron-icon"
                    onClick={() => handleTableClick(row)}>
                </i>
            </div>
        );
    }

    const getFlightNoHeader = () => {
        return (
            <div>
                <span>Flight No</span>
                <i className= {(count1>1)? (count1%2 === 0 ? 'sort-icon pi pi-sort-amount-up-alt' : 'sort-icon pi pi-sort-amount-down') : 'sort-icon pi pi-sort-alt'}  onClick={(e) => {
                    e.preventDefault();
                    count1 === 1 ? setCount1(2) : count1 === 2? setCount1(3) : setCount1(1)
                    setCount2(1)
                    setCount3(1)
                    setCount4(1)
                    setCount5(1)
                    setCount6(1)
                    setCount7(1)
                    setCount8(1)
                    handleColumnSort("NUMBER",count1 === 1 ? 2 : count1 === 2? 3 : 1)}}></i>
               
            </div>
        )
    }

    const getOriginHeader = () => {
        return (
            <div>
                <span>Origin</span>
                <i className={(count2>1)? (count2%2 === 0 ? 'sort-icon pi pi-sort-amount-up-alt' : 'sort-icon pi pi-sort-amount-down') : 'sort-icon pi pi-sort-alt'} onClick={(e) => {
                    e.preventDefault();
                    count2 === 1 ? setCount2(2) : count2 === 2? setCount2(3) : setCount2(1)
                    setCount1(1)
                    setCount3(1)
                    setCount4(1)
                    setCount5(1)
                    setCount6(1)
                    setCount7(1)
                    setCount8(1)
                    handleColumnSort("ORIGIN",count2 === 1 ? 2 : count2 === 2? 3 : 1)}}></i>
            </div>
        )
    }

    const getDestinationHeader = () => {
        return (
            <div>
                <span>Destination</span>
                <i className={(count3>1)? (count3%2 === 0 ? 'sort-icon pi pi-sort-amount-up-alt' : 'sort-icon pi pi-sort-amount-down') : 'sort-icon pi pi-sort-alt'} onClick={(e) => {
                    e.preventDefault();
                    count3 === 1 ? setCount3(2) : count3 === 2? setCount3(3) : setCount3(1)
                    setCount1(1)
                    setCount2(1)
                    setCount4(1)
                    setCount5(1)
                    setCount6(1)
                    setCount7(1)
                    setCount8(1)
                    handleColumnSort("DESTINATION",count3 === 1 ? 2 : count3 === 2? 3 : 1)}}></i>
            </div>
        )
    }

    const getDepartureHeader = () => {
        return (
            <div>
                <span>Departure date</span>
                {/*changed */}
                {/* <i className={(count4>1)? (count4%2 === 0 ? '' : '') : ''} onClick={(e) => {
                    e.preventDefault();
                    count4 === 1 ? setCount4(2) : count4 === 2? setCount4(3) : setCount4(1)
                    setCount1(1)
                    setCount2(1)
                    setCount3(1)
                    setCount5(1)
                    setCount6(1)
                    setCount7(1)
                    handleColumnSort("DEPARTURETIME",count4 === 1 ? 2 : count4 === 2? 3 : 1)}}></i> */}
            </div>
        )
    }

    const getArrivalHeader = () => {
        return (
            <div>
                <span>Arrival date</span>
                <i className={(count5>1)? (count5%2 === 0 ? 'sort-icon pi pi-sort-amount-up-alt' : 'sort-icon pi pi-sort-amount-down') : 'sort-icon pi pi-sort-alt'} onClick={(e) => {
                    e.preventDefault();
                    count5 === 1 ? setCount5(2) : count5 === 2? setCount5(3) : setCount5(1)
                    setCount1(1)
                    setCount2(1)
                    setCount3(1)
                    setCount4(1)
                    setCount6(1)
                    setCount7(1)
                    setCount8(1)
                    handleColumnSort("ARRIVALTIME",count5 === 1 ? 2 : count5 === 2? 3 : 1)}}></i>
            </div>
        )
    }

    const getStatusHeader = () => {
        return (
            <div>
                <span>Status</span>
                <i className={(count6>1)? (count6%2 === 0 ? 'sort-icon pi pi-sort-amount-up-alt' : 'sort-icon pi pi-sort-amount-down') : 'sort-icon pi pi-sort-alt'} onClick={(e) => {
                    e.preventDefault();
                    count6 === 1 ? setCount6(2) : count6 === 2? setCount6(3) : setCount6(1)
                    setCount1(1)
                    setCount2(1)
                    setCount3(1)
                    setCount4(1)
                    setCount5(1)
                    setCount7(1)
                    setCount8(1)
                    handleColumnSort("STATUS",count6 === 1 ? 2 : count6 === 2? 3 : 1)}}></i>
            </div>
        )
    }

    const getAWBHeader = () => {
        return (
            <div>
                <span>Impacted AWBs</span>
                <i className={(count7>1)? (count7%2 === 0 ? 'sort-icon pi pi-sort-amount-up-alt' : 'sort-icon pi pi-sort-amount-down') : 'sort-icon pi pi-sort-alt'} onClick={(e) => {
                    e.preventDefault();
                    count7 === 1 ? setCount7(2) : count7 === 2? setCount7(3) : setCount7(1)
                    setCount1(1)
                    setCount2(1)
                    setCount3(1)
                    setCount4(1)
                    setCount5(1)
                    setCount6(1)
                    setCount8(1)
                    handleColumnSort("IMPACTEDAWBS",count7 === 1 ? 2 : count7 === 2? 3 : 1)}}></i>
            </div>
        )
    }
    const getRemainingTimeBeforeStd = () => {
        return (
            <div>
                <span>Remaining time before DEP</span>
                <i className={(count8>1)? (count8%2 === 0 ? 'sort-icon pi pi-sort-amount-up-alt' : 'sort-icon pi pi-sort-amount-down') : 'sort-icon pi pi-sort-alt'} onClick={(e) => {
                    e.preventDefault();
                    count8 === 1 ? setCount8(2) : count8 === 2? setCount8(3) : setCount8(1)
                    setCount1(1)
                    setCount2(1)
                    setCount3(1)
                    setCount4(1)
                    setCount5(1)
                    setCount6(1)
                    setCount7(1)
                    handleColumnSort("REMAININGTIMEBEFORESTD",count8 === 1 ? 2 : count8 === 2? 3 : 1)}}></i>
            </div>
        )
    }

    const [expandedRows, setExpandedRows] = useState([]);
    const [expandedArrivalRows,setEexpandedArrivalRows]=useState([]);

    const toggleRow = (rowIndex) => {
        setExpandedRows((prevExpandedRows) =>
            prevExpandedRows.includes(rowIndex)
                ? prevExpandedRows.filter((row) => row !== rowIndex)
                : [...prevExpandedRows, rowIndex]
        );
    };

    const toggleArrivalRow = (rowIndex) => {
        setEexpandedArrivalRows((prevExpandedArrivalRows) =>
            prevExpandedArrivalRows.includes(rowIndex)
                ? prevExpandedArrivalRows.filter((row) => row !== rowIndex)
                : [...prevExpandedArrivalRows, rowIndex]
        );
    };

    return (
        <div>
            <div className="csn-table card">
                <div className="row">
                    <div className="col col-12 col-md-6 flight-date-subhead">
                        <h3>UAE PLACI - Flight Dashboard</h3>
                        <p>Flight date : {`${flightFromDate} - ${flightToDate}`}</p>
                    </div>
                    <div className="col col-12 col-md-6 doughnut-summary">
                        <div className='row'>
                            <div className='col col-4'>
                            </div>
                            <div className='col col-6'>
                                <div className='placi-chart'>
                                    <DoughnutChart
                                        dataSetLabel='Flights'
                                        labels={['Cleared', 'In progress', 'On hold', 'Cancelled', 'No bookings']}
                                        backgroundColor={['#559E49', '#F68D2E', '#FF5A4B','#fde8d5', '#CEE5FA']}
                                        borderColor={'#ebe8e1'}
                                        data={getFlightData()}
                                        showFlightData={true}
                                        flightPercentage={totalFlights !== 0 ? parseInt((flights / totalFlights) * 100) : 0}
                                        flights={flights}
                                        flightCount={totalFlights}
                                    />
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <FilterPopup
                    op={op}
                    setFilterSummary={setFilterSummary}
                    handleFilterAction={handleFilterAction}
                    maxDate={maxDate}
                    flightFromDate={flightFromDate}
                    flightToDate={flightToDate}
                    formatDate={formatDate}
                    setCalculatedPageNumber={setCalculatedPageNumber}
                    setPageIndex={setPageIndex}
                    flightSummaryApplyFilter={flightSummaryApplyFilter}
                    flightSummaryFilter={flightSummaryFilter}
                    setFlightsRefreshed={setFlightsRefreshed}
                    flightsRefreshed={flightsRefreshed}
                    setFiltered={setFiltered}
                    setFlightFilter={setFlightFilter}
                    flightFilter={flightFilter}
                    setFilterValues={setFilterValues}
                    filterValues={filterValues}
                    airportsMasterData={airportsMasterData}
                />
                <div className='gear-position'>
                    <FilterColumn
                        refs={refs}
                        flightNumberSelection={flightNumberSelection}
                        departureTimeSelection={departureTimeSelection}
                        arrivalTimeSelection={arrivalTimeSelection}
                        remainingTimeSelection={remainingTimeSelection}
                        flightOriginSelection={flightOriginSelection}
                        flightDestinationSelection={flightDestinationSelection}
                        statusSelection={statusSelection}
                        impactedAWBSelection={impactedAWBSelection}
                        setFlightNumberSelection={setFlightNumberSelection}
                        setDepartureTimeSelection={setDepartureTimeSelection}
                        setArrivalTimeSelection={setArrivalTimeSelection}
                        setRemainingTimeSelection={setRemainingTimeSelection}
                        setFlightOriginSelection={setFlightOriginSelection}
                        setFlightDestinationSelection={setFlightDestinationSelection}
                        setStatusSelection={setStatusSelection}
                        setImpactedAWBSelection={setImpactedAWBSelection}
                    />
                </div>
                <div className='table-position'>
                    <DataTable value={products} responsiveLayout="scroll" id="userList" className="csn-flights-table mt-3">
                        {flightNumberSelection && (
                            <Column field="flightNo" header={getFlightNoHeader()} body={(e) => getFlightNo(e)}></Column>
                        )
                        }
                        {departureTimeSelection && (
                            <Column
                                field="departureDetails.departureDate"
                                header={getDepartureHeader()}
                                body={(e, { rowIndex }) => {
                                    const isExpanded = expandedRows.includes(rowIndex);

                                    const hasDepartureSTD = e.departureDetails?.departureDate && e.departureDetails?.departureTime;
                                    const hasDepartureETD = e.estDepartureDetails?.departureDate && e.estDepartureDetails?.departureTime;
                                    const hasDepartureATD = e.actDepartureDetails?.departureDate && e.actDepartureDetails?.departureTime;


                                    let defaultDepartureDate = null;
                                    let defaultDepartureTime = null;
                                    let defaultDepartureLabel = "";
                                    let expandedDetails = [];

                                    if (hasDepartureATD) {
                                        defaultDepartureDate = e.actDepartureDetails.departureDate;
                                        defaultDepartureTime = e.actDepartureDetails.departureTime;
                                        defaultDepartureLabel = "ATD";


                                        if (hasDepartureETD) {
                                            expandedDetails.push({
                                                label: "ETD",
                                                date: e.estDepartureDetails.departureDate,
                                                time: e.estDepartureDetails.departureTime,
                                            });
                                        }
                                        if (hasDepartureSTD) {
                                            expandedDetails.push({
                                                label: "STD",
                                                date: e.departureDetails.departureDate,
                                                time: e.departureDetails.departureTime,
                                            });
                                        }
                                    } else if (hasDepartureETD) {
                                        defaultDepartureDate = e.estDepartureDetails.departureDate;
                                        defaultDepartureTime = e.estDepartureDetails.departureTime;
                                        defaultDepartureLabel = "ETD";


                                        if (hasDepartureATD) {
                                            expandedDetails.push({
                                                label: "ATD",
                                                date: e.actDepartureDetails.departureDate,
                                                time: e.actDepartureDetails.departureTime,
                                            });
                                        }
                                        if (hasDepartureSTD) {
                                            expandedDetails.push({
                                                label: "STD",
                                                date: e.departureDetails.departureDate,
                                                time: e.departureDetails.departureTime,
                                            });
                                        }
                                    } else if (hasDepartureSTD) {
                                        defaultDepartureDate = e.departureDetails.departureDate;
                                        defaultDepartureTime = e.departureDetails.departureTime;
                                        defaultDepartureLabel = "STD";


                                        if (hasDepartureATD) {
                                            expandedDetails.push({
                                                label: "ATD",
                                                date: e.actDepartureDetails.departureDate,
                                                time: e.actDepartureDetails.departureTime,
                                            });
                                        }
                                        if (hasDepartureETD) {
                                            expandedDetails.push({
                                                label: "ETD",
                                                date: e.estDepartureDetails.departureDate,
                                                time: e.estDepartureDetails.departureTime,
                                            });
                                        }
                                    }

                                    return (
                                        <div>
                                            {defaultDepartureDate && defaultDepartureTime && (
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        {`${formatDate(defaultDepartureDate)} ${formatTime(defaultDepartureTime)} ${defaultDepartureLabel}`}
                                                    </div>
                                                    {expandedDetails.length > 0 && (
                                                        <button
                                                            className="extend-button btn-link p-1 ms-2"
                                                            onClick={() => toggleRow(rowIndex)}
                                                            aria-expanded={isExpanded}
                                                            aria-label="Toggle additional details"
                                                        >
                                                            <i className={`pi ${isExpanded ? "pi-chevron-down" : "pi-chevron-right"} ms-2`}></i>
                                                        </button>
                                                    )}
                                                </div>
                                            )}

                                            {isExpanded && expandedDetails.length > 0 && (
                                                <div className="border rounded mt-2 p-3 bg-white">
                                                    {expandedDetails.map((detail, index) => (
                                                        <div className='expand-details' key={index}>
                                                            {`${formatDate(detail.date)} ${formatTime(detail.time)} ${detail.label}`}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    );
                                }}
                            ></Column>
                        )}

                        {arrivalTimeSelection && (
                            <Column
                                field="arrivalDetails.arrivalDate"
                                header={getArrivalHeader()}
                                body={(e, { rowIndex }) => {
                                    const isArrivalExpanded = expandedArrivalRows.includes(rowIndex);

                                    const hasArrivalATA = e.actArrivalDetails?.arrivalDate && e.actArrivalDetails?.arrivalTime;
                                    const hasArrivalETA = e.estArrivalDetails?.arrivalDate && e.estArrivalDetails?.arrivalTime;
                                    const hasArrivalSTA = e.arrivalDetails?.arrivalDate && e.arrivalDetails?.arrivalTime;

                                    let defaultArrivalDate = null;
                                    let defaultArrivalTime = null;
                                    let defaultArrivalLabel = "";
                                    let expandedDetails = [];

                                    if (hasArrivalATA) {
                                        defaultArrivalDate = e.actArrivalDetails.arrivalDate;
                                        defaultArrivalTime = e.actArrivalDetails.arrivalTime;
                                        defaultArrivalLabel = "ATA";


                                        if (hasArrivalETA) {
                                            expandedDetails.push({
                                                label: "ETA",
                                                date: e.estArrivalDetails.arrivalDate,
                                                time: e.estArrivalDetails.arrivalTime,
                                            });
                                        }
                                        if (hasArrivalSTA) {
                                            expandedDetails.push({
                                                label: "STA",
                                                date: e.arrivalDetails.arrivalDate,
                                                time: e.arrivalDetails.arrivalTime,
                                            });
                                        }
                                    } else if (hasArrivalETA) {
                                        defaultArrivalDate = e.estArrivalDetails.arrivalDate;
                                        defaultArrivalTime = e.estArrivalDetails.arrivalTime;
                                        defaultArrivalLabel = "ETA";


                                        if (hasArrivalATA) {
                                            expandedDetails.push({
                                                label: "ATA",
                                                date: e.actArrivalDetails.arrivalDate,
                                                time: e.actArrivalDetails.arrivalTime,
                                            });
                                        }
                                        if (hasArrivalSTA) {
                                            expandedDetails.push({
                                                label: "STA",
                                                date: e.arrivalDetails.arrivalDate,
                                                time: e.arrivalDetails.arrivalTime,
                                            });
                                        }
                                    } else if (hasArrivalSTA) {
                                        defaultArrivalDate = e.arrivalDetails.arrivalDate;
                                        defaultArrivalTime = e.arrivalDetails.arrivalTime;
                                        defaultArrivalLabel = "STA";


                                        if (hasArrivalATA) {
                                            expandedDetails.push({
                                                label: "ATA",
                                                date: e.actArrivalDetails.arrivalDate,
                                                time: e.actArrivalDetails.arrivalTime,
                                            });
                                        }
                                        if (hasArrivalETA) {
                                            expandedDetails.push({
                                                label: "ETA",
                                                date: e.estArrivalDetails.arrivalDate,
                                                time: e.estArrivalDetails.arrivalTime,
                                            });
                                        }
                                    }

                                    return (
                                        <div>

                                            {defaultArrivalDate && defaultArrivalTime && (
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        {`${formatDate(defaultArrivalDate)} ${formatTime(defaultArrivalTime)} ${defaultArrivalLabel}`}
                                                    </div>
                                                    {expandedDetails.length > 0 && (
                                                        <button
                                                            className="extend-button btn-link p-1 ms-2"
                                                            onClick={() => toggleArrivalRow(rowIndex)}
                                                            aria-expanded={isArrivalExpanded}
                                                            aria-label="Toggle additional details"
                                                        >
                                                            <i className={`pi ${isArrivalExpanded ? "pi-chevron-down" : "pi-chevron-right"} ms-2`}></i>
                                                        </button>
                                                    )}
                                                </div>
                                            )}


                                            {isArrivalExpanded && expandedDetails.length > 0 && (
                                                <div className="border rounded mt-2 p-3 bg-white">
                                                    {expandedDetails.map((detail, index) => (
                                                        <div className='expand-details' key={index}>
                                                            {`${formatDate(detail.date)} ${formatTime(detail.time)} ${detail.label}`}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    );
                                }}
                            ></Column>
                        )}
                        {remainingTimeSelection && (
                            <Column field="New field" header={getRemainingTimeBeforeStd()} body={(e) => getRemainingTime(e)}></Column>
                        )}
                        {flightOriginSelection && (
                            <Column field="origin" header={getOriginHeader()}></Column>
                        )}
                        {flightDestinationSelection && (
                            <Column field="destination" header={getDestinationHeader()}></Column>
                        )}
                        {statusSelection && (
                            <Column field="status" header={getStatusHeader()} body={(e) => stockBodyTemplate(e)}></Column>
                        )}
                        {impactedAWBSelection && (
                            <Column field="impactedAWBs" header={getAWBHeader()}></Column>
                        )}
                        <Column field="AWB" align="right" body={(row) => getExpander(row)}></Column>
                    </DataTable>
                </div>
            </div>
            {products && products.length > 0 ? (
            <div className='paginationWrapper'>
                <PaginatorComponent
                    initialSearch={initialSearch}
                    currentPage={currentPage}
                    pageIndex={pageIndex}
                    rowCount={rowCount}
                    totalRecords={totalRecordCount}
                    onPageIndexChange={onPageIndexChange}
                    pageReset={pageReset}
                />
            </div>): 
            ( !showLoader && <p className='data-table-empty'>There is no data that matches those criteria</p>)
            }
            {showLoader ?
                <LoadingIndicator /> : <></>
            }
        </div>
    );
}

const mapStateToProps = ({
    csnDashboard
 }) => ({
    flightSummaryFilter: csnDashboard.flightSummaryFilter
})

const mapDispatchToProps = (dispatch) => ({
	flightSummaryApplyFilter: (filter) => {
		dispatch(flightSummaryApplyFilter(filter))
	},
})


export default connect(mapStateToProps,mapDispatchToProps)(FlightSummary)
