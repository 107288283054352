import moment from 'moment'
import { Calendar } from 'primereact/calendar'
import React, { useEffect, useState, lazy, Suspense } from 'react'
import { Alert } from '../../../common/Alert'
import { Dropdown } from '../../../common/DropDown'
import { AUTOCOMPLETE_TYPE, BOOKING_STATUS } from '../../../constants'
import { PRODUCT_PLACEHOLDER } from '../../../constants/Placeholders'
import SessionKeys from '../../../constants/sessionKeys'
import { getProducts } from '../../../services/manageBookingService'
import { getAirportsByCode } from '../../../services/searchPanelServices'
import getCurrentUser from '../../../utils/getCurrentUser'
import {
	clearItemFromSessionStorage,
	getBase64Decoded,
	getBase64Encoded,
	getFromSessionStorage,
	setToSessionStorage,
} from '../../../utils/StorageUtils'
import { padLeadingZeros } from '../../../utils/Validations'
import AirportAutocomplete from '../../AirportAutocomplete'
import ProductAutocomplete from '../../ShippingItemAutocomplete/ProductAutoComplete'
import BookingDetailsList from '../BookingDetailsList/BookingDetailsList'
import './list-booking.scss'
import {
	allowAlphaOnly,
	allowNumricOnly,
	formatInputTypeNumber,
	hasError,
	removeError,
	restrictSpaceSpecial,
	validateAWB,
	validateAwbPrefixRequired,
	validateCarrierCodeRequired,
	validateFlightNoRegex,
} from './ListbookingValidations'
import { useTranslation } from 'react-i18next'
import { addError, pushPageData,pushEventBookingSearch } from '../../../utils/analytics'

import pageNames from '../../../constants/pageNames'
import { PAGE_PRIMARY_CATEGORY, PAGE_SUB_CATEGORY1, BOOKING_PAGE_SUB_CATEGORY2 } from '../../../constants'
import BookingListMain from '../BookingDetailsList/BookingListMobileMain'
import LoadingIndicator from '../../LoadingIndicator'

const ListBooking = ({
	listBookingResponse,
	listBookingError,
	getBookingDetails,
	retainFormData,
	gsaAgentDetails
}) => {
	const { t, i18n } = useTranslation(['labels', 'errors'])
	const { agentDetails } = gsaAgentDetails

	const [selectedOrigin, setSelectedOrigin] = useState(null)
	const [selectedDestination, setSelectedDestination] = useState(null)

	const [advancedSearch, setAdvancedSearch] = useState(false)
	const [products, setProducts] = useState([])
	const [selectedProduct, setSelectedProduct] = useState(null)

	const [error, setError] = useState(null)

	const [notificationType, setNotificationType] = useState(null)
	const [notificationMessage, setNotificationMessage] = useState('')

	const [isLoading, setIsLoading] = useState(false)

	const initialState = {
		origin: selectedOrigin,
		destination: selectedDestination,
		fromBookingDate: moment(
			new Date().setDate(new Date().getDate() - 14)
		).toDate(),
		toBookingDate: new Date(),
		shipmentIdentifierDetails: {
			shipmentPrefix: '607',
			masterDocumentNumber: '',
		},
		bookingStatus: null,
		product: null,
		commodity: null,
		carrierCode: 'EY',
		flightNumber: '',
		flightDate: null,
	}

	const [initialSearch, setInitialSearch] = useState(null)
	const initialPageNumber = 1
	const [bookingSearchFormData, setBookingSearchFormData] =
		useState(initialState)
	const [defaultorigin, setDefaultOrigin] = useState(null)

	const [customWidth, setcustomWidth] = useState(window.innerWidth)

	const updateDimensions = () => {
		setcustomWidth(window.innerWidth)
	}
	const[data,setData]=useState()

	useEffect(() => {
		window.addEventListener('resize', updateDimensions)
		return () => window.removeEventListener('resize', updateDimensions)
	}, [])

	useEffect(() => {
		if (!bookingSearchFormData.origin) {
			const userDetails = getCurrentUser()
			let defaultAirport = userDetails && userDetails.defaultAirportCode ?  userDetails.defaultAirportCode : userDetails?.stationCode
			if(agentDetails?.agentStationCode){
				defaultAirport = agentDetails?.agentStationCode
			}
			if (userDetails && defaultAirport) {
				getAirportsByCode(defaultAirport)
					.then((defaultOrigin) => {
						if (
							!getFromSessionStorage(SessionKeys.MANAGE_BOOKING_SEARCH_INFO)
						) {
							setIsLoading(true)
							getBookingDetails(
								bookingSearchFormData,
								initialPageNumber,
								defaultOrigin
							)
							setSelectedOrigin(defaultOrigin)
							}
					})
					.catch((error) => console.log(error))
					addError('LOADING_ERROR', error) 
					}
		}

		;(async () => {
			try {
				const productList = await getProducts()
				const allOption = {
					name: 'All',
				}
				productList.unshift(allOption)
				if (productList) {
					setProducts(productList)
				}
				setSelectedProduct(allOption)
				pushPageData(
					pageNames.LIST_BOOKING,
					PAGE_PRIMARY_CATEGORY.E_SERVICES,
					PAGE_SUB_CATEGORY1.BOOKING,
					BOOKING_PAGE_SUB_CATEGORY2.MY_BOOKINGS
				)
			} catch (error) {
				console.log(error.message)
				addError('LOADING_PRODUCT_ERROR', error) 
				pushPageData(
					pageNames.LIST_BOOKING,
					PAGE_PRIMARY_CATEGORY.E_SERVICES,
					PAGE_SUB_CATEGORY1.BOOKING,
					BOOKING_PAGE_SUB_CATEGORY2.MY_BOOKINGS
				)
			}
		})()
	}, [])

	useEffect(() => {
		if (agentDetails?.agentStationCode) {
			getAirportsByCode(agentDetails.agentStationCode)
				.then((defaultOrigin) => {
					if (
						!getFromSessionStorage(SessionKeys.MANAGE_BOOKING_SEARCH_INFO)
					) {
						setIsLoading(true)
						getBookingDetails(
							bookingSearchFormData,
							initialPageNumber,
							defaultOrigin
						)
						setSelectedOrigin(defaultOrigin)
					}
				})
				.catch((error) => console.log(error))
			addError('LOADING_ERROR', error)
		}
	}, [agentDetails])



	useEffect(() => {
		setBookingSearchFormData({
			...bookingSearchFormData,
			origin: selectedOrigin,
		})
	}, [selectedOrigin])

	useEffect(() => {
		setBookingSearchFormData({
			...bookingSearchFormData,
			product: selectedProduct,
		})
	}, [selectedProduct])

	useEffect(() => {
		setBookingSearchFormData({
			...bookingSearchFormData,
			destination: selectedDestination,
		})
	}, [selectedDestination])

	useEffect(() => {
		if (listBookingResponse || listBookingError) {
			setIsLoading(false)
		}
	}, [listBookingResponse, listBookingError])
	const clearError = () => {}

	useEffect(() => {
		if (retainFormData !== null) {
			const encodedBookingSearchForm = getFromSessionStorage(
				SessionKeys.MANAGE_BOOKING_SEARCH_INFO
			)
			if (
				retainFormData &&
				encodedBookingSearchForm &&
				encodedBookingSearchForm !== 'null'
			) {
				const bookingSearchData = getBase64Decoded(encodedBookingSearchForm)
				setBookingSearchFormData(bookingSearchData)
				setSelectedOrigin(bookingSearchData.origin)
				setSelectedDestination(bookingSearchData.destination)
				setSelectedProduct(bookingSearchData.product)
				setIsLoading(true)
				getBookingDetails(bookingSearchData, initialPageNumber, null)
				if (
					(bookingSearchData.product &&
						bookingSearchData.product.name !== 'All') ||
					bookingSearchData.flightDate !== null ||
					bookingSearchData.flightNumber !== '' ||
					(bookingSearchData.shipmentIdentifierDetails !== null &&
						bookingSearchData.shipmentIdentifierDetails.masterDocumentNumber !==
							'')
				) {
					setAdvancedSearch(true)
				}
			} else {
				//console.log("clearing session storage details file : listbooking");
				 //clearItemFromSessionStorage(SessionKeys.MANAGE_BOOKING_SEARCH_INFO)
				setBookingSearchFormData(initialState)
				setSelectedOrigin(bookingSearchFormData.origin)
				setSelectedDestination(bookingSearchFormData.destination)
				setSelectedProduct(bookingSearchFormData.product)
			}
		}
	}, [retainFormData])

	const handleChangeOfAWB = (value) => {
		if (value.length <= 12) {
			setBookingSearchFormData({
				...bookingSearchFormData,
				shipmentIdentifierDetails: {
					...bookingSearchFormData.shipmentIdentifierDetails,
					masterDocumentNumber: value,
				},
			})
			//validateAWB(value, setError, 'awbNumber')
		}
	}

	const validateFlightNo = (flightNo) => {
		let newFlightNumber = flightNo
		if (flightNo) {
			if (flightNo.length < 4) {
				newFlightNumber = padLeadingZeros(flightNo, 4)
				setBookingSearchFormData({
					...bookingSearchFormData,
					flightNumber: newFlightNumber,
				})
			}
			return validateFlightNoRegex(
				newFlightNumber,
				bookingSearchFormData.carrierCode,
				setError
			)
		} else {
			return true
		}
	}

	const handleChangeOfFlightNo = (flightNo) => {
		if (flightNo.length <= 5) {
			setBookingSearchFormData({
				...bookingSearchFormData,
				flightNumber: flightNo,
			})
		}
	}

	const handleSubmit = (event) => {
		event.preventDefault()

		const isAwbValid = validateAWB(
			bookingSearchFormData.shipmentIdentifierDetails.masterDocumentNumber,
			setError,
			'awbNumber'
		)
		const isAwbPrefixValid = validateAwbPrefixRequired(
			bookingSearchFormData.shipmentIdentifierDetails.masterDocumentNumber,
			bookingSearchFormData.shipmentIdentifierDetails.shipmentPrefix,
			setError,
			'awbPrefix'
		)
		const isFlightNumberValid = validateFlightNo(
			bookingSearchFormData.flightNumber
		)

		const isCarrierCodeValid = validateCarrierCodeRequired(
			bookingSearchFormData.flightNumber,
			bookingSearchFormData.carrierCode,
			setError,
			'carrierCode'
		)
		if (
			isAwbValid &&
			isAwbPrefixValid &&
			isFlightNumberValid &&
			isCarrierCodeValid
		) {
			//console.log("set session storage from listbooking page");
			setToSessionStorage(
				SessionKeys.MANAGE_BOOKING_SEARCH_INFO,
				getBase64Encoded(bookingSearchFormData)
			)
			setIsLoading(true)
			getBookingDetails(bookingSearchFormData, initialPageNumber, null)
			setInitialSearch(true)
			pushEventBookingSearch(data)
		}
	}

	const getPaginatedBookingResults = (currentPageNumber) => {
		if (initialSearch !== null) {
			setIsLoading(true)
			getBookingDetails(bookingSearchFormData, currentPageNumber, null)
		}
	}
	const changeBookingStatius = (value) => {
		if (value === t('COMMON.ALL')) {
			setBookingSearchFormData({
				...bookingSearchFormData,
				bookingStatus: null,
			})
		} else {
			setBookingSearchFormData({
				...bookingSearchFormData,
				bookingStatus: value,
			})
		}
	}
	// console.log(bookingSearchFormData,"bookingSearchFormData")

	const handleSetError = (key, text) => {
		error
			? setError({
					...error,
					[key]: text,
			  })
			: setError({
					[key]: text,
			  })
	}

	const handleAlertClose = () => {
		setNotificationMessage('')
		setNotificationType(null)
	}

	const draftCallback = () => {}

	return (
		<div>
			{notificationMessage && notificationMessage !== '' && (
				<div className='pb-2'>
					<Alert
						showAlert={true}
						message={t(notificationMessage, { ns: 'errors' })}
						setTimeout={true}
						onAlertClose={handleAlertClose}
						type={notificationType}
					/>
				</div>
			)}
			{isLoading ? <LoadingIndicator /> : <></>}
			<div className='listBookingForm' id='list-booking'>
				<div id='basicSearch'>
					<div className='form-row mb-3'>
						{/* <div className='col-12 col-md-9'>
							<div className='form-row'>
								 */}
						<div
							className='col-12 col-lg-3 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label'>{t('COMMON.SHP_FROM')}</label>
							<AirportAutocomplete
								autoCompleteType={AUTOCOMPLETE_TYPE.ORIGIN}
								selectedOrigin={selectedOrigin}
								selectedDestination={selectedDestination}
								setSelectedOrigin={setSelectedOrigin}
								handleSetError={handleSetError}
								clearError={clearError}
								screen='MANAGE_BOOKING'
								draftCallback={draftCallback}
								setNotificationMessage={setNotificationMessage}
								setNotificationType={setNotificationType}
							/>
						</div>
						<div
							className='col-12 col-lg-3 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label'>{t('COMMON.SHP_TO')}</label>
							<AirportAutocomplete
								autoCompleteType={AUTOCOMPLETE_TYPE.DESTINATION}
								selectedOrigin={selectedOrigin}
								selectedDestination={selectedDestination}
								setSelectedDestination={setSelectedDestination}
								handleSetError={handleSetError}
								clearError={clearError}
								screen='MANAGE_BOOKING'
								draftCallback={draftCallback}
								setNotificationMessage={setNotificationMessage}
								setNotificationType={setNotificationType}
							/>
						</div>
						<div className='col-12 col-lg-3 calendar-group form-item'>
							<label className='form-item__label'>
								{t('BKGLIST.BKG_CREATION_FRM')}
							</label>

							<Calendar
								className='form-item__field'
								dateFormat='dd M yy'
								value={moment(bookingSearchFormData.fromBookingDate).toDate()}
								maxDate={moment().toDate()}
								onChange={(e) => {
									setBookingSearchFormData({
										...bookingSearchFormData,
										fromBookingDate: e.target.value,
									})
								}}
								placeholder='Booking date (from)'
								showOtherMonths={false}
							/>
						</div>

						<div className='col-12  col-lg-3 calendar-group form-item '>
							<label className='form-item__label'>
								{t('BKGLIST.BKG_CREATION_TO')}
							</label>

							<Calendar
								className='form-item__field'
								dateFormat='dd M yy'
								value={moment(bookingSearchFormData.toBookingDate).toDate()}
								minDate={moment(bookingSearchFormData.fromBookingDate).toDate()}
								maxDate={moment().toDate()}
								onChange={(e) => {
									setBookingSearchFormData({
										...bookingSearchFormData,
										toBookingDate: e.target.value,
									})
								}}
								placeholder='Booking date (to)'
								showOtherMonths={false}
							/>
						</div>
						{/* </div>

					<div className='form-row m-t-25 mb-3'> */}
						<div className='col-12 mt-lg-2 col-lg-3 form-item mb-0'>
							<Dropdown
								className='cust-dropdown'
								value={bookingSearchFormData.bookingStatus}
								options={BOOKING_STATUS}
								label={t('BKGLIST.BKG_STATUS')}
								onChange={(e) => {
									changeBookingStatius(e.target.value)
								}}
							></Dropdown>
						</div>
					</div>
				</div>
				<div className='form-row mt-lg-5 mt-lg-3'>
					<div className='col-12'>
						{!advancedSearch ? (
							<div className='form-row'>
								<div
									className='col-12 col-lg-6 mt-3 mb-4 m-lg-0'
									onClick={() => {
										setAdvancedSearch(true)
									}}
								>
									<a className='link'>
										<span>{t('COMMON.ADV_SEARCH')}</span>
									</a>
								</div>
								<div className='col-12 col-lg-6 cta_wrap flex-column flex-lg-row text-right p-1'>
									<button
										type='button'
										className='primary-button'
										onClick={handleSubmit}

									>
										{t('COMMON.SEARCH')}
									</button>
								</div>
							</div>
						) : (
							<div
								className='mt-3 m-lg-0 m-lg-0'
								onClick={() => {
									setAdvancedSearch(false)
								}}
							>
								<a className='link'>
									<span>{t('COMMON.ADV_SEARCH')}</span>
								</a>
							</div>
						)}
					</div>
				</div>

				{advancedSearch ? (
					<div id='advancedSearch'>
						<div className='form-row custom-margin-search mb-3'>
							<div
								className='col-12 col-lg-3 form-item form-item--text autocomplete-dropdown'
								data-module='molecules/form-item/FormItem'
							>
								<label className='form-item__label'>{t('COMMON.PRD')}</label>

								<ProductAutocomplete
									selectedProduct={selectedProduct}
									setSelectedProduct={setSelectedProduct}
									products={products}
									placeholder={t(PRODUCT_PLACEHOLDER)}
								/>
							</div>
							<div
								className='col-12 col-lg-3 form-item form-item--text'
								data-module='molecules/form-item/FormItem'
							>
								<label className='form-item__label'>
									{t('COMMON.AWB_NUMBER')}
								</label>
								<div className='form-row'>
									<div className='col-4 col-md-4'>
										<input
											id='awbPrefix'
											name='awbPrefix'
											type='text'
											className='form-item__field'
											aria-invalid={hasError('awbPrefix', error)}
											value={
												bookingSearchFormData.shipmentIdentifierDetails
													.shipmentPrefix
											}
											maxLength='3'
											onKeyPress={(evt) => {
												return allowNumricOnly(evt)
											}}
											onChange={(e) => {
												setBookingSearchFormData({
													...bookingSearchFormData,
													shipmentIdentifierDetails: {
														...bookingSearchFormData.shipmentIdentifierDetails,
														shipmentPrefix: e.target.value,
													},
												})
											}}
											onFocus={(e) => {
												removeError(e, setError)
											}}
										/>
										<span className='form-item__error'>
											{error && error['awbPrefix']
												? t(error['awbPrefix'], { ns: 'errors' })
												: ''}
										</span>
									</div>

									<div className='col-8 col-md-8'>
										<input
											id='awbNumber'
											name='awbNumber'
											type='number'
											className='form-item__field'
											aria-invalid={hasError('awbNumber', error)}
											placeholder='AWB'
											value={
												bookingSearchFormData.shipmentIdentifierDetails
													.masterDocumentNumber
											}
											onKeyDown={(evt) => formatInputTypeNumber(evt)}
											onChange={(e) => handleChangeOfAWB(e.target.value)}
											onBlur={(e) => {
												validateAWB(e.target.value, setError, 'awbNumber')
											}}
											onFocus={(e) => {
												removeError(e, setError)
											}}
										/>
										<span className='form-item__error'>
											{error && error['awbNumber']
												? t(error['awbNumber'], { ns: 'errors' })
												: ''}
										</span>
									</div>
								</div>
							</div>

							<div
								className='col-12 col-lg-3 form-item form-item--text'
								data-module='molecules/form-item/FormItem'
							>
								<label className='form-item__label'>{t('COMMON.FLT_NO')}</label>
								<div className='form-row'>
									<div className='col-4 col-md-4'>
										<input
											id='carrierCode'
											name='carrierCode'
											type='text'
											className='form-item__field textFiledCaps'
											aria-invalid={hasError('carrierCode', error)}
											maxLength='2'
											value={bookingSearchFormData.carrierCode}
											onKeyPress={(envt) => {
												return allowAlphaOnly(envt)
											}}
											onChange={(e) => {
												setBookingSearchFormData({
													...bookingSearchFormData,
													carrierCode: e.target.value,
												})
											}}
											onBlur={(e) => {
												validateCarrierCodeRequired(
													bookingSearchFormData.flightNumber,
													e.target.value,
													setError,
													'carrierCode'
												)
											}}
											onFocus={(e) => {
												removeError(e, setError)
											}}
										/>
										<span className='form-item__error'>
											{error && error['carrierCode']
												? t(error['carrierCode'], { ns: 'errors' })
												: ''}
										</span>
									</div>

									<div className='col-8 col-md-8 '>
										<input
											id='flightNumber'
											name='flightNumber'
											type='text'
											placeholder='Flight number'
											className='form-item__field'
											aria-invalid={hasError('flightNumber', error)}
											value={bookingSearchFormData.flightNumber}
											onKeyPress={(e) => {
												return restrictSpaceSpecial(e)
											}}
											onFocus={(e) => {
												removeError(e, setError)
											}}
											onChange={(e) => handleChangeOfFlightNo(e.target.value)}
											onBlur={(e) => {
												validateFlightNo(e.target.value)
											}}
										/>
										<span className='form-item__error'>
											{error && error['flightNumber']
												? t(error['flightNumber'], { ns: 'errors' })
												: ''}
										</span>
									</div>
								</div>
							</div>

							<div className='col-6 col-lg-3 calendar-group'>
								<label className='form-item__label'>
									{t('COMMON.FLT_DATE')}
								</label>

								<Calendar
									className='form-item__field'
									dateFormat='dd M yy'
									value={moment(bookingSearchFormData.flightDate).toDate()}
									onChange={(e) => {
										setBookingSearchFormData({
											...bookingSearchFormData,
											flightDate: e.target.value,
										})
									}}
									placeholder='Flight date'
									showOtherMonths={false}
								/>
							</div>
						</div>
					</div>
				) : (
					<></>
				)}

				{advancedSearch && (
					<div className='form-row mt-5 mt-md-4'>
						<div className='col-12 cta_wrap flex-column flex-lg-row text-right'>
							<button type='button' className='primary-button' onClick={handleSubmit}>
								{t('COMMON.SEARCH')}
							</button>
						</div>
					</div>
				)}
			</div>
			{customWidth > 991 ? (
				<BookingDetailsList
					initialSearch={initialSearch}
					setInitialSearch={setInitialSearch}
					listBookingResponse={listBookingResponse}
					listBookingError={listBookingError}
					getPaginatedBookingResults={getPaginatedBookingResults}
					setNotificationMessage={setNotificationMessage}
					setNotificationType={setNotificationType}
				/>
			) : (
				<BookingListMain
					listBookingResponse={listBookingResponse}
					listBookingError={listBookingError}
					setNotificationMessage={setNotificationMessage}
					setNotificationType={setNotificationType}
					bookingSearchFormData={bookingSearchFormData}
					getBookingDetails={getBookingDetails}
					initialSearch={initialSearch}
					setInitialSearch={setInitialSearch}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
				/>
			)}
		</div>
	)
}

export default ListBooking
