import React, { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import './popup.scss'

const DraftPopup = ({
	display,
	header,
	message,
	primaryBtnLbl,
	acceptFn = () => { },
	secondaryBtnLbl,
	rejectFn,
	closeErrorPopup,
	closeFunction,
	icon,
	openOnAccept,
	onHide = () => { },
}) => {
	const footer = (
		<div className='popup-modal'>
			<button
				
				className='primary-button'
				type='button'
				onClick={(e) => {
					acceptFn(e)
					if (!openOnAccept) {
						closeErrorPopup()
					}
				}}
			>{primaryBtnLbl ? primaryBtnLbl : 'Ok'}</button>
			<button
				className='secondary-button'
				type='button'
				onClick={() => {
					rejectFn()
					closeErrorPopup()
				}}
			>{secondaryBtnLbl ? secondaryBtnLbl : 'Ok'}</button>
			{secondaryBtnLbl && rejectFn ? (
				// <Button
				// 	label={secondaryBtnLbl}
				// 	className='button-second'
				// 	type='button'
				// 	onClick={() => {
				// 		rejectFn()
				// 		closeErrorPopup()
				// 	}}
				// />
				<img src={require('../../assets/images/suggestSimiliarClose.png').default}
					className='closeImagePopup'
					onClick={() => {
						closeFunction()
					}}
				/>
			) : null}
		</div>
	)

	return display ? (
		<>
			<div className='portal-popup-modal'>
				<Dialog
					footer={footer}
					visible
					closable={false}
					modal
					header={header}
					onHide={onHide}
				>
					{message}
				</Dialog>
			</div>
		</>
	) : (
		<></>
	)
}

export default DraftPopup
