import React, { useEffect, useState } from 'react'
import { Route, useHistory, Switch, Redirect } from 'react-router-dom'
import FlightSearch from './FlightSearch'
import './flight-enquiry.scss'

const FlightEnquiry = () => {
	const history = useHistory()

	return (
		<div className='cargoportal-main-container'>
			<div class='container-lg'>
				<div className='flight-enquiry'>
					<div className='row'>
						<div className='col-lg-12'>
							<div class='flight-enquiry__search'>
								<Switch>
									<Route
										exact
										path='/flight-enquiry'
										render={() => {
											history.push('/flight-enquiry/search')
										}}
									/>
									<Route
										path='/flight-enquiry/search'
										component={FlightSearch}
									/>
								</Switch>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default FlightEnquiry
