import React, { useEffect, useState } from 'react'
import getCurrentUser from '../../../utils/getCurrentUser'
import '../index.scss'
import TemplateDisplay from './TemplateDisplay'
import TemplateFilter from './TemplateFilter'
import { Alert } from '../../../common/Alert'
import BookingMenu from '../../BookingMenu'
import { useTranslation } from 'react-i18next'
import { addError, pushPageData } from '../../../utils/analytics'
import pageNames from '../../../constants/pageNames'
import { PAGE_PRIMARY_CATEGORY, PAGE_SUB_CATEGORY1, BOOKING_PAGE_SUB_CATEGORY2 } from '../../../constants'
const ManageTemplate = ({
	manageTemplateResponse,
	manageTemplateError,
	getTemplateDetails,
	updateStatus,
}) => {
	const { t, i18n } = useTranslation(['labels', 'errors'])

	const userDetails = getCurrentUser()
	const [templateList, setTemplateList] = useState([])
	
	const [deleteSuccess, setDeleteSuccess] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')

	useEffect(()=>{
			pushPageData(
				pageNames.TEMPLATES,
				PAGE_PRIMARY_CATEGORY.E_SERVICES,
				PAGE_SUB_CATEGORY1.TEMPLATES,
				BOOKING_PAGE_SUB_CATEGORY2.MANAGE_TEMPLATES)
	},[])

	useEffect(() => {
		if (updateStatus && updateStatus.message) {
			setAlertMessage(updateStatus.message)
			setDeleteSuccess(updateStatus.success)
			window.scrollTo(0, 0)
			}
	}, [updateStatus])

	const handleAlertClose = () => {
		setAlertMessage('')
		setDeleteSuccess(false)
	}

	return userDetails ? (
		<div className='container-lg cargo-portal-container '>
			<div className='templateListWrapper'>
				<div className='tableHeader'>
					<div className='col-12 pl-0 pr-0'>
						<BookingMenu activeItem={3} />
						{/*<div className='col-12 col-sm-6 col-md-6 pl-0'>
							<h3 className='page-heading pt-2'>
								Templates ({templateList.length != 0 ? templateList.length : 0})
							</h3>
						</div>*/}
						<div className='col-12 col-sm-12 col-md-12 pr-0 pl-0 float-right'>
							<TemplateFilter
								userDetails={userDetails}
								getTemplateDetails={getTemplateDetails}
								deleteSuccess={deleteSuccess}
							/>
						</div>
					</div>
				</div>
				{alertMessage !== '' && (
					<Alert
						isError={!deleteSuccess}
						showAlert={true}
						message={t(alertMessage, { ns: 'errors' })}
						onAlertClose={handleAlertClose}
						setTimeout={true}
					/>
				)}
				<TemplateDisplay
					manageTemplateResponse={manageTemplateResponse}
					manageTemplateError={manageTemplateError}
					userDetails={userDetails}
					setAlertMessage={setAlertMessage}
					setDeleteSuccess={setDeleteSuccess}
				/>
			</div>
		</div>
	) : (
		<></>
	)
}

export default ManageTemplate
