import React, { Component } from 'react'
import XLSX from 'xlsx'
import { SheetJSFT } from './Types'
import './loose-line-item.scss'
import Popup from '../Popup/Popup'
import ExportToExcel from './Content'
import { withTranslation } from 'react-i18next'
import Messages from '../../constants/Messages'

const keyMap = {
	Pieces: 'pieces',
	Length: 'length',
	Width: 'width',
	Height: 'height',
	Weight: 'weight',
	'Stackable (Yes/No)': 'stackable',
	'Tiltable (Yes/No)': 'turnable',
	'Weight Type (Per Item/Total)': 'weightType',
	'Dimension Unit': 'dimensionUnit',
	'Weight Unit': 'weightUnit',
}

const mapToResponse = (data) =>
	data.map((row) => {
		let mapped = {}
		let packingType=[]
		Object.keys(row).forEach((key) => {
			const mappedKey = keyMap[key]
			packingType.push(mappedKey)
			if (['stackable', 'turnable'].includes(mappedKey)) {				
				return (mapped[mappedKey] = row[key] == 'Y' || row[key] == 'y')
			}

			if (key === 'Pieces' || key === 'Length' || key === 'Width' || key === 'Height') {
				return (mapped[keyMap[key]] = row[key] ? Math.round(row[key]) : row[key])
			}

			if (key === 'Weight') {
				return (mapped[keyMap[key]] = row[key] ? Number(row[key]).toFixed(2) : row[key])
			}
			return (mapped[keyMap[key]] = row[key])
		})
		if (packingType && !packingType.includes('stackable')){
			 mapped['stackable'] = true			
		}
		if (packingType && !packingType.includes('turnable')){
			mapped['turnable'] = true
			
		}
		return mapped
	})

//Faheem
class ExcelReader extends Component {
	constructor(props) {
		super(props)
		this.state = {
			popUp: false,
			mappedResponse: [],
			weightCellState: undefined,
			dimensionCellState: undefined,
			fileName: undefined,
			error: ''
		}
	}

	validateData = (parsedData) => {
		return parsedData.every((row) => {
			let isValid = true
			Object.keys(row).forEach((key) => {
				switch (key) {
					case 'Stackable (Yes/No)':
					case 'Tiltable (Yes/No)':
						if (!['Y', 'y', 'N', 'n'].includes(row[key])) {
							isValid = false
						}
						break
					case 'Weight Type (Per Item/Total)':
						if (!['PER_ITEM', 'TOTAL'].includes(row[key])) {
							isValid = false
						}
						break
					default:
						if (!Number.isInteger(row[key])) {
							isValid = false
						}
				}
			})
			return isValid
		})
	}

	validatedataFunction = (parsedData) => {
		return parsedData.every((row) => {
			let isValid = true;
			if (Object.keys(row).length === 8) {
				isValid = true
			} else {
				isValid = false
			}
			return isValid
		})
	}

	handleChange = (files) => {
		var f = files[0]
		var reader = new FileReader()
		reader.onload = (e) => {
			var data = e.target.result
			let readedData = XLSX.read(data, { type: 'binary' })
			const wsname = readedData.SheetNames[0]
			const ws = readedData.Sheets[wsname]
			const weightCell = ws['B1'] && ws['B1'].v
			const dimensionCell = ws['B2'] && ws['B2'].v

			/* Convert array to json*/
			const parsedData = XLSX.utils.sheet_to_json(ws, { range: 3 })
			// this.props.onRead(mapToResponse(parsedData), weightCell, dimensionCell)
			this.setState({
				mappedResponse: mapToResponse(parsedData),
				weightCellState: weightCell,
				dimensionCellState: dimensionCell,
				fileName: f?.name
			})
			this.validatedataFunction(this.state.mappedResponse) === true 
				? this.setState({ error: '' })
				: this.setState({ error: Messages.EXCEL_UPLOAD_EMPTY_FIELDS })
		}
		reader.readAsBinaryString(f)
	}

	handleOk = () => {
		this.state.mappedResponse.length > 0 &&
			this.validatedataFunction(this.state.mappedResponse) === true ?
			this.props.onRead(this.state.mappedResponse, this.state.weightCellState, this.state.dimensionCellState) :
			this.setState({ error:  Messages.EXCEL_UPLOAD_EMPTY_FIELDS })

		if (this.state.fileName != undefined && this.state.mappedResponse.length > 0 && this.validatedataFunction(this.state.mappedResponse) === true) {
			this.setState({
				popUp: false,
				weightCellState: undefined,
				dimensionCellState: undefined,
				fileName: undefined
			})
		} else if (this.state.fileName != undefined && this.state.mappedResponse.length === 0) {
			this.setState({
				popUp: true,
				weightCellState: undefined,
				dimensionCellState: undefined,
				fileName: undefined,
				error: Messages.EXCEL_UPLOAD_EMPTY_FILE,
			})
		} else if (this.state.fileName != undefined && this.state.mappedResponse.length > 0 && this.validatedataFunction(this.state.mappedResponse) === false) {
			this.setState({
				popUp: true,
				weightCellState: undefined,
				dimensionCellState: undefined,
				error: Messages.EXCEL_UPLOAD_EMPTY_FIELDS,
			})
		} else {
			this.setState({
				popUp: true,
				weightCellState: undefined,
				dimensionCellState: undefined,
				fileName: undefined,
				error: Messages.CHOOSE_UPLOAD_FILE,
			})
		}
	}

	componentDidUpdate(previousProps, previousState) {
		previousState.fileName != this.state.fileName && this.state.fileName != undefined &&
			this.setState({ error: '' })
	}

	render() {
		const { t } = this.props

		return (
			<form>
				<p onClick={() => this.setState({ popUp: !this.state.popUp })} className='mb-0 excel_Upload_button mr-3'>
					<img src={require('../../assets/images/UploadExcel.png').default} />
					{t('BOOKING.UPLOAD')}
				</p>
				<Popup
					primaryBtnLbl={t('EXCEL.UPLOAD')}
					display={this.state.popUp}
					secondaryBtnLbl={t('COMMON.CANCEL')}
					rejectFn={() => {
						this.setState({ popUp: false, error: '', fileName: undefined })
					}}
					icon='noIcon'
					header={t('BOOKING.EXCEL_UPLOAD')}
					acceptFn={this.handleOk}
					closeErrorPopup={() => { }}
					message=
					{
						<div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
							<div className='selected-file-panel'>
								<label htmlFor='fileButton' className='button-choose' style={{ paddingLeft: "6px", paddingRight: "6px" ,display:'flex', alignItems:'center', height:'100%' }}>
									<div style={{ padding: "7px", fontFamily: " 'EtihadAltis-Book', sans-serif " }}>
									{t('BOOKING.CHOOSE_FILE_BUTTON')}
									</div>
								</label>
								<div className='selected-showcase'>
									{this.state.fileName}
								</div>
							</div>
							<input
								type='file'
								id='fileButton'
								accept={SheetJSFT}
								onChange={(e) => {
									this.handleChange(e.target.files)
									e.target.value = ''
								}}
								style={{ display: 'none' }}
							/>
							<span className='search-panel__errormsg mt-2'>
								{this.state.error && this.state.error !== ''
									? t(this.state.error, { ns: 'errors' })
									: ''}
							</span>
							<ExportToExcel pageName={this.props.pageName}>Export</ExportToExcel>
						</div>}
				/>
			</form>
		)
	}
}

export default withTranslation()(ExcelReader)

