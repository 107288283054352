import React from 'react'
import './index.scss'
import moment from 'moment'
import { useHistory, Link } from 'react-router-dom'
import { getWeightUnit } from '../../../constants'
import envConfigs from '../../../configs/envConfigs'
import { useTranslation } from 'react-i18next'
import Messages from '../../../constants/Messages'
const RecentBookingList = ({ recentBookings, isLoading }) => {
	const { t, i18n } = useTranslation(['labels', 'errors'])

	const history = useHistory()
	const { parentUrl } = envConfigs

	const bookingStatusFunction = (recentBook) => {
		let status = recentBook?.bookingStatus
		if (status === 'X') {
			return (
				<td>
					<p className='other-text'>{t('COMMON.CNC')}</p>
				</td>
			)
		} else if (status === 'C') {
			return (
				<td>
					<p className='other-text'>{t('COMMON.CONFIRMED')}</p>
				</td>
			)
		} else if (status === 'Q') {
			return (
				<td>
					<p className='other-text'>{t('COMMON.QUEUED')}</p>
				</td>
			)
		} else if (status === 'R') {
			return (
				<td>
					<p className='other-text'>{t('VIEWBKG.ST_REJECTED')}</p>
				</td>
			)
		} else if (status === 'W') {
			return (
				<td>
					<p className='other-text'>{'Waitlisted'}</p>
				</td>
			)
		} else return
	}

	const redirectToTrackBooking = (evnt, result) => {
		const language = i18n.language ? i18n.language : 'en'
		evnt.stopPropagation()
		const airwayBill = result.shipmentIdentifierDetails
			? result.shipmentIdentifierDetails.shipmentPrefix +
			  result.shipmentIdentifierDetails.masterDocumentNumber
			: ''
		window.open(
			`${
				parentUrl ? parentUrl : 'https://www.etihadcargo.com'
			}/en/e-services/shipment-tracking?awb=${airwayBill}`,
			'_blank'
		)
	}

	return (
		<div className='recent-booking-container'>
			<h3 className='section-sub-heading pl-4'>{t('COMMON.RECENT_BKG')}</h3>
			<div className='recent-booking-container_wrap'>
				{recentBookings &&
					recentBookings.bookingList &&
					recentBookings.bookingList.length > 0 ? (
					<table>
						<tr>
							<th>{t('COMMON.AWB_NUMBER')}</th>
							<th>{t('DASHBOARD.ORG_DEST')}</th>
							<th>{t('DASHBOARD.SHIPMT_DATE')}</th>
							<th style={{ width: '165px' }}>{t('DASHBOARD.DTLS')}</th>
							<th>{t('COMMON.STATUS')}</th>
							<th>{''}</th>
						</tr>
						{recentBookings.bookingList?.slice(0, 5)?.map((recentBook, key) => (
							<tr>
								<td>
									<Link
										className='link awbnum-link'
										to={`/manage-booking/${recentBook?.shipmentIdentifierDetails?.shipmentPrefix}${recentBook?.shipmentIdentifierDetails?.masterDocumentNumber}`}
									>
										{recentBook?.shipmentIdentifierDetails?.shipmentPrefix}-
										{recentBook?.shipmentIdentifierDetails?.masterDocumentNumber}
									</Link>
								</td>
								<td>
									<p className='other-text'>
										{recentBook?.origin}-{recentBook?.destination}
									</p>
								</td>
								<td>
									<p className='other-text'>
										{moment(recentBook?.shippingDate).format('DD MMM YYYY')}
									</p>
								</td>
								<td>
									<p className='other-text'>
										{recentBook?.product}&nbsp;
										{recentBook?.commodity}-
										{recentBook?.pieces + (recentBook?.pieces > 1 ? 'pcs' : 'pc')}
										/{recentBook?.weight + getWeightUnit(recentBook?.weightUnit)}
									</p>
								</td>
								{bookingStatusFunction(recentBook)}
								<td>
									<p
										className='link track-text'
										onClick={(e) => redirectToTrackBooking(e, recentBook)}
									>
										{t('COMMON.TRACK')} &nbsp;
										<img
											style={{ width: '15px', height: '20px' }}
											src={require('../../../assets/images/LinkBlue.svg').default}
											alt='Track'
										/>
									</p>
								</td>
							</tr>
						))}
						<tr>
							<td>
								<Link className='track-text link' to='/manage-booking'>
									{t('DASHBOARD.VIEW_ALL')}
									<img
										style={{ width: '15px', height: '20px', marginLeft: '8px' }}
										src={require('../../../assets/images/LinkBlue.svg').default}
										alt={t('DASHBOARD.VIEW_ALL')}
									/>
								</Link>
							</td>
						</tr>
					</table>
				) : (
					!isLoading && (
						<div className='empty-records mb-4' id='booking-list'>
							{t(Messages.NO_BOOKINGS_AVAILABLE, { ns: 'errors' })}
						</div>
					)
				)}
			</div>
		</div>
	)
}

export default RecentBookingList
