import React, { useState, useEffect } from 'react';
import ReportsMenu from './ReportsMenu';
import { Calendar } from 'primereact/calendar'
import moment from 'moment';
import { generateDapReport } from '../../services/generateReportsServices';
// import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next'
import {
    getFromLocalStorage,
} from '../../utils/StorageUtils'
import LoadingIndicator from '../LoadingIndicator'
import {
    FORM_FIELDS,
} from '../../constants'
import MESSAGES from '../../constants/Messages'
import { Alert } from '../../common/Alert'
import { Dropdown } from '../../common/DropDown'

const DapReport = () => {

    const [isLoading, setIsLoading] = useState(false)
    const { t, i18n } = useTranslation(['labels', 'errors'])
    const [month, setMonth] = useState('');
    const [awbNumber, setAwbNumber] = useState(null);
    const [reportType, setReportType] = useState('csv');
    const [maxDate, setMaxDate] = useState(moment().toDate())
    //const [minDate, setMinDate] = useState(moment().subtract(2, 'years').toDate())
    const [error, setError] = useState(null)
    const [alertMessage, setAlertMessage] = useState('')
    const [status, setStatus] = useState('')
    const [months, setMonths] = useState([]);

    const handleDownload = () => {
        let monthSelected = moment(month).format('MMM yy')
        var agentDetails = getFromLocalStorage('userDetails')
        const handleSetError = (key, text) => {
            error
                ? setError({
                    ...error,
                    [key]: text,
                })
                : setError({
                    [key]: text,
                })
        }

        if (!month || month === '') {
            const errorObj = {}
            errorObj[FORM_FIELDS.INVALID_REPORT_MONTH] = 'Please select a month'
            setError(errorObj)
        }

        else {
            setIsLoading(true)
            const data = {
                awbNumber: awbNumber,
                customerName: JSON.parse(agentDetails).companyName,
                monthYear: monthSelected,
                agentCode: JSON.parse(agentDetails).agentCode,
                reportType: 'pdf'
            }

            generateDapReport(data).then((res) => {
                setIsLoading(false)
                if (res && res.data && res.data !== '') {
                    let url = res.data
                    setStatus(true)
                    setAlertMessage('Report generated sucessfully')
                    window.open(url, "_blank")
                } else {
                    setStatus(false)
                    setAlertMessage('Data is not available for the selected month')
                }
                //reportType === 'csv' ? window.open(url, "_self") : window.open(url, "_blank")

            }).catch((error) => {
                setStatus(false)
                setAlertMessage('Sorry, we could not generate the report. Please try again later.')
                setIsLoading(false)
            })
        }

    }


    const clearError = (key) => {
        const tempError = error ? { ...error } : null
        if (tempError) {
            tempError[key] = null
            setError(tempError)
        }
    }
    const handleSetError = (key, text) => {
        error
            ? setError({
                ...error,
                [key]: text,
            })
            : setError({
                [key]: text,
            })
    }

    function getMonths() {
        let months = []
        for (let i = 12; i > 0; i--) {
            let today = moment()
            const dateVar = today.subtract(i, "months");
            months.push({
                label: dateVar.format('MMMM yyyy'),
                value: dateVar.format('MMM yyyy'),
            })
        }
        return months
    }

    const handleMonth = (month) => {
        setMonth(month)
    }

    useEffect(() => {
        let defaultDate = moment().subtract(1, 'months')
        let month = defaultDate.format('MMM yyyy')
        setMonth(month)
        setMonths(getMonths())
    }, [])

    return (
        <div className='cargo-portal-container'>
            {alertMessage !== '' && status !== null && (
                <Alert
                    isError={!status}
                    showAlert={true}
                    message={t(alertMessage, { ns: 'errors' })}
                    setTimeout={true}
                />
            )}
            <ReportsMenu
                activeItem={1}
            />
            <div className='form-row search-panel'>
                <div
                    className={`col col-12 col-lg-3 ${error && error[FORM_FIELDS.INVALID_REPORT_MONTH] ? 'red-border' : ''
                        }`}
                >


                    <Dropdown
                        value={month}
                        options={months}
                        label=''
                        onChange={(e) => {
                            handleMonth(e.target.value)
                        }}
                        isUpdate={true}
                        label='Select a month'
                    ></Dropdown>

                    <span className='search-panel__errormsg'>
                        {error && error[FORM_FIELDS.INVALID_REPORT_MONTH]
                            ? t(error[FORM_FIELDS.INVALID_REPORT_MONTH], { ns: 'errors' })
                            : ''}
                    </span>

                </div>

                {/*<div className='col col-12 col-lg-3'>
                    <lablel>AWB number (Optional)</lablel>
                    <input
                        className='form-control form-item__field'
                        type='text'
                        onChange={(e) => setAwbNumber(e.target.value)}
                    />
                        </div>*/}
                <div className='col col-12 col-lg-3 mt-1'>
                    <button type='button' className='primary-button mt-4' disabled={month && month !== '' ? false : true}
                        onClick={handleDownload}
                    >
                        {'Download'}
                    </button>
                </div>
            </div>

            {isLoading ? <LoadingIndicator /> : <></>}
        </div>
    )
}

export default DapReport;