import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
	ALLOWABLE_BOOKING_DATE,
	DISPLAY_DATE_RANGE,
} from '../../configs/appConfigs'
import {
	clearItemFromSessionStorage,
	getBase64Decoded,
	getFromSessionStorage,
	getBase64Encoded,
	setToSessionStorage,
} from '../../utils/StorageUtils'
import BookingMenu from '../BookingMenu'
import FastTrack from '../FastTrack'
import FlightDetailsList from '../FlightDetailsList'
import PriceCalendar from '../PriceCalendar'
import ShipmentSummary from '../ShipmentSummary'
import MESSAGES from '../../constants/Messages'
import { addError, updateDigitaldataFlightSearch } from '../../utils/analytics'
import pageNames from '../../constants/pageNames'
import { PAGE_PRIMARY_CATEGORY, PAGE_SUB_CATEGORY1, BOOKING_PAGE_SUB_CATEGORY2 } from '../../constants'
const SearchResults = ({
	searchFormData,
	setSearchFormData,
	setCalendar,
	resultSet,
	filters,
	clearFiltersFromState,
	clearQuoteDetails,
	validationError,
}) => {
	//for multilingual
	const { t, i18n } = useTranslation(['labels', 'errors'])

	const [selectedDate, setSelectedDate] = useState(null)

	const [selectionStartDate, setSelectionStartDate] = useState(null)
	const [selectionDateRange, setSelectionDateRange] = useState([])

	const history = useHistory()

	const [calendarStartDate, setCalendarStartDate] = useState(null)
	const [calendarEndDate, setCalendarEndDate] = useState(null)

	// flight enquiry data set
	const [enquiredPresent, setEnquiredPresent] = useState(false)
	const encodedflightParameterObj = getFromSessionStorage('flightParameterObj')


	const [airportList, setAirportList] = useState([])
	const [fastTrackList, setFastTrackList] = useState([])
	const [fasterToDefaultByMin, setFasterToDefaultByMin] = useState(null)
	const [fasttrackLowestRate, setFasttrackLowestRate] = useState(false)

	const [filterUnknownShipment, setFilterUnknownShipment] = useState(false)
	const [isInstantConfirmation, setInstantConfimation] = useState('');
	const [fromModifyForm, setFromModifyForm] = useState(false);


	useEffect(() => {
		if (history.location.state && history.location.state.modifyForm) {
			setFromModifyForm(history.location.state.modifyForm)
		}
	}, [history])

	useEffect(() => {
		const encodedSearchObject = getFromSessionStorage('searchObject')
		if (!encodedSearchObject) return history.push('/booking')
		setSearchFormData(getBase64Decoded(encodedSearchObject))
		clearItemFromSessionStorage('quotationDetails')
		clearItemFromSessionStorage('quotationId')
		//clearItemFromSessionStorage('upsellOffered')	
		let _searchObject = getBase64Decoded(encodedSearchObject);
		if(_searchObject){
		_searchObject.isInstantConfirmation = isInstantConfirmation;
		let _encodedSearchObject = getBase64Encoded(_searchObject);
		setToSessionStorage('searchObject',_encodedSearchObject);	
		}
		updateDigitaldataFlightSearch(
			pageNames.CHOOSE_FLIGHT,
			PAGE_PRIMARY_CATEGORY.E_SERVICES,
			PAGE_SUB_CATEGORY1.BOOKING,
			BOOKING_PAGE_SUB_CATEGORY2.NEW_BOOKING
		)
		return function cleanUp() {
			clearQuoteDetails()
			clearFiltersFromState()
		}
	}, [])


	useEffect(() => {
		if (!searchFormData) return
		let calendarStartDate = moment()
		let calendarEndDate = moment().add(ALLOWABLE_BOOKING_DATE - 1, 'days')
		const { shippingDate, arrivalDate } = searchFormData

		if (arrivalDate) {
			calendarStartDate = moment(shippingDate, 'DD-MMM-YYYY')
			if (
				!moment(arrivalDate).isAfter(
					moment().add(ALLOWABLE_BOOKING_DATE - 1, 'days')
				)
			) {
				calendarEndDate = moment(arrivalDate, 'DD-MMM-YYYY')
			}
		}
		if (
			searchFormData.isModifyBooking &&
			searchFormData.sccCodes &&
			searchFormData.sccCodes.length &&
			searchFormData.sccCodes.some((e) => e.scc === 'UKN')
		) {
			setFilterUnknownShipment(true)
		}

		setCalendarStartDate(calendarStartDate)
		setCalendarEndDate(calendarEndDate)
		setSelectedDate(searchFormData.shippingDate)
	}, [searchFormData])

	useEffect(() => {
		if (!calendarEndDate) return
		setSelectionStartDate(
			getInitSelectionStartDate(
				searchFormData.shippingDate,
				searchFormData.arrivalDate
			)
		)
	}, [searchFormData, calendarEndDate])

	//This useEffect is to set slectionEndDate based on electionStartDate
	useEffect(() => {
		if (selectionStartDate)
			setSelectionDateRange(
				getDateRange(selectionStartDate, DISPLAY_DATE_RANGE)
			)
	}, [selectionStartDate])

	//This useEffect is to set calendar to store based on selectionStartDate
	useEffect(() => {
		if (calendarStartDate) {
			if (searchFormData.arrivalDate) {
				setCalendar(
					getDateRange(
						calendarStartDate,
						calendarEndDate.diff(calendarStartDate, 'days') + 1
					)
				)
			} else {
				setCalendar(getDateRange(calendarStartDate, ALLOWABLE_BOOKING_DATE))
			}
		}
	}, [calendarStartDate, searchFormData])

	const getDateRange = (startDate, duration) => {
		const date = moment(startDate, startDate.format('DD-MMM-YYYY'))
		let dateArray = []
		for (let i = 0; i < duration; i++) {
			dateArray = [...dateArray, date.format('DD-MMM-YYYY')]
			date.add(1, 'days')
		}
		return dateArray
	}

	/**
	 * pass in positive or negative number
	 * if positive number is passed increates the selectionStartDate by 1
	 * if negative number is passed decreases the selectionStartDate by 1
	 */
	const increaseSelectionStartDate = (incearement) => {
		const curSelectionDateRange = moment(
			selectionStartDate,
			selectionStartDate.format('DD-MMM-YYYY')
		)
		if (incearement > 0) curSelectionDateRange.add(1, 'days')
		if (incearement < 0) curSelectionDateRange.subtract(1, 'days')
		setSelectionStartDate(curSelectionDateRange)
	}

	/**
	 * @author A-9467
	 * @param {*} shippingDate in DD-MMM-YYYY format
	 * @param {*} arrivalDate in DD-MMM-YYYY format
	 * DD-MMM-YYYY
	 * selectionStartDate based on the selected shipping date
	 *
	 */
	const getInitSelectionStartDate = (shippingDate, arrivalDate) => {
		let initSelectionStartDate = arrivalDate
			? moment(shippingDate, 'DD-MMM-YYYY')
			: moment(shippingDate, 'DD-MMM-YYYY').subtract(2, 'days')
		if (arrivalDate) {
			if (
				moment(initSelectionStartDate)
					.add(DISPLAY_DATE_RANGE - 1, 'days')
					.isAfter(moment().add(ALLOWABLE_BOOKING_DATE - 1, 'days'))
			) {
				return moment().add(ALLOWABLE_BOOKING_DATE - 5, 'days')
			} else {
				return moment(shippingDate, 'DD-MMM-YYYY')
			}
		}
		if (initSelectionStartDate.isBefore(calendarStartDate)) {
			initSelectionStartDate = calendarStartDate
		}
		const diff = calendarEndDate.diff(initSelectionStartDate, 'days')
		if (diff < DISPLAY_DATE_RANGE) {
			initSelectionStartDate = moment(calendarEndDate).subtract(
				DISPLAY_DATE_RANGE - 1,
				'days'
			)
		}

		return initSelectionStartDate
	}

	useEffect(() => {
		if (resultSet) {
			handleFilterChange()
		}
	}, [filters, resultSet, selectedDate])

	const handleFilterChange = () => {

		let airportList =
			resultSet[selectedDate] &&
				resultSet[selectedDate].flightList &&
				resultSet[selectedDate].flightList.services.DEFAULT
				? [
					...resultSet[selectedDate].flightList.services.DEFAULT
						.flightItineraryList,
				]
				: []
		let fastTrackList =
			resultSet[selectedDate] &&
				resultSet[selectedDate].flightList &&
				resultSet[selectedDate].flightList.services &&
				resultSet[selectedDate].flightList.services.XPS
				? [...resultSet[selectedDate].flightList.services.XPS.flightItineraryList]
				: []
		let fasttrackLowestRate =
			resultSet[selectedDate] &&
				resultSet[selectedDate].flightList &&
				resultSet[selectedDate].flightList.services &&
				resultSet[selectedDate].flightList.services.XPS
				? resultSet[selectedDate].flightList.services.XPS.lowestRate
				: ''
		airportList =
			airportList && airportList.length
				? filters && (filters.stops || filters.stops == 0)
					? airportList.filter((destinationAirport) => {
						if (filters.stops != '2+') {
							return (
								destinationAirport.flightSegmentList.length - 1 ===
								filters.stops
							)
						} else {
							return destinationAirport.flightSegmentList.length - 1 > 1
						}
					})
					: airportList
				: []
		airportList =
			airportList && airportList.length
				? filters && filters.duration && filters.duration > 0
					? airportList.filter((destinationAirport) => {
						let hoursMinutes =
							destinationAirport.cumulativeJourneyTime.split(/[.:]/)
						let hours = parseInt(hoursMinutes[0], 10)
						let minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0
						return hours + minutes / 60 <= filters.duration
					})
					: airportList
				: []
		airportList =
			airportList && airportList.length
				? filters && filters.departureTime && filters.departureTime.length > 0
					? airportList.filter((destinationAirport) => {
						let departureDate = new Date(destinationAirport.departureDateTime)
						let hours = departureDate.getHours()
						let minutes = departureDate.getMinutes() / 60
						let timeInMinute = (hours + minutes) * 60
						return (
							timeInMinute >= filters.departureTime[0] &&
							timeInMinute <= filters.departureTime[1]
						)
					})
					: airportList
				: []
		airportList =
			airportList && airportList.length
				? filters && filters.arrivalTime && filters.arrivalTime.length > 0
					? airportList.filter((destinationAirport) => {
						let arrivalDate = new Date(destinationAirport.arrivalDateTime)
						let hours = arrivalDate.getHours()
						let minutes = arrivalDate.getMinutes() / 60
						let timeInMinute = (hours + minutes) * 60
						return (
							timeInMinute >= filters.arrivalTime[0] &&
							timeInMinute <= filters.arrivalTime[1]
						)
					})
					: airportList
				: []
		airportList =
			airportList && airportList.length
				? filters && filters.LATTime && filters.LATTime.length > 0
					? airportList.filter((destinationAirport) => {
						let latDate = new Date(destinationAirport.lastAcceptanceTime)
						let hours = latDate.getHours()
						let minutes = latDate.getMinutes() / 60
						let timeInMinute = (hours + minutes) * 60
						return (
							timeInMinute >= filters.LATTime[0] &&
							timeInMinute <= filters.LATTime[1]
						)
					})
					: airportList
				: []
		airportList =
			airportList && airportList.length
				? filters && filters.EDTTime && filters.EDTTime.length > 0
					? airportList.filter((destinationAirport) => {
						let edtDate = new Date(destinationAirport.earliestDeliveryTime)
						let hours = edtDate.getHours()
						let minutes = edtDate.getMinutes() / 60
						let timeInMinute = (hours + minutes) * 60
						return (
							timeInMinute >= filters.EDTTime[0] &&
							timeInMinute <= filters.EDTTime[1]
						)
					})
					: airportList
				: []
		airportList =
			airportList && airportList.length
				? filters && filters.isAllotmentsOnly
					? airportList.filter((destinationAirport) => {
						return filters.isAllotmentsOnly
							? destinationAirport.allotmentAvailable
								? true
								: false
							: true
					})
					: airportList
				: []
		airportList =
			airportList && airportList.length
				? (filters && filters.isUnknownShipment) || filterUnknownShipment
					? airportList.filter((destinationAirport) => {
						return filters.isUnknownShipment || filterUnknownShipment
							? !destinationAirport.usoutbound
							: true
					})
					: airportList
				: []

		airportList =
			airportList && airportList.length
				? filters && filters.aircraftClassification
					? airportList.filter((destinationAirport) => {
						return destinationAirport.flightSegmentList.every(
							(segment) =>
								(filters.aircraftClassification.freighter &&
									segment.aircraftClassification === 'Freighter') ||
								(filters.aircraftClassification.narrowBody &&
									segment.aircraftClassification === 'Narrow Body') ||
								(filters.aircraftClassification.wideBody &&
									segment.aircraftClassification === 'Wide Body') ||
								(filters.aircraftClassification.truck &&
									segment.aircraftClassification === 'Truck')
						)
					})
					: airportList
				: []

		const fasterToDefaultByMin =
			resultSet[selectedDate] &&
				resultSet[selectedDate].flightList &&
				resultSet[selectedDate].flightList.services &&
				resultSet[selectedDate].flightList.services.XPS
				? resultSet[selectedDate].flightList.services.XPS.fasterToDefaultByMins
				: 0

		if (filters && filters.leastStops) {
			if (airportList.length) {
				airportList.sort((a, b) => {
					return a.flightSegmentList.length - 1 - (b.flightSegmentList.length - 1)
				})
			}
		}
		if (filters && filters.cheepest) {
			if (airportList.length) {
				airportList.sort((a, b) => {
					return (
						a.ratingDetails[0].grandTotal * 1 - b.ratingDetails[0].grandTotal * 1
					)
				})
			}
		}
		if (filters && filters.fastest) {
			if (airportList.length) {
				airportList.sort((a, b) => {
					return (
						parseInt(a.cumulativeJourneyTime) - parseInt(b.cumulativeJourneyTime)
					)
				})
			}
		}

		fastTrackList =
			fastTrackList && fastTrackList.length
				? (filters && filters.isUnknownShipment) || filterUnknownShipment
					? fastTrackList.filter((destinationAirport) => {
						return filters.isUnknownShipment || filterUnknownShipment
							? !destinationAirport.usoutbound
							: true
					})
					: fastTrackList
				: []

		setAirportList(airportList)
		setFasterToDefaultByMin(fasterToDefaultByMin)
		setFasttrackLowestRate(fasttrackLowestRate)
		setFastTrackList(fastTrackList)

	}

	const handleBack = () => {
		if (validationError || fromModifyForm) {
			history.push({
				pathname: `/booking/modify/${searchFormData.awbNo}`,
				state: {
					isBackButtonAction: true,
				},
			})
		} else {
			history.push(`/manage-booking/${searchFormData.awbNo}`)
		}
	}

	if (!searchFormData) return <></>

	return (
		<div>
			<div className='col-12'>
				<BookingMenu
					activeItem={searchFormData && searchFormData.isModifyBooking ? 1 : 0}
				/>
			</div>
			<div className='title col-12'>
				<h3> {t('COMMON.CHOOSE_FLIGHT')} </h3>
			</div>

			<div className='flight-summary col-12'>
				<ShipmentSummary
					searchFormData={searchFormData}
					setSeachFormData={setSearchFormData}
				/>
			</div>

			{encodedflightParameterObj != null ? (
				enquiredPresent === true ? (
					<></>
				) : resultSet[selectedDate] && resultSet[selectedDate].isLoading ? (
					<></>
				) : (
					<div className='flight_choose-err cargo-portal-container col-12'>
						<div className='col-12'>
							<p
								className='alert alert-danger booking-validation-error'
								style={{ color: '#4E4C4A' }}
							>
								{t(MESSAGES.NO_FLIGHT_TO_BOOK, { ns: 'errors' })}
								{addError(
									MESSAGES.NO_FLIGHT_TO_BOOK,
									i18n.getResource('en', 'errors', MESSAGES.NO_FLIGHT_TO_BOOK)
								)}
							</p>
						</div>
					</div>
				)
			) : (
				<></>
			)}

			{validationError && searchFormData && !searchFormData.isModifyBooking && (
				<div className='custom-mobile-row cargo-portal-container col-12'>
					<div className='custom-mt-r-0 col custom-mb-1 custom-pl-5 custom-pr-5'>
						<p
							className='alert alert-danger booking-validation-error'
							style={{ color: '#4E4C4A' }}
						>
							{
							(t(validationError, { ns: 'errors' }))
}
							{addError('VALIDATION_ERROR', validationError)}
						</p>
					</div>
				</div>
			)}

			{validationError && searchFormData && searchFormData.isModifyBooking && (
				<div className='custom-mobile-row cargo-portal-container col-12'>
					<div className='col custom-mt-r-0 custom-mb-1 custom-pl-5 custom-pr-5'>
						<p
							className='alert alert-danger booking-validation-error'
							style={{ color: '#4E4C4A' }}
						>
							{validationError}
							{addError('VALIDATION_ERROR', validationError)}
						</p>
					</div>
				</div>
			)}

			<PriceCalendar
				calendarDates={selectionDateRange}
				selectedDate={selectedDate}
				setSelectedDate={setSelectedDate}
				searchFormData={searchFormData}
				increaseSelectionStartDate={increaseSelectionStartDate}
				calendarStartDate={calendarStartDate}
				calendarEndDate={calendarEndDate}
				resultSet={resultSet}
			/>
			{fastTrackList && fastTrackList.length ? (
				<FastTrack
					setEnquiredPresent={setEnquiredPresent}
					fasttrackLowestRate={fasttrackLowestRate}
					fastTrackList={[...fastTrackList]}
					searchFormData={searchFormData}
					fasterToDefaultByMin={fasterToDefaultByMin}
				/>
			) : (
				<></>
			)}
			<FlightDetailsList
				setEnquiredPresent={setEnquiredPresent}
				response={resultSet[selectedDate]}
				airportList={airportList}
				selectedDate={selectedDate}
				setInstantConfimation={setInstantConfimation}
			/>
			{searchFormData.isModifyBooking && (
				<div className='col-12 p-l-0 pt-5'>
					<button
						type='button'
						className='secondary-button mr-3'
						onClick={() => handleBack()}
					>
						{t('COMMON.BACK_BUTTON')}
					</button>
				</div>
			)}
		</div>
	)
}

export default SearchResults
