import React, { useState, useEffect } from 'react'
import { getTemplateDetails, getRecentBookingDetails } from './templateUtils'
import CustomAutoCompleteRoot from '../../common/CustomAutoComplete'
import { useHistory } from 'react-router-dom'
import { checkIsBackButtonAction } from './formUtils'
import { NOTIFICATION_MESSAGE_TYPE } from '../../constants'
import {
	clearItemFromSessionStorage,
} from '../../utils/StorageUtils'
import { useTranslation } from 'react-i18next'
import Messages from '../../constants/Messages'
const SearchHeader = ({
	searchFormData,
	setNotLeave,
	updateTemplateView,
	setUpdateTemplateView,
	templateParam,
	serialNo,
	listTemplateNamesResponse,
	listDraftsResponse,
	setSearchFormDataToState,
	setAdditionalInfoData,
	setIsLoading,
	setTemplateView,
	setTemplateName,
	setSerialNumber,
	shippingItems,
	handleClear,
	isModifyBooking,
	awbNo,
	setAlertMessage,
	setTemplateSuccess,
	setArrivalDate,
	getlistDraftsResponse,
	setSelectedTemplate,
	selectedTemplate,
	listQuotesResponse,
	listQuoteError,
	listTemplateNamesError,
	listDraftsError,
	setNotificationMessage,
	setNotificationType,
	isSeasonalBooking,
	setInitialList,
	navigateBack,
}) => {
	const history = useHistory()

	const [isBookingDetailsLoading, setIsBookingDetailsLoading] = useState(false)


	//for multilingual translation
	const { t } = useTranslation(['labels', 'errors'])

	//=====changing properties of draft listingh array=====//

	const newArrayOfObj = listDraftsResponse?.bookingDraft?.map(
		({ draftName: templateName, ...rest }) => ({
			templateName,
			...rest,
		})
	)

	//====================================================//

	const handleClearForm = (e) => {
		handleClear()
		setSelectedTemplate('')
		setNotLeave(false)
	}

	const handleChangeTemplate = async (templateVal) => {
		setSelectedTemplate(templateVal)
	}

	const handleSelectTemplate = async (templateVal) => {
		setSelectedTemplate(templateVal)
		populateTemplateDetailsInForm(templateVal?.templateName, templateVal?.type)
	}

	const handleSelectedItem = async (name, type) => {
		setSelectedTemplate(name)
		populateTemplateDetailsInForm(name, type)
	}


	const populateTemplateDetailsInForm = async (templateName, type) => {
		let templateParameters = await getTemplateDetails(
			setSearchFormDataToState,
			setAdditionalInfoData,
			templateName,
			type,
			setIsLoading,
			getlistDraftsResponse
		)

		setTemplateView(true)
		if (templateParameters) {
			setTimeout(function () {
				setSearchFormDataToState(null)
				setAdditionalInfoData({
					type: null,
					pharma: {},
				})
				setSearchFormDataToState(templateParameters)
				if (templateParameters.additionalInfo) {
					setAdditionalInfoData(templateParameters.additionalInfo)
				}
				setIsLoading(false)
			}, 1000)
		} else {
			if (templateParameters === null) {
				type === 'DRAFT'
					? setAlertMessage(t(Messages.NO_DRAFT_FOUND, { ns: 'errors' }))
					: setAlertMessage(t(Messages.TEMP_NOT_FOUND, { ns: 'errors' }))
				setTemplateSuccess(false)
				window.scrollTo(0, 0)
				setIsLoading(false)
			}
		}
	}

	const populateQuoteDetailsInSummary = (quotationName) => {
		history.push(`/booking/summary/${quotationName}`)
	}

	useEffect(() => {
		async function populateTemplate() {
			if (templateParam && templateParam !== '' && serialNo && serialNo != '') {
				setUpdateTemplateView(true)
				setTemplateName(templateParam)
				setSerialNumber(serialNo)
				populateTemplateDetailsInForm(templateParam)
			}
		}

		populateTemplate()
	}, [shippingItems])

	useEffect(() => {
		async function populateRecentBookingDetails() {
			if (shippingItems && shippingItems.length > 0 && awbNo && !isBookingDetailsLoading) {
				setIsBookingDetailsLoading(true)
				const data = await getRecentBookingDetails(
					setSearchFormDataToState,
					setAdditionalInfoData,
					shippingItems,
					awbNo,
					setIsLoading,
					isModifyBooking
				)
				setTemplateView(true)
				//setSearchFormDataToState(data)
				if (data && !data.errorDescription) {
					setTimeout(function () {
						//setSearchFormDataToState(null)
						setArrivalDate(null)
						setAdditionalInfoData({
							type: null,
							pharma: {},
						})
						//setSearchFormDataToState(data)
						if (data.additionalInfo) {
							setAdditionalInfoData(data.additionalInfo)
						}
						setIsLoading(false)
					}, 1000)
				} else if (data && data.errorDescription) {
					setAlertMessage(`${awbNo} - ${data.errorDescription}`)
					setTemplateSuccess(false)
					window.scrollTo(0, 0)
				}
			}
		}
		let isBackButtonAction = checkIsBackButtonAction(history)
		if (isBackButtonAction && searchFormData) {
			setTemplateView(true)
		} else {
			populateRecentBookingDetails()
		}
	}, [shippingItems])

	useEffect(() => {
		if (awbNo && !checkIsBackButtonAction(history)) {
			setIsLoading(true)
			setSearchFormDataToState(null)
		}
	}, [awbNo])

	return (
		<div class='search-panel__booking-title'>
			<div className='row'>
				{!updateTemplateView && !isModifyBooking && (
					<div
						style={{ border: 'none', width: '100%' }}
						className='title-content w-100 col-12'
					>
						<div
							style={{ display: 'flex', alignItems: 'center' }}
							className={` row d-flex ${!isSeasonalBooking ? 'flex-row-reverse' : ''} `}
						>
							<div className='col-12 col-lg-4'>
								<CustomAutoCompleteRoot
									draftList={listDraftsResponse}
									templateList={listTemplateNamesResponse}
									quoteList={listQuotesResponse}
									handleSelectedItem={handleSelectedItem}
									setSelectedItem={setSelectedTemplate}
									selectedItem={selectedTemplate}
									populateQuoteDetailsInSummary={populateQuoteDetailsInSummary}
									listQuoteError={listQuoteError}
									listTemplateNamesError={listTemplateNamesError}
									listDraftsError={listDraftsError}
									isSeasonalBooking={isSeasonalBooking}
									setInitialList={setInitialList}

								/>
							</div>
						</div>
					</div>
				)}

				{isSeasonalBooking && selectedTemplate.length === 0 ?
					<>
						<div className='col-12 mt-3'>
						<div className='permanent-booking-wrapper row '>
							{navigateBack && 
								<div className='col-1 btn-back '>
									<button
										type='submit'
										value='Submit'
										className='primary-button mr-2'
										onClick={(e) => {
											setInitialList()
										}
										}
									>
										{t('COMMON.BACK_BUTTON')}
									</button>
								</div>
							}
							<div className='col-3'>
								<button
									value='Create new template'
									className='secondary-button mr-2'
									onClick={(e) => {
										history.push('/booking/search')
									}
									}
								>
									{t('TEMPLATES.CREATE_TEMPLATE')}
								</button>
							</div>
						</div>
						</div>
					</>
					: <></>}
				{updateTemplateView && (
					<div
						style={{ display: 'flex', justifyContent: 'space-between' }}
						className='title-content modify_temp ml-3'
					>
						<h3>
							{t('MNGTMPLT.MODIFY_TEMPLATE')} - {templateParam}{' '}
						</h3>
					</div>
				)}

				{!updateTemplateView && isModifyBooking && (
					<div
						style={{ display: 'flex', justifyContent: 'space-between' }}
						className='title-content modify_temp ml-3'
					>
						<h3>{t('COMMON.MODIFYBOOKING')}</h3>
					</div>
				)}
			</div>
		</div>
	)
}

export default SearchHeader
