import React, { useState, useEffect } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import './filter-popup.scss';
import { useTranslation } from 'react-i18next'
import filterIcon from '../../../../assets/images/filter.svg';
import filterActiveIcon from '../../../../assets/images/filter-active.svg';
import {
    formatInputTypeNumber,
    allowNumricOnly,
    validateAWB,
    removeError,
    hasError,
} from '../../../ManageBooking/ListBooking/ListbookingValidations'
import { validateRequired } from '../../../CaptureAWB/captureAwbUtils'

const FilterPopup = ({
    op,
    setFilterSummary,
    handleFilterAction,
    maxDate,
    formatDate,
    setAwbRefreshed,
    awbRefreshed,
    setAwbFilter,
    awbFilter,
}) => {
    const { t, i18n } = useTranslation(['labels', 'errors'])
    //const [bookingStatus, setBookingStatus] = useState([])
    const [confirmed,setConfirmed] = useState(false);
    const [cancelled,setCancelled] = useState(false);
    const [queued,setQueued] = useState(false);
    const [approved,setApproved] = useState(false);
    const [onHold,setOnHold] = useState(false);
    const [inProgress,setInProgress] = useState(false);
    const [noInformation,setNoInformation] = useState(false);
    const [missingMAWB,setMissingMAWB] = useState(false);
    const [missingHAWB,setMissingHAWB] = useState(false);
    const [notified,setNotified] = useState(false);
    const [notNotified, setNotNotified] = useState(false);
    const [notApplicable, setNotApplicable] = useState(false);
    const [acknowledged, setAcknowledged] = useState(false);
    const [acknowledgementResponse, setAcknowledgementResponse] = useState(false);
    const [escalationAcknowledged, setEscalationAcknowledged] = useState(false);
    const [awbPrefix, setAwbPrefix] = useState('607')
    const [awbNumber, setAwbNumber] = useState('')
    const [error, setError] = useState([])
    const [filterType, setFilterType] = useState('awb-filter')
    const [mismatch, setMismatch] = useState(false);

    const [fmaReceived, setFmaReceived] = useState(false);
    const [fnaReceived, setFnaReceived] = useState(false);
    const [fmaFnaNotacknowledged, setFmaFnaNotAcknowledged] = useState(false);

    const [hsCodeValid, setHsCodeValid] = useState(false);
    const [hsCodeInValid, setHsCodeInValid] = useState(false);

    const getIntialFilter = () => {
        return {
            booking: {
                confirmed,
                queued,
            },
            customs: {
                approved,
                onHold,
                inProgress,
                missingHAWB,
                missingMAWB,
            },
            notification: {
                notified,
                notNotified,
                notApplicable,
            },
            acknowledgement: {
                acknowledged,
                acknowledgementResponse,
                escalationAcknowledged,
            },
            awbFilter: {
                awbPrefix,
                awbNumber,
            },
            fmaFnaAckStatus: {
                fmaReceived,
                fnaReceived,
                fmaFnaNotacknowledged,
            },
            hsCodeStatus: {
                hsCodeValid,
                hsCodeInValid
            }
        }
    }

    const [filterValues, setFilterValues] = useState(getIntialFilter());

    useEffect(() => {
        if(awbRefreshed) {
            //clearFilterStates()
            setAwbRefreshed(false)
        }
    }, [awbRefreshed])

    const getFilterData = () => {
        let filter = {
            "bookingStatus": getBookingStatus(),
            "customStatus": getCustomStatus(),
            "filterMissingMAWB": missingMAWB,
            "filterMissingHAWB": missingHAWB,
            "notificationStatus": getNotificationStatus(),
            "acknowledgementStatus": getAcknowledgementStatus(),
            fmaFnaAckStatus: getFMAFNAStatusFilter(),
            hsCodeStatus: getHSCodeStatusFilter(),
            airlinePrefix: awbNumber && awbNumber !== '' && awbPrefix && awbPrefix != ''
                ? awbPrefix : null,
            awbNumber: awbNumber && awbNumber !== '' ? awbNumber : null,
            filterMismatch: mismatch,
        }

        let filterData = {
            booking: {
                confirmed,
                queued,
            },
            customs: {
                approved,
                onHold,
                inProgress,
                missingHAWB,
                missingMAWB,
                mismatch,
            },
            notification: {
                notified,
                notNotified,
                notApplicable,
            },
            acknowledgement: {
                acknowledged,
                acknowledgementResponse,
                escalationAcknowledged
            },
            awbFilter: {
                awbPrefix,
                awbNumber,
            },
            fmaFnaAckStatus: {
                fmaReceived,
                fnaReceived,
                fmaFnaNotacknowledged,
            },
            hsCodeStatus: {
                hsCodeValid,
                hsCodeInValid
            }
        }
        setFilterValues(filterData)
        setFilterSummary(filter);
        handleFilterAction(filter);

        // Reset filter data if not filter selected
        if (!confirmed && !queued && !approved && !onHold && !inProgress && !missingHAWB && !missingMAWB
            && !notified && !notApplicable && !notNotified && !acknowledged && !acknowledgementResponse && !escalationAcknowledged
            && awbNumber === '' && !fmaReceived && !fnaReceived && !fmaFnaNotacknowledged && !hsCodeValid && !hsCodeInValid && !mismatch) {
            setAwbFilter(null)
        }
    }

    const getBookingStatus = () => {
        let bookingStatus = [];
        if(confirmed){
            bookingStatus.push('CONFIRMED');
        }
        if(cancelled){
            bookingStatus.push('CANCELLED');
        }
        if(queued){
            bookingStatus.push('QUEUED');
        }
        return bookingStatus;
    }

    const getCustomStatus = () => {
        let customStatus = [];
        if(approved){
            customStatus.push('APPROVED');
        }
        if(onHold){
            customStatus.push('ONHOLD');
        }
        if(inProgress){
            customStatus.push('REQUIRED_INFORMATION');
        }
        if(missingMAWB || missingHAWB){
            customStatus.push('NO_INFORMATION');
        }
        if(mismatch) {
            customStatus.push('MISMATCH')
        }
        return customStatus;
    }

    const getNoInfoStatus = () => {
        let noInfoStatus = [];
        if(missingMAWB){
            noInfoStatus.push('Missing MAWB');
        }
        if(missingHAWB){
            noInfoStatus.push('Missing HAWB');
        }
        return noInfoStatus
    }

    const getNotificationStatus = () => {
        let notificationStatus = [];
        if(notified){
            notificationStatus.push('NOTIFIED');
        }
        if(notNotified){
            notificationStatus.push('NOT_NOTIFIED');
        }
        if(notApplicable){
            notificationStatus.push('NOT_APPLICABLE');
        }
        return notificationStatus;
    }

    const getAcknowledgementStatus = () => {
        let acknowledgementStatus = [];
        if(acknowledged){
            acknowledgementStatus.push('ACKNOWLEDGED');
        }
        if(acknowledgementResponse){
            acknowledgementStatus.push('NOTACKNOWLEDGED');
        }
        if(escalationAcknowledged){
            acknowledgementStatus.push('ESCACK');
        }
        return acknowledgementStatus;
    }

    const getFMAFNAStatusFilter = () => {
        let fmaFnaStatusFilter = [];
        if(fmaReceived){
            fmaFnaStatusFilter.push('FMA_RECEIVED');
        }
        if(fnaReceived){
            fmaFnaStatusFilter.push('FNA_RECEIVED');
        }
        if(fmaFnaNotacknowledged){
            fmaFnaStatusFilter.push('NOT_ACK');
        }
        return fmaFnaStatusFilter;
    }

    const getHSCodeStatusFilter = () => {
        let hsCodeStatusFilter = [];
        if (hsCodeValid) {
            hsCodeStatusFilter.push('VALID');
        }
        if (hsCodeInValid) {
            hsCodeStatusFilter.push('INVALID');
        }
        return hsCodeStatusFilter;
    }

    const showFilter = (e) => {
        op.current.toggle(e)
        if(filterValues && filterValues.booking) {
            setConfirmed(filterValues.booking.confirmed)
            setQueued(filterValues.booking.queued)
            if (filterValues.booking.queued || filterValues.booking.confirmed) {
                setFilterType('other-filters')
            }
        }
        if(filterValues && filterValues.customs) {
            setApproved(filterValues.customs.approved)
            setOnHold(filterValues.customs.onHold)
            setInProgress(filterValues.customs.inProgress)
            setMissingHAWB(filterValues.customs.missingHAWB)
            setMissingMAWB(filterValues.customs.missingMAWB)
            setMismatch(filterValues.customs.mismatch)
            if (filterValues.customs.approved || filterValues.customs.onHold
                || filterValues.customs.inProgress || filterValues.customs.missingHAWB
                || filterValues.customs.missingMAWB) {
                setFilterType('other-filters')
            }
        }
        if(filterValues && filterValues.notification) {
            setNotified(filterValues.notification.notified)
            setNotNotified(filterValues.notification.notNotified)
            setNotApplicable(filterValues.notification.notApplicable)
            if (filterValues.notification.notified || filterValues.notification.notNotified
                || filterValues.notification.notApplicable) {
                setFilterType('other-filters')
            }
        }
        if(filterValues && filterValues.acknowledgement) {
            setAcknowledged(filterValues.acknowledgement.acknowledged)
            setAcknowledgementResponse(filterValues.acknowledgement.acknowledgementResponse)
            setEscalationAcknowledged(filterValues.acknowledgement.escalationAcknowledged)
            if (filterValues.acknowledgement.acknowledged || filterValues.acknowledgement.acknowledgementResponse
                || filterValues.acknowledgement.escalationAcknowledged) {
                setFilterType('other-filters')
            }
        }

        if (filterValues && filterValues.fmaFnaAckStatus) {
            setFmaReceived(filterValues.fmaFnaAckStatus.fmaReceived)
            setFnaReceived(filterValues.fmaFnaAckStatus.fnaReceived)
            setFmaFnaNotAcknowledged(filterValues.fmaFnaAckStatus.fmaFnaNotacknowledged)
            if (filterValues.fmaFnaAckStatus.fmaReceived || filterValues.fmaFnaAckStatus.fnaReceived ||
                filterValues.fmaFnaAckStatus.fmaFnaNotacknowledged) {
                setFilterType('other-filters')
            }
        }

        if (filterValues && filterValues.hsCodeStatus) {
            setHsCodeValid(filterValues.hsCodeStatus.hsCodeValid)
            setHsCodeInValid(filterValues.hsCodeStatus.hsCodeInValid)
            if (filterValues.hsCodeStatus.hsCodeValid || filterValues.hsCodeStatus.hsCodeInValid) {
                setFilterType('other-filters')
            }
        }

        if(filterValues && filterValues.awbFilter) {
            setAwbPrefix(filterValues.awbFilter.awbPrefix)
            setAwbNumber(filterValues.awbFilter.awbNumber)
            if (filterValues?.awbFilter?.airlinePrefix
                && filterValues?.awbFilter?.awbNumber) {
                    setFilterType('awb-filter')
            } 
        } 
        if (awbFilter && awbFilter.airlinePrefix && awbFilter.airlinePrefix !== ''
            && awbFilter.awbNumber && awbFilter.awbNumber !== '') {
            setAwbPrefix(awbFilter.airlinePrefix)
            setAwbNumber(awbFilter.awbNumber)
            setFilterType('awb-filter')
        }
        setError([])
    }

    const handleConfirmed = () => {
        setConfirmed(!confirmed);
    }

    const handleCancelled = () => {
        setCancelled(!cancelled);
    }

    const handleQueued = () => {
        setQueued(!queued);
    }

    const handleApproved = () => {
        setApproved(!approved);
    }

    const handleOnHold = () => {
        setOnHold(!onHold)
    }

    const handleReqInfo = () => {
        setInProgress(!inProgress)
    }

    const handleNoInfo = () => {
        setNoInformation(!noInformation)
    }

    const handleMissingMAWB = () => {
        setMissingMAWB(!missingMAWB)
    }

    const handleMissingHAWB = () => {
        setMissingHAWB(!missingHAWB)
    }

    const handleMismatch = () => {
        setMismatch(!mismatch)
    }

    const handleNotified = () => {
        setNotified(!notified)
    }

    const handleNotNotified = () => {
        setNotNotified(!notNotified)
    }

    const handleNotApplicable = () => {
        setNotApplicable(!notApplicable)
    }

    const handleAcknowledgementResponse = () => {
        setAcknowledgementResponse(!acknowledgementResponse)
    }

    const handleAcknowledged =()=>{
        setAcknowledged(!acknowledged)
    }

    const handleEscalationAcknowledged =()=>{
        setEscalationAcknowledged(!escalationAcknowledged)
    }

    const clearFilterStates = (filterTypeChanged) => {
        setConfirmed(false);
        setCancelled(false);
        setQueued(false);
        setApproved(false);
        setOnHold(false);
        setMissingMAWB(false)
        setMissingHAWB(false)
        setNotified(false);
        setNotNotified(false);
        setNotApplicable(false);
        setAcknowledged(false);
        setAcknowledgementResponse(false);
        setEscalationAcknowledged(false);
        setInProgress(false)
        setAwbPrefix('607')
        setAwbNumber('')
        setFmaReceived(false)
        setFnaReceived(false)
        setFmaFnaNotAcknowledged(false)

        setHsCodeValid(false)
        setHsCodeInValid(false)
        setMismatch(false)
        
        if (!filterTypeChanged) {
            setFilterType('awb-filter')
        }
    }


    const onFilter = (e) => {
        getFilterData();
        
    }

    const handleChangeOfAWB = (value) => {
		if (value.length <= 8) {
			setAwbNumber(value)
		}
	}

    return (
        <li className='filter__item'>
            <div className={`showFilter csn-filter filter-link mt-3 mb-4 ${awbFilter ?  'active' : '' }`} onClick={(e) => showFilter(e)}>
                <span className='icon-filter'>
                    <img className='uld-form filter-icon' src={awbFilter ? filterActiveIcon : filterIcon} />
                </span>
                <span>Filters</span>
            </div>

            <div className='b-none d-lg-block'>
                <OverlayPanel
                    ref={op}
                    className='custom_overlay_panel placi-filter'
                    appendTo={document.body}
                >
                     
               <div>
                    <div className="popup-header">
                        <button type="button" className='ml-3 primary-button'
                         onClick={() => clearFilterStates()}>Clear</button>
                    </div>             
                </div>
                    <div className='form-container pt-3 cargo-portal-container'>
                        <div className='form-item form-item--radio mb-4'>
                            <input id='awb-filter' type='radio' value='awb-filter' name='filter-choice' className='form-item__field'
                                aria-invalid='false' pattern='[a-zA-Z]' aria-describedby='input-radio-description'
                                checked={filterType && filterType === 'awb-filter' ? true : false}
                                onChange={(e) => {
                                    setFilterType(e.target.value)
                                    clearFilterStates(true)
                                }} />
                            <label for='awb-filter' className='form-item__label filter-title' style={{ 'font-weight': 'bold' }}>
                                Search AWB number
                            </label>
                        </div>
                        <label className={`form-item__label ${filterType && filterType !== 'awb-filter'  ? 'disable-time-selection' : ''}`}>
                            {t('COMMON.AWB_NUMBER')}
                        </label>
                        <div className={`form-row ${filterType && filterType !== 'awb-filter'  ? 'disable-time-selection' : ''}`}>
                            <div className='col-3 col-md-1'>
                                <input
                                    id='awbPrefix'
                                    name='awbPrefix'
                                    type='text'
                                    className='form-item__field'
                                    aria-invalid={hasError('awbPrefix', error)}
                                    value={awbPrefix}
                                    maxLength='3'
                                    onKeyPress={(evt) => {
                                        return allowNumricOnly(evt)
                                    }}
                                    onChange={(e) => {
                                        setAwbPrefix(e.target.value)
                                    }}
                                    onFocus={(e) => {
                                        removeError(e, setError)
                                    }}
                                    onBlur={(e) => {
                                        //validateRequired(e.target.value, setError, 'awbPrefix')
                                    }}
                                />
                                <span className='form-item__error'>
                                    {error && error['awbPrefix']
                                        ? t(error['awbPrefix'], { ns: 'errors' })
                                        : ''}
                                </span>
                            </div>

                            <div className='col-9 col-md-3'>
                                <input
                                    id='awbNumber'
                                    name='awbNumber'
                                    type='number'
                                    className='form-item__field'
                                    aria-invalid={hasError('awbNumber', error)}
                                    placeholder='AWB'
                                    value={awbNumber}
                                    onKeyDown={(evt) => formatInputTypeNumber(evt)}
                                    onChange={(e) => handleChangeOfAWB(e.target.value)}
                                    onBlur={(e) => {
                                        validateAWB(e.target.value, setError, 'awbNumber')
                                        if (e.target.value !== '') {
                                            validateRequired(awbPrefix, setError, 'awbPrefix')
                                        }
                                    }}
                                    onFocus={(e) => {
                                        removeError(e, setError)
                                    }}
                                />
                                <span className='form-item__error'>
                                    {error && error['awbNumber']
                                        ? t(error['awbNumber'], { ns: 'errors' })
                                        : ''}
                                </span>
                            </div>
                        </div>
                        <div className='form-item form-item--radio mt-5 mb-3'>
                            <input id='other-filters' type='radio' value='other-filters' name='filter-choice' className='form-item__field'
                                aria-invalid='false' pattern='[a-zA-Z]' aria-describedby='input-radio-description'
                                checked={filterType && filterType === 'other-filters' ? true : false}
                                onChange={(e) => {
                                    setFilterType(e.target.value)
                                    clearFilterStates(true)
                                }} />
                            <label for='other-filters' className='form-item__label filter-title' style={{ 'font-weight': 'bold' }}>
                                Other filters
                            </label>
                        </div>
                    </div>
                    <div className={`row form-container pt-3 ${filterType} ${filterType && filterType !== 'other-filters'  ? 'disable-time-selection' : ''}`}>
                  
                    <div className='col-md-2 filterField_wrap stops-radio-wrapper mt-3'>
                                <label className="filter-title">Booking Status</label> 
                                    <div class="checkbox-custom col mt-2">
                                        <div class="checkbox-item">
                                            <input
                                                id='confirmed'
                                                type='checkbox'
                                                name='confirmed'
                                                className='checkbox-item__field'
                                                aria-describedby='confirmed'
                                                onChange={handleConfirmed}
                                                checked={confirmed}
                                            />
                                            <label htmlFor="confirmed" className='checkbox-item__label'>
                                                Confirmed
                                            </label>
                                        </div>
                                    </div>
                                    {/*<div class="checkbox-custom col mt-4">
                                        <div class="checkbox-item">
                                            <input
                                                id='cancelled'
                                                type='checkbox'
                                                name='cancelled'
                                                className='checkbox-item__field'
                                                aria-describedby='cancelled'
                                                onChange={handleCancelled}
                                                checked={cancelled}
                                            />
                                            <label htmlFor='cancelled' className='checkbox-item__label'>
                                                Cancelled
                                            </label>
                                        </div>
                                    </div>*/}
                                    <div class="checkbox-custom col mt-4">
                                        <div class="checkbox-item">
                                            <input
                                                id='queued'
                                                type='checkbox'
                                                name='queued'
                                                className='checkbox-item__field'
                                                aria-describedby='queued'
                                                onChange={handleQueued}
                                                checked={queued}
                                            />
                                            <label htmlFor='queued' className='checkbox-item__label'>
                                                Queued
                                            </label>
                                        </div>
                                    </div>
                            </div>
                            <div className='col-md-2 filterField_wrap stops-radio-wrapper mt-3'>
                                <label className="filter-title">Customs Status</label> 
                                <div class="checkbox-custom col mt-2">
                                        <div class="checkbox-item">
                                            <input
                                                id='approved'
                                                type='checkbox'
                                                name='approved'
                                                className='checkbox-item__field'
                                                aria-describedby='approved'
                                                onChange={handleApproved}
                                                checked={approved}
                                            />
                                            <label htmlFor='approved' className='checkbox-item__label'>
                                                Cleared
                                            </label>
                                        </div>
                                    </div>
                                    <div class="checkbox-custom col mt-4">
                                        <div class="checkbox-item">
                                            <input
                                                id='onHold'
                                                type='checkbox'
                                                name='onHold'
                                                className='checkbox-item__field'
                                                aria-describedby='onHold'
                                                onChange={handleOnHold}
                                                checked={onHold}
                                            />
                                            <label htmlFor='onHold' className='checkbox-item__label'>
                                                On hold
                                            </label>
                                        </div>
                                    </div>
                                    <div class="checkbox-custom col mt-4">
                                        <div class="checkbox-item">
                                            <input
                                                id='in-progress'
                                                type='checkbox'
                                                name='in-progress'
                                                className='checkbox-item__field'
                                                aria-describedby='in-progress'
                                                onChange={handleReqInfo}
                                                checked={inProgress}
                                            />
                                            <label htmlFor='in-progress' className='checkbox-item__label'>
                                                Under assessment by NAIC
                                            </label>
                                        </div>
                                    </div>
                                    <div class="checkbox-custom col mt-4">
                                        <div class="checkbox-item">
                                            <input
                                                id='no-info-mawb'
                                                type='checkbox'
                                                name='no-info-mawb'
                                                className='checkbox-item__field'
                                                aria-describedby='no-info-mawb'
                                                onChange={handleMissingMAWB}
                                                checked={missingMAWB}
                                            />
                                            <label htmlFor='no-info-mawb' className='checkbox-item__label'>
                                               Missing MAWB
                                            </label>
                                        </div>
                                    </div>
                                    <div class="checkbox-custom col mt-4">
                                        <div class="checkbox-item">
                                            <input
                                                id='no-info-hawb'
                                                type='checkbox'
                                                name='no-info-hawb'
                                                className='checkbox-item__field'
                                                aria-describedby='no-info-hawb'
                                                onChange={handleMissingHAWB}
                                                checked={missingHAWB}
                                            />
                                            <label htmlFor='no-info-hawb' className='checkbox-item__label'>
                                                Missing HAWB
                                            </label>
                                        </div>
                                    </div>
                                    <div class="checkbox-custom col mt-4">
                                        <div class="checkbox-item">
                                            <input
                                                id='mawb-mismatch'
                                                type='checkbox'
                                                name='mawb-mismatch'
                                                className='checkbox-item__field'
                                                aria-describedby='mawb-mismatch'
                                                onChange={handleMismatch}
                                                checked={mismatch}
                                            />
                                            <label htmlFor='mawb-mismatch' className='checkbox-item__label'>
                                                Mismatch(Pcs/Wgt)
                                            </label>
                                        </div>
                                    </div>
                            </div>
                            <div className='col-md-2 filterField_wrap stops-radio-wrapper mt-3 pr-2 pl-1'>
                                <label className="filter-title">FMA/FNA Status</label> 
                                    <div class="checkbox-custom col mt-2">
                                        <div class="checkbox-item">
                                            <input
                                                id='fmaReceived'
                                                type='checkbox'
                                                name='fmaReceived'
                                                className='checkbox-item__field'
                                                aria-describedby='fmaReceived'
                                                onChange={()=> {
                                                    setFmaReceived(!fmaReceived)
                                                }}
                                                checked={fmaReceived}
                                            />
                                            <label htmlFor='fmaReceived' className='checkbox-item__label'>
                                                FMA received
                                            </label>
                                        </div>
                                    </div>
                                    <div class="checkbox-custom col mt-4">
                                        <div class="checkbox-item">
                                            <input
                                                id='fnaReceived'
                                                type='checkbox'
                                                name='fnaReceived'
                                                className='checkbox-item__field'
                                                aria-describedby='fnaReceived'
                                                onChange={()=> {
                                                    setFnaReceived(!fnaReceived)
                                                }}
                                                checked={fnaReceived}
                                            />
                                            <label htmlFor='fnaReceived' className='checkbox-item__label'>
                                                FNA received
                                            </label>
                                        </div>
                                    </div>
                                    <div class="checkbox-custom col mt-4 pr-0">
                                        <div class="checkbox-item">
                                            <input
                                                id='fmaFnaNotAcknowledged'
                                                type='checkbox'
                                                name='fmaFnaNotAcknowledged'
                                                className='checkbox-item__field'
                                                aria-describedby='fmaFnaNotAcknowledged'
                                                onChange={()=> {
                                                    setFmaFnaNotAcknowledged(!fmaFnaNotacknowledged)
                                                }}
                                                checked={fmaFnaNotacknowledged}
                                            />
                                            <label htmlFor='fmaFnaNotAcknowledged' className='checkbox-item__label'>
                                                Not acknowledged
                                            </label>
                                        </div>
                                    </div>
                            </div>
                            <div className='col-md-2 filterField_wrap stops-radio-wrapper mt-3'>
                                <label className="filter-title">HS code status</label> 
                                    <div class="checkbox-custom col mt-2">
                                        <div class="checkbox-item">
                                            <input
                                                id='hsCodeValid'
                                                type='checkbox'
                                                name='hsCodeValid'
                                                className='checkbox-item__field'
                                                aria-describedby='hsCodeValid'
                                                onChange={()=> {
                                                    setHsCodeValid(!hsCodeValid)
                                                }}
                                                checked={hsCodeValid}
                                            />
                                            <label htmlFor='hsCodeValid' className='checkbox-item__label'>
                                                Valid
                                            </label>
                                        </div>
                                    </div>
                                    <div class="checkbox-custom col mt-4">
                                        <div class="checkbox-item">
                                            <input
                                                id='hsCodeInValid'
                                                type='checkbox'
                                                name='hsCodeInValid'
                                                className='checkbox-item__field'
                                                aria-describedby='hsCodeInValid'
                                                onChange={()=> {
                                                    setHsCodeInValid(!hsCodeInValid)
                                                }}
                                                checked={hsCodeInValid}
                                            />
                                            <label htmlFor='hsCodeInValid' className='checkbox-item__label'>
                                                Invalid
                                            </label>
                                        </div>
                                    </div>
                            </div>
                            <div className='col-md-2 filterField_wrap stops-radio-wrapper mt-3'>
                                <label className="filter-title">Acknowledgement Status</label> 
                                    <div class="checkbox-custom col mt-2">
                                        <div class="checkbox-item">
                                            <input
                                                id='acknowledged'
                                                type='checkbox'
                                                name='acknowledged'
                                                className='checkbox-item__field'
                                                aria-describedby='acknowledged'
                                                onChange={handleAcknowledged}
                                                checked={acknowledged}
                                            />
                                            <label htmlFor='acknowledged' className='checkbox-item__label'>
                                                Ack by GHA
                                            </label>
                                        </div>
                                    </div>
                                    <div class="checkbox-custom col mt-4">
                                        <div class="checkbox-item">
                                            <input
                                                id='acknowledgementResponse'
                                                type='checkbox'
                                                name='response'
                                                className='checkbox-item__field'
                                                aria-describedby='acknowledgementResponse'
                                                 onChange={handleAcknowledgementResponse}
                                               //onChange={setAcknowledgementResponse}
                                                checked={acknowledgementResponse}
                                            />
                                            <label htmlFor='acknowledgementResponse' className='checkbox-item__label'>
                                                Not Ack by GHA
                                            </label>
                                        </div>
                                    </div>
                                    <div class="checkbox-custom col mt-4">
                                        <div class="checkbox-item">
                                            <input
                                                id='escalationAcknowledged'
                                                type='checkbox'
                                                name='escalationAcknowledged'
                                                className='checkbox-item__field'
                                                aria-describedby='escalationAcknowledged'
                                                onChange={handleEscalationAcknowledged}
                                                checked={escalationAcknowledged}
                                            />
                                            <label htmlFor='escalationAcknowledged' className='checkbox-item__label'>
                                                Escln ack by ACI
                                            </label>
                                        </div>
                                    </div>
                            </div>
                            <div className='col-md-2 filterField_wrap stops-radio-wrapper mt-3'>
                                <label className="filter-title">Notification Status</label> 
                                <div class="checkbox-custom col mt-2">
                                        <div class="checkbox-item">
                                            <input
                                                id='notified'
                                                type='checkbox'
                                                name='notified'
                                                className='checkbox-item__field'
                                                aria-describedby='notified'
                                                onChange={handleNotified}
                                                checked={notified}
                                            />
                                            <label htmlFor='notified' className='checkbox-item__label'>
                                                Notified
                                            </label>
                                        </div>
                                    </div>
                                    <div class="checkbox-custom col mt-4">
                                        <div class="checkbox-item">
                                            <input
                                                id='notNotified'
                                                type='checkbox'
                                                name='notNotified'
                                                className='checkbox-item__field'
                                                aria-describedby='notNotified'
                                                onChange={handleNotNotified}
                                                checked={notNotified}
                                            />
                                            <label htmlFor='notNotified' className='checkbox-item__label'>
                                                Not Notified
                                            </label>
                                        </div>
                                    </div>
                                    <div class="checkbox-custom col mt-4">
                                        <div class="checkbox-item">
                                            <input
                                                id='notApplicable'
                                                type='checkbox'
                                                name='notApplicable'
                                                className='checkbox-item__field'
                                                aria-describedby='notApplicable'
                                                onChange={handleNotApplicable}
                                                checked={notApplicable}
                                            />
                                            <label htmlFor='notApplicable' className='checkbox-item__label'>
                                                Not Applicable
                                            </label>
                                        </div>
                                    </div>
                            </div>
                    </div>
                    <div>
                        <div className='col-12'>
                            <div className='popup-footer'>
                                <button
                                    type='button'
                                    className='secondary-button'
                                    onClick={(e) => {
                                        op.current.hide(e)
                                        setFilterSummary({})
                                    }
                                        
                                    }
                                >
                                    {t('COMMON.CANCEL')}
                                </button>
                                <button
                                    type='button'
                                    disabled={filterType && filterType === 'awb-filter' && hasError('awbNumber', error) ? true : false}
                                    className='ml-3 primary-button'
                                    onClick={(e) => {
                                        onFilter(e)
                                        op.current.hide(e)
                                    }
                                    }
                                >
                                    {t('COMMON.FILTER')}
                                </button>
                            </div>
                        </div>
                    </div>
                </OverlayPanel>
            </div>
        </li>
    )
}

export default FilterPopup;