import React, { useCallback, useState, useEffect, useRef } from 'react'
import { memo } from "react";
import { connect } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import moment from 'moment';
import { useTranslation } from 'react-i18next'
import './dgdoc-upload.scss';
import { v4 as uuidv4 } from 'uuid';
import XLSX from 'xlsx'
import { cells } from "./excellColumns.json"
import getCurrentUser from '../../../utils/getCurrentUser'
import {
  savespecialcommodityattachment,
} from "../../../services/dgBookingServices";
import { Toast } from 'primereact/toast';
import {
  getToastSuccess,
  getToastError
} from '../../../redux/actions/toastActions';
import { getShippingItems } from '../../../services/searchPanelServices';
import LoadingIndicator from "../../LoadingIndicator";
import { t } from 'i18next';


var arrayListAVI = []

const DgdocUpload = ({
  setDisplayUploadSection,
  toastDetails,
  getToastSuccess,
  getToastError,
  setDropDownFileList,
  setListPageOpen,
  setFileName,
  fileList,
  handleFileNameSelect,
  index,
  file,
  setFile,
  fileCount,
  dgarrayListDoc,
  // setDgArrayListDoc,
  unid,

  searchFormData,
  setIsLoading,
  onCommodityChange,
  setOnCommodityChange,

}) => {

  const { t, i18n } = useTranslation(['labels', 'errors'])

  var arr = [dgarrayListDoc]
  const [isLoading, setLoading] = useState(false);
  const myToast = useRef(null);

  const [loadingArray, setLoadingArray] = useState([]);


  const [uuid, setuuid] = useState(uuidv4());

  const [attachmentUNID, setAttachmentUNID] = useState(unid)

  // const [documentArray, setDocumentArray] = useState([dgarrayListDoc])

  const [finalarray, setFinalArray] = useState([])




  useEffect(() => {

    if (uuid !== '') {
      searchFormData && searchFormData?.dgAttachementDetails && searchFormData?.dgAttachementDetails[0]?.attachmentId && !onCommodityChange ?
        setuuid(searchFormData?.dgAttachementDetails[0]?.attachmentId) : (onCommodityChange ? setuuid(uuidv4) : setuuid(uuid))

    }
    // else {
    uuid === '' ? setuuid(uuidv4) : unid
    // }
    // setFinalArray([arrayListAVI])
    setOnCommodityChange(false)
  }, [file])





  const checkFileName = (acceptedFiles) => {

    let isValid = false
    if (dgarrayListDoc.length >= 1) {
      const checkArray = dgarrayListDoc.map((el) => { return el.fileName })
      const retunCheck = (checkArray.includes((acceptedFiles[0].name)) ? true : false)
      return isValid = retunCheck
    }
    else {

      return isValid
    }

  }

  const onDrop = (async (acceptedFiles) => {

    setDisplayUploadSection(false);
    setIsLoading(true);

    var formData = new FormData();
    const filepathArray = acceptedFiles[0]?.path?.split('.')

    if (filepathArray[filepathArray.length - 1] !== 'xlsx' &&
      filepathArray[filepathArray.length - 1] !== 'xls' &&
      filepathArray[filepathArray.length - 1] !== 'xlx' && filepathArray[filepathArray.length - 1] !== 'docx' &&
      filepathArray[filepathArray.length - 1] !== 'pdf' && filepathArray[filepathArray.length - 1] !== 'jpeg' && filepathArray[filepathArray.length - 1] !== 'png'
      && filepathArray[filepathArray.length - 1] !== 'PNG' && filepathArray[filepathArray.length - 1] !== 'JPEG' && filepathArray[filepathArray.length - 1] !== 'jpg'
    ) {
      setIsLoading(false);
      getToastError("Upload a vaild file format(pdf ,doc ,xlsx ,xls ,xlx ,jpeg ,png ,JPEG , PNG, jpg)");
      return
    }

    else {

      let fileCheck = checkFileName(acceptedFiles)



      if (!fileCheck) {

        formData.append("attachmentId", (searchFormData && searchFormData?.dgAttachementDetails && searchFormData?.dgAttachementDetails[0]?.attachmentId && !onCommodityChange ?
          searchFormData?.dgAttachementDetails[0]?.attachmentId : uuid));
        formData.append("multipartFile", acceptedFiles[0]);

        savespecialcommodityattachment(formData).then(async (res) => {
          setIsLoading(true)

          try {
            if (res.status === "SUCCESS") {
              setIsLoading(false);
              //call toaster
              await getToastSuccess("File uploaded successfully");
              myToast.current.show({ ...toastDetails, life: 100 })
              // setFile(null);
              setFile([res]);
            }

          }
          catch (error) {
            setIsLoading(false);
            setFile(null);
            getToastError("Failed to upload file");
            myToast.current.show({ ...toastDetails, life: 100 })
            return
          }
        }
        )
      }

      else {

        // formData.append("attachmentId", uuidv4());
        setIsLoading(false);
        getToastError("File already Exists")
        myToast.current.show({ ...toastDetails, life: 100 })

      }
    }

  }
  )

  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false })

  return (
    <>

      {
        isLoading ? <LoadingIndicator /> : ''
      }
      <div className='file-name'>
        <div>

          <div className="secondary-button d-flex ml-3">
            <Toast ref={myToast} />

            <div className="m-0 d-flex align-items-center justify-content-center" {...getRootProps()}>
              <input {...getInputProps()} />
              <p className="m-0 text-center">{fileCount ? t('COMMON.UPLOAD_ANOTHER_DGFILE') : t('COMMON.UPLOAD_DGFILE')}</p>
            </div>
          </div>
        </div>

        {/* {

          file ?
            (
              <div className='filename-upload uploadfile'>
                {`${file[index]?.path} - ${file[index]?.size} kb`}
              </div>
            ) : <></>


        } */}
      </div>

    </>
  )
}

const mapStateToProps = ({ loader, toast }) => ({
  //	principal: auth.principal,
  //	isLoading: loader.isLoading,
  toastDetails: toast.toastDetails,
})

const mapDispatchToProps = (dispatch) => ({
  getToastSuccess: (message) => {
    dispatch(getToastSuccess(message))
  },
  getToastError: (message) => {
    dispatch(getToastError(message))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(DgdocUpload)
