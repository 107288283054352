import React, { useEffect, useState } from 'react'
import { Route, useHistory, Switch, Redirect } from 'react-router-dom'
import './csn-dashboard.scss'
import { TabPanel, TabView } from 'primereact/tabview'
import { useTranslation } from 'react-i18next';
import AwbSummary from './AwbSummary'
import FlightSummary from './FightSummary'
import refresh_icon from '../../assets/images/refresh_icon.png'
import { getFlightDetails, getFlighFFMDetails, getAwbDetails, getAirportsMaster } from '../../../src/services/csnDashboardService'
import getCurrentUser from '../../../src/utils/getCurrentUser';
import getPrincipal from '../../utils/getPrincipal'
import getPLACIUser from '../../utils/getPLACIUser'
import FFMSummary from './FFMSummary'
import { setToLocalStorage, getFromLocalStorage, getBase64Decoded, getBase64Encoded } from '../../utils/StorageUtils'
import sessionKeys from '../../constants/sessionKeys'
import { v4 as uuidv4 } from 'uuid';

const CsnDashboard = () => {

    const { t } = useTranslation(['labels']);
    const [activeIndex, setActiveIndex] = useState(0);
    const [tabClicked, setTabClicked] = useState(null);
    const [lastUpdated, setLastUpdated] = useState('')
    const [nextUpdated, setNextUpdated] = useState('')
    const handleTabChange = (index) => {
        setActiveIndex(index)
        if (index === 0) {
            setTabClicked('FLIGHT')
        } else if (index === 1) {
            setTabClicked('FFM')
        } else if (index === 2) {
            setTabClicked('AWB')
        }
    }
    const [flightData, setSelectedFlightData] = useState({});
    const [flightFromDate, setFlightFromDate] = useState(null);
    const [flightToDate, setFlightToDate] = useState(null)
    const [maxDate, setMaxDate] = useState(null);
    const [totalRecordCount, setTotalRecordCount] = useState(0)
    const [totalRecordCountFFM, setTotalRecordCountFFM] = useState(0)
    const [showLoader, setShowLoader] = useState(true);
    const [products, setProducts] = useState([]);
    const [productsFFM, setProductsFFM] = useState([]);

    const [totalFlights, setTotalFlights] = useState(0);
    const [totalFlightsFFM, setTotalFlightsFFM] = useState(0);
    const [flights, setFlights] = useState(0);
    const [flightsFFM, setFlightsFFM] = useState(0);

    const [consolidatedFlightDetails, setConsolidatedFlightDetails] = useState({});
    const [consolidatedFlightDetailsFFM, setConsolidatedFlightDetailsFFM] = useState({});

    const [awbProducts, setAwbProducts] = useState([]);
    const [awbCount, setAwbCount] = useState(0);
    const [hasSelfFiler, setHasSelfiler] = useState(false);
    const [awbTotalRecordCount, setAwbTotalRecordCount] = useState(0)
    const [isFlightCancelled,setIsFlightCancelled] = useState(false);

    const [flightNumberSelection, setFlightNumberSelection] = useState(true);
    const [departureTimeSelection, setDepartureTimeSelection] = useState(true);
    const [arrivalTimeSelection, setArrivalTimeSelection] = useState(true);
    const [remainingTimeSelection, setRemainingTimeSelection] = useState(true);
    const [flightOriginSelection, setFlightOriginSelection] = useState(true);
    const [flightDestinationSelection, setFlightDestinationSelection] = useState(true);
    const [statusSelection, setStatusSelection] = useState(true);
    const [impactedAWBSelection, setImpactedAWBSelection] = useState(true);
    const [initialAWB, setInitialAWB] = useState(false);
    const [awbRefreshed, setAwbRefreshed] = useState(false);
    const [flightsRefreshed, setFlightsRefreshed] = useState(false);
    const [filtered, setFiltered] = useState(false)
    const [flightFilter, setFlightFilter] = useState(null)
    const [flightFilterFFM, setFlightFilterFFM] = useState(null)
    const [awbFilter, setAwbFilter] = useState(null)
    const [pageIndex, setPageIndex] = useState(1)
    const [currentPage, setCurrentPage] = useState(0)
    const [pageReset, setPageReset] = useState(true);

    const [pageIndexFFM, setPageIndexFFM] = useState(1)
    const [currentPageFFM, setCurrentPageFFM] = useState(0)
    const [pageResetFFM, setPageResetFFM] = useState(true);
    const [ffmFlightFilter, setFfmFlightFilter] = useState(false);
    const [clearFlightFilter, setClearFlightFilter] = useState(false);
    const [airportsMasterData, setAirportsMasterData] = useState(null);

    useEffect(() => {
        if (pageReset) {
            setPageIndex(1)
            setCurrentPage(0)
        }
    }, [pageReset])

    useEffect(() => {
        if (pageResetFFM) {
            setPageIndexFFM(1)
            setCurrentPageFFM(0)
        }
    }, [pageResetFFM])

    const getInitialFilter = () => {
        return {
            depDetails: {
                fromDate: '',
                toDate: '',
                utc: false,
                utcInterval: '',
                departureTimeInHours: [
                    '0:00 AM',
                    '23:59 PM',
                ],
                departureTime: [0, 1439],
            },
            arrDetails: {
                fromDate: '',
                toDate: '',
                arrivalTimeInHours: [
                    '0:00 AM',
                    '23:59 PM',
                ],
                arrivalTime: [0, 1439],
            },
            customsStatus: {
                finalized: false,
                onHold: false,
                inProgress: false,
                isFlightCancelled: false,
                isNoBookingChecked: false,
            },
            carrierCode: 'EY',
            flightNumber: '',
            airlinePrefix: '607',
            awbNumber: '',
            stations: []
        }
    }

    const getInitialFilterFFM = () => {
        return {
            depDetails: {
                fromDate: '',
                toDate: '',
                utc: false,
                utcInterval: '',
                departureTimeInHours: [
                    '0:00 AM',
                    '23:59 PM',
                ],
                departureTime: [0, 1439],
            },
            arrDetails: {
                fromDate: '',
                toDate: '',
                arrivalTimeInHours: [
                    '0:00 AM',
                    '23:59 PM',
                ],
                arrivalTime: [0, 1439],
            },
            customsStatus: {
                received: false,
                notReceived: false,
            },
            carrierCode: 'EY',
            flightNumber: '',
        }
    }
    const [filterValues, setFilterValues] = useState(getInitialFilter())

    const [filterValuesFFM, setFilterValuesFFM] = useState(getInitialFilterFFM())

    let userDetails = getCurrentUser();

    let principal = getPrincipal()

    let placiUser = getPLACIUser()

    const refresh = async () => {

        // setShowLoader(true); 

        if (activeIndex === 0) {
            getFlightSummarydata()

        } else if (activeIndex === 1) {
            getFlightFFMdata()

        } else if (activeIndex === 2) {
            getAwbDetailsdata()

        }
    }

    const setRequestObj = () => {
        let reqObj = {
            "requestId": uuidv4(),
            "agentCode": userDetails ? userDetails.agentCode : principal ? principal.sub : null,
            "userId": userDetails ? userDetails.userId : principal ? principal.sub : null,
            userType: principal ? principal.type : null,
            stationCode: placiUser ? placiUser.stationCode : '',
            "pagination": {
                "pageNumber": pageIndex && pageIndex > 0 ? pageIndex : 1,
                "pageSize": 10
            },
        }

        if (flightFilter) {
            let reqData = {
                ...reqObj,
                ...flightFilter,
            }
            if (flightFilter.airlinePrefix && flightFilter.airlinePrefix !== '' && flightFilter.awbNumber && flightFilter.awbNumber !== '') {
                let awbFilterData = {
                    airlinePrefix: flightFilter.airlinePrefix,
                    awbNumber: flightFilter.awbNumber,
                }
                setAwbFilter(awbFilterData)
            }
            return reqData
        } else {

            return reqObj
        }

    }

    const setFFMRequestObj = () => {
        let reqObj = {
            "requestId": uuidv4(),
            "agentCode": userDetails ? userDetails.agentCode : principal ? principal.sub : null,
            "userId": userDetails ? userDetails.userId : principal ? principal.sub : null,
            userType: principal ? principal.type : null,
            stationCode: placiUser ? placiUser.stationCode : '',
            "pagination": {
                "pageNumber": pageIndexFFM && pageIndexFFM > 0 ? pageIndexFFM : 1,
                "pageSize": 10
            },
        }

        if (flightFilterFFM) {
            let reqData = {
                ...reqObj,
                ...flightFilterFFM,
            }
            return reqData
        } else {

            return reqObj
        }

    }

    const setAwbRequestObj = () => {
        let reqObj = {
            "requestId": uuidv4(),
            "agentCode": userDetails ? userDetails.agentCode : principal ? principal.sub : null,
            "flightNo": flightData?.flightNo,
            "carrierCode": flightData?.carrierCode,
            "userId": userDetails ? userDetails.userId : principal ? principal.sub : null,
            userType: principal ? principal.type : null,
            stationCode: placiUser ? placiUser.stationCode : '',
            "departureDate": flightData?.departureDate,
            "arrivalDate": flightData?.arrivalDate,
             "origin": flightData?.origin,
             "destination":flightData?.destination,
             "flightDate":flightData?.flightDate,
            departureDateTimeUTC: flightData?.departureDateTimeUTC,
            arrivalDateTimeUTC: flightData?.arrivalDateTimeUTC,
            fwbSearch: flightData?.fwbPresent
            // "pagination": {
            //     "pageNumber": 1,
            //     "pageSize": 10
            // }
        }
        if (awbFilter) {
            let reqData = {
                ...reqObj,
                ...awbFilter,
            }
            return reqData
        } else {

            return reqObj
        }

    }

    useEffect(() => {
        ; (async () => {
            let airportsMaster = getFromLocalStorage(sessionKeys.AIRPORTS_MASTER)
            if (airportsMaster) {
                if (!airportsMasterData) {
                    airportsMaster = getBase64Decoded(airportsMaster)

                    setAirportsMasterData(JSON.parse(airportsMaster))
                }
            } else {
                const response = await getAirportsMaster().catch((error) => {
                    console.log(error)
                })
                if (response && response.data && response.data.airports) {
                    airportsMaster = response.data
                    setAirportsMasterData(airportsMaster)
                    setToLocalStorage(sessionKeys.AIRPORTS_MASTER, getBase64Encoded(JSON.stringify(airportsMaster)))
                }
            }
        })()
    }, [])

    const getFlightSummarydata = async () => {
        setShowLoader(true);
        let reqObj = setRequestObj()

        try {
            setProducts([])
            const res = await getFlightDetails(reqObj);
            if (res && res.data) {
                setFlights(res.data?.consolidatedFlightDetails?.flightsCleared);
                setConsolidatedFlightDetails(res.data?.consolidatedFlightDetails)
                setTotalFlights(res.data.consolidatedFlightDetails.totalFlights);
                setProducts(res.data.flightDetails);
                setTotalRecordCount(res.data.pagination.totalRecordCount)
                setMaxDate(res.data.maxDate);
                setFlightFromDate(res.data.flightFromDate);
                setFlightToDate(res.data.flightToDate);
                setLastUpdated(res.data.lastRefreshedTime)
                setNextUpdated(res.data.nextRefreshTime)
                setAwbProducts([])
                setAwbCount(0)
                setFlightsRefreshed(true)
                setAwbFilter(null)
                setShowLoader(false);
            } else {
                setShowLoader(false);
            }
        }
        catch (err) {
            console.log(err)
            setShowLoader(false);
        }


    }

    const getFlightFFMdata = async () => {
        setShowLoader(true);
        let reqObj = setFFMRequestObj()

        try {
            setProductsFFM([])
            const res = await getFlighFFMDetails(reqObj);
            if (res && res.data) {
                setFlightsFFM(res.data?.consolidatedFlightDetails?.flightsCleared);
                setConsolidatedFlightDetailsFFM(res.data?.consolidatedFlightDetails)
                setTotalFlightsFFM(res.data.consolidatedFlightDetails.totalFlights);
                setProductsFFM(res.data.flightFFMDetails);
                setTotalRecordCountFFM(res.data.pagination.totalRecordCount)
                setFlightFromDate(res.data.flightFromDate);
                setFlightToDate(res.data.flightToDate);
                setLastUpdated(res.data.lastRefreshedTime)
                setNextUpdated(res.data.nextRefreshTime)
                setAwbProducts([])
                setFlightsRefreshed(true)
                setAwbFilter(null)
                setShowLoader(false);
            } else {
                setShowLoader(false);
            }
        }
        catch (err) {
            console.log(err)
            setShowLoader(false);
        }


    }

    const getAwbDetailsdata = async () => {
        setShowLoader(true);
        let AwbReqObj = setAwbRequestObj()

        try {
            setAwbProducts([])
            setAwbCount(0)
            const res = await getAwbDetails(AwbReqObj);
            setAwbProducts(res.data.awbDetails);
            setAwbCount(res?.data?.bookingCount)
            setLastUpdated(res.data.lastRefreshedTime);
            setNextUpdated(res.data.nextRefreshTime);
            // setAwbTotalRecordCount(res.data.pagination.totalRecordCount)
            setAwbRefreshed(true)
            setHasSelfiler(res?.data?.hasSelfFiler)
            setIsFlightCancelled(res?.data?.isFlightCancelled);
            setShowLoader(false);
        }
        catch (err) {
            console.log(err)
            setShowLoader(false);
        }
    }


    return (
        <>
            <div className='container-lg'>
                <div className='get-quote'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='cargo-portal-container'>
                                <div className='booking-heading'>
                                    <div className="page-heading-div">
                                        <h1>UAE PLACI Dashboard</h1>
                                    </div>
                                </div>
                                <div className="last_updated_span">
                                    <div>
                                        <span>{`Last booking data refresh : ${lastUpdated}`}</span>
                                        <img className='refresh-icon'
                                            onClick={() => refresh()} src={refresh_icon}
                                        >
                                        </img>
                                    </div>
                                    <div>
                                        <span >{`Next booking data refresh   :   ${nextUpdated}`}</span>
                                    </div>

                                </div>


                                <div className='portal-tab-menu'>
                                    <TabView
                                        activeIndex={activeIndex}
                                        onTabChange={(e) => handleTabChange(e.index)}
                                    >
                                        <TabPanel
                                            header='Flight summary'
                                            headerClassName='csn-flight'>
                                            <FlightSummary
                                                handleTabChange={handleTabChange}
                                                setSelectedFlightData={setSelectedFlightData}
                                                setLastUpdated={setLastUpdated}
                                                setNextUpdated={setNextUpdated}
                                                setFlights={setFlights}
                                                flights={flights}
                                                setTotalFlights={setTotalFlights}
                                                totalFlights={totalFlights}
                                                setProducts={setProducts}
                                                products={products}
                                                setTotalRecordCount={setTotalRecordCount}
                                                totalRecordCount={totalRecordCount}
                                                setMaxDate={setMaxDate}
                                                maxDate={maxDate}
                                                setFlightFromDate={setFlightFromDate}
                                                flightFromDate={flightFromDate}
                                                flightToDate={flightToDate}
                                                setFlightToDate={setFlightToDate}
                                                showLoader={showLoader}
                                                setShowLoader={setShowLoader}
                                                flightNumberSelection={flightNumberSelection}
                                                departureTimeSelection={departureTimeSelection}
                                                arrivalTimeSelection={arrivalTimeSelection}
                                                remainingTimeSelection={remainingTimeSelection}
                                                flightOriginSelection={flightOriginSelection}
                                                flightDestinationSelection={flightDestinationSelection}
                                                statusSelection={statusSelection}
                                                impactedAWBSelection={impactedAWBSelection}
                                                setFlightNumberSelection={setFlightNumberSelection}
                                                setDepartureTimeSelection={setDepartureTimeSelection}
                                                setArrivalTimeSelection={setArrivalTimeSelection}
                                                setRemainingTimeSelection={setRemainingTimeSelection}
                                                setFlightOriginSelection={setFlightOriginSelection}
                                                setFlightDestinationSelection={setFlightDestinationSelection}
                                                setStatusSelection={setStatusSelection}
                                                setImpactedAWBSelection={setImpactedAWBSelection}
                                                setInitialAWB={setInitialAWB}
                                                consolidatedFlightDetails={consolidatedFlightDetails}
                                                setConsolidatedFlightDetails={setConsolidatedFlightDetails}
                                                setAwbProducts={setAwbProducts}
                                                setFlightsRefreshed={setFlightsRefreshed}
                                                flightsRefreshed={flightsRefreshed}
                                                setFiltered={setFiltered}
                                                setFlightFilter={setFlightFilter}
                                                flightFilter={flightFilter}
                                                setAwbFilter={setAwbFilter}
                                                setFilterValues={setFilterValues}
                                                filterValues={filterValues}
                                                tabClicked={tabClicked}
                                                setTabClicked={setTabClicked}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                pageIndex={pageIndex}
                                                setPageIndex={setPageIndex}
                                                pageReset={pageReset}
                                                setPageReset={setPageReset}
                                                setFfmFlightFilter={setFfmFlightFilter}
                                                ffmFlightFilter={ffmFlightFilter}
                                                setClearFlightFilter={setClearFlightFilter}
                                                airportsMasterData={airportsMasterData}
                                            />
                                        </TabPanel>
                                        <TabPanel
                                            header='Flight FFM status details'
                                            headerClassName='csn-ffm'>
                                            <FFMSummary
                                                handleTabChange={handleTabChange}
                                                setSelectedFlightData={setSelectedFlightData}
                                                setLastUpdated={setLastUpdated}
                                                setNextUpdated={setNextUpdated}
                                                setFlights={setFlightsFFM}
                                                flights={flightsFFM}
                                                setTotalFlights={setTotalFlightsFFM}
                                                totalFlights={totalFlightsFFM}
                                                setProducts={setProductsFFM}
                                                products={productsFFM}
                                                setTotalRecordCount={setTotalRecordCountFFM}
                                                totalRecordCount={totalRecordCountFFM}
                                                setFlightFromDate={setFlightFromDate}
                                                flightFromDate={flightFromDate}
                                                flightToDate={flightToDate}
                                                setFlightToDate={setFlightToDate}
                                                showLoader={showLoader}
                                                setShowLoader={setShowLoader}
                                                setInitialAWB={setInitialAWB}
                                                consolidatedFlightDetails={consolidatedFlightDetailsFFM}
                                                setConsolidatedFlightDetails={setConsolidatedFlightDetailsFFM}
                                                setAwbProducts={setAwbProducts}
                                                setFlightsRefreshed={setFlightsRefreshed}
                                                flightsRefreshed={flightsRefreshed}
                                                setFiltered={setFiltered}
                                                setFlightFilter={setFlightFilterFFM}
                                                flightFilter={flightFilterFFM}
                                                setAwbFilter={setAwbFilter}
                                                setFilterValues={setFilterValuesFFM}
                                                filterValues={filterValuesFFM}
                                                tabClicked={tabClicked}
                                                setTabClicked={setTabClicked}
                                                currentPage={currentPageFFM}
                                                setCurrentPage={setCurrentPageFFM}
                                                pageIndex={pageIndexFFM}
                                                setPageIndex={setPageIndexFFM}
                                                pageReset={pageResetFFM}
                                                setPageReset={setPageResetFFM}
                                                setFlightFilterSummary={setFlightFilter}
                                                setFilterValuesSummary={setFilterValues}
                                                getInitialFilter={getInitialFilter}
                                                setFfmFlightFilter={setFfmFlightFilter}
                                                ffmFlightFilter={ffmFlightFilter}
                                            />
                                        </TabPanel>
                                        {initialAWB && activeIndex != 0 && activeIndex != 1 ? (
                                            <TabPanel
                                                header='AWB details'
                                                headerClassName='csn-awb'
                                            >
                                                <AwbSummary
                                                    flightData={flightData}
                                                    setProducts={setAwbProducts}
                                                    products={awbProducts}
                                                    awbCount={awbCount}
                                                    setAwbCount={setAwbCount}
                                                    setTotalRecordCount={setAwbTotalRecordCount}
                                                    totalRecordCount={awbTotalRecordCount}
                                                    showLoader={showLoader}
                                                    setShowLoader={setShowLoader}
                                                    setLastUpdated={setLastUpdated}
                                                    setNextUpdated={setNextUpdated}
                                                    setAwbRefreshed={setAwbRefreshed}
                                                    awbRefreshed={awbRefreshed}
                                                    setAwbFilter={setAwbFilter}
                                                    awbFilter={awbFilter}
                                                    hasSelfFiler={hasSelfFiler}
                                                    setHasSelfiler={setHasSelfiler}
                                                    setFilterValues={setFilterValues}
                                                    setFlightFilter={setFlightFilter}
                                                    clearFlightFilter={clearFlightFilter}
                                                    getInitialFilter={getInitialFilter}
                                                    isFlightCancelled={isFlightCancelled}
                                                    setIsFlightCancelled={setIsFlightCancelled}
                                                />
                                            </TabPanel>
                                        ) : (<TabPanel headerStyle={{ display: "none" }}></TabPanel>)
                                        }
                                    </TabView>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CsnDashboard
