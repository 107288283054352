import React, { useState, useEffect } from 'react'
import './item-form.scss'
import {
	FORM_FIELDS,
	WEIGHT_UNIT,
	DIMENSION_UNIT,
	getInitLooseItemDetail,
} from '../../constants'
import { Button } from 'primereact/button'
import ULDOverflow from '../ULDOverflow'
import ULDLineItem from './ULDLineItem'
import { calculateWeightFromKg, calculateWeightToKg } from '../../utils/common'
import { useTranslation } from 'react-i18next'
import ShippingItemValidationPopUp from '../../components/SearchPanel/ShippingItemValidationPopUp'
const ULDItemForm = ({
	ULDDetails,
	setULDDetails,
	selectedOrigin,
	weightUnit,
	dimensionUnit,
	setDimensionUnit,
	setWeightUnit,
	handleAddLineItem,
	setTotalVolumeInMeters,
	setTotalPieces,
	error,
	setError,
	setTotalWeightInKg,
	totalWeightInKilo,
	setChargeableWeight,
	setChargeableWeightInSelectedUnit,
	searchFormData,
	mixLooseDetails,
	setMixLooseDetails,
	envConfig,
	expandAccordian,
	setExpandAccordian,
	setActualVolume,
	setActualGrossWeight,
	draftCallback,
	setNotificationMessage,
	setNotificationType,
	DgEnabled,
	setdisplayDgULDItemPopup
}) => {
	//for Mutilingual
	const { t, i18n } = useTranslation(['labels'])

	const [totalWeight, setTotalWeight] = useState(null)

	//Mix Booking
	const [looseItemsTotalWeight, setLooseItemsTotalWeight] = useState(
		searchFormData ? searchFormData.totalWeight : null
	)
	const [looseItemsGrossWeight, setLooseItemsGrossWeight] = useState(0)
	// const [dimensionUnit, setDimensionUnit] = useState(
	// 	searchFormData ? searchFormData.dimensionUnit : DIMENSION_UNIT.CENTIMETERS
	// )
	const [isAllStackable, setAllStackable] = useState(
		searchFormData ? searchFormData.isAllStackable : false
	)
	const [isAllTiltable, setAllTiltable] = useState(
		searchFormData ? searchFormData.isAllTiltable : false
	)
	const [totalLooseWeightInKg, setTotalLooseWeightInKg] = useState(
		searchFormData ? searchFormData.totalLooseWeightInKg : 0
	)
	const [totalLooseVolumeInMtrs, setTotalLooseVolumeInMeters] = useState(
		searchFormData ? searchFormData.totalLooseVolumeInMtrs : 0
	)
	const [actualLooseTotalVolume, setActualLooseTotalVolume] = useState(
		searchFormData ? searchFormData.actualLooseTotalVolume : 0
	)
	const [calculatedTotalWeight, setCalculatedTotalWeight] = useState(0)
	const [showValidation, setshowValidation] = useState(false)
	const [totalLoosePieces, setTotalLoosePieces] = useState(
		searchFormData ? searchFormData.totalLoosePieces : 0
	)
	const [looseChargeableWeight, setLooseChargeableWeight] = useState(
		searchFormData ? searchFormData.looseChargeableWeight : 0
	)
	const [removedRowCount, setRemovedRowCount] = useState(0)

	useEffect(() => {
		handleSummaryChange()
		calculateTotalWeight()
		calculateChargableWeight()
	}, [ULDDetails, weightUnit, looseItemsGrossWeight])

	// useEffect(() => {
	// 	calculateTotalWeight()
	// 	calculateTotalPivotWeight()
	// }, [ULDDetails])

	const calculateChargableWeight = () => {
		let chargeableWeight = Number(calculateWeightToKg(Number(looseItemsGrossWeight), weightUnit))
		let chargeableWeightInSelectedUnit = Number(calculatedTotalWeight)
		if (ULDDetails && ULDDetails.length) {
			ULDDetails.forEach(item => {
				const quantity = Number(item.pieces)
				const itemChargableWeight = Math.max(calculateWeightToKg(Number(item.weight),
					weightUnit), Number(item.pivotWeight)) * quantity
				const itemChargableWeightInSelectedUnit = Math.max(Number(item.weight),
					calculateWeightFromKg(Number(item.pivotWeight), weightUnit)) * quantity
				chargeableWeight += itemChargableWeight
				chargeableWeightInSelectedUnit += itemChargableWeightInSelectedUnit
			})
			if (chargeableWeight) {
				setChargeableWeight(chargeableWeight)
				setChargeableWeightInSelectedUnit(chargeableWeightInSelectedUnit)
			}
		}
	}

	useEffect(() => {
		//setTotalUldWeightInKg(calculateWeightToKg(totalWeight, weightUnit))
		let totalWeightInKg = calculateWeightToKg(totalWeight, weightUnit)
		if (looseItemsGrossWeight) {
			totalWeightInKg =
				totalWeightInKg * 1 +
				calculateWeightToKg(looseItemsGrossWeight, weightUnit) * 1
		}
		let actualWeight = totalWeight * 1 + looseItemsGrossWeight * 1
		setActualGrossWeight(actualWeight)
		totalWeightInKg = (totalWeightInKg * 1).toFixed(2)
		setTotalWeightInKg(totalWeightInKg)
	}, [totalWeight, weightUnit, looseItemsGrossWeight])

	useEffect(() => {
		if (totalLoosePieces > 0) {
			handleSummaryChange()
			if (totalLoosePieces && totalLoosePieces > 0) {
				setExpandAccordian(true)
			} else {
				setExpandAccordian(false)
			}
		}
	}, [totalLoosePieces, totalLooseVolumeInMtrs, actualLooseTotalVolume])

	// useEffect(() => {
	// 	let chargeableWeight = 0
	// 	if (totalPivoteWeightInKg) {
	// 		chargeableWeight = Math.max(
	// 			totalWeightInKilo * 1,
	// 			totalPivoteWeightInKg * 1
	// 		)
	// 	}
	// 	setChargeableWeight(chargeableWeight)
	// }, [totalWeightInKilo, totalPivoteWeightInKg])

	const calculateKgWeight = (weight, weightUnit) => {
		if (!weight) {
			return 0
		}
		if (weightUnit == WEIGHT_UNIT.TON) {
			return (weight * 907.185).toFixed(2)
		} else if (weightUnit == WEIGHT_UNIT.POUND) {
			return (weight * 0.453592).toFixed(2)
		} else {
			return weight.toString()
		}
	}

	const handleSummaryChange = () => {
		let quantity =
			ULDDetails.reduce((curr, next) => ({
				pieces:
					(curr && curr.pieces ? curr.pieces * 1 : 0) +
					(next && next.pieces ? next.pieces * 1 : 0),
			})).pieces || 0
		let totalVolumeinMeters = 0
		let actualVolumeInSelectedUnit = 0
		let totalVolumeinSelectedUnit = 0
		ULDDetails.forEach((item) => {
			totalVolumeinMeters +=
				(item && item.uldVolume ? item.uldVolume * 1 : 0) *
				(item && item.pieces ? item.pieces * 1 : 0)
		})
		ULDDetails.forEach((item) => {
			if (dimensionUnit == DIMENSION_UNIT.CENTIMETERS) {
				actualVolumeInSelectedUnit +=
					(item && item.uldVolume ? item.uldVolume * 1000000 : 0) *
					(item && item.pieces ? item.pieces * 1 : 0)
			}
			if (dimensionUnit == DIMENSION_UNIT.INCHES) {
				actualVolumeInSelectedUnit +=
					(item && item.uldVolume ? item.uldVolume * 61023.7 : 0) *
					(item && item.pieces ? item.pieces * 1 : 0)
			}
		})
		if (actualVolumeInSelectedUnit) {
			totalVolumeinSelectedUnit =
				actualVolumeInSelectedUnit * 1 + actualLooseTotalVolume * 1
		}
		if (totalLooseVolumeInMtrs) {
			totalVolumeinMeters = totalVolumeinMeters * 1 + totalLooseVolumeInMtrs * 1
		}
		setTotalVolumeInMeters(totalVolumeinMeters * 1)
		setActualVolume(totalVolumeinSelectedUnit)
		if (totalLoosePieces) {
			quantity = quantity * 1 + totalLoosePieces * 1
		}
		setTotalPieces(quantity)
	}

	const handleItemChange = (index, item) => {
		const tempULDDetails = JSON.parse(JSON.stringify(ULDDetails))
		tempULDDetails[index] = item
		setULDDetails([...tempULDDetails])
	}

	const handleRemoveItem = (index) => {
		const itemsTemp = ULDDetails.filter((itemDetail, curr) => {
			return index !== curr
		})
		setULDDetails(itemsTemp)
		handleSummaryChange()
		setRemovedRowCount(removedRowCount * 1 + 1)
	}

	const calculateTotalWeight = () => {
		const calculatedTotalWeight = [...ULDDetails, {}].reduce((curr, next) => {
			// return {
			// 	weight:
			// 		(curr.weight ? curr.weight * 1 : 0) +
			// 		(next.weight ? next.weight * 1 : 0),
			// }
			return {
				weight:
					(curr.weight ? curr.weight * 1 : 0) *
					(curr.pieces ? curr.pieces * 1 : 0) +
					(next.weight ? next.weight * 1 : 0) *
					(next.pieces ? next.pieces * 1 : 0),
				pieces: 1,
			}
		}).weight
		setTotalWeight(calculatedTotalWeight)
	}
	// const calculateTotalPivotWeight = () => {
	// 	const calculatedTotalPivotWeight = [...ULDDetails, {}].reduce(
	// 		(prev, current) => {
	// 			return {
	// 				pivotWeight:
	// 					(prev.pivotWeight ? prev.pivotWeight * 1 : 0) *
	// 					(prev.pieces ? prev.pieces * 1 : 0) +
	// 					(current.pivotWeight ? current.pivotWeight * 1 : 0) *
	// 					(current.pieces ? current.pieces * 1 : 0),
	// 				pieces: 1,
	// 				weight: 0,
	// 			}
	// 		}
	// 	).pivotWeight
	// 	setTotalPivoteWeight(calculatedTotalPivotWeight)
	// }

	const clearItemError = (key, index) => {
		const tempError = error ? { ...error } : null
		if (
			tempError &&
			tempError[FORM_FIELDS.ULD_ITEMS] &&
			tempError[FORM_FIELDS.ULD_ITEMS][index]
		) {
			tempError[FORM_FIELDS.ULD_ITEMS][index][key] = null
			setError(tempError)
		}
	}
	const handleAddLooseLineItem = (e) => {
		e.preventDefault()
		setMixLooseDetails([...mixLooseDetails, getInitLooseItemDetail()])
	}

	const handleAddOverflow = (e) => {
		e.preventDefault()
		setExpandAccordian(true)
	}
	return (
		<>

			{
				!DgEnabled ?

					(
						<>
							{ULDDetails &&
								ULDDetails.map((item, index) => (
									<ULDLineItem
										item={item}
										index={index}
										selectedOrigin={selectedOrigin}
										handleItemChange={handleItemChange}
										envConfig={envConfig}
										error={error}
										clearItemError={clearItemError}
										ULDDetails={ULDDetails}
										handleSummaryChange={handleSummaryChange}
										calculateTotalWeight={calculateTotalWeight}
										weightUnit={weightUnit}
										handleRemoveItem={handleRemoveItem}
										setWeightUnit={setWeightUnit}
										removedRowCount={removedRowCount}
										draftCallback={draftCallback}
										setNotificationMessage={setNotificationMessage}
										setNotificationType={setNotificationType}
									/>
								))}
							<div className='form-row'>
								<div className='form-group col-12 col-lg-10 m-b-0'>
									<Button
										label={t('COMMON.ADD_ANOTHER')}
										icon='pi pi-plus'
										className='d-block custom-xs primary-button'
										type='button'
										onClick={(e) => handleAddLineItem(e)}
									/>
								</div>
							</div>
							<div className='form-row' hidden={expandAccordian}>
								<div className='form-group col-12 col-lg-10 m-b-0'>
									<Button
										label={t('COMMON.ADD_OVERFLOW')}
										className='p-button-link add-overflow-link p-1'
										type='button'
										onClick={(e) => handleAddOverflow(e)}
									/>
								</div>
							</div>
							<div className='form-row overflow-accordian' hidden={!expandAccordian}>
								<div className='form-group col-12'>
									<ULDOverflow
										looseDetails={mixLooseDetails}
										setLooseDetails={setMixLooseDetails}
										totalWeight={looseItemsTotalWeight}
										setTotalWeight={setLooseItemsTotalWeight}
										setLooseItemsGrossWeight={setLooseItemsGrossWeight}
										weightUnit={weightUnit}
										setWeightUnit={setWeightUnit}
										dimensionUnit={dimensionUnit}
										setDimensionUnit={setDimensionUnit}
										isAllStackable={isAllStackable}
										setAllStackable={setAllStackable}
										isAllTiltable={isAllTiltable}
										setAllTiltable={setAllTiltable}
										handleAddLineItem={handleAddLooseLineItem}
										setTotalPieces={setTotalLoosePieces}
										setTotalVolumeInMeters={setTotalLooseVolumeInMeters}
										setActualLooseTotalVolume={setActualLooseTotalVolume}
										setChargeableWeight={setLooseChargeableWeight}
										setTotalWeightInKg={setTotalLooseWeightInKg}
										totalWeightInKilo={totalLooseWeightInKg}
										totalVolumeinMtrs={totalLooseVolumeInMtrs}
										error={error}
										setError={setError}
										calculatedTotalWeight={calculatedTotalWeight}
										setCalculatedTotalWeight={setCalculatedTotalWeight}
										showValidation={showValidation}
										expandAccordian={expandAccordian}
										setExpandAccordian={setExpandAccordian}
										ULDLineItem={ULDLineItem}
										draftCallback={draftCallback}
									/>
								</div>
							</div>
						</>
					)
					: <></>
			}
		</>

	)
}

export default ULDItemForm
