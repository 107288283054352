import moment from 'moment'
import { Message } from 'primereact/message'
import { SelectButton } from 'primereact/selectbutton'
import { Dropdown } from 'primereact/dropdown'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { Alert } from '../../common/Alert'
import BeforeClosePrompt from '../../common/BeforeClosePrompt/BeforeClosePrompt'
import Calendar from '../../common/Calendar'
import { ALLOWABLE_BOOKING_DATE } from '../../configs/appConfigs'
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import infoicon from '../../../src/assets/images/infoicon.png';
import { getUnidInfo } from '../../services/dgBookingServices'
import trashIcon from '../../../src/assets/images/trash.svg';
import { deletespecialcommodityattachment } from "../../services/dgBookingServices";

import {
	getToastSuccess,
	getToastError
} from '../../redux/actions/toastActions';

import { validateSeasonalBooking } from './formUtils'

import {
	AUTOCOMPLETE_TYPE,
	AUTOCOMPLETE_UNID,
	DIMENSION_UNIT,
	FORM_FIELDS,
	FRESH_FORWARD_PRODUCT,
	getInitLooseItemDetail,
	getInitPharmaObject,
	getInitULDItemDetail,
	PHARMA_PRODUCT,
	PHARMA_SHIPPING_TYPE,
	IS_KNOWN_UNKNOWN,
	PRICE_CODE_TYPE,
	SCC_CODES,
	SHIPPING_TYPE,
	VALIDATE_BOOKINGS_ERROR_CODES,
	WEIGHT_UNIT,
	NOTIFICATION_MESSAGE_TYPE,
	SYSTEM_ADDED_SCCS,
	PAGE_PRIMARY_CATEGORY,
	PAGE_SUB_CATEGORY1,
	getInitPetDetail,
	getInitUnidDetail,
	getRoundedValue,
	getRoundedValueDecimal,
	LOOSE_ITEM_KEYS,
	getInitCageDetails,
	DETAILS_APPLY_FOR,
	BOOKING_PAGE_SUB_CATEGORY2,
} from '../../constants'
import {
	calculateWeightToKg,
	round,
} from '../../utils/common'
import { SHIPPING_ITEM_PLACEHOLDER } from '../../constants/Placeholders'
import {
	getPromoCodeResponse,
	getValidateSpotRateResponse,
} from '../../services/quoteServices'
import {
	checkSccCompatibility,
	getAirportsByCode,
	getServices,
	getShippingItems,
} from '../../services/searchPanelServices'
import {
	saveSeasonalBooking
} from '../../services/permanentService'
import { getAviBookingBreeds } from '../../services/aviBookingService'
import {
	clearItemFromSessionStorage,
	getBase64Decoded,
	getBase64Encoded,
	getFromSessionStorage,
	setToSessionStorage,
	setToLocalStorage,
	getFromLocalStorage,
} from '../../utils/StorageUtils'
import AdditionalInfoForm from '../AdditionalInfoForm'
import AirportAutocomplete from '../AirportAutocomplete'
import UnidAutocomplete from '../UnidAutocomplete'
import LoadingIndicator from '../LoadingIndicator'
import LooseItemForm from '../LooseItemForm'
import RecommendedSccList from '../RecommendedSccList/RecommendedSccList'
import SccAutoComplete from '../SccAutoComplete'
import SccListDisplay from '../SccListDisplay'
import SearchSummary from '../SearchSummary/SearchSummary'
import ShippingItemAutocomplete from '../ShippingItemAutocomplete'
import ULDItemForm from '../ULDItemForm'
import ContainerValidationPopUp from './ContainerValidationPopUp'
import DateToggleButton from './DateToggleButton'
import {
	checkIsBackButtonAction,
	handleTilteableAndStackable,
	validateFormData,
	checkIsBigOrOHG,
} from './formUtils'
import './search-panel.scss'
import SearchButtonGroup from './SearchButtonGroup'
import SeasonalButtonGroup from '../SeasonalBooking/SeasonalButtonGroup'
import SchedulerMultiFlightsContainer from '../SeasonalBooking/schedulerMultiFlightsContainer'
import SearchHeader from './SearchHeader'
import ShippingItemValidationPopUp from './ShippingItemValidationPopUp'
import AviShippingItemValidationPopUp from './AviShippingItemValidationPopUp'
import {
	getBookingFlightDetails,
	removeDuplicates,
	updateTemplateDetails,
} from './templateUtils'
import MESSAGES from '../../constants/Messages'
import { convertToLowerCase, removeCommaFromNumber } from '../../utils/common'
import getCurrentUser from '../../utils/getCurrentUser'
import {
	EYP_SCC,
	EXCEPTION_SCCS,
	OHG_SCC,
	BIG_SCC,
	FORCE_QUEUE_SCC,
} from '../../constants/sccTypes'
import { useTranslation } from 'react-i18next'
import { UNKNOWN_SHIPPER_ENABLED } from '../../configs/featureControlConfig'

import { addError, pushPageData, updatePromoCodeError } from '../../utils/analytics'
import pageNames from '../../constants/pageNames'
import MultiplePetsContainer from './multiplePets/multiplePetsContainer'

import { Dialog } from 'primereact/dialog'
import closeIcon from '../../assets/images/icon-close-alert.png'


import ConfirmationPopUp from '../../common/ConfirmationPopUp'
import DgdocUpload from './DgBooking/dgdoc-upload'

import pdf from '../../assets/images/pdf.png';
import excel from '../../assets/images/excel.png';
import word from '../../assets/images/word.png';
import walpaper from '../../assets/images/walpaper.png';
import document from '../../assets/images/document.png';

import DgValidationPopup from './DgBooking/dgValidationPopup';
import DgMultiUnidContainer from './DgBooking/dgMultiUnidContainer';
import UldValidationPopupdg from './DgBooking/UldValidationPopupdg';
import dgRestrictedSccs from '../../configs/dgRestrictedSccs';
import SearchPanelMessage from './SearchPanelMessage';
import restrictedSeasonalSccs from '../../configs/restrictedSeasonalSccs'
import { getInitSeasonalFlightDetails } from '../../constants'
import { getRestrictedList } from '../../services/quoteServices'
import sessionKeys from '../../constants/sessionKeys'


const priceCodeTypesSelectItems = [
	{ label: 'Promo code', value: PRICE_CODE_TYPE.PROMO_RATE },
	{ label: 'Spot id', value: PRICE_CODE_TYPE.SPOT_RATE },
]

const SearchPanel = ({
	searchFormData,
	setSearchFormDataToState,
	showErrorPopup,
	closeErrorPopup,
	getlistTemplateNamesResponse,
	listTemplateNamesResponse,
	getSaveTemplateResponse,
	saveTemplateResponse,
	awbNo,
	serialNo,
	templateParam,
	isModifyBooking,
	disableBeforeClosePrompt,
	validateBookingResponse,
	validateBookingError,
	validateBookingRequest,
	clearValidateBookingResponse,
	getSaveDraftResponse,
	saveDraftResponse,
	saveDraftError,
	getlistDraftsResponse,
	listDraftsResponse,
	clearSaveTemplateResponse,
	getlistQuoteResponse,
	listQuotesResponse,
	listQuoteError,
	clearSaveDraftResponse,
	setNotificationMessage,
	setNotificationType,
	listTemplateNamesError,
	listDraftsError,
	aviBookingDetaills,


	//seasonal booking props
	isSeasonalBooking,
	seasonalError,
	setSeasonalError,
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	flightList,
	setFlightList,
	flightSelectedOrigin,
	setFlightSelectedOrigin,
	flightsMaxShippingDate,
	setFlightsMaxShippingDate,
	flightsMinDate,
	setFlightsMinDate,

	schedulerMaxShippingDate,
	setSchedulerMaxShippingDate,
	schedulerMinDate,
	setSchedulerMinDate,
	weekdays,
	setWeekdays,
	dateScheduler,
	setDateScheduler,

	isListPageOpen,
	setListPageOpen,
	setInitialList,
	clearToken,
	navigateBack,
	gsaAgentDetails
}) => {
	const [unid, setUnid] = useState(null);
	const history = useHistory();
	const draftref = useRef(false);
	const { agentDetails }  = gsaAgentDetails;

	const [userDetails, setUserDetails] = useState(null)

	const [shippingItems, setShippingItems] = useState([])


	const [services, setServices] = useState([])

	const [recommendedServices, setRecommendedServices] = useState([])

	const [selectedOrigin, setSelectedOrigin] = useState(
		searchFormData ? searchFormData.origin : null
	)
	const [defaultOrigin, setDefaultOrigin] = useState(null)
	const [isErrors, setIsErrors] = useState(false)

	const [selectedDestination, setSelectedDestination] = useState(
		searchFormData ? searchFormData.destination : null
	)

	const [documentRequired, setDocumentRequired] = useState({

	})

	const [isSearchByArrivalDate, setIsSearchByArrivalDate] = useState(
		searchFormData && searchFormData.arrivalDate ? true : false
	)

	const [shippingDate, setShippingDate] = useState(null)

	const [arrivalDate, setArrivalDate] = useState(null)

	const [isUSBound, setIsUSBound] = useState(false)

	const [exceptionTypeDg, setExceptionTypeDg] = useState('')

	const [isUnknownShipment, setIsUnknownShipment] = useState(
		searchFormData && searchFormData.isUnknownShipment
			? searchFormData.isUnknownShipment
			: null
	)

	const [spUnid, setSpUnid] = useState()

	const [warning, setWarning] = useState(false)

	const [noanimals, setNoAnimals] = useState()

	const [maxShippingDate, setMaxShippingDate] = useState(moment().add(ALLOWABLE_BOOKING_DATE - 1, 'days').toDate())

	const [shippingItem, setShippingItem] = useState(
		searchFormData ? searchFormData.shippingItemCode : null
	)

	const [shipmentSccs, setShipmentSccs] = useState(
		searchFormData ? searchFormData.shipmentSccs : []
	)
	const [appliedSccs, setAppliedSccs] = useState(
		searchFormData ? searchFormData.appliedSccs : []
	)
	const [additionalSccs, setAdditionalSccs] = useState(
		searchFormData ? searchFormData.additionalSccs : []
	)

	const [appliedServices, setAppliedServices] = useState(
		searchFormData ? searchFormData.appliedServices : []
	)

	const [dgbookcheck, setDgbookcheck] = useState(
		searchFormData ? searchFormData.dgbookcheck : false)


	const [shippingType, setShippingType] = useState(
		searchFormData ? searchFormData.shippingType : SHIPPING_TYPE.LOOSE
	)

	const [isAllotmentRequired, setIsAllotmentRequired] = useState(true)
	const [isAllotmentRequiredFlightDate, setIsAllotmentRequiredFlightDate] = useState(true)
	const [indexArray, setIndexArray] = useState([])

	//Units
	const [dimensionUnit, setDimensionUnit] = useState(
		searchFormData ? searchFormData.dimensionUnit : DIMENSION_UNIT.CENTIMETERS
	)
	const [weightUnit, setWeightUnit] = useState(
		searchFormData ? searchFormData?.weightUnit : WEIGHT_UNIT.KILOGRAM
	)

	//Loose Items Details Array
	const [looseItemsTotalWeight, setLooseItemsTotalWeight] = useState(
		searchFormData ? searchFormData?.totalWeight : null
	)

	//adjustedWeight
	const [adjustedWeight, setAdjustedWeight] = useState(
		searchFormData ? searchFormData?.adjustedWeight : null
	)



	const [looseItemsGrossWeight, setLooseItemsGrossWeight] = useState(0)
	const [calculatedTotalWeight, setCalculatedTotalWeight] = useState(0)
	const [showValidation, setshowValidation] = useState(false)
	const [looseDetails, setLooseDetails] = useState(
		searchFormData && searchFormData.looseDetails
			? searchFormData.looseDetails
			: [getInitLooseItemDetail()]
	)
	const [isAllStackable, setAllStackable] = useState(
		searchFormData ? (searchFormData.isAllStackable || false) : true
	)
	const [isAllTiltable, setAllTiltable] = useState(
		searchFormData ? (searchFormData.isAllTiltable || false) : false
	)

	const [sccWarning, setSccWarning] = useState(null)

	const [unidWarning, setUnidWarning] = useState(null)
	const [embargoErrorCheck, setEmbargoErrorCheck] = useState(true)
	const [specialProvisonErrorCheck, setSpecialProvisonErrorCheck] = useState(true)


	//ULD Items Details Array
	const [ULDDetails, setULDDetails] = useState(
		searchFormData && searchFormData.ULDDetails
			? searchFormData.ULDDetails
			: [getInitULDItemDetail()]
	)

	//Pharma Life && VAC specific state values...
	const [additionalInfoData, setAdditionalInfoData] = useState({
		type: null,
		pharma: {},
	})
	const [handlingInformation, setHandlingInformation] = useState(searchFormData && searchFormData.handlingInformation ? searchFormData.handlingInformation : null)

	const [displayContainerPopup, setDisplayContainerPopup] = useState(false)
	const [displayShippingItemPopup, setDisplayShippingItemPopup] = useState(false)
	// const [displayDgULDItemPopup, setDisplayDgULDItemPopup] = useState(false)

	//Summary bucket state values
	const [actualVolume, setActualVolume] = useState(0)
	const [actualGrossWeight, setActualGrossWeight] = useState(0)
	const [actualTotalIneItemWeight, setActualTotalIneItemWeight] = useState(0)
	const [actualChargeableWeight, setActualChargeableWeight] = useState(0)

	const [grossWeightInSelectedUnit, setGrossWeightInSelectedUnit] = useState(0)
	const [grossWeightInConvertedUnit, setGrossWeightInConvertedUnit] =
		useState(0)
	const [densityInSelectedUnit, setDensityInSelectedUnit] = useState(0)
	const [densityInConvertedUnit, setdensityInConvertedUnit] = useState(0)
	const [chargeableWeightInSelectedUnit, setChargeableWeightInSelectedUnit] =
		useState(0)

	const [totalWeightInKg, setTotalWeightInKg] = useState(
		searchFormData ? searchFormData.totalWeightInKg : 0
	)
	const [totalPieces, setTotalPieces] = useState(
		searchFormData ? searchFormData?.totalPieces : 0
	)
	const [totalVolumeInMtrs, setTotalVolumeInMeters] = useState(
		searchFormData ? searchFormData.totalVolumeInMtrs : 0
	)
	const [chargeableWeight, setChargeableWeight] = useState(
		searchFormData ? searchFormData?.chargeableWeight : 0
	)

	const [cargoFlights, setCargoFlights] = useState(
		searchFormData ? searchFormData?.cargoFlightCheck : '')

	const [sccCombinationError, setSccCombinationError] = useState('')

	const [sccCompatibilityError, setSccCompatibilityError] = useState('')

	const [sccCompatibility, setSccCompatibility] = useState('')

	const [error, setError] = useState(null)

	const [hasDangerous, setHasDangerous] = useState(false)

	const [hasDangerous1, setHasDangerous1] = useState(false)

	const [gradeoutQ, setGradeoutQ] = useState(false)

	const [priceCodeType, setPriceCodeType] = useState(PRICE_CODE_TYPE.PROMO_RATE)

	const [promoCode, setPromoCode] = useState('')

	const [spotRateCode, setSpotRateCode] = useState('')

	const [spotRateDetails, setSpotRateDetails] = useState(isModifyBooking && searchFormData && searchFormData.spotRateDetails
		? searchFormData.spotRateDetails : null)

	const [AviEnabled, setAviEnabled] = useState('false');
	const [dgarrayListDoc, setDgArrayListDoc] = useState(searchFormData && searchFormData?.dgAttachementDetails
		? [searchFormData?.dgAttachementDetails]
		: [])

	// const [dgarrayListDoc, setDgArrayListDoc] = useState([])
	const [arrayListDoc, setArrayListDoc] = useState([])

	//Mix Booking
	const [mixLooseDetails, setMixLooseDetails] = useState(
		searchFormData && searchFormData.mixLooseDetails
			? searchFormData.mixLooseDetails
			: [getInitLooseItemDetail()]
	)
	const [expandAccordian, setExpandAccordian] = useState(
		searchFormData ? searchFormData.expandAccordian : false
	)

	const [footerErrorMsg, setFooterErrorMsg] = useState(null)

	const initShippingItem = useRef(
		searchFormData ? searchFormData.shippingItemCode : null
	)

	// onCommodity Change attachmentId change

	const [onCommodityChange, setOnCommodityChange] = useState(false)

	//Save template
	const [templateModal, setTemplateModal] = useState(false)
	const [templateName, setTemplateName] = useState('')
	const [serialNumber, setSerialNumber] = useState(null)
	const [selectedTemplate, setSelectedTemplate] = useState('')

	//save draft
	const [notLeave, setNotLeave] = useState(draftref?.current);
	const [draftName, setDraftName] = useState(`Draft`)
	const [locationListened, setLocationListened] = useState('');
	const [forInternalNav, setForInternalNav] = useState(false);

	const [initialLoading, setInitialLoading] = useState(false)
	//for multilingual
	const { t, i18n } = useTranslation(['labels', 'errors'])

	const [showOpWeight, setShowOpweight] = useState(
		searchFormData ? searchFormData.showOpWeight : false
	)

	const [sccCodeEntered, setSccCodeEntered] = useState('');
	const [animalNo, setAnimalNo] = useState();
	const [cageList, setCageList] = useState(
		searchFormData && searchFormData.crates
			? [...searchFormData.crates]
			:
			[getInitCageDetails(weightUnit, dimensionUnit)]
	)

	const setCageLists = (cageListNew) => {
		setCageList(cageListNew)
		setToSessionStorage('cageList', JSON.stringify(cageListNew))
	}
	const [breedRejected, setBreedRejected] = useState(false);

	const [petsAttachmentList, setPetAttachmentList] = useState([])



	const [unidList, setUnidList] = useState(searchFormData && searchFormData.unid
		? [...searchFormData.unid]
		: [getInitUnidDetail()])


	const [unidSccList, setUnidSccList] = useState([])
	const [file, setFile] = useState([]);
	const [aviBookingError, setAviBookingError] = useState(false);
	const [dgBookingError, setDgBookingError] = useState(false);
	const [dgdocUpload, setDgdocUpload] = useState(false);
	const [dogBreeds, setDogBreeds] = useState([]);

	const [displayInfo, setDisplayInfo] = useState(aviBookingError ? true : false)
	const [displayDglist, setDisplayDglist] = useState(false)
	const [stubNose, setStubNose] = useState(false)



	const [unidName, setUnidName] = useState()
	const [errorMsgUnid, setErrorMsgUnid] = useState(false)

	const [dgEnabled, setDgEnabled] = useState(false)
	const [bookingRestrictionError, setBookingRestrictionError] = useState(false);
	const [bookingRestrictionType, setBookingRestrictionType] = useState(null);

	const [restrictionList, setRestrictionList] = useState([]);
	const [errorMessage, setErrorMessage] = useState('');


	const listDgArray = [...file]

	useEffect(() => {

		listDgArray?.map((element) => {
			setDgArrayListDoc([...dgarrayListDoc, element])
		})



	}, [file])

	useEffect(() => {
		if (dgbookcheck) {
			setDgEnabled(true)
		}
		else {
			setDgEnabled(false)
		}


	}, [dgbookcheck])

	useEffect(() => {
		if (shippingItem) {
			var isNonStackable = looseDetails.some((stack) => !stack.stackable)
			if (isNonStackable) {
				let tempAppliedSccs = [...appliedSccs]
				const nscObj = {
					scc: "NST",
					sccDescription: "NON STACKABLE CARGO",
				}
				let isNscIncluded = tempAppliedSccs.some((item) => item.scc === 'NST')
				if (!isNscIncluded && shippingItem && shippingItem.product !== "LiveAnimals") {
					//Appending NST additional scc
					tempAppliedSccs.push(nscObj)

					setTimeout(() => {
						setAppliedSccs(tempAppliedSccs)
					}, 500);
				}
			}
		}

	}, [shippingItem])

	const handelPopup = () => {
		setDisplayDglist(true)
	}
	const [displayUploadSection, setDisplayUploadSection] = useState(false);
	const [fileList, setFileList] = useState([]);
	const [fileName, setFileName] = useState('');

	const [specialProvison, setSpecialProvison] = useState()
	const [displayDgValidationPopup, setDisplayDgValidationPopup] = useState(false)
	const [displayDgULDItemPopup, setDisplayDgULDItemPopup] = useState(false)
	const [popupNotificationPopup, setPopupNotificationPopup] = useState({})
	const [deletedCageIds, setDeletedCageIds] = useState([]);
	const [deletedPetIds, setDeletedPetIds] = useState([]);

	const [unidArray, setUnidArray] = useState([]);

	const [dgSccCode, setDgSccCode] = useState(JSON.parse(getFromSessionStorage('dgsccCode')))

	const [autoAdd, setAutoAdd] = useState(false)

	const specailProvialMessage = (e) => {

		if (e === "Yes") {
			setDisplayDgValidationPopup(true);
			setPopupNotificationPopup(
				{
					header: "",
					body: t('COMMON.SP_VALID_MSG')
				}
			)
		}
		else {
			setDisplayDgValidationPopup(true);
			setPopupNotificationPopup(
				{
					header: t('COMMON.SP_VALID_RESTRICTED'),
					body: t('COMMON.SP_VALID_RES_MSG')
				}
			)
		}
	}


	const handleRemovedg = (element, index) => {
		const data = {
			"attachmentId": element.attachmentId,
			"fileName": element.fileName
		}
		setIsLoading(true)
		deletespecialcommodityattachment(data).then(async (res) => {


			try {
				if (res.status === "SUCCESS") {
					setIsLoading(false);
					//call toaster
					getToastSuccess("File Deleted");
					// myToast.current.show({ ...toastDetails, life: 4000 })
					const itemsTemp = dgarrayListDoc.filter((element, curr) => {
						return index !== curr
					})

					setDgArrayListDoc([...itemsTemp])

				}

			} catch (error) {
				setIsLoading(false);
				setFile(null);
				getToastError("Failed to Delete");
			}
		})


	}





	const closeDialog = () => {
		setDisplayInfo(false)
		setAviBookingError(false)
		setDisplayDglist(false)
		setDgBookingError(false)
	}

	const customClose = (
		<i>
			<img src={closeIcon} alt='close' title='close' onClick={closeDialog} />
		</i>
	)

	useEffect(() => {
		getUnidInfo()
			.then((sccs) => {

				if (sccs.length > 0) {
					// setToSessionStorage('unidList', JSON.stringify(sccs))
					setUnidArray(sccs)
					setErrorMsgUnid(false)
				}
				else {
					setErrorMsgUnid(true)
				}
			})

		if (shippingItem?.code === 'DOG' || shippingItem?.code === 'CAT') {
			getAviBookingBreeds(shippingItem?.code).then((response) => {
				let breedsData = response;
				let otherOption = {
					"breedCode": "Other",
					"breedName": "Other"
				}
				breedsData.push(otherOption)
				setDogBreeds(breedsData);
				setAdjustedWeight(null)
				setShowOpweight(false)

			})
		}

		setDgbookcheck(false);
		setUnidName()
		setUnid()
		setUnidList([getInitUnidDetail()]);
		// setUnidSelectedValidation('')
		setAviBookingError(false)
		setDgBookingError(false)
		setFile([])
		setExceptionTypeDg('')
		setDisplayDgValidationPopup(false)
		// setDisplayContainerPopup()
		//setDisplayShippingItemPopup(false)
		setStubNose(false)
		setDgArrayListDoc([])
		setCageList([])
		setToSessionStorage('ifDg', false)
		setHandlingInformation(null)
		// setOnCommodityChange(true)


	}, [shippingItem?.code])

	useEffect(() => {
		let totalWeight = 0;
		let totalWeightinSelectedUnit = 0
		if (cageList.length > 0) {
			[...cageList].map((item) => {
				totalWeight = totalWeight + Number(calculateWeightToKg(item.weight, weightUnit))
				totalWeightinSelectedUnit = totalWeightinSelectedUnit + item.weight
			})
			if (shippingItem?.code === "DOG" || shippingItem?.code === "CAT") {
				setActualGrossWeight(totalWeightinSelectedUnit)

				setTotalWeightInKg(totalWeight)
			}
			setCageLists(cageList)
		}
		if (cageList.length > 0 && cageList[0]?.weight) {
			handleSummaryChange(cageList)
			let filteredDimensions = [...cageList].map((item) => {
				let items = {};
				items[LOOSE_ITEM_KEYS.LENGTH] = item.length
				items[LOOSE_ITEM_KEYS.WIDTH] = item.width
				items[LOOSE_ITEM_KEYS.HEIGHT] = item.height
				items[LOOSE_ITEM_KEYS.PIECES] = 1
				items[LOOSE_ITEM_KEYS.WEIGHT] = item.weight
				items[LOOSE_ITEM_KEYS.TILTABLE] = 'N'
				items[LOOSE_ITEM_KEYS.STACKABLE] = 'N'
				items[LOOSE_ITEM_KEYS.WEIGHT_UNIT] = WEIGHT_UNIT.KILOGRAM,
					items[LOOSE_ITEM_KEYS.DIMENSION_UNIT] = "C"
				return items;
			})
			setLooseDetails(filteredDimensions)
		}
	}, [cageList])


	const flatten = (arr) => {
		return arr.reduce(function (flat, toFlatten) {
			return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
		}, []);
	}

	useEffect(() => {
		if (cageList?.length > 0 && cageList[0]?.weight) {
			let petAttachmentsArray = [];
			let snubNozedErrorArray = [];

			for (let i = 0; i < cageList.length; i++) {
				for (let j = 0; j < cageList[i].pets.length; j++) {
					//attachements
					petAttachmentsArray.push(cageList[i].pets[j].attachments)

					//snubNosed error check
					if (cageList[i].pets[j]?.error && cageList[i].pets[j]?.error.snubNosed) {
						snubNozedErrorArray.push(cageList[i].pets[j].error.snubNosed)
					}
				}
			}
			//attachments
			let attachmentList = flatten(petAttachmentsArray);
			setPetAttachmentList(attachmentList)

			//snubNosed check
			let snubNozedErrorList = flatten(snubNozedErrorArray);
			if (snubNozedErrorList.length > 0) {
				setBreedRejected(true)
			}
			else {
				setBreedRejected(false)
			}
		}

	}, [cageList])


	useEffect(() => {
		if (searchFormData) {
			if (
				searchFormData.sccCodes &&
				searchFormData.sccCodes.length &&
				searchFormData.sccCodes.some((e) => e.scc === 'UKN')
			) {
				setIsUnknownShipment(true)
			} else if (searchFormData.isModifyBooking) {
				setIsUnknownShipment(false)
				// setUnid
			}

			//appending NST scc in edit
			setTimeout(() => {
				setHandlingInformation(searchFormData.handlingInformation)
				var isNonStackable = searchFormData.sccCodes.some((sccCode) => sccCode.scc === 'NST')
				if (isNonStackable) {
					let tempAppliedSccs = [...appliedSccs]
					const nscObj = {
						scc: "NST",
						sccDescription: "NON STACKABLE CARGO",
					}
					let isNscIncluded = tempAppliedSccs.some((item) => item.scc === 'NST')
					if (!isNscIncluded && searchFormData.shippingItemCode && searchFormData.shippingItemCode?.product !== "LiveAnimals") {
						//Appending NST additional scc
						tempAppliedSccs.push(nscObj)
						setAppliedSccs(tempAppliedSccs)
					}
				}
			}, 500)
		}

		setSpecialProvison()
	}, [searchFormData])


	useEffect(() => {
		if (searchFormData?.crates?.length > 0) {
			let data = [...searchFormData.crates]
			setCageLists(data)
		}
	}, [searchFormData])


	const setDropDownFileList = (list) => {
		setFileList(list)
	}

	const shippingTypesSelectItems = [
		{ label: t('COMMON.LOOSE'), value: SHIPPING_TYPE.LOOSE },
		{ label: t('COMMON.ULD'), value: SHIPPING_TYPE.ULD },
	]

	const unkownShipperSelectItems = [
		{ label: t('BOOKING.SHPR_KNOWN_BUTTON'), value: IS_KNOWN_UNKNOWN.KNOWN },
		{
			label: t('BOOKING.SHPR_UNKNOWN_BUTTON'),
			value: IS_KNOWN_UNKNOWN.UNKNOWN,
		},
	]

	const draftCallback = () => {
		draftref.current = true
	}


	const handelDocRequired = (event) => {

		setDocumentRequired((prevState) => ({
			...prevState,
			[documentRequired.id]: true,
		}))
	}

	useEffect(() => {
		setNotLeave(draftref.current)
	}, [draftref?.current]);



	const onTabOkclick = (selectedCargoType) => {
		closeErrorPopup()
		setShippingType(selectedCargoType)
		if (selectedCargoType && selectedCargoType === "ULD") {

			if ((dgSccCode?.includes(shippingItem.scc)) || selectedCargoType === "ULD" && dgbookcheck) {
				setDisplayDgULDItemPopup(true);
				setDgEnabled(true)
				setExceptionTypeDg('DG_ULD')
			}
			setAdjustedWeight(null)
			setShowOpweight(false)
		}
	}

	const onTabCancelclick = () => {
		closeErrorPopup()
	}
	const showTabPopup = (selectedCargoType) => {
		const header = t('COMMON.CONFIRM')
		const message =
			shippingType && SHIPPING_TYPE.LOOSE === shippingType
				? t(MESSAGES.BKG_TAB_MESSAGE_LOOSE, { ns: 'errors' })
				: t(MESSAGES.BKG_TAB_MESSAGE_ULD, { ns: 'errors' })
		const primaryBtnLbl = t('COMMON.OK')
		const acceptFn = () => {
			onTabOkclick(selectedCargoType)
		}
		const secondaryBtnLbl = t('COMMON.CANCEL')
		const rejectFn = () => {
			onTabCancelclick()
		}
		showErrorPopup(
			header,
			message,
			primaryBtnLbl,
			acceptFn,
			secondaryBtnLbl,
			rejectFn
		)
	}


	useEffect(() => {

		if (aviBookingDetaills?.length > 0) {

			setToSessionStorage('dgsccCode', JSON.stringify(aviBookingDetaills[1]?.scccodes))
		}


		if (shippingItem?.code === "DOG" || shippingItem?.code === "CAT" || (dgSccCode?.includes(shippingItem?.scc) || (!(dgSccCode?.includes(shippingItem?.scc)) && dgBookingError))) {

			if ((dgSccCode?.includes(shippingItem?.scc))) {

				setHasDangerous1(true)
			}
			if (aviBookingDetaills?.length > 0) {
				let aviBookingMinDate = moment().add(aviBookingDetaills[0].minBookingDays, 'days').toDate()
				let dgBookingMinDate = moment().add(aviBookingDetaills[1].minBookingDays, 'days').toDate()
				if (moment(shippingDate) < moment(aviBookingMinDate) || moment(shippingDate) < moment(dgBookingMinDate) && shippingItem !== null) {
					if (shippingItem?.code === "DOG" || shippingItem?.code === "CAT") {
						setAviBookingError(true)
						setDgBookingError(false)
					}
					else if ((dgSccCode?.includes(shippingItem?.scc) || (!(dgSccCode?.includes(shippingItem?.scc)) && dgBookingError))) {
						setDgBookingError(true)
						setAviBookingError(false)
					}
					// setShippingDate(null)
				}
				else {
					setAviBookingError(false)
					setDgBookingError(false)
				}

			}
			setShippingType('LOOSE')

		}
		else {
			setHasDangerous1(false)
			setAviBookingError(false)
			setDgBookingError(false)
			// setDisplayShippingItemPopup(false)
			setExceptionTypeDg()
			setDisplayDgULDItemPopup(false);
			setDgEnabled(false)
			//setShippingType('LOOSE')
		}
		// setUnidList([getInitUnidDetail()]);


	}, [shippingDate, shippingItem])

	useEffect(() => {
		if (saveDraftResponse?.bookingDraft?.draftName) {
			const encodedDraftSearchObject = getFromSessionStorage('savedDraftObject')
			if (encodedDraftSearchObject) {
				if (
					saveDraftResponse.draftNameDuplicate === false &&
					encodedDraftSearchObject != null
				) {
					handleDraftValueCallback()
				} else {
					setNotLeave(true)
				}
			} else {
				return
			}
		} else {
			return
		}
	}, [saveDraftResponse])

	useEffect(() => {
		if (unidList.length > 0) {
			[...unidList].map((item) => {
				setUnid(item.unid)
			})
		}
	}, [unidList])


	useEffect(() => {
		if (aviBookingDetaills?.length > 0) {
			// const dgSccCode = JSON.parse(getFromSessionStorage('dgsccCode'))
			// console.log("DGsccCode", dgSccCode, additionalSccs)
			const isDgsccInclude = dgSccCode?.some(r => additionalSccs?.scc?.indexOf(r) >= 0)
			const dgCheck = additionalSccs?.some(e => dgSccCode?.includes(e.scc))
			if (dgCheck) {
				setDgbookcheck(true)
				setGradeoutQ(true)
			}
			else {
				if (searchFormData?.dgbookcheck && shippingItem !== null && dgbookcheck) {
					setDgbookcheck(true)
				}
				// else if (additionalSccs?.length > 0) {
				// 	setDgbookcheck(true)
				// }
				else {
					setDgbookcheck(false)
				}
				setGradeoutQ(false)
				if (unidList[0]?.unid) {
					setDgbookcheck(true)
				}
			}
		}

	}, [additionalSccs])

	useEffect(() => {
		const fetchRestrictData = async () => {
			try {
				const activeLanguage = getFromLocalStorage(sessionKeys.I18NEST_LNG);
				const data = {
					restrictionTypes: ["ORG", "DEST"],
					lang: activeLanguage
				};
				const response = await getRestrictedList(data);
				setRestrictionList(response);
			} catch (error) {
				console.error('Failed to fetch restriction list', error);
			}
		};

		// Fetch the restriction data 
		fetchRestrictData();
	}, []);


	useEffect(() => {
		if (UNKNOWN_SHIPPER_ENABLED &&
			selectedOrigin?.countryCode === 'US' &&
			selectedDestination &&
			selectedDestination?.countryCode !== 'US') {
			setIsUSBound(true)
		} else {
			setIsUSBound(false)
			setIsUnknownShipment(null)
		}
		if (aviBookingDetaills?.length > 0 && selectedOrigin !== null) {
			setToSessionStorage('dgsccCode', JSON.stringify(aviBookingDetaills[1]?.scccodes))
			const dgSccCode = JSON.parse(getFromSessionStorage('dgsccCode'))
			setDgSccCode(dgSccCode)
		}
		// Restricting the orgin and destination based on booking-restriction and Show Popup
		const checkRestrictions = async () => {
			let isRestricted = false;
			let restrictionMsg = '';


			const getRestrictionData = (restriction, selectedOriginOrDestination) => {
				const restrictedAirport = restriction?.airports?.find(airport => airport.locationCode?.trim() === selectedOriginOrDestination?.code);
				if (restrictedAirport) {
					isRestricted = true;
					restrictionMsg = restrictedAirport.message;
				} else {
					const restrictedCountry = restriction?.countries?.find(country => country.locationCode?.trim() === selectedOriginOrDestination?.countryCode);
					if (restrictedCountry) {
						isRestricted = true;
						restrictionMsg = restrictedCountry.message;
					}
				}
			}

			restrictionList.forEach(restriction => {
				switch (restriction.restrictionType) {
					case 'ORG':
						getRestrictionData(restriction, selectedOrigin);
						break;
					case 'DEST':
						getRestrictionData(restriction, selectedDestination);
						break;
					default:
						break;
				}
			});

			// If a restriction is found, set the error message and trigger the popup
			if (isRestricted) {
				setErrorMessage(restrictionMsg);
				setBookingRestrictionError(true);
			} else {
				setBookingRestrictionError(false);
				setBookingRestrictionType(null)
			}
		};

		// Run the restriction check after fetching the restriction list
		checkRestrictions();


	}, [selectedOrigin, selectedDestination, restrictionList])


	useEffect(() => {
		setBookingRestrictionType('ORG')
	}, [selectedOrigin])

	useEffect(() => {
		setBookingRestrictionType('DEST')
	}, [selectedDestination])

	const onCloseRussianResPopup = () => {
		if (bookingRestrictionType === 'DEST') {
			setSelectedDestination(null);
		} else if (bookingRestrictionType === 'ORG') {
			setSelectedOrigin(null);
		}
		setBookingRestrictionError(false);
		setBookingRestrictionType(null)
	};

	const checkChanges = (selectedCargoType) => {
		let findChanges = 0
		if (selectedCargoType) {
			if (selectedCargoType === SHIPPING_TYPE.ULD) {
				looseDetails.some((item) => {
					Object.keys(item).some(function (k) {
						if (item[k] && k === 'pieces') {
							showTabPopup(selectedCargoType)
							findChanges++
						}
					})
				})
			} else {
				ULDDetails.some((item) => {
					Object.keys(item).some(function (k) {
						if (item[k] && k === 'pieces') {
							showTabPopup(selectedCargoType)
							findChanges++
						}
					})
				})
				mixLooseDetails.some((item) => {
					Object.keys(item).some(function (k) {
						if (item[k] && k === 'pieces') {
							showTabPopup(selectedCargoType)
							findChanges++
						}
					})
				})
			}
		}
		if (findChanges > 0) {
			return true
		} else {
			if (selectedCargoType && selectedCargoType === "ULD") {

				if ((dgSccCode?.includes(shippingItem?.scc)) || selectedCargoType === "ULD" && dgbookcheck) {
					setDisplayDgULDItemPopup(true);
					setDgEnabled(true)
					setExceptionTypeDg('DG_ULD')
				}
				setAdjustedWeight(null)
				setShowOpweight(false)
			}
			return false
		}
	}

	useEffect(() => {
		if (arrivalDate) {
			const maxShippingDate = getMaxShippingDate(arrivalDate)
			setMaxShippingDate(maxShippingDate)
		}
		else {
			setMaxShippingDate(moment().add(ALLOWABLE_BOOKING_DATE - 1, 'days').toDate())
		}
	}, [arrivalDate])

	useEffect(() => {
		if (!searchFormData) return
		setSelectedOrigin(searchFormData.origin)
		setSelectedDestination(searchFormData.destination)
		setShippingDate(searchFormData.orginalShipingDate)
		setArrivalDate(searchFormData.arrivalDate)
		setShippingItem(searchFormData.shippingItemCode)
		setShipmentSccs(
			searchFormData.shipmentSccs ? searchFormData.shipmentSccs : []
		)
		const appliedSccs = searchFormData.appliedSccs &&
			searchFormData.appliedSccs.length ? (
			searchFormData.isModifyBooking ? searchFormData.appliedSccs :
				searchFormData.appliedSccs.filter(e => !SYSTEM_ADDED_SCCS.includes(e.scc))
		) : []
		setAppliedSccs(appliedSccs)
		setAdditionalSccs(
			searchFormData.additionalSccs ? searchFormData.additionalSccs : []
		)
		setAppliedServices(searchFormData.appliedServices)
		setShippingType(searchFormData.shippingType)
		setDimensionUnit(searchFormData.dimensionUnit)
		setWeightUnit(searchFormData.weightUnit)
		setLooseItemsTotalWeight(searchFormData.totalWeight)
		setAdjustedWeight(searchFormData.adjustedWeight)
		setLooseDetails(
			searchFormData.looseDetails
				? searchFormData.looseDetails
				: [getInitLooseItemDetail()]
		)
		setAllStackable(searchFormData.isAllStackable)
		setAllTiltable(searchFormData.isAllTiltable)
		setULDDetails(
			searchFormData.ULDDetails
				? searchFormData.ULDDetails
				: [getInitULDItemDetail()]
		)
		setMixLooseDetails(
			searchFormData.mixLooseDetails
				? searchFormData.mixLooseDetails
				: [getInitLooseItemDetail()]
		)
		setPromoCode(searchFormData.promoCode ? searchFormData.promoCode : '')
		setSpotRateDetails(isModifyBooking && searchFormData && searchFormData.spotRateDetails
			? searchFormData.spotRateDetails : null)
		if (searchFormData.spotRateCode) {
			setPriceCodeType(PRICE_CODE_TYPE.SPOT_RATE)
			setSpotRateCode(searchFormData.spotRateCode)
		}

		const encodedSearchObject = getFromSessionStorage('searchObject')

		if (encodedSearchObject) {
			const searchObject = getBase64Decoded(encodedSearchObject)
			if (
				searchFormData.isUSBound &&
				(searchObject.isUnknownShipment || !searchObject.isUnknownShipment)
			) {
				setIsUnknownShipment(searchObject.isUnknownShipment)
			}
		}
		if (searchFormData?.dgbookcheck === true && searchFormData?.unid) {
			setDgbookcheck(true)
		}

	}, [searchFormData])

	// useEffect(() => {
	// 	if (chargeableWeight) {
	// 		if (weightUnit === WEIGHT_UNIT.POUND) {
	// 			setChargeableWeightInSelectedUnit(chargeableWeight * KG_TO_POUND_VALUE)
	// 		}
	// 		if (weightUnit === WEIGHT_UNIT.TON) {
	// 			setChargeableWeightInSelectedUnit(chargeableWeight * KG_TO_TON_VALUE)
	// 		}
	// 		if (weightUnit === WEIGHT_UNIT.KILOGRAM) {
	// 			setChargeableWeightInSelectedUnit(chargeableWeight)
	// 		}
	// 	} else {
	// 		setChargeableWeightInSelectedUnit(0)
	// 	}
	// }, [chargeableWeight])

	useEffect(() => {
		var isNonStackable = looseDetails.some((stack) => !stack.stackable)
		if (isNonStackable) {
			let tempAppliedSccs = [...appliedSccs]
			const nscObj = {
				scc: "NST",
				sccDescription: "NON STACKABLE CARGO",
			}
			let isNscIncluded = tempAppliedSccs.some((item) => item.scc === 'NST')
			if (!isNscIncluded && shippingItem && shippingItem.product !== "LiveAnimals") {
				//Appending NST additional scc
				tempAppliedSccs.push(nscObj)
				setAppliedSccs(tempAppliedSccs)
			}
		}
		else {
			let tempAppliedSccs = [...appliedSccs]
			let isNscIncluded = tempAppliedSccs.some((item) => item.scc === 'NST')
			if (isNscIncluded) {
				let filteredSccs = tempAppliedSccs.filter((item) => item.scc !== 'NST')
				setAppliedSccs([...filteredSccs])
			}
		}
	}, [looseDetails])

	useEffect(() => {
		var isNonStackable = mixLooseDetails.some((stack) => !stack.stackable)
		if (isNonStackable) {
			let tempAppliedSccs = [...appliedSccs]
			const nscObj = {
				scc: "NST",
				sccDescription: "NON STACKABLE CARGO",
			}
			let isNscIncluded = tempAppliedSccs.some((item) => item.scc === 'NST')
			if (!isNscIncluded && shippingItem && shippingItem.product !== "LiveAnimals") {
				//Appending NST additional scc
				tempAppliedSccs.push(nscObj)
				setAppliedSccs(tempAppliedSccs)
			}
		}
		else {
			let tempAppliedSccs = [...appliedSccs]
			let isNscIncluded = tempAppliedSccs.some((item) => item.scc === 'NST')
			if (isNscIncluded) {
				let filteredSccs = tempAppliedSccs.filter((item) => item.scc !== 'NST')
				setAppliedSccs([...filteredSccs])
			}
		}
	}, [mixLooseDetails])


	const getQuotes = () => {
		if (userDetails && !updateTemplateView && !isModifyBooking) {
			const listQuoteRequest = {
				requestID: uuidv4(),
				agentCode: userDetails.agentCode ? userDetails.agentCode : null,
			}
			getlistQuoteResponse(listQuoteRequest)
		}
	}

	useEffect(() => {
		const userDetails = getCurrentUser()
		//setInitialLoading(true)
		setUserDetails(userDetails)
		clearItemFromSessionStorage('awbNumber')
		clearItemFromSessionStorage('flightSelected')
		clearItemFromSessionStorage('bookingDetails')
		clearItemFromSessionStorage('draftSelected')
		clearItemFromSessionStorage('quotationDetails')
		clearItemFromSessionStorage('quotationId')
		clearItemFromSessionStorage('sortOption')
		clearItemFromSessionStorage('selectedfilter')
		clearItemFromSessionStorage('quoteName')
		clearItemFromSessionStorage('flightUpsellOffered')
		clearItemFromSessionStorage('spotRate')
		clearItemFromSessionStorage('allInRate')

		const encodedSearchObject = getFromSessionStorage('searchObject')
		const encodedFlightEnquiryObject = getFromSessionStorage('flightEnquiryObj')
		const upsellOffered = getFromSessionStorage('upsellOffered')
		if (encodedSearchObject && encodedSearchObject !== 'null') {
			const searchObject = getBase64Decoded(encodedSearchObject)

			setIsUnknownShipment(searchObject.isUnknownShipment)
			if (
				searchObject &&
				// searchObject.isModifyBooking &&
				// searchObject.isModifyBooking !== true) ||
				!searchObject.isModifyBooking ||
				(isModifyBooking && checkIsBackButtonAction(history))
			) {
				setSearchFormDataToState(null)
				//setSearchFormDataToState(searchObject)
				setTimeout(() => {
					setSearchFormDataToState(searchObject)
				}, 2000)
			} else if (searchObject.isModifyBooking) {
				setSearchFormDataToState(null)

			}
			setTimeout(() => {
				clearItemFromSessionStorage('searchObject')
				//	clearItemFromSessionStorage('upsellOffered')
			}, 2000)
		} else if (
			encodedFlightEnquiryObject &&
			encodedFlightEnquiryObject !== 'null'
		) {
			const searchFlightEnquiryObject = getBase64Decoded(
				encodedFlightEnquiryObject
			)
			if (searchFlightEnquiryObject?.isModifyBooking !== true) {
				setSearchFormDataToState(searchFlightEnquiryObject)
			} else if (searchFlightEnquiryObject.isModifyBooking) {
				setSearchFormDataToState(null)
			}
			setTimeout(() => {
				clearItemFromSessionStorage('flightEnquiryObj')
				//	clearItemFromSessionStorage('upsellOffered')
			}, 1000)
		} else {
			handleClear()
			if (defaultOrigin) {
				setSelectedOrigin(defaultOrigin)
			} else {
				getDefaultOrigin()
			}
		}

		getTemplates()
		getQuotes()

		const encodedAdditionalObject = getFromSessionStorage('additionalFormData')
		if (encodedAdditionalObject && encodedAdditionalObject !== 'null') {
			let additionalObj = getBase64Decoded(encodedAdditionalObject)
			setAdditionalInfoData(additionalObj)
			setTimeout(() => {
				setAdditionalInfoData({
					type: null,
					pharma: {},
				})
				setAdditionalInfoData(additionalObj)
				clearItemFromSessionStorage('additionalFormData')
			}, 2000)
		}
		const encodedPharmaData = getFromSessionStorage('pharmaFormData')
		const dummy = {
			duration: 0,
			aircraftClassification: '',
			stops: null,
		}
		setToSessionStorage('selectedfilter', getBase64Encoded(dummy))
		pushPageData(
			pageNames.NEW_BOOKING,
			PAGE_PRIMARY_CATEGORY.E_SERVICES,
			PAGE_SUB_CATEGORY1.BOOKING,
			BOOKING_PAGE_SUB_CATEGORY2.NEW_BOOKING
		)
	}, [])

	useEffect(() => {
		setFooterErrorMsg(null)
	}, [
		selectedOrigin,
		selectedDestination,
		shippingDate,
		shippingItem,
		additionalInfoData,
		unid,
		looseItemsTotalWeight,
		looseDetails,
		ULDDetails,
		mixLooseDetails,
		expandAccordian,
		embargoErrorCheck,
		flightList,
		weekdays
	])
	useEffect(() => {
		if (!searchFormData) {
			if (userDetails && userDetails.preference &&
				userDetails.preference.weightUnit &&
				userDetails.preference.dimensionUnit) {
				setWeightUnit(userDetails.preference.weightUnit)
				setDimensionUnit(userDetails.preference.dimensionUnit)
			}
			getDefaultOrigin()
		}

		getTemplates()
		getQuotes()

			; (async () => {
				try {
					let shippingItems = await getShippingItems()
					if (shippingItems) {
						shippingItems = shippingItems.sort((a, b) => {
							if (a.description < b.description) {
								return -1
							}
							if (a.description > b.description) {
								return 1
							}
							return 0
						})
					}
					if (isSeasonalBooking) {
						let restrictedSCCs = restrictedSeasonalSccs
						let seasonalshippingItems = shippingItems.filter(item => !restrictedSCCs.includes(item.scc));
						setShippingItems(seasonalshippingItems)
					}
					else {
						setShippingItems(shippingItems)
					}
				} catch (err) {
					setNotificationMessage(
						'Sorry, Shipping Details could not retrive now .Please try again later '
					)
					addError(
						'SHIPPING_ITEM_API_ERROR',
						'Sorry, Shipping Details could not retrive now .Please try again later'
					)
					setNotificationType(NOTIFICATION_MESSAGE_TYPE.ERROR)
				}
			})()
	}, [userDetails])

	useEffect(() => {
		if (shippingDate) {
			error
				? setError({
					...error,
					[FORM_FIELDS.SHIPPING_DATE]: null,
				})
				: setError({
					[FORM_FIELDS.SHIPPING_DATE]: null,
				})
		}
	}, [shippingDate])

	useEffect(() => {
		; (async () => {
			try {
				const response = await getServices()
				const { services } = response
				setServices(services)
			} catch (err) {
				setNotificationMessage('Service error')
				setNotificationType(NOTIFICATION_MESSAGE_TYPE.ERROR)
			}
		})()
	}, [])

	useEffect(() => {
		setError({
			...error,
			[FORM_FIELDS.LOOSE_ITEMS]: [],
			[FORM_FIELDS.ULD_ITEMS]: [],
			[FORM_FIELDS.TOTAL_WEIGHT]: '',
		})
		setshowValidation(false)
	}, [shippingType])

	useEffect(() => {
		let volumetricWeight = 0
		let chargeableWeight
		let chargeableWeightInSelectedUnit
		if (totalVolumeInMtrs) {
			volumetricWeight = Number(totalVolumeInMtrs).toFixed(2) * 166.6667
			chargeableWeight = getRoundedValue(Number(volumetricWeight))
			// setChargeableWeight(chargeableWeight)
			// setChargeableWeightInSelectedUnit(chargeableWeight)
		}

		//Calculate totalWeight before taking stage build

		if (volumetricWeight) {
			const adjustedWeightInKg = adjustedWeight ? calculateWeightToKg(
				adjustedWeight,
				weightUnit
			) : null
			if (Number(totalWeightInKg) >= Number(volumetricWeight)) {
				if (
					adjustedWeightInKg &&
					Number(totalWeightInKg) <= Number(adjustedWeightInKg)
				) {
					chargeableWeight = getRoundedValue(Number(adjustedWeight))
					chargeableWeightInSelectedUnit = getRoundedValue(Number(adjustedWeight))
				} else {
					chargeableWeight = getRoundedValue(Number(totalWeightInKg))
					if (weightUnit == WEIGHT_UNIT.POUND) {
						chargeableWeight = getRoundedValueDecimal(Number(totalWeightInKg) * 2.2046)
					} else if (weightUnit == WEIGHT_UNIT.TON) {
						chargeableWeight = getRoundedValueDecimal(Number(totalWeightInKg) * 0.00110231)
					}
				}


			} else {
				if (
					adjustedWeightInKg &&
					Number(volumetricWeight) <= Number(adjustedWeightInKg)
				) {
					chargeableWeight = getRoundedValue(Number(adjustedWeight))
					chargeableWeightInSelectedUnit = getRoundedValue(Number(adjustedWeight))
				} else {
					chargeableWeight = getRoundedValue(Number(volumetricWeight))
					if (weightUnit == WEIGHT_UNIT.POUND) {
						chargeableWeight = getRoundedValueDecimal(Number(volumetricWeight) * 2.2046)
					} else if (weightUnit == WEIGHT_UNIT.TON) {
						chargeableWeight = getRoundedValueDecimal(Number(volumetricWeight) * 0.00110231)
					}
				}
			}
			setChargeableWeight(chargeableWeight)
			setChargeableWeightInSelectedUnit(chargeableWeight)
		}
	}, [totalVolumeInMtrs, totalWeightInKg])

	const getDefaultOrigin = () => {
		let defaultAirport = userDetails && userDetails.defaultAirportCode ?  userDetails.defaultAirportCode : userDetails?.stationCode;
		// If GSA User Agent, default value is agentStationCode
		if(agentDetails?.agentStationCode){
			defaultAirport = agentDetails.agentStationCode
		}
		if (userDetails && defaultAirport) {
			getAirportsByCode(defaultAirport)
				.then((origin) => {
					setDefaultOrigin(origin)
					if (
						!getFromSessionStorage('searchObject') &&
						!getFromSessionStorage('flightEnquiryObj')
					) {
						setSelectedOrigin(origin)
					}
				})
				.catch((error) => console.log(error))
		}
	}

	useEffect(() => {
		const defaultAirport = agentDetails?.agentStationCode;
		if (defaultAirport) {
			getAirportsByCode(defaultAirport)
				.then((origin) => {
					setDefaultOrigin(origin)
					if (
						!getFromSessionStorage('searchObject') &&
						!getFromSessionStorage('flightEnquiryObj')
					) {
						setSelectedOrigin(origin)
					}
				})
				.catch((error) => console.log(error))
		}
	}, [agentDetails])

	const handelAddCAOAuto = (unidScc) => {
		setShipmentSccs(unidScc)
	}

	const handleRemoveScc = (scc) => {
		if (additionalSccs.map((item) => item.scc).includes(scc.scc)) {
			setAdditionalSccs(additionalSccs.filter((item) => item.scc !== scc.scc))
		}
		if (appliedServices.map((item) => item.scc).includes(scc.scc)) {
			setAppliedServices(appliedServices.filter((item) => item.scc !== scc.scc))
		}
	}

	const handleSummaryChange = (LooseDetails) => {
		let quantity = 0
		let totalVolumeinMeters = 0
		let actualVolumeInSelectedUnit = 0
		LooseDetails?.forEach(function (element) {
			if (element.pieces) {
				quantity += element.pieces * 1
			}
			if (element.pieces && element.length && element.width && element.height) {
				actualVolumeInSelectedUnit +=
					parseFloat(element.width * element.length * element.height) *
					parseInt(element.pieces)
			}
			if (element.pieces && element.length && element.width && element.height) {
				if (dimensionUnit == DIMENSION_UNIT.INCHES) {
					totalVolumeinMeters +=
						parseFloat(
							element.width *
							0.0254 *
							(element.length * 0.0254) *
							(element.height * 0.0254)
						) * parseInt(element.pieces)
				}
				if (dimensionUnit == DIMENSION_UNIT.CENTIMETERS) {
					totalVolumeinMeters +=
						parseFloat(
							element.width *
							0.01 *
							(element.length * 0.01) *
							(element.height * 0.01)
						) * parseInt(element.pieces)
				}
			}
		})
		if (totalVolumeinMeters && totalVolumeinMeters < 0.01) {
			totalVolumeinMeters = 0.01
		}
		if (actualVolumeInSelectedUnit && actualVolumeInSelectedUnit < 0.01) {
			actualVolumeInSelectedUnit = 0.01
		}

		setActualVolume(actualVolumeInSelectedUnit)

		setTotalVolumeInMeters(totalVolumeinMeters)
		setTotalPieces(quantity)
	}


	const showCombinationError = (pharmaType, selectedScc) => {
		let convertedPharmaType = convertToLowerCase(pharmaType)
		setSccCombinationError(
			t(MESSAGES.COMPATIBILITY_ERROR, {
				SCC1: selectedScc,
				SCC2: convertedPharmaType,
				ns: 'errors',
			})
		)
		setTimeout(function () {
			setSccCombinationError('')
		}, 3000)
	}

	const showSccCompatibilityError = (errorMsg) => {
		setSccCompatibilityError(errorMsg)
		addError('SCC_COMPATIBILITY_ERROR', errorMsg)
		setTimeout(function () {
			setSccCompatibilityError('')
		}, 3000)
	}
	const showWarning = () => {
		setWarning(true)
		setTimeout(function () {
			setWarning(false)
		}, 3000)
	}
	const checkShippingTypeCombinationScc = (scc) => {
		let validCombination = true
		if (
			additionalInfoData.pharma.shippingPharmaType ===
			PHARMA_SHIPPING_TYPE.PASSIVE
		) {
			if (
				scc === SCC_CODES.PIP ||
				scc === SCC_CODES.ACT ||
				scc === SCC_CODES.FRO
			) {
				validCombination = false
			}
		}
		if (
			additionalInfoData.pharma.shippingPharmaType ===
			PHARMA_SHIPPING_TYPE.ACTIVE
		) {
			if (scc === SCC_CODES.PIP) {
				validCombination = false
			}
		}

		if (
			additionalInfoData.pharma.shippingPharmaType ===
			PHARMA_SHIPPING_TYPE.HYBRID
		) {
			if (scc === SCC_CODES.ACT) {
				validCombination = false
			}
		}
		return validCombination
	}
	const checkDryIceCombinationScc = (scc) => {
		let validCombination = true
		if (
			additionalInfoData.pharma.isDryIceAdded === 'NO' &&
			scc === SCC_CODES.ICE
		) {
			validCombination = false
		}
		return validCombination
	}
	const checkTemperatureCombinationScc = (scc) => {
		let validCombination = true
		let temperatureArray = [
			SCC_CODES.TMP1,
			SCC_CODES.TMP2,
			SCC_CODES.TMP3,
			SCC_CODES.FRO,
		]
		if (temperatureArray.includes(scc)) {
			temperatureArray.forEach(function (element) {
				if (
					scc !== element &&
					(additionalSccs.map((item) => item.scc).includes(element) ||
						appliedSccs.map((item) => item.scc).includes(element))
				) {
					validCombination = false
				}
			})
		}
		return validCombination
	}

	const handleSccCompatibilityCheck = async (sccList) => {
		try {
			const responseBody = await checkSccCompatibility(sccList)

			if (responseBody && !responseBody.compatible) {
				let message = responseBody.message
				message = message.replace('ER023', t('ER023', { ns: 'errors' }))
				showSccCompatibilityError(message)
			}
			return responseBody.compatible
		} catch (error) {
			setNotificationMessage(
				'SCC comapatibility check error :' + error.message ? error.message : ''
			)
			addError(
				'SCC_COMPATIBILITY_API_ERROR',
				'SCC comapatibility check error :' + error.message ? error.message : ''
			)
			setNotificationType(NOTIFICATION_MESSAGE_TYPE.ERROR)
		}
	}

	const handleAddAdditinalScc = async (scc) => {

		let compatible
		const selectedSccList = [
			...shipmentSccs,
			...appliedSccs,
			...additionalSccs,
			...appliedServices,
		].map((item) => item.scc)

		if (!selectedSccList?.length && scc) {
			setAdditionalSccs([...additionalSccs, scc])

		} else if (selectedSccList?.length && !selectedSccList?.includes(scc?.scc)) {
			compatible = await handleSccCompatibilityCheck({
				selectedItems: selectedSccList,
				newItem: scc?.scc,
			})

			if (additionalInfoData.pharma.shippingPharmaType && compatible) {
				if (
					!checkShippingTypeCombinationScc(scc.scc) ||
					!checkDryIceCombinationScc(scc.scc) ||
					!checkTemperatureCombinationScc(scc.scc)
				) {
					showCombinationError(additionalInfoData.pharma.shippingPharmaType, scc.scc)
				} else {
					setAdditionalSccs([...additionalSccs, scc])
					if (
						scc.scc === SCC_CODES.ICE &&
						additionalInfoData.pharma.tempratureRange
					) {
						setAdditionalInfoData({
							...additionalInfoData,
							pharma: { ...additionalInfoData.pharma, isDryIceAdded: 'YES' },
						})
					}
					if (
						scc.scc === SCC_CODES.ACT &&
						(shippingItem.product === FRESH_FORWARD_PRODUCT ||
							shippingItem.product === PHARMA_PRODUCT)
					) {
						setAdditionalInfoData({
							...additionalInfoData,
							pharma: {
								...additionalInfoData.pharma,
								shippingPharmaType: PHARMA_SHIPPING_TYPE.ACTIVE,
							},
						})
					}
				}
			} else if (compatible) {
				setAdditionalSccs([...additionalSccs, scc])
			}
			setSccWarning(null)
		} else {
			if (autoAdd) {
				setSccWarning(null)
			}
			else {
				setSccWarning(t(MESSAGES.SELECTED, { ns: 'errors' }))
			}
		}

	}

	const handleAppliedRecommendedScc = async (scc) => {
		let compatible
		const selectedSccList = [
			...shipmentSccs,
			...appliedSccs,
			...additionalSccs,
			...appliedServices,
		].map((item) => item.scc)
		if (!selectedSccList.includes(scc.scc)) {
			compatible = await handleSccCompatibilityCheck({
				selectedItems: selectedSccList,
				newItem: scc.scc,
			})
			if (compatible) {
				setAppliedServices([...appliedServices, scc])
			}
		}

	}

	const clearPharmaState = () => {
		setAdditionalInfoData({
			...additionalInfoData,
			type:
				shippingItem &&
					(shippingItem.product === FRESH_FORWARD_PRODUCT ||
						shippingItem.product === PHARMA_PRODUCT)
					? 'pharma'
					: null,
			pharma: getInitPharmaObject(),
		})
	}

	useEffect(() => {
		if (
			(initShippingItem.current
				&& shippingItem
				&& initShippingItem.current.key === shippingItem.key) ||
			(shippingItem &&
				searchFormData &&
				searchFormData.sccCodesList &&
				searchFormData.sccCodesList.includes(shippingItem.scc))
		) {
			return
		}
		initShippingItem.current = shippingItem
		clearItemFromSessionStorage('upsellOffered')
		setRecommendedServices(recommendedServices)
		if (shippingItem && shippingItem.scc) {
			if (additionalSccs.map((item) => item.scc).includes(shippingItem.scc)) {
				let tempAdditionalScc = additionalSccs.filter(function (obj) {
					return obj.scc !== shippingItem.scc
				})
				setAdditionalSccs([...tempAdditionalScc])
			}
		}

		const selectedShipmentSccs =

			shippingItem && shippingItem.scc
				? [

					{
						scc: shippingItem.scc,
						sccDescription: shippingItem.sccDescription,
					},
				]
				: []
		setShipmentSccs(selectedShipmentSccs)

		setAdditionalSccs([])
		setAppliedSccs([])
		setRecommendedServices([])
		setAppliedServices([])
		clearPharmaState()
		// setUnidList(searchFormData && searchFormData.unid
		// 	? [...searchFormData.unid]
		// 	:
		// 	[getInitUnidDetail()])


		setSccWarning(null)
		if (shippingItem && shippingItem?.code === 'APHARMA') {
			setAdditionalInfoData({
				...additionalInfoData,
				type: 'pharma',
				pharma: {
					...additionalInfoData?.pharma,
					shippingPharmaType: PHARMA_SHIPPING_TYPE.ACTIVE,
				},
			})
		}
		setCageLists([getInitCageDetails(weightUnit, dimensionUnit)])
		if (shippingItem && shippingItem?.scc === 'AVI') {
			setLooseDetails([getInitLooseItemDetail()])
		}

		if ((dgSccCode?.includes(shippingItem?.scc))) {
			setDisplayShippingItemPopup(false)
		}
		setDgbookcheck(false);
		setUnidList([getInitUnidDetail()])
		setUnidName()
		setUnid()

		if (dgarrayListDoc.length <= 0) {
			setTimeout(async () => {
				await setSearchFormDataToState(null)
			}, 100)

			setOnCommodityChange(true)

		}





		// if (searchFormData?.dgbookcheck) {
		// 	setDgbookcheck(true)
		// 	setToSessionStorage('ifDg', true)

		// }
		// else {
		// 	setDgbookcheck(false)
		// 	setToSessionStorage('ifDg', false)
		// }
	}, [shippingItem])

	useEffect(() => {
		if ([...shipmentSccs, ...appliedSccs].length && additionalSccs.length) {
			const tempAdditionalSccs = additionalSccs.filter(
				(scc) =>
					![...shipmentSccs, ...appliedSccs].map((e) => e.scc).includes(scc.scc)
			)
			setAdditionalSccs(tempAdditionalSccs)
		}

	}, [shipmentSccs, appliedSccs])



	useEffect(() => {
		if ([...shipmentSccs, ...appliedSccs, ...additionalSccs].length) {
			const recommendedSccs = (
				shippingItem && shippingItem.serviceSelection
					? shippingItem.serviceSelection.split(',')
					: []
			).filter(
				(scc) =>
					![
						...shipmentSccs,
						...appliedSccs,
						...additionalSccs,
						...appliedServices,
					]
						.map((item) => item.scc)
						.includes(scc)
			)
			setRecommendedServices(recommendedSccs)
		}
		// if (cargoFlight) {
		// 	const caoAppend = {
		// 		"scc": "CAO",
		// 		"sccDescription": "CARGO AIRCRAFT ONLY"
		// 	}
		// 	setAdditionalSccs([...additionalSccs, caoAppend])
		// }
	}, [shipmentSccs, appliedSccs, additionalSccs, appliedServices])

	useEffect(() => {
		if (shippingItem && appliedServices && appliedServices.length) {
			const XPSService = appliedServices.find(service => service.scc === 'XPS')
			if (XPSService) {
				const service = services.find(el => el.scc === XPSService.scc)
				if (service) {
					//setShippingItem({ ...shippingItem, serviceProduct: service.product })
					setShippingItem({ ...shippingItem, serviceProduct: shippingItem.product })
				}
			} else {
				if (shippingItem.serviceProduct) {
					setShippingItem({ ...shippingItem, serviceProduct: null })
				}
			}
		}
		else {
			if (shippingItem && shippingItem.serviceProduct) {
				setShippingItem({ ...shippingItem, serviceProduct: null })
			}
		}
		//console.log("shipping item", shippingItem)
	}, [appliedServices])

	const handleCancel = () => {
		setAviBookingError(false)
		setDgBookingError(false)
		setShippingDate(null)
	}

	const handleConfirm = async () => {
		setAviBookingError(false)
		setDgBookingError(false)
		setShippingDate(null)
	}

	const RenderFooter = ({ onConfirmClick }) => {
		return (
			<div>
				<button className='primary-button mt-1' onClick={onConfirmClick || handleConfirm}>
					{t('COMMON.OK')}
				</button>
				{/* <button className='button secondary' onClick={handleCancel}>
					{t('COMMON.CANCEL')}
				</button> */}
			</div>
		)
	}


	const clearError = (key) => {
		const tempError = error ? { ...error } : null
		if (tempError) {
			tempError[key] = null
			setError(tempError)
		}
	}

	const handleAddLineItem = (e) => {
		e.preventDefault()

		if (shippingType === SHIPPING_TYPE.LOOSE) {
			setLooseDetails([
				...looseDetails,
				handleTilteableAndStackable(
					getInitLooseItemDetail(),
					isAllTiltable,
					isAllStackable
				),
			])
		} else {



			setULDDetails([...ULDDetails, getInitULDItemDetail()])
		}
	}


	const handleAppliedAddScc = async (sccCode) => {
		let scc, compatible
		scc = { scc: sccCode }
		const selectedSccList = [
			...shipmentSccs,
			...appliedSccs,
			...additionalSccs,
			...appliedServices,
		].map((item) => item.scc)

		if (!selectedSccList.includes(scc)) {
			compatible = await handleSccCompatibilityCheck({
				selectedItems: selectedSccList,
				newItem: sccCode,
			})
			if (compatible) {
				setSccCompatibility('true')
				setAppliedSccs([...appliedSccs, scc])
			} else {
				setSccCompatibility('false')
			}
		}
	}

	const initiateListFlightRequest = async (tempLooseDetails, type) => {
		const bookedFlightDetails = await getBookingFlightDetails(
			searchFormData,
			shippingDate
		)

		let formData = {
			origin: selectedOrigin,
			destination: selectedDestination,
			orginalShipingDate: shippingDate
				? moment(shippingDate, 'DD-MMM-YYYY').format('DD-MMM-YYYY')
				: null,
			shippingDate: shippingDate
				? moment(shippingDate, 'DD-MMM-YYYY').format('DD-MMM-YYYY')
				: null,
			arrivalDate: arrivalDate
				? moment(arrivalDate, 'DD-MMM-YYYY').format('DD-MMM-YYYY')
				: null,
			shippingItemCode: shippingItem,
			shippingType: shippingType,
			isUSBound,
			isUnknownShipment,
			weightUnit,
			dimensionUnit,
			shipmentSccs,
			appliedSccs,
			additionalSccs,
			appliedServices,
			sccCodes: removeDuplicates([
				...shipmentSccs,
				...appliedSccs,
				...additionalSccs,
				...appliedServices,
			]),
			totalWeight: looseItemsTotalWeight ? looseItemsTotalWeight : null,
			adjustedWeight: adjustedWeight,
			totalVolumeInMtrs,
			totalWeightInKg,
			actualGrossWeight,
			calculatedTotalWeight,
			actualTotalIneItemWeight,
			actualVolume,
			actualChargeableWeight,
			totalPieces,
			looseDetails: tempLooseDetails ? tempLooseDetails : looseDetails,
			isAllStackable,
			isAllTiltable,
			ULDDetails,
			chargeableWeight,
			mixLooseDetails,
			expandAccordian,
			promoCode: (priceCodeType === PRICE_CODE_TYPE.PROMO_RATE
				&& promoCode && promoCode.length) ? promoCode : null,
			spotRateCode: (priceCodeType === PRICE_CODE_TYPE.SPOT_RATE
				&& spotRateCode && spotRateCode.length) ? spotRateCode : null,
			spotRateDetails: isModifyBooking && spotRateDetails ? spotRateDetails : null,
			grossWeightInSelectedUnit: grossWeightInSelectedUnit,
			//removeCommaFromNumber(grossWeightInSelectedUnit),
			grossWeightInConvertedUnit,
			densityInSelectedUnit,
			densityInConvertedUnit,
			chargeableWeightInSelectedUnit: chargeableWeightInSelectedUnit,
			additionalInfo: additionalInfoData,
			handlingInformation: handlingInformation,
			currency:
				searchFormData && searchFormData.currency
					? searchFormData.currency
					: '',
			bookingFlightDetails: bookedFlightDetails,
			isModifyBooking: isModifyBooking,
			awbNo: awbNo,
			showOpWeight,
			unid: unidList,
			unidSccList: unidSccList,
			dgAttachementDetails: shippingItem?.code === 'DOG' || shippingItem?.code === 'CAT' ? petsAttachmentList : dgarrayListDoc,
			crates: cageList,
			deletedCageIds: deletedCageIds,
			deletedPetIds: deletedPetIds,
			cargoFlightCheck: cargoFlights,
			aviBookingDetaills: aviBookingDetaills,
			dgbookcheck: dgbookcheck,

		}
		if (type === 'continue') {
			if (
				priceCodeType === PRICE_CODE_TYPE.SPOT_RATE &&
				formData &&
				formData.spotRateCode &&
				formData.spotRateCode.length
			) {
				try {
					setIsLoading(true)
					const validateSpotRateResponse = await getValidateSpotRateResponse(
						formData
					)
					if (
						!validateSpotRateResponse.isApplicable &&
						validateSpotRateResponse.message
					) {
						updatePromoCodeError(
							MESSAGES.VALIDATE_SPOT_RATE_ERROR,
							i18n.getResource(
								'en',
								'errors',
								MESSAGES.VALIDATE_SPOT_RATE_ERROR
							)
						)
						setFooterErrorMsg(MESSAGES.VALIDATE_SPOT_RATE_ERROR)
						return
					}
				} catch (err) {
					setFooterErrorMsg('ER143')
					updatePromoCodeError('ER143', i18n.getResource('en', 'errors', 'ER143'))
					return
				} finally {
					setIsLoading(false)
				}
			}

			//////Validate Promo code//////
			if (
				priceCodeType === PRICE_CODE_TYPE.PROMO_RATE &&
				formData &&
				formData.promoCode &&
				formData.promoCode.length
			) {
				try {
					setIsLoading(true)
					const promoCodeResponse = await getPromoCodeResponse(formData)
					if (!promoCodeResponse.isApplicable && promoCodeResponse.message) {
						updatePromoCodeError(
							MESSAGES.VALIDATE_PROMO_CODE_ERROR,
							i18n.getResource(
								'en',
								'errors',
								MESSAGES.VALIDATE_PROMO_CODE_ERROR
							)
						)
						setFooterErrorMsg(
							t(MESSAGES.VALIDATE_PROMO_CODE_ERROR, {
								promoCode: formData.promoCode,
								ns: 'errors',
							})
						)
						return
					}
				} catch (err) {
					updatePromoCodeError('ER140', i18n.getResource('en', 'errors', 'ER140'))
					setFooterErrorMsg('ER140')
					return
				} finally {
					setIsLoading(false)
				}
			}

			if (formData && formData.sccCodes && formData.sccCodes.length) {
				formData.originalSccCodes = formData.sccCodes
				if (isModifyBooking) {
					let filteredSccs = formData.sccCodes.filter(function (
						scc,
						index,
						arr
					) {
						return !EXCEPTION_SCCS.includes(scc.scc)
					})
					formData.sccCodes = filteredSccs
				}
				formData.sccCodes = removeDuplicates([...formData.sccCodes, EYP_SCC])
			}
			setToSessionStorage('searchObject', getBase64Encoded(formData))
			if (searchFormData) {
				setSearchFormDataToState(formData)
			}
			if (
				shippingItem &&
				(shippingItem.product === FRESH_FORWARD_PRODUCT ||
					shippingItem.product === PHARMA_PRODUCT)
			) {
				// setPharmFormDataToState({
				//   additionalInfoData
				// })
				setToSessionStorage(
					'additionalFormData',
					getBase64Encoded(additionalInfoData)
				)
			}
			if (isModifyBooking) {
				if (bookedFlightDetails) {
					//validateBookingRequest(awbNo, formData, null)
					clearItemFromSessionStorage('bookingDetails')
					//history.push('/booking/flightlist')

					history.push({
						pathname: '/booking/flightlist',
						state: {
							modifyForm: true,
						},
					})
				} else {
					clearItemFromSessionStorage('bookingDetails')
					history.push('/booking/flightlist')
				}
			} else {
				clearItemFromSessionStorage('bookingDetails')
				history.push('/booking/flightlist')
			}
		} else if (type === 'saveDraft') {

			let unidInfoArray = [];
			formData?.unid?.filter((item, index) => {

				unidInfoArray.push({
					id: item?.id,
					unid: item?.unid,
					shippingName: item?.unidName,
					packingGroup: item?.packingGroup
				})

			})



			const unidDetails = (dgSccCode?.includes(shippingItem?.scc)) || dgbookcheck ?
				unidInfoArray
				: null
			unidDetails === null ? formData.additionalCommodityDetail = null : formData.additionalCommodityDetail = {
				dangerousGoods: dgbookcheck ? dgbookcheck : true,
				specialProvision: true,
				unidInfo: unidDetails
			}

			const specializedCommodity = []

			formData?.dgAttachementDetails?.filter(item => {

				specializedCommodity.push(
					{
						attachmentId: item?.attachmentId,
						fileName: item?.fileName,
						id: item?.id
					})
			})

			formData.specializedCommodity = specializedCommodity;

			let payload = {
				requestId: uuidv4(),
				formDraftParameters: formData,
				bookingDraft: {
					draftName: draftName,
					userId: userDetails.userId,
					draftDescription: draftName,
					agentCode: userDetails ? userDetails.agentCode : null,
					serialNumber:
						serialNumber && serialNumber !== '' ? parseInt(serialNumber) : 0,
				},
			}
			getSaveDraftResponse(payload)
			setToSessionStorage('savedDraftObject', getBase64Encoded(formData))

		} else if (type === 'draftValues') {
			let unidInfoArray = [];
			formData?.unid?.filter((item, index) => {

				unidInfoArray.push({
					id: item?.id,
					unid: item?.unid,
					shippingName: item?.unidName,
					packingGroup: item?.packingGroup
				})

			})
			const unidDetails = (dgSccCode?.includes(shippingItem?.scc)) || dgbookcheck ?
				unidInfoArray
				: null
			unidDetails === null ? formData.additionalCommodityDetail = null : formData.additionalCommodityDetail = {
				dangerousGoods: dgbookcheck,
				specialProvision: true,
				unidInfo: unidDetails
			}

			const specializedCommodity = []

			formData?.dgAttachementDetails?.filter(item => {

				specializedCommodity.push(
					{
						attachmentId: item?.attachmentId,
						fileName: item?.fileName,
						id: item?.id
					})
			})

			formData.specializedCommodity = specializedCommodity;

			const encodedDraftSearchObject = getFromSessionStorage('savedDraftObject')
			let draftSavedObject = getBase64Decoded(encodedDraftSearchObject)

			if (draftSavedObject && JSON.stringify(draftSavedObject) === JSON.stringify(formData)) {
				setNotLeave(false);
				setTimeout(() => {
					if (forInternalNav) {
						window.location.href = locationListened.pathname;
					} else {
						// setNotLeave(false)
						window.location.reload(true);
					}
				}, 1000)
			} else {
				// setNotLeave(true);
			}
		} else {
			if (formData && formData.totalWeightInKg === 0) {
				formData.totalWeightInKg = null
			}

			let unidInfoArray = [];
			formData?.unid?.filter((item, index) => {

				unidInfoArray.push({
					id: item?.id,
					unid: item?.unid,
					shippingName: item?.unidName,
					packingGroup: item?.packingGroup
				})

			})
			const unidDetails = (dgSccCode?.includes(shippingItem?.scc)) || dgbookcheck ?
				unidInfoArray
				: null
			unidDetails === null ? formData.additionalCommodityDetails = null : formData.additionalCommodityDetail = {
				dangerousGoods: dgbookcheck ? dgbookcheck : true,
				specialProvision: true,
				unidInfo: unidDetails
			}
			let payload = {
				requestId: uuidv4(),
				formTemplateParameters: formData,
				bookingTemplate: {
					templateName: templateName,
					userId: userDetails.userId,
					templateDescription: templateName,
					agentCode: userDetails ? userDetails.agentCode : null,
					serialNumber:
						serialNumber && serialNumber !== '' ? parseInt(serialNumber) : 0,
				},
			}
			if (updateTemplateView) {
				const updateTemplateResponse = await updateTemplateDetails(
					payload,
					setIsLoading
				)
				handleTemplateAction(updateTemplateResponse, false)
			} else {
				getSaveTemplateResponse(payload)
			}
		}
	}

	useEffect(() => {
		; (async () => {
			if (validateBookingResponse && validateBookingResponse.bookingDetails) {
				const bookingDetails = {
					bookingFlightDetails:
						validateBookingResponse.bookingDetails.bookingFlightDetails,
					rateDetails: validateBookingResponse.bookingDetails.rateDetails,
					shipmentDetails: validateBookingResponse.bookingDetails.shipmentDetails,
					awbNo: `${validateBookingResponse.bookingDetails.shipmentIdentifierDetails.shipmentPrefix}-${validateBookingResponse.bookingDetails.shipmentIdentifierDetails.masterDocumentNumber}`,
				}
				const encodedSearchObject = getFromSessionStorage('searchObject')
				let searchObject = null
				let redirectToFlightSearch = false
				if (encodedSearchObject && encodedSearchObject !== 'null') {
					searchObject = getBase64Decoded(encodedSearchObject)
					let sccCodes = searchObject.sccCodes
					let originalSccCodes = searchObject.originalSccCodes
					const validateSccs = validateBookingResponse.bookingDetails.sccCodes

					if (validateSccs && validateSccs.length) {
						if (validateSccs.includes(BIG_SCC.scc)) {
							const response = await checkIsBigOrOHG(searchObject, setIsLoading)
							if (response && response.bigShipment) {
								sccCodes = [...sccCodes, BIG_SCC]
								searchObject.bigShipment = true
							}
						}
						if (validateSccs.includes(OHG_SCC.scc)) {
							sccCodes = [...sccCodes, OHG_SCC]
							searchObject.overHangShipment = true
						}

						if (sccCodes.includes(FORCE_QUEUE_SCC.scc)) {
							searchObject.isForceQueued = true
						}

						if (
							originalSccCodes &&
							originalSccCodes.length > 0 &&
							searchObject
						) {
							if (
								(originalSccCodes.includes(BIG_SCC.scc) ||
									originalSccCodes.includes(OHG_SCC.scc)) &&
								!searchObject.overHangShipment &&
								!searchObject.bigShipment
							) {
								redirectToFlightSearch = true
							} else if (
								(searchObject.bigShipment || searchObject.overHangShipment) &&
								!originalSccCodes.includes(OHG_SCC.scc) &&
								!originalSccCodes.includes(BIG_SCC.scc)
							) {
								redirectToFlightSearch = true
							}
						}
					}
					sccCodes = removeDuplicates(sccCodes)
					searchObject.sccCodes = sccCodes
					setToSessionStorage('searchObject', getBase64Encoded(searchObject))
				}
				if (redirectToFlightSearch) {
					clearValidateBookingResponse()
					history.push({
						pathname: '/booking/flightlist',
					})
				} else {
					setToSessionStorage(
						'bookingDetails',
						getBase64Encoded(bookingDetails)
					)
					clearValidateBookingResponse()
					history.push({
						pathname: '/booking/summary',
						state: {
							isModifyBooking: isModifyBooking,
						},
					})
				}
			}
		})()
	}, [validateBookingResponse])

	useEffect(() => {
		if (validateBookingError) {
			const error = validateBookingError

			if (
				validateBookingError.errorCode &&
				VALIDATE_BOOKINGS_ERROR_CODES.includes(error.errorCode)
			) {
				setAlertMessage(
					`The booking could not be modified. ${error.description}`
				)
				addError(
					'VALIDATE_BOOKING_ERROR',
					`The booking could not be modified. ${error.description}`
				)
				setTemplateSuccess(false)
				window.scrollTo(0, 0)
				clearValidateBookingResponse()
			} else {
				clearItemFromSessionStorage('bookingDetails')
				clearValidateBookingResponse()
				history.push({
					pathname: '/booking/flightlist',
					state: {
						validationError: error.description,
					},
				})
			}
		}
	}, [validateBookingError])

	const [saveTempError, setSaveTempError] = useState(null)
	const [alertMessage, setAlertMessage] = useState('')
	const [templateSuccess, setTemplateSuccess] = useState(false)

	//for drafts
	const [saveDraftsError, setSaveDraftError] = useState(null)
	const [alertMessageDraft, setAlertMessageDraft] = useState('')
	const [draftSuccess, setDraftSuccess] = useState(false)

	useEffect(() => {
		handleTemplateAction(saveTemplateResponse, true)
	}, [saveTemplateResponse])

	const handleTemplateAction = (saveTemplateResponse, isSave) => {
		if (saveTemplateResponse) {
			let action = 'modified'
			if (isSave === true) {
				action = 'saved'
			}
			if (
				saveTemplateResponse.errorDetails &&
				saveTemplateResponse.errorDetails.length > 0
			) {
				setTemplateModal(false)
				setTemplateName('')
				setSaveTempError(null)
				setTemplateSuccess(false)
				if (action === 'saved') {
					setAlertMessage(t(MESSAGES.TEMP_SAVE_FAILED, { ns: 'errors' }))
					addError(
						MESSAGES.TEMP_SAVE_FAILED,
						i18n.getResource('en', 'errors', MESSAGES.TEMP_SAVE_FAILED)
					)
				} else {
					setAlertMessage(t(MESSAGES.TEMP_MODIFY_FAILED, { ns: 'errors' }))
					addError(
						MESSAGES.TEMP_MODIFY_FAILED,
						i18n.getResource('en', 'errors', MESSAGES.TEMP_MODIFY_FAILED)
					)
				}
				window.scrollTo(0, 0)
			} else if (
				saveTemplateResponse.templateNameDuplicate &&
				saveTemplateResponse.templateNameDuplicate === true &&
				isSave === true
			) {
				setSaveTempError(MESSAGES.TEMP_NAME_EXISTS_ERROR)
				addError(
					MESSAGES.TEMP_NAME_EXISTS_ERROR,
					i18n.getResource('en', 'errors', MESSAGES.TEMP_NAME_EXISTS_ERROR)
				)
			} else {
				setTemplateModal(false)
				setTemplateName('')
				setSaveTempError(null)
				if (isSave) {
					draftref.current = false
					setTemplateSuccess(true)
					action === 'saved'
						? setAlertMessage(t('SAVETMPLT.TMPLT_SAVE_SUCCESS'))
						: setAlertMessage(t('MNGTMPLT.MODIFY_SUCCESS'))
					getTemplates()
					window.scrollTo(0, 0)
					clearSaveTemplateResponse()
				} else {
					draftref.current = false
					setNotLeave(false)
					const updateStatus = {
						message:
							action === 'saved'
								? t('SAVETMPLT.TMPLT_SAVE_SUCCESS')
								: t('MNGTMPLT.MODIFY_SUCCESS'),
						success: true,
					}
					clearSaveTemplateResponse()
					history.push({
						pathname: '/manage-template',
						state: {
							updateStatus: updateStatus,
						},
					})
				}
			}
		}
	}

	const handleClear = (e) => {
		setSearchFormDataToState(null)
		setSelectedOrigin(defaultOrigin)
		setSelectedDestination(null)
		setShippingItem(null)
		// setPharmFormDataToState(null)
		setShippingDate(null)
		setArrivalDate(null)
		setLooseDetails([getInitLooseItemDetail()])
		setULDDetails([getInitULDItemDetail()])
		setMixLooseDetails([getInitLooseItemDetail()])
		setShippingType(SHIPPING_TYPE.LOOSE)
		setLooseItemsTotalWeight('')
		setError(null)
		setPromoCode('')
		setSpotRateCode('')
		setSpotRateDetails(null)
		setAllStackable(true)
		setAllTiltable(false)
		setMixLooseDetails([getInitLooseItemDetail()])
		setExpandAccordian(false)
		setWeightUnit(WEIGHT_UNIT.KILOGRAM)
		setDimensionUnit(DIMENSION_UNIT.CENTIMETERS)
		setAdditionalSccs([])
		setCalculatedTotalWeight(0)
		setActualChargeableWeight(0)
		setTotalWeightInKg(0)
		setTemplateName('')
		setSerialNumber(null)
		setTemplateView(false)
		setUpdateTemplateView(false)
		setSaveTempError(null)
		setAlertMessage('')
		setTemplateSuccess(false)
		setSaveDraftError(null)
		setAdjustedWeight(null)
		setShowOpweight(false)
		clearItemFromSessionStorage('savedDraftObject')
		setCageLists([getInitCageDetails(weightUnit, dimensionUnit)])
		setUnidList([getInitUnidDetail()])

	}


	const handleClearSeasonal = (e) => {
		setSearchFormDataToState(null)
		setSelectedOrigin(defaultOrigin)
		setSelectedDestination(null)
		setShippingItem(null)
		// setPharmFormDataToState(null)
		setShippingDate(null)
		setArrivalDate(null)
		setLooseDetails([getInitLooseItemDetail()])
		setULDDetails([getInitULDItemDetail()])
		setMixLooseDetails([getInitLooseItemDetail()])
		setShippingType(SHIPPING_TYPE.LOOSE)
		setLooseItemsTotalWeight('')
		setError(null)
		setPromoCode('')
		setSpotRateCode('')
		setSpotRateDetails(null)
		setAllStackable(true)
		setAllTiltable(false)
		setMixLooseDetails([getInitLooseItemDetail()])
		setExpandAccordian(false)
		setWeightUnit(WEIGHT_UNIT.KILOGRAM)
		setDimensionUnit(DIMENSION_UNIT.CENTIMETERS)
		setAdditionalSccs([])
		setCalculatedTotalWeight(0)
		setActualChargeableWeight(0)
		setTotalWeightInKg(0)
		setTemplateName('')
		setSerialNumber(null)
		setTemplateView(false)
		setUpdateTemplateView(false)
		setSaveTempError(null)
		setAlertMessage('')
		setTemplateSuccess(false)
		setSaveDraftError(null)
		setAdjustedWeight(null)
		setShowOpweight(false)
		clearItemFromSessionStorage('savedDraftObject')
		setCageLists([getInitCageDetails(weightUnit, dimensionUnit)])
		setUnidList([getInitUnidDetail()])
		setStartDate('')
		setEndDate('')
		setFlightList([getInitSeasonalFlightDetails()])

	}

	//======= function for draft value comparison======//

	const handleDraftValueCallback = (isSave) => {
		if (
			looseItemsTotalWeight &&
			shippingType === SHIPPING_TYPE.LOOSE &&
			calculatedTotalWeight &&
			calculatedTotalWeight * 1 !== looseItemsTotalWeight * 1
		) {
			const tempLooseDetails = JSON.parse(JSON.stringify(looseDetails)).map(
				(dimDetails) => {
					return { ...dimDetails, weight: null }
				}
			)
			setLooseDetails([...tempLooseDetails])
			initiateListFlightRequest(tempLooseDetails, 'draftValues')
		} else {
			initiateListFlightRequest(null, 'draftValues')
		}
	}

	//======= function for template saving======//

	const handleSaveTemplate = (isSave) => {
		if (isSave === true && templateName === '') {
			setSaveTempError(MESSAGES.MANDATORY_TEMP_NAME)
			return
		}
		if (
			looseItemsTotalWeight &&
			shippingType === SHIPPING_TYPE.LOOSE &&
			calculatedTotalWeight &&
			calculatedTotalWeight * 1 !== looseItemsTotalWeight * 1
		) {
			const tempLooseDetails = JSON.parse(JSON.stringify(looseDetails)).map(
				(dimDetails) => {
					return { ...dimDetails, weight: null }
				}
			)
			setLooseDetails([...tempLooseDetails])
			initiateListFlightRequest(tempLooseDetails, 'saveTemplate')
		} else {
			initiateListFlightRequest(null, 'saveTemplate')
		}
	}

	const handleSubmitDraft = (isSave) => {
		if (isSave === true && draftName === '') {
			setSaveDraftError(MESSAGES.MANDATORY_DRAFT_NAME)
			// return
		} else if (
			looseItemsTotalWeight &&
			shippingType === SHIPPING_TYPE.LOOSE &&
			calculatedTotalWeight &&
			calculatedTotalWeight * 1 !== looseItemsTotalWeight * 1
		) {
			const tempLooseDetails = JSON.parse(JSON.stringify(looseDetails)).map(
				(dimDetails) => {
					return { ...dimDetails, weight: null }
				}
			)
			setLooseDetails([...tempLooseDetails])
			initiateListFlightRequest(tempLooseDetails, 'saveDraft')
		} else {
			initiateListFlightRequest(null, 'saveDraft')
		}
	}
	//=========================================//
	const handleSubmit = (e) => {
		e.preventDefault()
		setshowValidation(true)
		const errorObj = validateFormData({
			selectedOrigin,
			selectedDestination,
			isSearchByArrivalDate,
			shippingDate,
			arrivalDate,
			shippingItem,
			unid,
			specialProvisonErrorCheck,
			hasDangerous,
			hasDangerous1,
			unidList,
			shippingType,
			totalWeight: looseItemsTotalWeight,
			adjustedWeight: adjustedWeight,
			looseDetails,
			ULDDetails,
			additionalInfoData,
			calculatedTotalWeight,
			mixLooseDetails,
			expandAccordian,
			priceCodeType,
			priceCode:
				priceCodeType === PRICE_CODE_TYPE.PROMO_RATE ? promoCode : spotRateCode,
			isUnknownShipment,
			isUSBound,
			isLiveAnimalBooking: shippingItem?.code === "DOG" || shippingItem?.code === "CAT" ?
				true : false,
			cageList: cageList,
		})

		setError(errorObj)

		if (!errorObj
			&& (sccCodeEntered === '' || typeof (sccCodeEntered) === 'object')
			&& embargoErrorCheck && specialProvisonErrorCheck) {
			setSccWarning(null)
			if (
				looseItemsTotalWeight &&
				shippingType === SHIPPING_TYPE.LOOSE &&
				calculatedTotalWeight &&
				calculatedTotalWeight * 1 !== looseItemsTotalWeight * 1
			) {
				const tempLooseDetails = JSON.parse(JSON.stringify(looseDetails)).map(
					(dimDetails) => {
						return { ...dimDetails, weight: null }
					}
				)
				setLooseDetails([...tempLooseDetails])
				initiateListFlightRequest(tempLooseDetails, 'continue')
			} else {
				initiateListFlightRequest(null, 'continue')
			}
		}
		else if (sccCodeEntered !== '' && typeof (sccCodeEntered) === 'string' && additionalSccs.length === 0) {
			setSccWarning(t(MESSAGES.VALID_ADD_SCC_CODE, { ns: 'errors' }))
			setFooterErrorMsg(
				t(MESSAGES.CORRECT_ERRORS_BFR_CONTINUE, { ns: 'errors' })
			)
		}
		else {
			setFooterErrorMsg(
				t(MESSAGES.CORRECT_ERRORS_BFR_CONTINUE, { ns: 'errors' })
			)
		}
		//set files to session storage
		//setToSessionStorage('petFiles', getBase64Encoded(file))

	}

	const [templateView, setTemplateView] = useState(false)
	const [updateTemplateView, setUpdateTemplateView] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [isCAOChecked, setIsCAOChecked] = useState(false)

	const getTemplates = () => {
		if (userDetails && !updateTemplateView && !isModifyBooking) {
			const listRequest = {
				requestId: uuidv4(),
				agentCode: userDetails.agentCode,
			}
			const listDraftsRequest = {
				requestId: uuidv4(),
				userId: userDetails.userId,
			}

			getlistTemplateNamesResponse(listRequest)
			getlistDraftsResponse(listDraftsRequest)
		}
	}

	const handleAlertClose = () => {
		setAlertMessage('')
		setTemplateSuccess(false)
		setAlertMessageDraft('')
		setDraftSuccess(false)
	}

	const handleSccChange = (e) => {
		setSccCodeEntered(e?.target?.value)
	}

	const handleSetError = (key, text) => {
		error
			? setError({
				...error,
				[key]: text,
			})
			: setError({
				[key]: text,
			})
	}

	const getMaxShippingDate = (arrivalDate) => {

		const bookingWindowEndDate = moment().add(ALLOWABLE_BOOKING_DATE - 1, 'days')
		let maxShippingDate = bookingWindowEndDate
		if (!arrivalDate) {
			return maxShippingDate.toDate()
		}

		const arrivalDateObj = moment(arrivalDate, 'DD-MM-YYYY')
		if (arrivalDateObj.isBefore(maxShippingDate)) {
			maxShippingDate = arrivalDateObj
		}
		return maxShippingDate.toDate()
	}

	const getSeasonalBookingFlightDetails = (flightData) => {
		// if (searchFormData.isModifyBooking && !selectedFlight) {
		// 	return searchFormData.bookingFlightDetails
		// } 
		// else if (selectedFlight) {
		const flightDetails = flightData.map((flight, index) => ({
			segmentOrigin: index !== 0 ? flightData[index - 1].destination.code : selectedOrigin?.code,
			segmentDestination: flight?.destination?.code,
			segmentArrivalDate: null,
			segmentDepartureDate: null,
			carrierCode: (!flight.flightNumber.toLowerCase().startsWith("ey") && flight.flightNumber.match("[a-zA-Z]{2}")) ? (flight.flightNumber[0] + flight.flightNumber[1]) : 'EY',
			flightNumber: flight.flightNumber.toLowerCase().startsWith("ey-") ? flight.flightNumber.replace(/^ey-/i, "") : flight.flightNumber.toLowerCase().startsWith("ey") ? flight.flightNumber.replace(/^ey\s*/i, "") : flight.flightNumber.match("[a-zA-Z]{2}-") ? flight.flightNumber.replace(/^[a-zA-Z]{2}-/, "") : flight.flightNumber.match("[a-zA-Z]{2}") ? flight.flightNumber.replace(/^[a-zA-Z]{2}/, "") : flight.flightNumber,
			flightDate: flight.flightDate ? moment(flight.flightDate, 'DD-MMM-YYYY').format('DD-MMM-YYYY') : null,
			allotmentId: flight?.allotmentId ? flight?.allotmentId : null
		}))
		return flightDetails
	}

	const getSccCodes = () => {
		return removeDuplicates([
			...shipmentSccs,
			...appliedSccs,
			...additionalSccs,
			...appliedServices,
		]).map((e) => e.scc)
	}


	const submitSeasonalBookingForm = () => {

		const seasonalFormData = {
			requestID: uuidv4(),
			saveMassBookingByTemplate: {
				bookingType: 'Template',
				userId: userDetails.userId,
				agentCode: userDetails.agentCode,
				fileName: selectedTemplate,
				startDate: startDate ? moment(startDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
				endDate: endDate ? moment(endDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
				frequency: dateScheduler,
				onDay: weekdays,
				// allotmentId: flightList.map(flight => flight.allotmentId).filter(id => id !== "")
			},
			bookingDetails: {
				shipmentIdentifierDetails: null,
				weightUnit: weightUnit,
				volumeUnit: "B",
				dimensionUnit: dimensionUnit,
				promoCode: (priceCodeType === PRICE_CODE_TYPE.PROMO_RATE
					&& promoCode && promoCode.length) ? promoCode : null,
				spotRateCode: (priceCodeType === PRICE_CODE_TYPE.SPOT_RATE
					&& spotRateCode && spotRateCode.length) ? spotRateCode : null,
				shipmentDetails: {
					shipmentOrigin: selectedOrigin?.code,
					shipmentDestination: selectedDestination?.code,
					shippingDate: shippingDate
						? moment(shippingDate, 'DD-MMM-YYYY').format('DD-MMM-YYYY')
						: null,
					agentCode: userDetails.agentCode ? userDetails.agentCode : null,
					customerCode: userDetails ? userDetails.customerCode : null,
					currency: "AED",
					productDetails: {
						productName: shippingItem ? shippingItem.product : null
					}
				},
				sccCodes: getSccCodes(),
				bookingCommodityDetails: [
					{
						commodityCode: shippingItem?.code,
						pieces: totalPieces,
						weight: totalWeightInKg,
						volume: Number(totalVolumeInMtrs).toFixed(2) > 0.01
							? Number(totalVolumeInMtrs).toFixed(2)
							: 0.01,
						shipmentDescription: shippingItem?.description,
						adjustedWeight: adjustedWeight
							? round(
								calculateWeightToKg(
									Number(adjustedWeight),
									searchFormData?.weightUnit
								),
								2
							)
							: 0,
					}
				],
				bookingFlightDetails: getSeasonalBookingFlightDetails(flightList),
				spotRate: null,
				allInRate: null,
				isSpotRateRequired: "N",
				specializedCommodity: [],
				additionalCommodityDetails: null,
			}
		}

		//setting dimenstion details
		if (shippingType === SHIPPING_TYPE.LOOSE) {
			seasonalFormData.bookingDetails.bookingCommodityDetails[0].dimensionDetaills = []
			looseDetails.forEach(function (element) {
				if (element.pieces > 0) {
					const weight = Number(element.weight) ? Number(element.weight).toFixed(2) : 0
					const weightType = !Number(element.weight)
						? DETAILS_APPLY_FOR.TOTAL
						: element.weightType
					let formattedItemDetail = {
						length: element.length,
						height: element.height,
						width: element.width,
						numberOfPieces: element.pieces,
						weight:
							weightType === DETAILS_APPLY_FOR.PER_ITEM
								? weight * element.pieces
								: weight,
						tiltable: !element.turnable ? 'N' : 'Y',
						stackable: !element.stackable ? 'N' : 'Y',
					}
					seasonalFormData.bookingDetails.bookingCommodityDetails[0].dimensionDetaills.push(
						formattedItemDetail
					)
				}
			})
		} else {
			seasonalFormData.bookingDetails.bookingCommodityDetails[0].uldInfoDetaills = []
			ULDDetails.forEach(function (element) {

				if (element.pieces > 0) {
					let formattedItemDetail = {
						uldType: element.uldType,
						numberOfUlds: element.pieces,
						uldWeight: element.weight,
						uldVolume: element.uldVolume,
						contour: element.contour,
					}
					seasonalFormData.bookingDetails.bookingCommodityDetails[0].uldInfoDetaills.push(
						formattedItemDetail
					)
				}
			})
			if (
				mixLooseDetails &&
				mixLooseDetails.length > 0 &&
				mixLooseDetails[0].weight !== ''
			) {
				seasonalFormData.bookingDetails.bookingCommodityDetails[0].dimensionDetaills =
					[]
				mixLooseDetails.forEach(function (element) {
					if (element.pieces > 0) {
						const weight = !Number(element.weight)
							? Number(totalWeightInKg) /
							mixLooseDetails.length
							: Number(element.weight)
						const weightType = !Number(element.weight)
							? DETAILS_APPLY_FOR.TOTAL
							: element.weightType
						let formattedItemDetail = {
							length: element.length,
							height: element.height,
							width: element.width,
							numberOfPieces: element.pieces,
							weight:
								weightType === DETAILS_APPLY_FOR.PER_ITEM
									? weight * element.pieces
									: weight,
							tiltable: !element.turnable ? 'N' : 'Y',
							stackable: !element.stackable ? 'N' : 'Y',
						}
						seasonalFormData.bookingDetails.bookingCommodityDetails[0].dimensionDetaills.push(
							formattedItemDetail
						)
					}
				})
			}
		}

		//checkErrors
		const errorObj = validateSeasonalBooking({
			seasonalFormData,
			selectedOrigin,
			selectedDestination,
			startDate,
			endDate,
			unidList,
			shippingItem,
			hasDangerous,
			hasDangerous1,
			flightList,
			shippingType,
			totalWeight: looseItemsTotalWeight,
			adjustedWeight: adjustedWeight,
			calculatedTotalWeight,
			isLiveAnimalBooking: shippingItem?.code === "DOG" || shippingItem?.code === "CAT" ?
				true : false,
			looseDetails,
			ULDDetails,
			mixLooseDetails,
			expandAccordian,
			isAllotmentRequired,
			isAllotmentRequiredFlightDate,
			dateScheduler,
			weekdays,
			indexArray
		})
		setSeasonalError(errorObj)
		setError(errorObj)
		if (!errorObj) {
			//call seasonal booking submit api
			setIsLoading(true);
			saveSeasonalBooking(seasonalFormData).then((response) => {
				if (response.data.status === 'success') {
					setInitialList()
					setListPageOpen(true)
					setIsLoading(false);

				}
			})

		}
		else {
			setIsLoading(false);
			setFooterErrorMsg(
				t(MESSAGES.CORRECT_ERRORS_BFR_CONTINUE, { ns: 'errors' })
			)
		}


		//dimension details
	}
	useEffect(() => {
		if (searchFormData) {
			setTimeout(() => {
				if (searchFormData?.dgbookcheck && shippingItem !== null) {
					setDgbookcheck(true)
				}
				if (searchFormData?.dgAttachementDetails?.length > 0) {

					let dgData = [...searchFormData?.dgAttachementDetails]
					setDgArrayListDoc(dgData)
				}
			}, 1000);
			if (searchFormData?.unid?.length > 0) {
				let unidData = [...searchFormData.unid]
				setTimeout(() => {
					setUnidList(unidData)
					if (searchFormData?.unidSccList?.length > 0) {
						setUnidSccList(searchFormData.unidSccList)
					}
				}, 800);

			}


		}
		// console.log("search", searchFormData)

	}, [searchFormData])


	//==========================================================//
	return (
		<BeforeClosePrompt
			history={history}
			isModifyBooking={isModifyBooking}
			saveDraftError={saveDraftsError}
			saveDraftResponse={saveDraftResponse}
			setSaveDraftError={setSaveDraftError}
			setAlertMessage={setAlertMessageDraft}
			setDraftSuccess={setDraftSuccess}
			notLeave={notLeave}
			setNotLeave={setNotLeave}
			acceptFunction={() => handleSubmitDraft(true)}
			setName={setDraftName}
			name={draftName}
			getTemplates={getTemplates}
			clearSaveDraftResponse={clearSaveDraftResponse}
			handleDraftValueCallback={handleDraftValueCallback}
			setLocationListened={setLocationListened}
			locationListened={locationListened}
			setForInternalNav={setForInternalNav}
			disableBeforeClosePrompt={disableBeforeClosePrompt}
			component={
				<div className='search-panel'>
					{alertMessage && alertMessage !== '' && (
						<div className='cargo-portal-container'>
							<Alert
								isError={!templateSuccess}
								showAlert={true}
								message={t(alertMessage, { ns: 'errors' })}
								setTimeout={true}
								onAlertClose={handleAlertClose}
							/>
						</div>
					)}
					{alertMessageDraft && alertMessageDraft !== '' && (
						<div className='cargo-portal-container'>
							<Alert
								isError={!draftSuccess}
								showAlert={true}
								message={t(alertMessageDraft, { ns: 'errors' })}
								setTimeout={true}
								onAlertClose={handleAlertClose}
							/>
						</div>
					)}
					<SearchHeader
						searchFormData={searchFormData}
						setNotLeave={setNotLeave}
						updateTemplateView={updateTemplateView}
						setUpdateTemplateView={setUpdateTemplateView}
						templateParam={templateParam}
						serialNo={serialNo}
						listTemplateNamesResponse={listTemplateNamesResponse}
						listDraftsResponse={listDraftsResponse}
						setSearchFormDataToState={setSearchFormDataToState}
						setAdditionalInfoData={setAdditionalInfoData}
						setIsLoading={setIsLoading}
						setTemplateView={setTemplateView}
						setTemplateName={setTemplateName}
						setSerialNumber={setSerialNumber}
						shippingItems={shippingItems}
						handleClear={handleClear}
						isModifyBooking={isModifyBooking}
						awbNo={awbNo}
						setAlertMessage={setAlertMessage}
						setTemplateSuccess={setTemplateSuccess}
						setArrivalDate={setArrivalDate}
						getlistDraftsResponse={getlistDraftsResponse}
						listQuotesResponse={listQuotesResponse}
						listQuoteError={listQuoteError}
						listTemplateNamesError={listTemplateNamesError}
						listDraftsError={listDraftsError}
						setSelectedTemplate={setSelectedTemplate}
						selectedTemplate={selectedTemplate}
						isSeasonalBooking={isSeasonalBooking}
						setInitialList={setInitialList}
						navigateBack={navigateBack}

					/>

					{isSeasonalBooking && (listTemplateNamesResponse?.templateList?.length == 0 || selectedTemplate == '') ?
						<SearchPanelMessage
							listTemplateNamesResponse={listTemplateNamesResponse}
							isSeasonalBooking={isSeasonalBooking}
							selectedTemplate={selectedTemplate}
						/> :
						<form>
							<div className='row mb-0'>
								<div
									className={`form-group col-12 col-lg-4 ${error && error[FORM_FIELDS.ORIGIN] ? 'red-border' : ''
										}`}
								>
									<label className='f-15'>{t('COMMON.SHP_FROM')}</label>
									<div class='input-wrapper f-15'>
										<AirportAutocomplete
											autoCompleteType={AUTOCOMPLETE_TYPE.ORIGIN}
											selectedOrigin={selectedOrigin}
											selectedDestination={selectedDestination}
											setSelectedOrigin={setSelectedOrigin}
											handleSetError={handleSetError}
											clearError={clearError}
											draftCallback={draftCallback}
											setNotificationMessage={setNotificationMessage}
											setNotificationType={setNotificationType}
										/>
										<span className='search-panel__errormsg'>
											{error && error[FORM_FIELDS.ORIGIN]
												? t(error[FORM_FIELDS.ORIGIN], { ns: 'errors' })
												: ''}
										</span>
									</div>
								</div>
								<div
									className={`form-group col-12 col-lg-4 ${error && error[FORM_FIELDS.DESTINATION] ? 'red-border' : ''
										}`}
								>
									<label className='f-15'>{t('COMMON.SHP_TO')}</label>
									<div class='input-wrapper f-15'>
										<AirportAutocomplete
											autoCompleteType={AUTOCOMPLETE_TYPE.DESTINATION}
											selectedOrigin={selectedOrigin}
											selectedDestination={selectedDestination}
											setSelectedDestination={setSelectedDestination}
											handleSetError={handleSetError}
											clearError={clearError}
											displaySimilarBooking={!updateTemplateView && !isModifyBooking}
											setNotLeave={setNotLeave}
											draftCallback={draftCallback}
											setNotificationMessage={setNotificationMessage}
											setNotificationType={setNotificationType}
										/>
										<span className='search-panel__errormsg'>
											{error && error[FORM_FIELDS.DESTINATION]
												? t(error[FORM_FIELDS.DESTINATION], { ns: 'errors' })
												: ''}
										</span>
									</div>
								</div>
								{isSearchByArrivalDate && !isSeasonalBooking ? (
									<div
										className={`form-group col-12 col-lg-4 calendar-group ${error && error[FORM_FIELDS.ARRIVAL_DATE] ? 'red-border' : ''
											}`}
									>
										{' '}
										<DateToggleButton
											isSearchByArrivalDate={isSearchByArrivalDate}
											setShippingDate={setShippingDate}
											setArrivalDate={setArrivalDate}
											handleSetError={handleSetError}
											setIsSearchByArrivalDate={setIsSearchByArrivalDate}
										/>
										<div class='input-wrapper f-15 col-6 col-lg-12'>
											<div className='row'>
												<Calendar
													className='form-control'
													value={moment(arrivalDate, 'DD-MMM-YYYY').toDate()}
													onChangeHandler={(arrivalDate) => {
														setArrivalDate(arrivalDate)
														if (arrivalDate) {
															const errorObj = error ? { ...error } : {}
															errorObj[FORM_FIELDS.ARRIVAL_DATE] = null
															errorObj[FORM_FIELDS.SHIPPING_DATE] = null
															setError(errorObj)
														}
													}}
													onFocusHandler={() => {
														clearError(FORM_FIELDS.ARRIVAL_DATE)
													}}
													placeholder='Arrival date'
													minDate={moment().toDate()}
													maxDate={moment()
														.add(ALLOWABLE_BOOKING_DATE + 1, 'days')
														.toDate()}
													onErrorHandler={() => {
														handleSetError(
															FORM_FIELDS.ARRIVAL_DATE,
															MESSAGES.INVALID_ARRIVAL_DATE
														)
													}}
												/>
												<span className='search-panel__errormsg'>
													{error && error[FORM_FIELDS.ARRIVAL_DATE]
														? t(error[FORM_FIELDS.ARRIVAL_DATE], { ns: 'errors' })
														: ''}
												</span>
											</div>
										</div>
									</div>
								) : (
									<></>
								)}
								{isSearchByArrivalDate && !arrivalDate ? (
									<></>
								) : !isSeasonalBooking ? (
									<div
										className={`form-group col-12 col-lg-4 calendar-group ${error && error[FORM_FIELDS.SHIPPING_DATE]
											? 'red-border'
											: ''
											}`}
									>
										{isSearchByArrivalDate ? (
											<label>
												{t('BOOKING.SEARCH_BY_ARR_DATE')}
											</label>
										) : (
											<DateToggleButton
												isSearchByArrivalDate={isSearchByArrivalDate}
												setShippingDate={setShippingDate}
												setArrivalDate={setArrivalDate}
												handleSetError={handleSetError}
												setIsSearchByArrivalDate={setIsSearchByArrivalDate}
											/>
										)}
										<div class='input-wrapper f-15 col-6 col-lg-12'>
											<div className='row'>
												<Calendar
													className='form-control'
													value={moment(shippingDate, 'DD-MMM-YYYY').toDate()}
													onChangeHandler={(shippingDate) => {
														setShippingDate(shippingDate)
													}}
													onFocusHandler={() =>
														clearError(FORM_FIELDS.SHIPPING_DATE)
													}
													placeholder='Shipping date'
													minDate={moment().toDate()}
													maxDate={maxShippingDate}
													onErrorHandler={() => {
														handleSetError(
															FORM_FIELDS.SHIPPING_DATE,
															MESSAGES.INVALID_SHIPPING_DATE
														)
													}}
												/>
												<span className='search-panel__errormsg'>
													{error && error[FORM_FIELDS.SHIPPING_DATE]
														? t(error[FORM_FIELDS.SHIPPING_DATE], { ns: 'errors' })
														: ''}
												</span>
											</div>
										</div>
									</div>
								) : <></>}
							</div>
							{isSeasonalBooking ?
								<div className='row'>
									<div className={`form-group col-12 col-lg-5}`}>
										<SchedulerMultiFlightsContainer
											startDate={startDate}
											setStartDate={setStartDate}
											endDate={endDate}
											setEndDate={setEndDate}
											flightList={flightList}
											setFlightList={setFlightList}
											flightSelectedOrigin={flightSelectedOrigin}
											setFlightSelectedOrigin={setFlightSelectedOrigin}
											flightsMaxShippingDate={flightsMaxShippingDate}
											setFlightsMaxShippingDate={setFlightsMaxShippingDate}
											flightsMinDate={flightsMinDate}
											setFlightsMinDate={setFlightsMinDate}
											isAllotmentRequired={isAllotmentRequired}
											isAllotmentRequiredFlightDate={isAllotmentRequiredFlightDate}
											setIsAllotmentRequired={setIsAllotmentRequired}
											setIsAllotmentRequiredFlightDate={setIsAllotmentRequiredFlightDate}
											indexArray={indexArray}

											schedulerMaxShippingDate={schedulerMaxShippingDate}
											setSchedulerMaxShippingDate={setSchedulerMaxShippingDate}
											schedulerMinDate={schedulerMinDate}
											setSchedulerMinDate={setSchedulerMinDate}
											weekdays={weekdays}
											setWeekdays={setWeekdays}
											dateScheduler={dateScheduler}
											setDateScheduler={setDateScheduler}

											seasonalError={seasonalError}
											setSeasonalError={setSeasonalError}
											selectedDestination={selectedDestination}

										/>
									</div>
								</div> : <></>
							}
							<div className='shipping-item-row'>
								<div className='row'>
									<div
										className={`form-group col-12 col-lg-5 ${error && error[FORM_FIELDS.SHIPPING_ITEM] ? 'red-border' : ''
											}`}
									>
										<label> {t('COMMON.SHP_ITEM')}</label>
										<div className='input-wrapper shipping-wrapper'>
											<ShippingItemAutocomplete
												fieldType='SHIPPING_ITEM'
												selectedShippingItem={shippingItem}
												setSelectedShippingItem={setShippingItem}
												error={error}
												clearError={clearError}
												setError={setError}
												shippingItems={shippingItems}
												placeholder={SHIPPING_ITEM_PLACEHOLDER}
												setDisplayShippingItemPopup={setDisplayShippingItemPopup}
												draftCallback={draftCallback}

											/>
										</div>
										<span className='search-panel__errormsg'>
											{error && error[FORM_FIELDS.SHIPPING_ITEM]
												? t(error[FORM_FIELDS.SHIPPING_ITEM], { ns: 'errors' })
												: ''}
										</span>
										{(shipmentSccs && shipmentSccs.length) ||
											(additionalSccs && additionalSccs.length) ||
											(additionalSccs && additionalSccs.length) ||
											(appliedSccs && appliedSccs.length) ? (
											<>

												<div className='form-row scc-row m-b-0'>
													<SccListDisplay
														nonRemovableSccs={[...shipmentSccs, ...appliedSccs]}
														additionalSccs={additionalSccs}
														appliedServices={appliedServices}
														handleRemoveAdditionalScc={handleRemoveScc}
														draftCallback={draftCallback}

														autoAdd={autoAdd}
													//services={services}
													/>
												</div>
											</>
										) : (
											<></>
										)}
										{(shippingItem && (shippingItem?.code === "DOG") || (shippingItem?.code === "CAT")) && aviBookingError ?

											<div className='cargo-portal-container dialog-container'>
												<div className='portal-dialog p-fluid'>
													<Dialog
														footer={<RenderFooter />}
														visible={aviBookingError}
														modal
														closable={true}
														header=''
														onHide={handleCancel}
													>
														<h3 className='mt-1 mb-4'>{t('COMMON.DATE_HEADER')}</h3>
														<p className='mt-2'>
															{
																`${t('BOOKING.PET_BOOKING_VALIDATION_TEXT', {
																	days: aviBookingDetaills[0].minBookingDays,
																})}`
															}
														</p>
													</Dialog>
												</div>
											</div>

											: <></>
										}
									</div>
									<div className={`col-12 col-lg-3 custom-padding form-group additional_scc`}>
										<label className="additional-style">{t('COMMON.ADD_SHC')}</label>
										<div class='input-wrapper'>
											<SccAutoComplete
												handleAddAdditinalScc={handleAddAdditinalScc}
												clearError={clearError}
												setSccWarning={setSccWarning}
												setNotificationMessage={setNotificationMessage}
												setNotificationType={setNotificationType}
												handleSccChange={handleSccChange}

												setAutoAdd={setAutoAdd}

											/>
										</div>
										<span className='search-panel__errormsg'>{sccWarning}</span>
									</div>
									{
										isSeasonalBooking ?
											<></> :
											<RecommendedSccList
												recommendedSccs={recommendedServices}
												handleAddAdditinalScc={handleAppliedRecommendedScc}
												services={services}
												showWarning={showWarning}
												draftCallback={draftCallback}
											/>
									}
								</div>
							</div>

							{/* Dg booking START */}


							{dgbookcheck && ((moment(shippingDate)) < (moment().add(aviBookingDetaills && aviBookingDetaills[1]?.minBookingDays, 'days').toDate())) ?


								<>

									<div className='cargo-portal-container dialog-container'>
										<div className='portal-dialog p-fluid'>
											<Dialog
												footer={<RenderFooter />}
												visible={!dgBookingError}
												modal
												closable={true}
												header=''
												onHide={handleCancel}
											>
												<h3 className='mt-1 mb-4'>{t('COMMON.DATE_HEADER')}</h3>
												<p className='mt-2'>
													{t('COMMON.DG_DATE_VAL_MSG', {
														DAYS: aviBookingDetaills[1].minBookingDays,
													})}
												</p>
											</Dialog>
										</div>
									</div>
								</>

								: <></>

							}



							{((dgSccCode?.includes(shippingItem?.scc)) || (shippingItem?.scc === "AVI") || (shippingItem?.scc === "AVI") || (shippingItem === null)) ?
								<>

									{((dgBookingError) && (shippingItem?.scc !== "AVI") && (shippingItem !== null)) ?
										<>

											< div className='cargo-portal-container dialog-container'>
												<div className='portal-dialog p-fluid'>
													<Dialog
														footer={<RenderFooter />}
														visible={dgBookingError}
														modal
														closable={true}
														header=''
														onHide={handleCancel}
													>
														<h3 className='mt-1 mb-4'>{t('COMMON.DATE_HEADER')}</h3>
														<p className='mt-2'>
															{t('COMMON.DG_DATE_VAL_MSG', {
																DAYS: aviBookingDetaills[1].minBookingDays,
															})}
															{/* {`Sorry, we usually do not accept DG bookings on flights which are within next ${aviBookingDetaills[1].minBookingDays} days. But you can reach out to local Etihad sales team, to see if they could make any exception.`} */}
														</p>
													</Dialog>
												</div>
											</div>
										</>

										: <></>
									}
								</>
								:
								<>
									<div className={gradeoutQ ? `is-disabled row additional-dg-style` : 'row additional-dg-style'} >
										<div className={`col-md form-group col-lg-4`} >
											<label className='dg-label'>{t('COMMON.VALIDATE_GOODS')}</label>
										</div>
										<div className={`col-md col-lg-4 `}>
											<div className='form-item codedg-type-btn code-type-btn-left'>
												<RadioButton
													id='dg-code-btn'
													className='select-button '
													value={"Yes"}
													onChange={(e) => {
														setDgbookcheck(true)
														setHasDangerous(true)
														setToSessionStorage('ifDg', true)


													}}
													checked={dgbookcheck}
												></RadioButton>
												<label className='dgcheck-label'>
													{t('COMMON.YES')}
												</label>

												<RadioButton
													id='dgn-code-btn'
													className='select-button'
													value={"No"}
													onChange={(e) => {

														setDgbookcheck(false)
														setUnidName('')
														setUnid('')
														{ setSpecialProvisonErrorCheck(true) }
														setUnidList([getInitUnidDetail()])
														setHasDangerous(false)
														setToSessionStorage('ifDg', false)

													}}
													checked={!dgbookcheck}
												></RadioButton>
												<label className='dgcheckn-label'> {t('COMMON.NO')}</label>
											</div>

										</div>
									</div>


								</>
							}


							{(dgSccCode?.includes(shippingItem?.scc)) || (dgbookcheck) ?
								<>
									{/* multi ui implementation */}
									{!aviBookingError && (shippingItem !== null) ?
										<>
											{setToSessionStorage('ifDg', true)}

											<DgMultiUnidContainer
												autoCompleteType={AUTOCOMPLETE_UNID.UNID}
												// displayShippingItemPopup={displayShippingItemPopup}
												// setDisplayShippingItemPopup={setDisplayShippingItemPopup}
												handleClear={handleClear}
												selectedDestination={selectedDestination}
												selectedOrigin={selectedOrigin}
												shippingDate={shippingDate}
												shippingItem={shippingItem}
												setIsLoading={setIsLoading}
												searchFormData={searchFormData}
												isModifyBooking={isModifyBooking}
												setShippingItem={setShippingItem}
												file={file}
												setFile={setFile}
												draftCallback={draftCallback}
												error={error}

												setUnidWarning={setUnidWarning}
												clearError={clearError}
												setSccWarning={setSccWarning}
												setNotificationMessage={setNotificationMessage}
												setNotificationType={setNotificationType}
												handleSccChange={handleSccChange}
												setUnidName={setUnidName}
												setUnid={setUnid}
												unid={unid}
												// unidSelectedValidation={unidSelectedValidation}
												// setUnidSelectedValidation={setUnidSelectedValidation}
												unidName={unidName}
												setSpecialProvison={setSpecialProvison}
												specialProvison={specialProvison}
												specailProvialMessage={specailProvialMessage}
												additionalSccs={additionalSccs}
												setUnidList={setUnidList}
												unidList={unidList}

												setCargoFlights={setCargoFlights}
												cargoFlights={cargoFlights}
												handleAddAdditinalScc={handleAddAdditinalScc}
												handleRemoveAdditionalScc={handleRemoveScc}
												setShipmentSccs={setShipmentSccs}
												shipmentSccs={shipmentSccs}
												setIsCAOChecked={setIsCAOChecked}
												isCAOChecked={isCAOChecked}
												setAutoAdd={setAutoAdd}
												autoAdd={autoAdd}
												setAdditionalSccs={setAdditionalSccs}

												setExceptionTypeDg={setExceptionTypeDg}
												exceptionTypeDg={exceptionTypeDg}
												setSpUnid={setSpUnid}
												appliedSccs={appliedSccs}
												setAppliedSccs={setAppliedSccs}
												unidSccList={unidSccList}
												setUnidSccList={setUnidSccList}
												unidArray={unidArray}


												additionalInfoData={additionalInfoData}
												setAdditionalInfoData={setAdditionalInfoData}
												displayDgValidationPopup={displayDgValidationPopup}
												setDisplayDgValidationPopup={setDisplayDgValidationPopup}




												popupNotificationPopup={popupNotificationPopup}
												// setUnidSelectedValidation={setUnidSelectedValidation}

												spUnid={spUnid}
												setEmbargoErrorCheck={setEmbargoErrorCheck}
												setSpecialProvisonErrorCheck={setSpecialProvisonErrorCheck}
											/>
										</>
										: <></>}
								</>
								: <></>
							}


							{/* Dg booking ENDS */}

							{/* AVI booking */}
							{(shippingItem?.code === "DOG" || shippingItem?.code === "CAT") &&
								!aviBookingError ?

								(
									<>
										<MultiplePetsContainer
											displayShippingItemPopup={displayShippingItemPopup}
											setDisplayShippingItemPopup={setDisplayShippingItemPopup}
											handleClear={handleClear}
											selectedDestination={selectedDestination}
											selectedOrigin={selectedOrigin}
											shippingDate={shippingDate}
											shippingItem={shippingItem}
											setIsLoading={setIsLoading}
											setNotificationMessage={setNotificationMessage}
											setNotificationType={setNotificationType}
											setShippingItem={setShippingItem}
											additionalSccs={additionalSccs}
											// setPetList={setPetList}
											// petList={petList}
											file={file}
											setFile={setFile}
											dogBreeds={dogBreeds}
											searchFormData={searchFormData}
											setArrayListDoc={setArrayListDoc}
											arrayListDoc={arrayListDoc}
											setCageList={setCageLists}
											cageList={cageList}
											setDeletedCageIds={setDeletedCageIds}
											deletedCageIds={deletedCageIds}
											setDeletedPetIds={setDeletedPetIds}
											deletedPetIds={deletedPetIds}
											error={error}
											setError={setError}
											weightUnit={weightUnit}
											dimensionUnit={dimensionUnit}
											setDimensionUnit={setDimensionUnit}
											setWeightUnit={setWeightUnit}
											stubNose={stubNose}
											setStubNose={setStubNose}
											breedRejected={breedRejected}
											setBreedRejected={setBreedRejected}

										/>

									</>) : null
							}

							<div className='row col-12 warning-error'>
								{warning == true ? (
									<Message
										severity='info'
										text={t(MESSAGES.ADD_SERVICE_INFO, { ns: 'errors' })}
									></Message>
								) : (
									<></>
								)}
							</div>
							{sccCombinationError || sccCompatibilityError ? (
								<div className='row'>
									<div className='col-12 warning-info mb-3'>
										{sccCombinationError ? (
											<Message
												severity='error'
												text={
													sccCombinationError
														? t(sccCombinationError, { ns: 'errors' })
														: sccCombinationError
												}
											></Message>
										) : (
											<Message
												severity='error'
												text={sccCompatibilityError}
											></Message>
										)}
									</div>
								</div>
							) : (
								<></>
							)}
							<AdditionalInfoForm
								shippingItem={shippingItem}
								appliedSccs={appliedSccs}
								setAppliedSccs={setAppliedSccs}
								additionalInfoData={additionalInfoData}
								setAdditionalInfoData={setAdditionalInfoData}
								error={error}
								setError={setError}
								setDisplayContainerPopup={setDisplayContainerPopup}
								additionalSccs={additionalSccs}
								setAdditionalSccs={setAdditionalSccs}
								sccCombinationError={sccCombinationError}
								setSccCombinationError={setSccCombinationError}
								handleAppliedAddScc={handleAppliedAddScc}
								sccCompatibility={sccCompatibility}
								setSccCompatibility={setSccCompatibility}
								setHandlingInformation={setHandlingInformation}
								handlingInformation={handlingInformation}
							/>
							{
								isUSBound ? (
									<div className='form-row mb-3 mt-3'>
										<div className='form-group col-12 col-md-4 mt-3'>
											{/* <label>Unknown Shipment</label> */}
											<div className='form-control search-panel__type grey-div pl-0 pr-0'>
												<div
													className='l-checkbox'
													id={`${isUnknownShipment == null
														? 'unknown-shipper-button'
														: ''
														}`}
												>
													{/* <input
													id={`unknown-shipemnt`}
													type='checkbox'
													checked={isUnknownShipment}
													onChange={() => setIsUnknownShipment(!isUnknownShipment)}
												/> */}
													<label htmlFor={`unknown-shipemnt`}>
														{t('BOOKING.UNKNWN_SHPR')}
													</label>
													<SelectButton

														className='custom-select-button'

														value={isUnknownShipment ? IS_KNOWN_UNKNOWN.UNKNOWN : IS_KNOWN_UNKNOWN.KNOWN}
														options={unkownShipperSelectItems}
														onChange={(e) => {
															clearError(FORM_FIELDS.UNKNOWN_SHIPMENT)
															//setInitialLoading(false)
															setIsUnknownShipment((e.value === IS_KNOWN_UNKNOWN.UNKNOWN) ? true : false)
														}}
													></SelectButton>
													<span className='search-panel__errormsg'>
														{error && error[FORM_FIELDS.UNKNOWN_SHIPMENT]
															? t(error[FORM_FIELDS.UNKNOWN_SHIPMENT], {
																ns: 'errors',
															})
															: ''}
													</span>
												</div>
											</div>
										</div>
									</div>
								) : (<></>)
							}
							{(shippingItem?.code !== "DOG" && shippingItem?.code !== "CAT") ?
								<div className='row'>
									<div className='form-group col-12' id="cargo-col-style">
										<label>{t('COMMON.CARGO')}</label>
										<SelectButton
											className='custom-select-button'
											value={shippingType}
											options={shippingTypesSelectItems}
											onChange={(e) => {
												if (e.value && !checkChanges(e.value)) {
													setShippingType(e.value)

													setDisplayDgULDItemPopup(false);

													if ((dgSccCode?.includes(shippingItem?.scc)) && e?.value === "ULD" || e?.value === "ULD" && dgbookcheck) {
														setDisplayDgULDItemPopup(true);
														setDgEnabled(true)
														setExceptionTypeDg('DG_ULD')
													}
													if (e.value === "LOOSE") {
														setExceptionTypeDg()
														setDisplayDgULDItemPopup(false);
														setDgEnabled(false)
													}
												}
											}}
										></SelectButton>
									</div>
								</div>
								: ''}
							<div className='row'>
								{(shippingItem?.code !== "DOG" && shippingItem?.code !== "CAT") ?
									<div className='form-group col-12  mb-0 '>
										{shippingType === 'LOOSE' ? (
											<LooseItemForm
												looseDetails={looseDetails}
												setLooseDetails={setLooseDetails}
												totalWeight={looseItemsTotalWeight}
												setTotalWeight={setLooseItemsTotalWeight}
												setLooseItemsGrossWeight={setLooseItemsGrossWeight}
												weightUnit={weightUnit}
												setWeightUnit={setWeightUnit}
												dimensionUnit={dimensionUnit}
												setDimensionUnit={setDimensionUnit}
												isAllStackable={isAllStackable}
												setAllStackable={setAllStackable}
												isAllTiltable={isAllTiltable}
												setAllTiltable={setAllTiltable}
												handleAddLineItem={handleAddLineItem}
												setTotalPieces={setTotalPieces}
												setTotalVolumeInMeters={setTotalVolumeInMeters}
												setChargeableWeight={setChargeableWeight}
												setChargeableWeightInSelectedUnit={setChargeableWeightInSelectedUnit}
												setTotalWeightInKg={setTotalWeightInKg}
												totalWeightInKilo={totalWeightInKg}
												totalVolumeinMtrs={totalVolumeInMtrs}
												error={error}
												setError={setError}
												calculatedTotalWeight={calculatedTotalWeight}
												setCalculatedTotalWeight={setCalculatedTotalWeight}
												setActualVolume={setActualVolume}
												setActualChargeableWeight={setActualChargeableWeight}
												setActualGrossWeight={setActualGrossWeight}
												actualTotalIneItemWeight={actualTotalIneItemWeight}
												setActualTotalIneItemWeight={setActualTotalIneItemWeight}
												draftCallback={draftCallback}
												adjustedWeight={adjustedWeight}
												setAdjustedWeight={setAdjustedWeight}
												showOpWeight={showOpWeight}
												setShowOpweight={setShowOpweight}
												AviEnabled={shippingItem?.code === "DOG" || shippingItem?.code === "CAT" ? true : false}
											/>
										) : (

											<ULDItemForm
												ULDDetails={ULDDetails}
												setULDDetails={setULDDetails}
												weightUnit={weightUnit}
												setWeightUnit={setWeightUnit}
												dimensionUnit={dimensionUnit}
												setDimensionUnit={setDimensionUnit}
												handleAddLineItem={handleAddLineItem}
												setTotalPieces={setTotalPieces}
												setTotalVolumeInMeters={setTotalVolumeInMeters}
												setTotalWeightInKg={setTotalWeightInKg}
												totalWeightInKilo={totalWeightInKg}
												setChargeableWeight={setChargeableWeight}
												setChargeableWeightInSelectedUnit={setChargeableWeightInSelectedUnit}
												error={error}
												setError={setError}
												searchFormData={searchFormData}
												mixLooseDetails={mixLooseDetails}
												setMixLooseDetails={setMixLooseDetails}
												selectedOrigin={selectedOrigin}
												expandAccordian={expandAccordian}
												setExpandAccordian={setExpandAccordian}
												setActualVolume={setActualVolume}
												setActualGrossWeight={setActualGrossWeight}
												draftCallback={draftCallback}
												setNotificationMessage={setNotificationMessage}
												setNotificationType={setNotificationType}
												DgEnabled={dgEnabled}
												setDisplayDgULDItemPopup={setDisplayDgULDItemPopup}
											/>
										)}
										{(dgSccCode?.includes(shippingItem?.scc)) || dgbookcheck && !isSeasonalBooking ?
											<>
												<div className='row dgDocAlignment'>
													<div className='form-group col-12 col-md-4 m-t-10'>

														<label className='mb-0 docstyle'>
															{t('COMMON.DG_DOCUMENTS')}
														</label>
													</div>


												</div>
												<div>
													<label className='filePetUpload' >
														{dgarrayListDoc.length > 0 ? `${t('COMMON.FILE_DG_UPLOADED')}` : `${t('COMMON.UPLOAD_RELEVANT')}`}

													</label>
													<i className="circle-padding" >
														<img
															src={infoicon}
															onClick={handelPopup}
															alt='Allowed list'
															title='Allowed list'
															className="circle-padding"

														/>
													</i>
												</div>

												{displayDglist ? (
													<div className='confirmation-dialog-infopet'>
														<Dialog
															// footer={modalFooter}
															visible={displayDglist}
															closable={false}
															modal
															icons={customClose}
															header={"Document Required"}
															className='modal-dialog '

														>
															{<>

																<div className='mt-2 mb-3 search-panel__type grey-div pl-0 pr-0'>

																	<label className='mb-0' htmlFor={`docReq9`}>
																		<li>{"DG declaration"}</li>
																	</label>

																</div>


																<div className='mt-2 mb-3 search-panel__type grey-div pl-0 pr-0'>


																	<label className='mb-0' htmlFor={`docReq2`}>
																		<li>{"License/Permit"}</li>
																	</label>

																</div>

															</>}
														</Dialog>
													</div>


												) : (
													<></>
												)}

												{/* displayDg DOC list */}

												{file?.length > 0 || dgarrayListDoc?.length > 0 ?
													<>

														<ul>
															{dgarrayListDoc?.map((element, i) => {
																const filArraypath = element?.fileName?.split('.')

																// if (index === element.petOrder) {
																return (

																	<li className="listyle">

																		<div className="listupload" key={i}>

																			<img src={filArraypath && filArraypath[1] === "pdf" ? pdf : filArraypath && filArraypath[1] === "xlsx" || filArraypath && filArraypath[1] === "xls" ? excel : filArraypath && filArraypath[1] === "docx" ? word : filArraypath && filArraypath[1] === "png" || filArraypath && filArraypath[1] === "jpeg" || filArraypath && filArraypath[1] === "jpg" ? walpaper : document} alt={filArraypath && filArraypath[1]} title={filArraypath && filArraypath[1]} className="fileicon" />
																			<span> {element.fileName}</span>
																		</div>

																		<div className='deleteicon' >

																			<span
																				onClick={(e) =>
																					handleRemovedg(element, i)}
																			>{t('COMMON.DELETE')}</span>

																		</div>
																	</li>

																)
																// }
															}

															)

															}

														</ul>
													</>

													: <></>
												}




												<div className='mt-2 mb-3 search-panel__type pl-0 pr-0'>

													<div className='row mb-0'>
														<DgdocUpload
															id={"dgUpload"}
															setDisplayUploadSection={setDisplayUploadSection}
															fileName={fileName}
															onCommodityChange={onCommodityChange}
															setOnCommodityChange={setOnCommodityChange}
															file={file}
															unid={unid}
															setUnid={setUnid}
															setFile={setFile}
															fileCount={dgarrayListDoc[0] ? true : false}
															dgarrayListDoc={dgarrayListDoc}
															searchFormData={searchFormData}
															setIsLoading={setIsLoading}

														/>
													</div>


												</div></> : <></>
										}

									</div>

									: <></>}


								<div className='form-row pl-2 m-b-0 mt-1 mb-1'>
									<div className='form-item  code-type-btn'>
										<RadioButton
											id='promo-code-btn'
											className='select-button radio-btn'
											value={PRICE_CODE_TYPE.PROMO_RATE}
											onChange={(e) => {
												// setPromoCode(spotRateCode)
												// setSpotRateCode(null)
												setPriceCodeType(e.value)
											}}
											checked={priceCodeType === PRICE_CODE_TYPE.PROMO_RATE}
										></RadioButton>
										<label className='promo-label'>
											{t('BOOKING.PROMO_CODE')}
										</label>
										<RadioButton
											id='spot-code-btn'
											className='select-button radio-btn'
											value={PRICE_CODE_TYPE.SPOT_RATE}
											onChange={(e) => {
												// setSpotRateCode(promoCode)
												// setPromoCode(null)
												setPriceCodeType(e.value)
											}}
											checked={priceCodeType === PRICE_CODE_TYPE.SPOT_RATE}
										></RadioButton>
										<label className='spot-label'>{t('BOOKING.SPOT_ID')}</label>
									</div>
								</div>
								<div className='form-group col-12 promo-div mt-2'>
									<div
										className={`search-panel__promo  ${error && error[FORM_FIELDS.PRICE_CODE] ? 'red-border' : ''
											}`}
									>
										<input
											type='text'
											className='form-control form-label-styling'
											placeholder={`${priceCodeType === PRICE_CODE_TYPE.PROMO_RATE
												? 'Enter promo code (optional)'
												: 'Enter spot ID (optional)'
												}`}
											value={
												priceCodeType === PRICE_CODE_TYPE.PROMO_RATE
													? promoCode
													: spotRateCode
											}
											onChange={({ target }) => {
												if (!target) return
												if (
													(target.value && target.value.length > 20) ||
													target.value.trim().split(' ').length > 1
												) {
													handleSetError(
														FORM_FIELDS.PRICE_CODE,
														`Enter valid ${priceCodeType === PRICE_CODE_TYPE.PROMO_RATE
															? 'promo code'
															: 'spot id'
														}`
													)
												} else {
													handleSetError(FORM_FIELDS.PRICE_CODE, null)
												}
												if (priceCodeType === PRICE_CODE_TYPE.PROMO_RATE) {
													setPromoCode(target.value.toUpperCase())
												} else {
													setSpotRateCode(target.value)
												}
											}}
										/>
										<span className='search-panel__errormsg'>
											{error && error[FORM_FIELDS.PRICE_CODE]
												? error[FORM_FIELDS.PRICE_CODE]
												: null}
										</span>
									</div>
								</div>
							</div>
							<SearchSummary
								totalPieces={totalPieces}
								weightUnit={weightUnit}
								dimensionUnit={dimensionUnit}
								totalWeightInKg={totalWeightInKg}
								totalVolumeInMtrs={totalVolumeInMtrs}
								chargeableWeight={chargeableWeight}
								looseDetails={looseDetails}
								shippingType={shippingType}
								totalWeight={looseItemsTotalWeight}
								looseItemsGrossWeight={looseItemsGrossWeight}
								calculatedTotalWeight={calculatedTotalWeight}
								actualGrossWeight={actualGrossWeight}
								actualTotalIneItemWeight={actualTotalIneItemWeight}
								actualVolume={actualVolume}
								actualChargeableWeight={actualChargeableWeight}
								setGrossWeightInSelectedUnit={setGrossWeightInSelectedUnit}
								setGrossWeightInConvertedUnit={setGrossWeightInConvertedUnit}
								setDensityInSelectedUnit={setDensityInSelectedUnit}
								setdensityInConvertedUnit={setdensityInConvertedUnit}
								chargeableWeightInSelectedUnit={chargeableWeightInSelectedUnit}
								cageList={cageList}
								shippingItem={shippingItem}
								AviEnabled={shippingItem?.code === "DOG" || shippingItem?.code === "CAT" ? true : false}
								cages={cageList}
							/>

							{footerErrorMsg ? (
								<div className='row'>
									<div className='col-12 warning-info mb-3'>
										<Message
											severity='error'
											text={t(footerErrorMsg, { ns: 'errors' })}
										></Message>
									</div>
								</div>
							) : (
								<></>
							)}
							<div className='form-row search-panel__footer'>
								{/* 	<div className='form-group col-9 search-panel__voice'></div>*/}
								{isSeasonalBooking ?
									<SeasonalButtonGroup
										handleSubmit={submitSeasonalBookingForm}
										handleClearSeasonal={handleClearSeasonal}
										setSelectedTemplate={setSelectedTemplate}
										setNotLeave={setNotLeave}
									/>
									:
									<SearchButtonGroup
										setNotLeave={setNotLeave}
										setSelectedTemplate={setSelectedTemplate}
										selectedTemplate={selectedTemplate}
										handleClear={handleClear}
										updateTemplateView={updateTemplateView}
										templateView={templateView}
										templateName={templateName}
										setTemplateName={setTemplateName}
										handleSubmit={handleSubmit}
										handleSaveTemplate={handleSaveTemplate}
										templateModal={templateModal}
										setTemplateModal={setTemplateModal}
										saveTempError={saveTempError}
										setSaveTempError={setSaveTempError}
										isModifyBooking={isModifyBooking}
										awbNo={awbNo}
										stubNose={stubNose}
										breedRejected={breedRejected}
										isSeasonalBooking={isSeasonalBooking}
										setIsLoading={setIsLoading}
									/>
								}
							</div>
						</form>}
					{displayContainerPopup ?
						(
							<ContainerValidationPopUp
								additionalInfoData={additionalInfoData}
								setAdditionalInfoData={setAdditionalInfoData}
								displayContainerPopup={displayContainerPopup}
								setDisplayContainerPopup={setDisplayContainerPopup}
								handleClear={handleClear}
								selectedDestination={selectedDestination}
								selectedOrigin={selectedOrigin}
								shippingDate={shippingDate}
								shippingItem={shippingItem}
								setIsLoading={setIsLoading}
								setNotificationMessage={setNotificationMessage}
								setNotificationType={setNotificationType}
								additionalSccs={additionalSccs}
							/>
						) : (
							<></>
						)}


					{displayShippingItemPopup ?

						(shippingItem?.code !== 'DOG') && (shippingItem?.code !== 'CAT') && (shippingItem?.scc !== 'AVI') && !(dgSccCode?.includes(shippingItem?.scc)) && (shippingItem !== null) ?
							<>
								(
								<ShippingItemValidationPopUp
									displayShippingItemPopup={displayShippingItemPopup}
									setDisplayShippingItemPopup={setDisplayShippingItemPopup}
									handleClear={handleClear}
									selectedDestination={selectedDestination}
									selectedOrigin={selectedOrigin}
									shippingDate={shippingDate}
									shippingItem={shippingItem}
									setIsLoading={setIsLoading}
									setNotificationMessage={setNotificationMessage}
									setNotificationType={setNotificationType}
									setShippingItem={setShippingItem}
									additionalSccs={additionalSccs}
								/>
								)
							</>
							: (
								(shippingItem?.code !== 'DOG') && (shippingItem?.code !== 'CAT') && (shippingItem?.scc === 'AVI') ?
									<>
										(
										<AviShippingItemValidationPopUp
											displayShippingItemPopup={displayShippingItemPopup}
											setDisplayShippingItemPopup={setDisplayShippingItemPopup}
											handleClear={handleClear}
											selectedDestination={selectedDestination}
											selectedOrigin={selectedOrigin}
											shippingDate={shippingDate}
											shippingItem={shippingItem}
											setIsLoading={setIsLoading}
											setNotificationMessage={setNotificationMessage}
											setNotificationType={setNotificationType}
											setShippingItem={setShippingItem}
											additionalSccs={additionalSccs}
										/>
										)
									</> : <></>
							) :
						<></>
					}


					{(displayDgULDItemPopup) &&
						((dgSccCode?.includes(shippingItem?.scc) || dgbookcheck)) ?
						(
							<UldValidationPopupdg

								setDisplayDgULDItemPopup={setDisplayDgULDItemPopup}
								displayDgULDItemPopup={displayDgULDItemPopup}
								handleClear={handleClear}
								selectedDestination={selectedDestination}
								selectedOrigin={selectedOrigin}
								shippingDate={shippingDate}
								shippingItem={shippingItem}
								setIsLoading={setIsLoading}
								setNotificationMessage={setNotificationMessage}
								setNotificationType={setNotificationType}
								additionalSccs={additionalSccs}
								popupNotificationPopup={popupNotificationPopup}
								// setUnidSelectedValidation={setUnidSelectedValidation}
								spUnid={spUnid}
								specialProvison={specialProvison}

								exceptionTypeDg={exceptionTypeDg}

								setExceptionTypeDg={setExceptionTypeDg}
								// setSpUnid={setSpUnid}
								unidList={unidList}
								setShippingType={setShippingType}
							/>
						)
						:
						<></>

					}

					{/* Booking Restriction Popup */}
					{bookingRestrictionError && (
						<div className="cargo-portal-container dialog-container">
							<div className="portal-dialog p-fluid">
								<Dialog
									footer={
										<RenderFooter
											onConfirmClick={onCloseRussianResPopup}
										/>
									}
									visible={bookingRestrictionError}
									modal
									closable={true}
									header=""
									onHide={onCloseRussianResPopup}
								>
									<h3 className="mt-1 mb-4">{t("COMMON.SP_VALID_RESTRICTED")}</h3>
									<div className="mt-2 restriction-content" dangerouslySetInnerHTML={{ __html: errorMessage }}>
									</div>
								</Dialog>
							</div>
						</div>
					)}

					{isLoading ? <LoadingIndicator /> : <></>}
				</div >
			}
		/>
	)
}

export default SearchPanel
