import React, { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import './popup.scss'

const Popup = ({
	display,
	header,
	message,
	errorDataList,
	primaryBtnLbl,
	acceptFn = () => { },
	secondaryBtnLbl,
	rejectFn,
	closeErrorPopup,
	icon,
	openOnAccept,
	onHide = () => { },
}) => {

	const footer = (
		<div className='popup-modal'>
			<button
				className='primary-button mr-4'
				id="button-first-ok"
				type='button'
				onClick={(e) => {
					acceptFn(e)
					if (!openOnAccept) {
						closeErrorPopup()
					}
				}}
			>{primaryBtnLbl ? primaryBtnLbl : 'Ok'}</button>
			{secondaryBtnLbl && rejectFn ? (
				<button
					className='secondary-button'
					id="button-first-cancel"
					type='button'
					onClick={() => {
						rejectFn()
						closeErrorPopup()
					}}
				>{secondaryBtnLbl}</button>) : null}
		</div>
	)

	return display ? (
		<>
			<div className='portal-popup-modal'>
				<Dialog
					footer={footer}
					visible
					closable={false}
					modal
					header={header}
					onHide={onHide}
					className='dialogWrapper'
				>
					<div>
						<p>{message}</p>
						{errorDataList &&
							errorDataList
						}
					</div>
				</Dialog>
			</div>
		</>
	) : (
		<></>
	)
}

export default Popup
