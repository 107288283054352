
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { InputText } from 'primereact/inputtext';
import { v4 as uuidv4 } from 'uuid'
// import PaginatorComponent from '../../Pagination/PaginatorComponent'
 import LoadingIndicator from '../../LoadingIndicator'
import { getAwbDetails } from '../../../services/csnDashboardService'
import FilterPopup from './filterPopup/filterPopup';
import getCurrentUser from '../../../utils/getCurrentUser';
import getPrincipal from '../../../utils/getPrincipal'
import moment from 'moment';
import './awb-summary.scss';

const AwbSummary = ({
    flightData,
    setProducts,
    products,
    showLoader,
    setShowLoader,
    setAwbRefreshed,
    awbRefreshed,
    setAwbFilter,
    awbFilter,
    hasSelfFiler,
    setHasSelfiler,
    setFilterValues,
    setFlightFilter,
    clearFlightFilter,
    getInitialFilter,
    awbCount,
    setAwbCount,
    isFlightCancelled,
    setIsFlightCancelled
}) => {
    // const [products, setProducts] = useState([]);
    const [expandedRows, setExpandedRows] = useState({});
    const isMounted = useRef(false);
    // const rowCount = 10;
    const [initialSearch, setInitialSearch] = useState(null)
    // const [pageIndex, setPageIndex] = useState(1)
    // const [currentPage, setCurrentPage] = useState(0)
    // const [totalRecordCount, setTotalRecordCount] = useState(0)
    // const [calculatedPageNumber, setCalculatedPageNumber] = useState(1)
    //const [showLoader, setShowLoader] = useState(true)
    const [filterSummary, setFilterSummary] = useState({});
    const [maxDate, setMaxDate] = useState(null);
    const [pageReset,setPageReset] = useState(true);

    const [count1, setCount1] = useState(1)
    const [count2, setCount2] = useState(1)
    const [count3, setCount3] = useState(1)
    const [count4, setCount4] = useState(1)
    const [count5, setCount5] = useState(1)
    const [count6, setCount6] = useState(1)
    const [count7, setCount7] = useState(1)
    
    const op = useRef(null);
    const ops = useRef(null);
    let userDetails = getCurrentUser();
    let principal = getPrincipal()
    

    useEffect(() => {
        if (isMounted.current) {
            const summary = expandedRows !== null ? 'All Rows Expanded' : 'All Rows Collapsed';
        }
       // expandedRows ? setExpanderState('expander') : setExpanderState('')
    }, [expandedRows]);

    useEffect(() => {
        if (products) {
            setExpandedRows({})
            /*setTimeout(() => {
                const expandedItems = []
                products.forEach((item) => {
                    if(item && item.customStatus !== 'APPROVED' && item.hawb && item.hawb.length > 0) {
                        expandedItems.push(item)
                    }
                })
                addExpandedRows(expandedItems)
            }, 300);*/
            if (clearFlightFilter) {
                setFlightFilter(null)
                setFilterValues(getInitialFilter())
            }
        }
    }, [products]);

    const getProducts = async (reqObj) => {
        setShowLoader(true)
        setProducts([])
        setAwbCount(0)
        try {
            const res = await getAwbDetails(reqObj)
            setExpandedRows({})
            setProducts(res.data.awbDetails);
            setAwbCount(res?.data?.bookingCount)
            setHasSelfiler(res?.data?.hasSelfFiler)
            setIsFlightCancelled(res?.data?.isFlightCancelled);
            //setLastUpdated(res.data.lastRefreshedTime)
            //setNextUpdated(res.data.nextRefreshTime)
            // setTotalRecordCount(res.data.pagination.totalRecordCount)
            setShowLoader(false)
        } catch (err) {
            console.log(err)
            setShowLoader(false);
        }
    }
    useEffect(() => {
        setShowLoader(true);
        let reqObj = setRequestObj()
        getProducts(reqObj);
        isMounted.current = true;
        setInitialSearch(false)
        // setProducts(data.RESPONSE);
    }, [])

    useEffect(() => {
        if (initialSearch) {
            setPageIndex(1)
            setCurrentPage(0)
        }
    }, [initialSearch])

    //uncomment this code for auto expansion of awb list in the initial load 
    // useEffect(() => {
    // if(products.length > 0){
    //     let expandedObj = {}
    //     products.forEach((data) => {
    //         if(data.hawb?.length > 0){
    //             let setObject = {};
    //             let value = data.id
    //             setObject[value] = true;
    //             Object.assign(expandedObj,setObject);
    //             //getExpander(setObject)
    //         }
    //     })
    //     setExpandedRows(expandedObj)
    // }
    // },[products])

    const handleColumnSort = (header) =>{
        let count = 0;
        if(header == "AWBNO"){
            count = count1
        }
        else if(header == "ORIGIN"){
            count = count2
        }
        else if(header == "DESTINATION"){
            count = count3
        }
        else if(header == "BOOKING"){
            count = count4
        }
        else if(header == "CUSTOMS"){
            count = count5
        }
        else if(header == "ACKNOWLEDGEMENT"){
            count = count6
        }
        else if(header == "NOTIFICATION"){
            count = count7
        }

        let sortValues ={
            "sortBy" : header ,
            "sortOrder": count%2==0 ? "DESC" : "ASC"
        }
        let reqObj = setRequestObj(filterSummary,sortValues);
        getProducts(reqObj);
        //setShowLoader(true);
        //setPageReset(1)
    }

    const formatDate = (date) => {
        if(date){
            let datearray = date.split("-");
            let newdate = datearray[1] + '/' + datearray[0] + '/' + datearray[2];
            let dateObject = new Date(newdate);
            return moment(dateObject).format('DD-MMM-YYYY');
        }
    }

    const formatDateWithSpace = (date) => {
        if(date){
            return moment(date).format('DD MMM YYYY');
        }
        else{
            return ''
        }
    }

    // const setRequestObj = (filterData,pageNumber,sortColumn) => {
        const setRequestObj = (filterData,sortColumn) => {

        let reqObj = {
                "requestId": uuidv4(),
                "agentCode": userDetails ? userDetails.agentCode : principal ? principal.sub : null,
                "flightNo": flightData?.flightNo,
                "carrierCode": flightData?.carrierCode,
                "userID": userDetails ? userDetails.userId : principal ? principal.sub : null,
                "departureDate": flightData?.departureDate,
                "arrivalDate":flightData?.arrivalDate,
                "origin": flightData?.origin,
                "destination":flightData?.destination,
                "flightDate":flightData?.flightDate,
                departureDateTimeUTC: flightData?.departureDateTimeUTC,
                arrivalDateTimeUTC: flightData?.arrivalDateTimeUTC,
                fwbSearch: flightData?.fwbPresent,
                // "pagination": {
                //   "pageNumber": pageNumber ? pageNumber : calculatedPageNumber,
                //   "pageSize": 10
                // },
            }

        let reqData = {
            ...reqObj,
            ...filterData,
            ...sortColumn
        }
        if (filterData && Object.keys(filterData).length > 0){
            return reqData
        } else if (awbFilter) {
            reqData = {
                ...reqObj,
                ...awbFilter,
                ...sortColumn,
            }
            return reqData;
        } else {
            return reqObj
        }
    }


    const handleFilterAction = (filterValues) => {
        setAwbFilter(filterValues)
        setFilterSummary(filterValues);
        let reqObj = setRequestObj(filterValues);
        getProducts(reqObj);
        //setShowLoader(true);
       // setPageReset(1)
    }


    // const onPageIndexChange = (newPageIndex, currentPageToDisplay) => {
    //     setPageIndex(newPageIndex)
    //     setCurrentPage(currentPageToDisplay)
    //     setInitialSearch(false)
    //     setCalculatedPageNumber(newPageIndex)
    //     let reqObj = setRequestObj(filterSummary,newPageIndex)
    //     getProducts(reqObj);
    //     setShowLoader(true);
    //     setPageReset(false);
    // }
    const getAwbNumber = (rowData) => {
        return (
            <div id={`awbNo-${rowData.awbNo}`} className='col-awbNo'>
                {rowData.awbNo}
                {rowData.selfFiler &&
                 <sup className='awb-sup'>*</sup>
                }
            </div>
        );
    }

    const getBookingStatus = (rowData) => {
        let statusClassName = '' 
        let statusValue = ''
        switch(rowData.bookingStatus) {
            case 'CONFIRMED': 
            statusClassName = 'status-finalized'
            statusValue = 'Confirmed'
            break;
            case 'CANCELLED' :
            statusClassName = 'status-onhold'
            statusValue = 'Cancelled'
            break;
            case 'QUEUED' :
            statusClassName = 'no-information'
            statusValue = 'Queued'
            break; 
            default: ''
            statusClassName = 'def'
        }
        return (
            <div className={`sign-post-container`}>
                <div className={`sign-post ${statusClassName}`} >
                    {statusValue}
                </div>
            </div>
        );
    }

    const getCustomsStatus = (rowData, awbNo) => {
        let statusClassName = '' 
        let statusValue = ''
        let statusCode = ''
        switch(rowData.customStatus) {
            case 'APPROVED': 
            statusClassName = 'status-finalized'
            statusValue = rowData.customsMessage !== null ? `${rowData.customsMessage}` : null
            statusCode = 'Cleared'
            break;
            case 'ONHOLD' :
            statusClassName = 'status-onhold'
            statusValue = rowData.customsMessage !== null ? `${rowData.customsMessage}` : null
            statusCode = 'On hold'
            break;
            case 'REQUIRED_INFORMATION' :
            statusClassName = 'no-information'
            statusValue = rowData.customsMessage !== null ? `${rowData.customsMessage}` : 'CSN not recieved'
            statusCode = 'In progress'
            break; 
            case 'NO_INFORMATION' :
            statusClassName = 'blue-status'
            statusValue = rowData.customsMessage !== null ? `${rowData.customsMessage}` : null
            statusCode = 'No information'
            break; 
            case 'MISMATCH' :
            statusClassName = 'yellow-status'
            statusValue = rowData.customsMessage !== null ? `${rowData.customsMessage} ${getMismatchData(rowData)}` : null
            statusCode = 'Mismatch'
            break; 
            default: ''
            statusClassName = 'def'
        }
        return (
            <div className={`sign-post-container`}>

                <div className={`tooltip-${rowData.awbNo ? rowData.awbNo : `${awbNo}-${rowData.hawb}`} sign-post ${statusClassName}`} >
                    {statusCode}
                </div>
                {(statusValue || rowData.ociDetails) &&
                    <div className="sign-post-info">{statusValue}
                        {
                            rowData.ociDetails && rowData.ociDetails !== '' && (statusCode === 'On hold' || statusCode === 'On hold') &&
                            <>
                                {statusValue && <span> - </span>}
                                <span className='oci-details'>{rowData.ociDetails}</span>
                            </>
                        }
                    </div>
                }

            </div>
        );
    }

    const getMismatchData = (rowData) => {
        if (rowData.piecesMismatch && rowData.weightMismatch) {
            return '(Weight & Pieces)'
        } else if (rowData.piecesMismatch) {
            return '(Pieces)'
        } else if (rowData.weightMismatch) {
            return '(Weight)'
        } else {
            return ''
        }
    }

    const getFMAFNAStatus = (rowData, awbNo) => {
        let statusClassName = ''
        let statusValue = ''
        let statusCode = ''
        switch (rowData.fmaFnaAckStatus) {
            case 'FMA_RECEIVED':
                statusClassName = 'status-finalized'
                statusValue = rowData.customsMessage !== null ? `${rowData.customsMessage}` : null
                statusCode = 'FMA received'
                break;
            case 'FNA_RECEIVED':
                statusClassName = 'status-onhold'
                statusValue = rowData.customsMessage !== null ? `${rowData.customsMessage}` : null
                statusCode = 'FNA received'
                break;
            case 'NOT_ACK':
                statusClassName = 'no-information'
                statusValue = rowData.customsMessage !== null ? `${rowData.customsMessage}` : 'CSN not recieved'
                statusCode = 'Not ack'
                break;
            default: ''
                statusClassName = 'def'
        }
        return (
            <div className={`sign-post-container`}>
                <div className={`sign-post ${statusClassName}`} >
                    {statusCode}
                </div>
            </div>
        );
    }

    const getHSCodeStatus = (rowData, awbNo) => {
        let statusClassName = ''
        let statusValue = ''
        let statusCode = ''
        switch (rowData.hsCodeStatus) {
            case 'VALID':
                statusClassName = 'status-finalized'
                statusValue = rowData.customsMessage !== null ? `${rowData.customsMessage}` : null
                statusCode = 'Valid'
                break;
            case 'INVALID':
                statusClassName = 'status-onhold'
                statusValue = rowData.customsMessage !== null ? `${rowData.customsMessage}` : null
                statusCode = 'Invalid'
                break;
            default: ''
                statusClassName = 'def'
        }
        return (
            <div className={`sign-post-container`}>
                <div className={`sign-post ${statusClassName}`} >
                    {statusCode}
                </div>
            </div>
        );
    }

       const getAcknowledgementStatus = (rowData) => {
      
        let statusClassName = '' 
        let statusValue= ''
        switch(rowData.acknowledgementStatus) {
            case 'ACKNOWLEDGED': 
            statusClassName = 'status-finalized'
            statusValue = 'Ack by GHA'
            break;
            case 'NOTACKNOWLEDGED' :
            statusClassName = 'status-onhold'
            statusValue = 'Not ack by GHA'
            break;
            case 'NO_INFORMATION' :
            statusClassName = 'no-information'
            statusValue = 'No information'
            break; 
            case 'ESCACK' :
            statusClassName = 'blue-status'
            statusValue = 'Escln ack by ACI'
            break; 
            default: ''
            statusClassName = 'def'
            break;
         }
        return (
            <div className={`sign-post-container`}>
                <div className={`sign-post ${statusClassName}`} >
                    {statusValue}
                </div>
            </div>
        );
    }

    const getNotificationStatus = (rowData) => {
      
        let statusClassName = '' 
        let statusValue = ''
        switch(rowData.notificationStatus) {
            case 'NOTIFIED': 
            statusClassName = 'status-finalized'
            statusValue = 'Notified'
            break;
            case 'REMINDER' :
            statusClassName = 'no-information'
            statusValue = 'Reminder'
            break;
            case 'ESCALATION' :
            statusClassName = 'status-onhold'
            statusValue = 'Escalation'
            break; 
            default: ''
            statusClassName = 'def'
        }
        

        return (
            <div className={`sign-post-container`}>
                <div className={`sign-post ${statusClassName}`} >
                    {statusValue}
                </div>
            </div>
        );
    }

    const getAWBNoHeader = () => {
        return (
            <div>
                <span>AWB No</span>
                <i className= {(count1>1)? (count1%2 === 0 ? 'sort-icon pi pi-sort-amount-up-alt' : 'sort-icon pi pi-sort-amount-down') : 'sort-icon pi pi-sort-alt'}  onClick={(e) => {
                    e.preventDefault();
                    setCount1(count1+1)
                    setCount2(1)
                    setCount3(1)
                    setCount4(1)
                    setCount5(1)
                    setCount6(1)
                    setCount7(1)
                    handleColumnSort("AWBNO")}}></i>
               
            </div>
        )
    }

    const getOriginHeader = () => {
        return (
            <div>
                <span>Org</span>
                <i className={(count2>1)? (count2%2 === 0 ? 'sort-icon pi pi-sort-amount-up-alt' : 'sort-icon pi pi-sort-amount-down') : 'sort-icon pi pi-sort-alt'} onClick={(e) => {
                    e.preventDefault();
                    setCount2(count2+1)
                    setCount1(1)
                    setCount3(1)
                    setCount4(1)
                    setCount5(1)
                    setCount6(1)
                    setCount7(1)
                    handleColumnSort("ORIGIN")}}></i>
            </div>
        )
    }

    const getDestinationHeader = () => {
        return (
            <div>
                <span>Dest</span>
                <i className={(count3>1)? (count3%2 === 0 ? 'sort-icon pi pi-sort-amount-up-alt' : 'sort-icon pi pi-sort-amount-down') : 'sort-icon pi pi-sort-alt'} onClick={(e) => {
                    e.preventDefault();
                    setCount3(count3+1)
                    setCount1(1)
                    setCount2(1)
                    setCount4(1)
                    setCount5(1)
                    setCount6(1)
                    setCount7(1)
                    handleColumnSort("DESTINATION")}}></i>
            </div>
        )
    }

    const getBookingStatusHeader = () => {
        return (
            <div>
                <span>Booking status</span>
                {/* <i className={(count4>1)? (count4%2 === 0 ? 'sort-icon pi pi-sort-amount-up-alt' : 'sort-icon pi pi-sort-amount-down') : 'sort-icon pi pi-sort-alt'} onClick={(e) => {
                    e.preventDefault();
                    setCount4(count4+1)
                    setCount1(1)
                    setCount2(1)
                    setCount3(1)
                    setCount5(1)
                    setCount6(1)
                    setCount7(1)
                    handleColumnSort("BOOKING")}}></i> */}
            </div>
        )
    }

    const getCustomsStatusHeader = () => {
        return (
            <div>
                <span>Customs status</span>
                {/* <i className={(count5>1)? (count5%2 === 0 ? 'sort-icon pi pi-sort-amount-up-alt' : 'sort-icon pi pi-sort-amount-down') : 'sort-icon pi pi-sort-alt'} onClick={(e) => {
                    e.preventDefault();
                    setCount5(count5+1)
                    setCount1(1)
                    setCount2(1)
                    setCount3(1)
                    setCount4(1)
                    setCount6(1)
                    setCount7(1)
                    handleColumnSort("CUSTOMS")}}></i> */}
            </div>
        )
    }

    const getFMAFNAStatusHeader = () => {
        return (
            <div>
                <span>FMA/FNA status</span>
            </div>
        )
    }

    const getHSCodeStatusHeader = () => {
        return (
            <div>
                <span>HS code status</span>
            </div>
        )
    }

    const getAcknowledgementStatusHeader = () => {
        return (
            <div>
                <span>Acknowledgement status</span>
                {/* <i className={(count6>1)? (count6%2 === 0 ? 'sort-icon pi pi-sort-amount-up-alt' : 'sort-icon pi pi-sort-amount-down') : 'sort-icon pi pi-sort-alt'} onClick={(e) => {
                    e.preventDefault();
                    setCount6(count6+1)
                    setCount1(1)
                    setCount2(1)
                    setCount3(1)
                    setCount4(1)
                    setCount5(1)
                    setCount7(1)
                    handleColumnSort("ACKNOWLEDGEMENT")}}></i> */}
            </div>
        )
    }

    const getNotificationStatusHeader = () => {
        return (
            <div>
                <span>Notification status</span>
                {/* <i className={(count7>1)? (count7%2 === 0 ? 'sort-icon pi pi-sort-amount-up-alt' : 'sort-icon pi pi-sort-amount-down') : 'sort-icon pi pi-sort-alt'} onClick={(e) => {
                    e.preventDefault();
                    setCount7(count7+1)
                    setCount1(1)
                    setCount2(1)
                    setCount3(1)
                    setCount4(1)
                    setCount5(1)
                    setCount6(1)
                    handleColumnSort("NOTIFICATION")}}></i> */}
            </div>
        )
    }

    const getExpander = (data) => {
        let setObject = {};
        let value = data.id
        setObject[value] = true;

        return (
            <div>
                {
                    data.hawb && data.hawb.length > 0?
                    <i className={expandedRows[data.id] ? 
                        "sort-icon pi pi-angle-down chevron-icon": "sort-icon pi pi-angle-right chevron-icon"} 
                        onClick={() => handleExpandedRow(data)}>
                    </i>
                    : ''
                 }
            </div>
        );
    }
    const addExpandedRows = (expandedItems) => {
        let expandedRows_ = { ...expandedRows }
        if (expandedItems && expandedItems.length > 0) {
            expandedItems.forEach((item) => {
                expandedRows_[item.id] = true;
            })
            setExpandedRows(expandedRows_);
        }
    } 

    const handleExpandedRow = (data, expandDefault) => {
        // setTotalHawbList(data.hawb);
        // setPageList(data.hawb.slice(0,20))
        //localStorage.setItem('hawbPageList',JSON.stringify(data.hawb.slice(0,20)));
        if(expandedRows[data.id] === true){
            if(!expandDefault) {
            let expandedRows_ = {...expandedRows}
            delete expandedRows_[data.id];
            setExpandedRows(expandedRows_);
            }
        }
        else{
            let expandedRows_ = {...expandedRows}
            expandedRows_[data.id] = true;
            setExpandedRows(expandedRows_);
        }
    }

    // const loadMore = () => {
    //     if (records === totalHawbList.length) {
    //       setHasMore(false);
    //     } else {
             
    //          let totalHawbListSliced = totalHawbList.slice(records, records + itemsPerPage)
            
    //          let updatedList = pageList.concat(totalHawbListSliced)
    //          setPageList(updatedList)
    //         setrecords(records + itemsPerPage);
    //         console.log(records+itemsPerPage,'count')
    //     }
    // };

    // const showItems = (data) => {
    //     console.log(data,'dataai')
    //     var items = [];
    //     for (var i = 0; i < records; i++) {
    //       items.push(
    //         <div className="post row" key={data[i].id}>
    //           <h3 className='col'>{data[i].hawb}</h3>
    //           <h3 className='col'></h3>
    //           <h3 className='col'></h3>
    //           <h3 className='col'>{getBookingStatus(data[i])}</h3>
    //           <h3 className='col'>{getCustomsStatus(data[i])}</h3>
    //           <h3 className='col'>{getAcknowledgementStatus(data[i])}</h3>
    //           <h3 className='col'>{getNotificationStatus(data[i])}</h3>
    //           {/* <p>{posts[i].body}</p> */}
    //         </div>
    //       );
    //     }
    //     return items;
    //   };



    const rowExpansionTemplate = (data) => {
        return (
            <div className="csn-table hawb-subtable">
                {/* <InfiniteScroll
                    pageStart={0}
                    loadMore={loadMore}
                    hasMore={hasMore}
                    loader={<h4 style={{textAlign: 'center'}}className="loader">Loading...</h4>}
                    useWindow={true}
                    > */}
                    {/* {showItems(data.hawb)} */}
                    <DataTable value={data.hawb} responsiveLayout="scroll">
                        <Column field="hawb" className="awbNum hawbNum" />
                        <Column field="origin" className='awbOrigin' />
                        <Column field="destination" className='awbDest'/>
                        <Column field="customStatus" className='awbCustomStatus' body={(e) => getCustomsStatus(e, data.awbNo)} />
                        <Column field="fmaFnaStatus" className='fmaFnaStatus' body={(e) => getFMAFNAStatus(e, data.awbNo)} />
                        <Column field="hsCodeStatus" className='hsCodeStatus' body={(e) => getHSCodeStatus(e, data.awbNo)} />
                        <Column field="bookingStatus" className='awbBookingStatus' body={(e) => getBookingStatus(e)} />
                        <Column field="acknowldegmentStatus" body={(e) => getAcknowledgementStatus(e)} className="awbAckStatus"  />
                        <Column field="notificationStatus" body={(e) => getNotificationStatus(e)}  className='notifStatus' />
                        <Column className="awbExpander" align="right" />
                    </DataTable>
                {/* </InfiniteScroll> */}
            </div>
        );
    }

    const rowClassName = (data) => {
        return {
            'awb-row': data.awbNo
        }
    }


    return (
        <>
            <div className="csn-table datatable-rowexpansion-demo awb-status-table">
                <div className="card">
                    <div className='d-flex flex-row'>
                    {flightData.carrierCode && flightData.flightNo &&
                        <h3>{`${flightData.carrierCode + flightData.flightNo} - ${formatDateWithSpace(flightData.departureDate)} 
                    (${flightData.origin}-${flightData.destination})`} </h3>
                    }
                    {isFlightCancelled && <div className='flight-status-card'>Cancelled</div>}
                    </div>
                    <div class='row '>
                        <div class='col col-8 pl-4'>
                        {!flightData?.fwbPresent ?

                        (<FilterPopup 
                            op={op}
                            setFilterSummary={setFilterSummary}
                            handleFilterAction={handleFilterAction}
                            maxDate={maxDate}
                            formatDate={formatDate}
                            pageReset={pageReset}
                            setAwbRefreshed={setAwbRefreshed}
                            awbRefreshed={awbRefreshed}
                            setAwbFilter={setAwbFilter}
                            awbFilter={awbFilter}
                        />) : (<div className='mt-5'></div>)
                        }
                        </div>

                        <div class={`col col-4 pr-4 ${flightData?.fwbPresent ? 'mt-4' : ''}`}>
                            <div className='awb-count'>
                                <p>MAWB count: {awbCount}</p>
                            </div>
                            {hasSelfFiler &&
                                <div className='awb-discalimers mt-2'><sup>*</sup>Self filer</div>
                            }
                        </div>
                    </div>
                    <DataTable value={products} expandedRows={expandedRows} className="table"  onRowToggle={(e) => setExpandedRows(e.data)}
                        responsiveLayout="scroll" rowExpansionTemplate={rowExpansionTemplate} dataKey="id" rowClassName={rowClassName}>
                        <Column field="awbNo" header={getAWBNoHeader()} className="awbNum" body={(e) => getAwbNumber(e)} />
                        {/* <Column field="agentNo" header="Agent" /> */}
                        <Column field="origin" header={getOriginHeader()} className='awbOrigin' />
                        <Column field="destination" header={getDestinationHeader()} className='awbDest'/>
                        <Column field="customStatus" header={getCustomsStatusHeader()} body={(e) => getCustomsStatus(e)} className='awbCustomStatus' />
                        <Column field="fmaFnaStatus" header={getFMAFNAStatusHeader()} body={(e) => getFMAFNAStatus(e)} className='fmaFnaStatus' />
                        <Column field="hsCodeStatus" header={getHSCodeStatusHeader()} body={(e) => getHSCodeStatus(e)} className='hsCodeStatus' />
                        <Column field="bookingStatus" header={getBookingStatusHeader()} body={(e) => getBookingStatus(e)} className='awbBookingStatus'/>
                        <Column field="acknowldegmentStatus" header={getAcknowledgementStatusHeader()} body={(e) => getAcknowledgementStatus(e)} className="awbAckStatus" />
                        <Column field="notificationStatus" header={getNotificationStatusHeader()} body={(e) => getNotificationStatus(e)} className='notifStatus'/>
                        <Column field="hawb" className="awbExpander" align="right" body={(e) => getExpander(e)}  />
                        {/* <Column expander className="awbExpander" align="right"  /> */}
                       
                    </DataTable>
                </div>
            </div>

              {/* <div className='paginationWrapper'> 
                <PaginatorComponent
                    initialSearch={initialSearch}
                    currentPage={currentPage}
                    pageIndex={pageIndex}
                    rowCount={rowCount}
                    totalRecords={totalRecordCount}
                    onPageIndexChange={onPageIndexChange}
                />
         </div> */}
            {products && products.length > 0 ? (<></>)
            :  (!showLoader ? <p className='data-table-empty'>There is no data that matches those criteria</p> : <></>)
            }
            <div>
            {showLoader ?
                <LoadingIndicator /> : <></>
            } 
            </div>
        </>
    );
}

export default AwbSummary;
