import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
	SHIPPING_TYPE,
	FORM_FIELDS,
} from '../../../constants'
import { SelectButton } from 'primereact/selectbutton'
import LooseItemForm from './SubSections/LooseItemForm'
import ULDItemForm from './SubSections/ULDItemForm'
import Popup from '../../Popup/Popup'
import closeIcon from '../../../assets/images/icon-close-alert.png'
import { useTranslation } from 'react-i18next'
import MESSAGES from '../../../constants/Messages'

const AdditionalCargoDetails = ({
	awbDetails,
	setAwbDetails,
	isAllStackable,
	setAllStackable,
	isAllTiltable,
	setAllTiltable,
	handleAddLineItem,
	error,
	setError,
	setCalculatedTotalWeight,
	setActualTotalIneItemWeight,
	setTotalPivoteWeightInKg,
	totalPivoteWeightInKg,
	showDetails,
	setShowDetails,
	setNotificationMessage,
	setNotificationType,
	handleWeightChange,
}) => {

	const { t, i18n } = useTranslation(['labels', 'errors'])

	const SHIPPING_TYPE_SELECT_ITEMS = [
		{ label: t('COMMON.LOOSE'), value: SHIPPING_TYPE.LOOSE },
		{ label: t('COMMON.ULD'), value: SHIPPING_TYPE.ULD },
	]

	const history = useHistory()
	//const [shippingType, setShippingType] = useState(SHIPPING_TYPE.LOOSE)
	let { ULDDetails, mixLooseDetails, looseDetails } = awbDetails
	let shippingType = awbDetails.shippingType
	const [popupDetails, setPopupDetails] = useState(null)

	useEffect(() => {}, [history])

	useEffect(() => {}, [])

	const handleShowDetails = () => {
		setShowDetails(!showDetails)
	}

	const checkChanges = (selectedCargoType) => {
		let findChanges = 0
		if (selectedCargoType) {
			if (selectedCargoType == SHIPPING_TYPE.ULD) {
				looseDetails.some((item) => {
					Object.keys(item).some(function (k) {
						if (item[k] && k === 'pieces') {
							showTabPopup(selectedCargoType)
							findChanges++
						}
					})
				})
			} else {
				ULDDetails.some((item) => {
					Object.keys(item).some(function (k) {
						if (item[k] && k === 'pieces') {
							showTabPopup(selectedCargoType)
							findChanges++
						}
					})
				})
				mixLooseDetails.some((item) => {
					Object.keys(item).some(function (k) {
						if (item[k] && k === 'pieces') {
							showTabPopup(selectedCargoType)
							findChanges++
						}
					})
				})
			}
		}
		if (findChanges > 0) {
			return true
		} else {
			return false
		}
	}

	const setShippingType = (val) => {
		setAwbDetails((prevState) => ({
			...prevState,
			shippingType: val,
		}))
	}

	const showTabPopup = (selectedCargoType) => {
		const header = t('COMMON.CONFIRM')
		const message =
			shippingType && SHIPPING_TYPE.LOOSE === shippingType
				? t(MESSAGES.BKG_TAB_MESSAGE_LOOSE, { ns: 'errors' })
				: t(MESSAGES.BKG_TAB_MESSAGE_ULD, { ns: 'errors' })
		const primaryBtnLbl = t('COMMON.OK')
		const acceptFn = () => {
			onTabOkclick(selectedCargoType)
		}
		const secondaryBtnLbl = t('COMMON.CANCEL')
		const rejectFn = () => {
			onTabCancelclick()
		}
		setPopupDetails({
			display: true,
			header: header,
			message: message,
			primaryBtnLbl: primaryBtnLbl,
			acceptFn: acceptFn,
			secondaryBtnLbl: secondaryBtnLbl,
			rejectFn: rejectFn,
		})
	}

	const onTabOkclick = (selectedCargoType) => {
		closeErrorPopup()
		setShippingType(selectedCargoType)
	}
	const onTabCancelclick = () => {
		closeErrorPopup()
	}

	const closeErrorPopup = () => {
		setPopupDetails(null)
	}

	return (
		<div className='form-row cutom-details'>
			<div className='col-12 col-md-12'>
				{!showDetails && (
					<div className='form-row'>
						<div className='col-12'>
							<button
								className='link'
								style={{ background: 'transparent' }}
								onClick={handleShowDetails}
							>
								{showDetails
									? t('CAPTURE_AWB.ADD_CARGO_DTLS')
									: t('CAPTURE_AWB.ADD_CARGO_DTLS')}
							</button>
						</div>
					</div>
				)}
				{showDetails && (
					<div className='mt-md-2 mt-0'>
						<div className='form-row'>
							<div className='col-12'>
								<h3
									className='section-sub-heading'
									onClick={handleShowDetails}
									style={{ cursor: 'pointer' }}
								>
									{t('CAPTURE_AWB.ADD_CARGO_DTLS')}
								</h3>
							</div>
						</div>
						<div className='cargo-details'>
							<button className='close-icon' onClick={handleShowDetails}>
								<i>
									<img src={closeIcon} alt='close' title='close' />
								</i>
							</button>
							<div className='form-row'>
								<div className='form-group col-12 col-lg-4'>
									<label className='form-item__label'>
										{t('COMMON.CARGO')}
									</label>
									<SelectButton
										className='custom-select-button shipping-type'
										id='loose-portal'
										value={shippingType}
										options={SHIPPING_TYPE_SELECT_ITEMS}
										onChange={(e) => {
											if (e.value && !checkChanges(e.value)) {
												setShippingType(e.value)
												setError((prevState) => ({
													...prevState,
													[FORM_FIELDS.LOOSE_ITEMS]: null,
												}))
											}
										}}
									></SelectButton>
								</div>
							</div>
							<div className='form-row'>
								<div className='form-group col-12 col-lg-12'>
									{shippingType === 'LOOSE' ? (
										<LooseItemForm
											awbDetails={awbDetails}
											setAwbDetails={setAwbDetails}
											isAllStackable={isAllStackable}
											setAllStackable={setAllStackable}
											isAllTiltable={isAllTiltable}
											setAllTiltable={setAllTiltable}
											handleAddLineItem={handleAddLineItem}
											setActualTotalIneItemWeight={setActualTotalIneItemWeight}
											setCalculatedTotalWeight={setCalculatedTotalWeight}
											setError={setError}
											error={error}
											totalWeightInKilo={awbDetails.totalWeightInKg}
											handleWeightChange={handleWeightChange}
										/>
									) : (
										<ULDItemForm
											handleAddLineItem={handleAddLineItem}
											totalWeightInKilo={awbDetails.totalWeightInKg}
											setTotalPivoteWeightInKg={setTotalPivoteWeightInKg}
											totalPivoteWeightInKg={totalPivoteWeightInKg}
											error={error}
											setError={setError}
											setActualTotalIneItemWeight={setActualTotalIneItemWeight}
											awbDetails={awbDetails}
											setAwbDetails={setAwbDetails}
											setNotificationMessage={setNotificationMessage}
											setNotificationType={setNotificationType}
										/>
									)}
								</div>
							</div>
							<div id='promo-code' className='form-row promo-div-awb'>
								<div className='col-12 col-lg-3 form-item form-item--text'>
									<label className='form-item__label'>
										{t('BOOKING.PROMO_CODE')}
									</label>
									<input 
										type='text' 
										className='form-item__field'									
										value={awbDetails.promoCode ? awbDetails.promoCode.toUpperCase() : ''}
										onChange={({ target }) => {	
											if (!target) return											
											setAwbDetails((prevState) => ({
												...prevState,
												promoCode: target.value.toUpperCase(),
											}))
										}}
									 />
								</div>
							</div>
							{popupDetails && popupDetails.display && (
								<Popup
									display={popupDetails.display}
									header={popupDetails.header}
									message={popupDetails.message}
									primaryBtnLbl={popupDetails.primaryBtnLbl}
									acceptFn={popupDetails.acceptFn}
									secondaryBtnLbl={popupDetails.secondaryBtnLbl}
									rejectFn={popupDetails.rejectFn}
									closeErrorPopup={closeErrorPopup}
								/>
							)}
						</div>
					</div>
				)}
				<div className='pt-md-2 pt-1 pb-md-3 p-2 section-seperator'>
					<hr />
				</div>
			</div>
		</div>
	)
}

export default AdditionalCargoDetails
