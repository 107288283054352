import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Popup from '../Popup/Popup'
import { useTranslation } from 'react-i18next'
const SearchButtonGroup = ({
	updateTemplateView,
	templateView,
	templateName,
	setTemplateName,
	handleSubmit,
	handleSaveTemplate,
	templateModal,
	setTemplateModal,
	saveTempError,
	setSaveTempError,
	isModifyBooking,
	awbNo,
	setNotLeave,
	setSelectedTemplate,
	handleClear,
	stubNose,
	breedRejected,
	isSeasonalBooking,
}) => {
	/*for multilingual*/
	const { t, i18n } = useTranslation(['labels', 'errors'])

	const history = useHistory()

	const handleCancelAction = () => {
		handleClear()
		history.push('/manage-template')
	}

	const handleClosePopUp = () => {
		setTemplateModal(false)
		setTemplateName('')
		setSaveTempError(null)
	}

	const handleSaveTemplateClick = () => {
		setTemplateModal(true)
	}

	const handleBackBtn = () => {
		history.push(`/manage-booking/${awbNo}`)
	}

	const handleClearForm = (e) => {
		handleClear()
		setSelectedTemplate('')
		setNotLeave(false)
	}

	return (
		<div className='col-12'>
				<div className='button-footer-panel cta_wrap flex-column flex-lg-row'>
					<div className={"cta_wrap " + (!updateTemplateView && isModifyBooking ? 'modifyBooking' : '')}>
						{!updateTemplateView && isModifyBooking && (
							<button
								type='button'
								className='modifyBookingBack secondary-button secondary-button--large custom-mr-3'
								onClick={handleBackBtn}
							>
								{t('COMMON.BACK_BUTTON')}
							</button>
						)}
						{!updateTemplateView && (
							<button
								type='button'
								className='primary-button'
								onClick={handleSubmit}
								disabled={stubNose}
							>
								{t('COMMON.CONT')}
							</button>
						)}
						{updateTemplateView && (
							<>
								<button
									type='button'
									className='primary-button'
									onClick={(e) => {
										handleSaveTemplate(false)
									}}
								>
									{t('MNGTMPLT.SAVE_CHANGES')}
								</button>
								<button
									type='button'
									className='mobile-mt-2 custom-ml-3 secondary-button'
									onClick={handleCancelAction}
								>
									{t('COMMON.CANCEL')}
								</button>
							</>
						)}
						{!templateView && !isSeasonalBooking && (
							<button
								type='button'
								className='mobile-mt-2 custom-ml-3 secondary-button'
								onClick={handleSaveTemplateClick}
							>
								{t('BOOKING.SAVE_TEMPLATE')}
							</button>
						)}
						<Popup
							display={templateModal}
							primaryBtnLbl={t('COMMON.SAVE')}
							secondaryBtnLbl={t('COMMON.CANCEL')}
							rejectFn={handleClosePopUp}
							icon='noIcon'
							header={t('SAVETMPLT.SAVE_TEMPLATE')}
							acceptFn={(e) => {
								handleSaveTemplate(true)
							}}
							closeErrorPopup={handleClosePopUp}
							openOnAccept={true}
							message={
								// <div>
								// 	<AutoComplete
								// 		value={templateName ? templateName : ''}
								// 		suggestions={[]}
								// 		completeMethod={''}
								// 		onChange={(e) => {
								// 			setSaveTempError(null)
								// 			setTemplateName(e.value)
								// 		}}
								// 		placeholder={'Enter template name'}
								// 	/>
								// 	<br />
								// 	<span className='search-panel__errormsg mt-2'>
								// 		{saveTempError && saveTempError !== '' ? saveTempError : ''}
								// 	</span>
								// </div>
								<div style={{ width: '90%' }}>
									<input
										type='text'
										placeholder={t('SAVETMPLT.TMPLT_NAME_PLACEHOLDER')}
										className={`form-item__field_template`}
										value={templateName ? templateName : ''}
										onChange={(e) => {
											setSaveTempError(null)
											setTemplateName(e.target.value)
										}}
									/>
									<br />
									<span className='search-panel__errormsg mt-2'>
										{saveTempError && saveTempError !== ''
											? t(saveTempError, { ns: 'errors' })
											: ''}
									</span>
								</div>
							}
						/>
					</div>
					<div className='mobile-mt-2' style={{ display: 'flex', alignItems: 'center' }}>
						<a className='clear-button' onClick={handleClearForm}>
							<span>{t('COMMON.CLR_ALL')}</span>
						</a>
					</div>
				</div>
			</div>
	)
}

export default SearchButtonGroup
