import React, { useCallback, useState, useEffect, useRef } from 'react'
import moment from 'moment'
import './week-selector.scss'
import LoadingIndicator from "../../LoadingIndicator";
import { t } from 'i18next';
import '../../../services/scheduleService'
import { Dropdown } from '../../../common/DropDown'

const WeekSelector = ({
  saveExcelData,
  handleBack,
  setStartDate,
  setEndDate,
  weekData,
  setWeekData,
  setMonth,
  month,
}) => {

  const [file, setFile] = useState(null);
  const [week, setWeek] = useState();
  const [weeks, setWeeks] = useState([]);
  const [months, setMonths] = useState([]);


  const MONTH_SELECTOR_OPTION = [
    { label: 'January', value: '01' },
    { label: 'February', value: '02' },
    { label: 'March', value: '03' },
    { label: 'April', value: '04' },
    { label: 'May', value: '05' },
    { label: 'June', value: '06' },
    { label: 'July', value: '07' },
    { label: 'August', value: '08' },
    { label: 'September', value: '09' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' },
  ]

  const WEEK_SELECTOR_OPTION = [
    { label: 'Week 1', value: 'Jan' },
    { label: 'Week 2', value: 'Feb' },
    { label: 'Week 3', value: 'Mar' },
  ]


  function getWeeks(month) {
    let day1 = `${month}-01`;
    const numberOfDaysMonth = moment(day1, 'YYYY-MMM-DD').daysInMonth()
    let weeks = [{ label: `Select week`, value: `` }]
    for (var i = 1; i <= numberOfDaysMonth; i = i + 6) {
      let dayStr = `${month}-` + i;
      if (i < 10) {
        dayStr = `${month}-` + '0' + i;
      }
      const today = moment(dayStr, 'YYYY-MMM-DD')
      const from_date = moment(today.startOf('week')).add(1, 'days')
      const to_date = moment(today.endOf('week')).add(1, 'days');
      const startWeek = `${from_date.format('DD')} ${from_date.format('MMM')}`
      const endWeek = `${to_date.format('DD')} ${to_date.format('MMM')}`

      const weekVal = {
        fromDate: from_date.format('DD-MMM-YYYY'),
        toDate: to_date.format('DD-MMM-YYYY')
      }
      const week = { label: `${startWeek} to ${endWeek}`, value: JSON.stringify(weekVal) }
      weeks.push(week)
    }
    const result = weeks.filter((obj, index) => {
      return index === weeks.findIndex(o => obj.value === o.value);
    });

    return result;
  }

  function getMonths() {
    let months = []
    for (let i = 0; i <= 12; i++) {
      let today = moment()
      const dateVar = today.add(i, "months");
      months.push({
        label: dateVar.format('MMMM yyyy'),
        value: dateVar.format('yyyy-MMM'),
      })
    }
    return months
  }


  const handleSubmit = () => {
    saveExcelData()
  }

  const handleMonth = (month) => {
    setWeek('')
    setWeekData(null)
    setMonth(month)
    setWeeks(getWeeks(month))
  }

  const handleWeek = (val) => {
    const value = JSON.parse(val)
    setWeek(val)
    setWeekData(value)
    let startDateVal = moment(value.fromDate)
    startDateVal = startDateVal.subtract(1, "days");
    startDateVal = startDateVal.format("DD-MMM-YYYY");
    setStartDate(startDateVal)
    setEndDate(value.toDate)
  }

  useEffect(() => {
    let defaultDate = moment()
    let month = defaultDate.format('yyyy-MMM')

    setMonth(month)
    setWeeks(getWeeks(`${month < 10 ? '0' + month : month}`))
    setMonths(getMonths())
  }, [])

  return (
    <>
      <div>
        <div className='form-row form-group'>
          <div className='col col-12 col-md-4 form-item'>
            <Dropdown
              value={month}
              options={months}
              label=''
              onChange={(e) => {
                handleMonth(e.target.value)
              }}
              isUpdate={true}
              label='Select a month'
            ></Dropdown>
          </div>
          <div className='col col-12 col-md-4 form-item'>
            <Dropdown
              value={week}
              options={weeks}
              label=''
              onChange={(e) => {
                handleWeek(e.target.value)
              }}
              isUpdate={true}
              label='Select a week'
            ></Dropdown>
          </div>

        </div>
        <div className='form-row form-group mt-md-5 pl-lg-1'>
          <div className='col col-12 col-md-3 col-lg-1 cta_wrap mr-lg-5 ml-lg-2'>
            <button className='primary-button next-action' onClick={handleSubmit} disabled={week && week !== '' ? false : true}>
              {t('COMMON.CONT')}
            </button>
          </div>
          <div className='col col-12 col-md-2 col-log-2 cta_wrap'>
            <button className='secondary-button' onClick={handleBack}>
              {t('COMMON.BACK_BUTTON')}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}



export default WeekSelector
